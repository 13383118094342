import React from "react";
import { useStore } from "../../../store";
import { variables } from "../../../cssInJs";
import { ErrorMessage, Loading } from "components";
import clsx from "clsx";
import env from "../../../env";
import * as yup from "yup";
import utils from "../../../utils";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import CircularProgress from "@material-ui/core/CircularProgress";
import LensIcon from "@material-ui/icons/Lens";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {
  //AppBar,
  //Avatar,
  //Tab,
  //Tabs,
  Toolbar,
  lighten,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  //TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  //Checkbox,
  TextField,
  IconButton,
  Tooltip,
  ButtonGroup,
  Button,
  Backdrop,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  //DialogActions,
  //ListItemIcon,
  //TextField,
  //Dialog,
  //Select,
  //DialogTitle,
  //List,
  //ListItem,
  //ListItemText,
  //DialogTitle,
  //DialogContent,
  //DialogContentText,
  //Box,
  //Grid,
  //Backdrop,
} from "@material-ui/core";

export function Dashboard(props) {
  
  return (
    <div>
      
        Test DAsh board
      
    </div>
  );
}