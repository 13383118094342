import React from "react";
import { variables } from "../../../cssInJs";
import { useParams } from "react-router-dom";
import env from "../../../env";
import { ErrorMessage, Loading } from "components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStore } from "../../../store";
import clsx from "clsx";
import * as yup from "yup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FilterListIcon from "@material-ui/icons/FilterList";
//import SearchIcon from "@material-ui/icons/Search";
//import DescriptionIcon from "@material-ui/icons/Description";
//import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import RefreshIcon from "@material-ui/icons/Refresh";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  //AppBar,
  //Avatar,
  //Tab,
  //Tabs,
  Toolbar,
  lighten,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  //TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  //Checkbox,
  TextField,
  //IconButton,
  Tooltip,
  ButtonGroup,
  Button,
  Backdrop,
  //FormControl,
  //InputLabel,
  //OutlinedInput,
  //InputAdornment,
  //DialogActions,
  //ListItemIcon,
  //TextField,
  //Dialog,
  //Select,
  //DialogTitle,
  //List,
  //ListItem,
  //ListItemText,
  //DialogTitle,
  //DialogContent,
  //DialogContentText,
  //Box,
  //Grid,
  //Backdrop,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    //minWidth: 750,
  },
  infoIcon: {
    cursor: "pointer",
    color: variables.colorBlue2,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 500,
    color: "#fff",
    //position: "absolute",
  },
  textField: {
    width: "-webkit-fill-available",
  },
  viewicon: {
    cursor: "pointer",
  },
  checkbox: {
    //backgroundColor : 'yellow',
    //accent-color: #9b59b6;
    //fill : 'yellow'
    "& path": {
      fill: "orange",
    },
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 auto",
  },
}));

const useSearchPanelStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "& > *": {
      //margin: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
  },
  textField: {
    //width: "-webkit-fill-available",
    //margin: theme.spacing(1),
    //width: `calc(33.3% - ${theme.spacing(2)}px)`,
  },
  formControl: {
    //width: `calc(33.3% - ${theme.spacing(2)}px)`,
    width: "-webkit-fill-available",
    display: "inline-flex",
    margin: theme.spacing(1),
  },
}));

const useETHeadStyles = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: variables.colorBlue3,
    "& .MuiTableCell-head": {
      color: "white",
    },
    "& .MuiIconButton-label": {
      color: "white",
    },
    "& .MuiTableSortLabel-root:hover": {
      color: "white",
    },
    "& .MuiTableSortLabel-root": {
      lineHeight: "1.1",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: "white",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon":
      {
        color: "white",
      },
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { handleFilter, reloadFunction, inputType } = props;

  return (
    <Toolbar className={clsx(classes.root)}>
      <Typography
        className={classes.title}
        //variant="h6"
        id="tableTitle"
        component="div"
        style={{ fontSize: "18px" }}
      >
        House Team Reports
      </Typography>

      <ButtonGroup size="small" aria-label="small outlined button group">
        {/* <Tooltip title="Create Belt Size" arrow>
            <Button component={Link} to={`/dashboard/belt-tying-video/new`}>
              <AddIcon fontSize="small" />
            </Button>
          </Tooltip> */}

        {inputType !== "tablet" && (
          <Tooltip title="Filters" arrow>
            <Button onClick={handleFilter}>
              <FilterListIcon fontSize="small" />
            </Button>
          </Tooltip>
        )}

        {inputType !== "tablet" && (
          <Tooltip title="Reload" arrow>
            <Button onClick={reloadFunction}>
              <RefreshIcon fontSize="small" />
            </Button>
          </Tooltip>
        )}

        {/* <Tooltip title="Active" arrow>
            <Button onClick={activeStatus}>
              <CheckCircleOutlineIcon fontSize="small" />
            </Button>
          </Tooltip>
         
          <Tooltip title="In Active" arrow>
            <Button onClick={inActiveStatus}>
              <RemoveCircleIcon fontSize="small" />
            </Button>
          </Tooltip>
          
          <Tooltip title="Delete" arrow>
            <Button onClick={deleteItems}>
              <DeleteIcon fontSize="small" />
            </Button>
          </Tooltip> */}
      </ButtonGroup>
    </Toolbar>
  );
};

const SearchPanel = (props) => {
  const classes = useSearchPanelStyles();
  const { terms, setValue, trigger, termId, handleChangeTerm } = props;

  //const { termId } = getValues();
  const term = (terms || []).find((x) => x.termId === termId) || null;

  return (
    <form
      method="POST"
      noValidate=""
      className={classes.root}
      //onSubmit={handleSubmit(submit)}
    >
      <Autocomplete
        id="termId"
        options={terms || []}
        className={classes.formControl}
        classes={{
          option: classes.option,
        }}
        autoHighlight
        getOptionLabel={(option) => option.termName}
        value={term}
        getOptionSelected={(option, value) => option.termId === value.termId}
        onChange={(event, newValue) => {
          if (newValue) {
            setValue("termId", newValue ? newValue.termId : null);
            trigger("termId");
            handleChangeTerm();
          }
        }}
        renderOption={(option) => (
          <React.Fragment>{option.termName}</React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Term"
            variant="outlined"
            size="small"
            //error={!!errors.term}
            //helperText={errors?.term?.message}
            inputProps={{
              ...params.inputProps,
              //autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
      />
    </form>
  );
};

const headCells = [
  {
    id: "HouseTeamName",
    numeric: false,
    disablePadding: false,
    label: "House Team Name",
    hasSort: false,
  },
  {
    id: "Logo",
    numeric: false,
    disablePadding: false,
    label: "Logo",
    hasSort: false,
  },
  {
    id: "Students",
    numeric: false,
    disablePadding: false,
    label: "Students",
    hasSort: false,
  },
  {
    id: "Points",
    numeric: false,
    disablePadding: false,
    label: "Points",
    hasSort: false,
  },
];

const EnhancedTableHead = (props) => {
  const classes = useETHeadStyles();
  const { orderBy, order, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headCells.map((headCell) => {
          if (headCell.hasSort) {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            );
          } else {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
              >
                {headCell.label}
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};

const schema = yup.object().shape({
  termId: yup.string(),
});

export function HouseTeamReports(props) {
  const { inputType } = props;
  const _inputType = inputType || "normal";
  const { studioId } = useParams();
  const classes = useStyles();
  const { dispatch } = useStore();
  const [apiTrigger, setApiTrigger] = React.useState(0);
  const [loading, setLoading] = React.useState(1);
  const [getApiResult, setGetApiResult] = React.useState();
  const [errorResult, setErrorResult] = React.useState({
    type: "server",
    errors: [],
  });
  const [funtionInfo, setFuntionInfo] = React.useState({
    filterOpen: _inputType === "tablet" ? false : true,
    order: "asc",
    orderBy: "FirstName",
  });
  const previousController = React.useRef();
  const timerController = React.useRef();

  const {
    //register,
    getValues,
    reset,
    setValue,
    trigger,
    //formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { termId } = getValues();

  const searchType = () => {
    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    timerController.current = setTimeout(() => {
      setLoading(2);
      setApiTrigger(apiTrigger + 1);
    }, 500);
  };

  const handleRequestSort = (event, property) => {
    const isAsc =
      funtionInfo.orderBy === property && funtionInfo.order === "asc";
    setFuntionInfo({
      ...funtionInfo,
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
    // if (getApiResult.appManages && getApiResult.appManages.length > 0) {
    //   setBackdropOpen(true);
    //   setApiTrigger(apiTrigger + 1);
    // }
  };

  const handleFilter = () => {
    setFuntionInfo({
      ...funtionInfo,
      filterOpen: !funtionInfo.filterOpen,
    });
  };

  const handleChangeTerm = async () => {
    setLoading(2);
    setApiTrigger(apiTrigger + 1);
  };

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    let timerBackdropOpen = null;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(
          env.apiBase + "/api/houseteam/gethouseteamreports",
          {
            method: "post",
            signal: signal,
            body: JSON.stringify({
              //...getValues(),
              // page: page,
              // rowsPerPage: rowsPerPage,
              // order: order,
              // orderBy: orderBy,
              // type: 5,
              //programId: programIdTab,
              //programId: funtionInfo.programTabId,
              ...getValues(),
              studioId: studioId,
            }),
            ...options,
          }
        );

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setErrorResult({
            type: "server",
            errors: data.errors,
          });
          setLoading(0);
          return;
        }

        if (data.results) {
          const { selectTermId } = data.results;

          if (selectTermId) {
            reset({
              termId: selectTermId,
            });
          }

          setGetApiResult({
            ...data.results,
          });
        }
      } catch (error) {
        const _errors = [];
        _errors.push("error : " + error.message);
        setErrorResult({
          type: "server",
          errors: _errors,
        });
        setLoading(0);
        return;
      }

      if (loading === 2) {
        timerBackdropOpen = setTimeout(() => {
          setLoading(0);
        }, 800);
      } else {
        setLoading(0);
      }
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [apiTrigger]);

  React.useEffect(() => {
    dispatch({
      type: "SET_STATE",
      payload: {
        breadcrumbs: [
          { title: "Dashboard", link: "/dashboard" },
          { title: "Studios", link: "/dashboard/studios" },
          { title: "House Team Reports", link: null },
        ],
      },
    });

    // eslint-disable-next-line
  }, []);

  if (loading === 1) {
    return <Loading />;
  } else if (errorResult.type === "server" && errorResult.errors.length > 0) {
    return <ErrorMessage errors={errorResult.errors} />;
  }
  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={loading === 2}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          handleFilter={handleFilter}
          reloadFunction={handleChangeTerm}
          inputType={_inputType}
        />

        {funtionInfo.filterOpen && (
          <SearchPanel
            {...getApiResult}
            searchType={searchType}
            termId={termId}
            setValue={setValue}
            trigger={trigger}
            handleChangeTerm={handleChangeTerm}
          />
        )}

        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              {...funtionInfo}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {getApiResult.houseTeams.map((houseTeamRow, index) => {
                const _img =
                  houseTeamRow.houseTeamLogo &&
                  houseTeamRow.houseTeamLogo !== ""
                    ? `${env.resourceBase}/media/uploadimages/${houseTeamRow.houseTeamLogo}`
                    : "/assets/no-image.png";

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={houseTeamRow.houseTeamId}
                  >
                    <TableCell align="left">
                      {" "}
                      {houseTeamRow.houseTeamName}{" "}
                    </TableCell>
                    <TableCell align="left">
                      <img width="40px" src={_img} alt="belt" />
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      {houseTeamRow.totalCount}{" "}
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      {houseTeamRow.sumEarningPoints}{" "}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
