import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./polyfill";

import React from "react";
import ReactDOM from "react-dom";
import "./_scss/index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

console.log("Test");

ReactDOM.render(<App />, document.getElementById("root"));
//ReactDOM.hydrate(<App />, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: (registration) => {
    console.log("updated", registration);
    const update = true; //window.confirm('New Update Available. Do you want to update? (Refresh page)');
    if (update) {
      if (!registration.waiting) {
        // Just to ensure registration.waiting is available before calling postMessage()
        return;
      }
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
    }
  },
});

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`, {
      scope: "/firebase-cloud-messaging-push-scope",
    })
    .then((registration) => {
      //return registration.update();
    })
    // .then((registration) => {
    //   console.log("sfdsdf", registration);
    //   // window.updateSW = function () {
    //   //   console.log("Checking SW updates... (force)");
    //   //   return registration.update();
    //   // };
    // });
}
