import React from "react";
import { getIdToken } from "auth";
import env from "../../../env";
import { ErrorMessage, Loading } from "components";
import Slider from "react-slick";
//import Skeleton from "@material-ui/lab/Skeleton";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Link } from "react-router-dom";
import { Paper, Box, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    //width: "100%",
    //display: "flex",
    //justifyContent: "center",
    //alignItems: "center",
    //position: "relative",
    //textAlign : 'center',
    //marginBottom: theme.spacing(2),
  },
}));

const useDashStyles = makeStyles((theme) => ({
  container: {
    height: `calc(100vh - 120px)`,
    width: "100%",
    position: 'relative'
  },
  homeImg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    margin: "auto",
    [theme.breakpoints.up("md")]: {
      width: "800px",
      height: `830px`,
    },
  },
  textBox: {
    position: "absolute",
    bottom: "15px",
    left: "0px",
    width: "100%",
    padding: theme.spacing(1),
    color: "white",
    backgroundColor: "rgba(0,0,0,0.4)",
  },
  arrowIcon: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: "0",
  },
}));

const DashboardView = (props) => {
  const { students } = props;
  const classes = useDashStyles();
  const settings = {
    dots: false,
    //infinite: true,
    //speed: 1500,
    //autoplaySpeed: adjustSpeed,
    //fade: true,
    //autoplay: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
  };

  const slides = (students || []).map((student, index) => {
    const _img =
      student && student.profilePic
        ? `${env.resourceBase}/media/uploadimages/${student.profilePic}`
        : "/assets/no-image.png";
    //const _img = '/assets/no-image.png'

    return (
      <div key={student.studentId} className={classes.container}>
        <img src={_img} className={classes.homeImg} alt="test" />
        <Box
          className={classes.textBox}
          component={Link}
          to={`/dashboard/student-detail/${student.studentId}`}
        >
          <Box style={{ position: "relative" }}>
            <Typography> {`${student.firstName} ${student.lastName}`} </Typography>
            <Typography> Open Student details </Typography>
            <ArrowForwardIosIcon
              fontSize="small"
              className={classes.arrowIcon}
            />
          </Box>
        </Box>
      </div>
    );
  });
  return <Slider {...settings}>{slides}</Slider>;
};

export function DashboardForUser(props) {
  const { userInfo } = props;
  const classes = useStyles();
  const [loading, setLoading] = React.useState(1);
  const [getApiResult, setGetApiResult] = React.useState();
  const [errorResult, setErrorResult] = React.useState({
    type: "server",
    errors: [],
  });

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    let timerBackdropOpen = null;

    const fetchData = async () => {
      try {
        const token = await getIdToken();
        const options = { headers: { "Content-Type": "application/json" } };
        if (token) {
          options.headers["Authorization"] = `Bearer ${token}`;
        }
        const res = await fetch(
          env.apiBase + "/api/student/getdashboardforuser",
          {
            method: "post",
            signal: signal,
            body: JSON.stringify({
              userInfo: userInfo,
            }),
            ...options,
          }
        );

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();
        
        if (data.errors && data.errors.length > 0) {
          setErrorResult({
            type: "server",
            errors: data.errors,
          });
          setLoading(0);
          return;
        }

        if (data.results) {
          setGetApiResult({
            ...data.results,
          });
        }
      } catch (error) {
        const _errors = [];
        _errors.push("error : " + error.message);
        setErrorResult({
          type: "server",
          errors: _errors,
        });
        setLoading(0);
        return;
      }

      if (loading === 2) {
        timerBackdropOpen = setTimeout(() => {
          setLoading(0);
        }, 800);
      } else {
        setLoading(0);
      }
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  if (loading === 1) {
    return <Loading />;
  } else if (errorResult.type === "server" && errorResult.errors.length > 0) {
    return <ErrorMessage errors={errorResult.errors} />;
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <DashboardView {...getApiResult} />
      </Paper>
    </div>
  );
}
