import React, { useEffect } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useParams, Link, useHistory } from "react-router-dom";
import env from "../../../env";
//import Autocomplete from "@material-ui/lab/Autocomplete";
import { variables } from "../../../cssInJs";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import { Loading, ErrorMessage, SnackbarMessageV2 } from "components";
import clsx from "clsx";
import utils from "../../../utils";
import { getIdToken } from "auth";
import { useStore } from "../../../store";
import {
  Box,
  Checkbox,
  Toolbar,
  makeStyles,
  lighten,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  InputAdornment,
  ButtonGroup,
  OutlinedInput,
  InputLabel,
  Button,
  Typography,
  Tooltip,
  TablePagination,
  Backdrop,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    //minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    //position: "absolute",
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const useTableHeadStyles = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: variables.colorBlue3,
    "& .MuiTableCell-head": {
      color: "white",
    },
    "& .MuiIconButton-label": {
      color: "white",
    },
    "& .MuiTableSortLabel-root:hover": {
      color: "white",
    },
    "& .MuiTableSortLabel-root": {
      lineHeight: "1.1",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: "white",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon":
      {
        color: "white",
      },
  },
  firstCell: {
    width: "65px",
    //width: "3%",
  },
  secondCell: {
    width: "14%",
    //width: "15.8%",
  },
}));

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  firstCell: {
    //width: "3%",
    width: "65px",
  },
  secondCell: {
    width: "14%",
  },
}));

const useDialogStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  title: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, handleFilter, studioId, handleOpenInfo } = props;
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          //color="inherit"
          //variant="subtitle1"
          component="div"
          style={{ fontSize: "18px" }}
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          //variant="h6"
          id="tableTitle"
          component="div"
          style={{ fontSize: "18px" }}
        >
          Terms
        </Typography>
      )}

      <ButtonGroup size="small" aria-label="small outlined button group">
        <Tooltip title="Create Term" arrow>
          <Button
            component={Link}
            to={`/dashboard/studio/${studioId}/term/new`}
          >
            <AddIcon fontSize="small" />
          </Button>
        </Tooltip>

        <Tooltip title="Filters" arrow>
          <Button onClick={handleFilter}>
            <FilterListIcon fontSize="small" />
          </Button>
        </Tooltip>

        <Tooltip title="Delete" arrow>
          <Button onClick={handleOpenInfo}>
            <DeleteIcon fontSize="small" />
          </Button>
        </Tooltip>
      </ButtonGroup>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const headCells = [
  {
    id: "termName",
    numeric: false,
    disablePadding: false,
    label: "Name",
    hasSort: true,
    classItem: "secondCell",
  },
  {
    id: "startDate",
    numeric: false,
    disablePadding: false,
    label: "Start Date",
    hasSort: true,
    classItem: "secondCell",
  },
  {
    id: "endDate",
    numeric: false,
    disablePadding: false,
    label: "End Date",
    hasSort: true,
    classItem: "secondCell",
  },
  {
    id: "noOfBatches",
    numeric: false,
    disablePadding: false,
    label: "No. Of Batches",
    hasSort: true,
    classItem: "secondCell",
  },
  {
    id: "info",
    numeric: false,
    disablePadding: false,
    label: "Info",
    hasSort: false,
    classItem: "secondCell",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    hasSort: true,
    classItem: "secondCell",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    hasSort: false,
    classItem: "",
  },
];

function EnhancedTableHead(props) {
  const {
    //classes,
    onSelectAllClick,
    //order,
    //orderBy,
    numSelected,
    rowCount,
    //onRequestSort,
  } = props;

  const classes = useTableHeadStyles();

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        <TableCell padding="checkbox" className={classes.firstCell}>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell, index) => {
          const _className = headCell.classItem
            ? classes[headCell.classItem]
            : "";
          return (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              className={_className}
            >
              {headCell.label}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
  //onRequestSort: PropTypes.func.isRequired,
  //order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  //orderBy: PropTypes.string.isRequired,
};

function Row(props) {
  const {
    year,
    terms,
    isSelected,
    handleChange,
    studioId,
    getButtonText,
    actionButton,
  } = props;
  const classes = useRowStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell className={classes.firstCell}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {year}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 0,
            paddingRight: 0,
          }}
          colSpan={8}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table aria-label="purchases">
                <TableBody>
                  {terms.map((term, index) => {
                    const isItemSelected = isSelected(term.termId);
                    const labelId = `enhanced-table-term-checkbox-${index}`;

                    const displayStartDate = utils.site.formatDDMMYYYY(
                      new Date(term.startDate)
                    );

                    const displayEndDate = utils.site.formatDDMMYYYY(
                      new Date(term.endDate)
                    );

                    const buttonText = getButtonText(term.termStatus);

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={term.termId}
                        selected={isItemSelected}
                      >
                        <TableCell
                          padding="checkbox"
                          className={classes.firstCell}
                        >
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            onChange={(event) =>
                              handleChange(event, term.termId)
                            }
                          />
                        </TableCell>
                        <TableCell align="left" className={classes.secondCell}>
                          <Link
                            to={`/dashboard/studio/${studioId}/term/${term.termId}`}
                          >
                            {term.termName}
                          </Link>
                        </TableCell>
                        <TableCell align="left" className={classes.secondCell}>
                          {displayStartDate}
                        </TableCell>
                        <TableCell align="left" className={classes.secondCell}>
                          {displayEndDate}
                        </TableCell>
                        <TableCell align="left" className={classes.secondCell}>
                          {term.batchCountInTerm ? term.batchCountInTerm : 0}
                        </TableCell>
                        <TableCell align="left" className={classes.secondCell}>
                          {" "}
                          Info
                        </TableCell>
                        <TableCell align="left" className={classes.secondCell}>
                          {" "}
                          {term.termStatusDisplay}{" "}
                        </TableCell>
                        <TableCell align="left">
                          {term.termStatus === 3 ? (
                            "N/A"
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => {
                                actionButton(term);
                              }}
                            >
                              {buttonText}
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  //row: PropTypes.shape({}).isRequired,
};

const searchPanelStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "& > *": {
      //margin: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
  },
  textField: {
    width: "-webkit-fill-available",
    margin: theme.spacing(1),
    // width: `calc(33.3% - ${theme.spacing(2)}px)`,
  },
}));

const SearchPanel = (props) => {
  const classes = searchPanelStyles();
  const { register, searchType } = props;
  return (
    <form
      method="POST"
      noValidate=""
      className={classes.root}
      //onSubmit={handleSubmit(submit)}
    >
      <FormControl
        className={classes.textField}
        variant="outlined"
        size="small"
      >
        <InputLabel htmlFor="outlined-adornment-password-cp">
          Search by Name
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-password-cp"
          type="text"
          inputProps={{
            //...params.inputProps,
            defaultValue: "",
            autoComplete: "off",
            //autoComplete: "new-password",
          }}
          {...register("search", {
            onChange: (e) => {
              searchType();
            },
          })}
          startAdornment={
            <InputAdornment position="start">
              <IconButton aria-label="toggle password visibility" edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          labelWidth={150}
        />
      </FormControl>
    </form>
  );
};

const SimpleDialog = (props) => {
  const { open, handleClose, deleteItems } = props;
  const classes = useDialogStyles();
  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={open}
      scroll="paper"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">
          Are you sure you want to delete this term(s)?
        </Typography>
      </DialogTitle>

      <DialogActions>
        <Button color="primary" onClick={deleteItems}>
          Yes
        </Button>
        <Button color="primary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const schema = yup.object().shape({
  search: yup.string(),
});

export function Terms(props) {
  const { userInfo } = props;
  const { dispatch } = useStore();
  const history = useHistory();
  const classes = useStyles();
  const { studioId } = useParams();
  const timerController = React.useRef();
  const previousController = React.useRef();
  const [loading, setLoading] = React.useState(true);
  const [serverErrors, setServerErrors] = React.useState([]);
  const [sortYearTerms, setSortYearTerms] = React.useState();
  const [totalTerms, setTotalTerms] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [trigger, setTrigger] = React.useState(0);
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [openSnackbarMessage, setOpenSnackbarMessage] = React.useState({
    openStatus: false,
    message: null,
  });
  const [openInfo, setOpenInfo] = React.useState({
    open: false,
    item: null,
  });
  const {
    getValues,
    register,
    //setValue,
    //formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    //defaultValues: { search: "" },
  });

  const handleFilter = () => {
    setFilterOpen(!filterOpen);
  };

  const searchType = () => {
    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    timerController.current = setTimeout(() => {
      setBackdropOpen(true);
      setTrigger(trigger + 1);
    }, 500);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      // const newSelecteds = sortYearTerms.terms.map((n) => n.termId);
      // setSelected(newSelecteds);
      // return;
      const newSelecteds = [];
      sortYearTerms.forEach((el) => {
        el.terms.forEach((term) => {
          newSelecteds.push(term.termId);
        });
      });
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChange = (event, termId) => {
    //event.preventDefault();
    //event.stopPropagation();

    const selectedIndex = selected.indexOf(termId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, termId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (sortYearTerms && sortYearTerms.length > 0) {
      setBackdropOpen(true);
      setTrigger(trigger + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    if (sortYearTerms && sortYearTerms.length > 0) {
      setBackdropOpen(true);
      setTrigger(trigger + 1);
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const getButtonText = (termStatus) => {
    let result = "";
    if (termStatus === 1) {
      result = "Start Term";
    } else if (termStatus === 2) {
      result = "Complete Term";
    }
    return result;
  };

  const actionButton = async (term) => {
    setBackdropOpen(true);

    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/term/updatetermaction", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          termId: term.termId,
          termStatus: term.termStatus,
          studioId : studioId
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        timerController.current = setTimeout(() => {
          setBackdropOpen(false);
          setOpenSnackbarMessage({
            openStatus: true,
            message: data.errors[0].errorMessage,
          });
        }, 500);
        return;
      } else {
        setTrigger(trigger + 1);
      }
    } catch (error) {
      serverErrors.push("error : " + error.message);
      setServerErrors([...serverErrors]);
      setBackdropOpen(false);
    }
  };

  const handleCloseFunction = () => {
    setOpenSnackbarMessage(false);
  };

  const deleteItems = async () => {
    if (!selected || selected.length <= 0) {
      return;
    }

    openInfo.open = false;
    setOpenInfo({
      ...openInfo,
    });

    setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/term/deleteitems", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          selectedIds: selected,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        timerController.current = setTimeout(() => {
          //setBackdropOpen(false);
          setOpenSnackbarMessage({
            openStatus: true,
            message: data.errors[0].errorMessage,
          });
          setSelected([]);
          setTrigger(trigger + 1);
        }, 500);
        return;
      } else {
        setSelected([]);
        setTrigger(trigger + 1);
      }
    } catch (error) {
      serverErrors.push("error : " + error.message);
      setServerErrors([...serverErrors]);
      setBackdropOpen(false);
    }
  };

  const handleOpenInfo = () => {
    if (!selected || selected.length <= 0) {
      return;
    }
    openInfo.open = true;
    setOpenInfo({
      ...openInfo,
    });
  };

  const handleClose = () => {
    openInfo.open = false;
    setOpenInfo({
      ...openInfo,
    });
  };

  useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    let timerBackdropOpen = null;

    const fetchData = async () => {
      try {
        const token = await getIdToken();
        const options = { headers: { "Content-Type": "application/json" } };
        if (token) {
          options.headers["Authorization"] = `Bearer ${token}`;
        }
        const res = await fetch(env.apiBase + "/api/term/getterms", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            ...getValues(),
            studioId: studioId,
            page: page,
            rowsPerPage: rowsPerPage,
            userInfo: userInfo,
          }),
          ...options,
        });

        if (!res.ok) {
          if (res.status === 401) {
            history.push(`/login`);
            return;
          } else {
            throw new Error(`Response status: ${res.status}`);
          }
        }

        const data = await res.json();

        if (data.results && data.results.sortYearTerms) {
          setSortYearTerms(data.results.sortYearTerms);
        }

        if (data.results && data.results.totalTerms) {
          setTotalTerms(data.results.totalTerms);
        }
      } catch (error) {
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }
      setLoading(false);
      timerBackdropOpen = setTimeout(() => {
        setBackdropOpen(false);
      }, 800);
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };
    // eslint-disable-next-line
  }, [trigger]);

  React.useEffect(() => {
    dispatch({
      type: "SET_STATE",
      payload: {
        breadcrumbs: [
          { title: "Dashboard", link: "/dashboard" },
          { title: "Studios", link: "/dashboard/studios" },
          { title: "Terms", link: null },
        ],
      },
    });

    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <SnackbarMessageV2
        {...openSnackbarMessage}
        handleCloseFunction={handleCloseFunction}
        severity="error"
      />

      <SimpleDialog
        {...openInfo}
        handleClose={handleClose}
        deleteItems={deleteItems}
      />

      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          handleFilter={handleFilter}
          studioId={studioId}
          handleOpenInfo={handleOpenInfo}
        />
        {filterOpen && (
          <SearchPanel searchType={searchType} register={register} />
        )}
        {sortYearTerms && (
          <>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={totalTerms}
                  //order={order}
                  //orderBy={orderBy}
                  //onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {sortYearTerms.map((row, index) => {
                    return (
                      <Row
                        key={row.year}
                        year={row.year}
                        terms={row.terms}
                        isSelected={isSelected}
                        handleChange={handleChange}
                        studioId={studioId}
                        getButtonText={getButtonText}
                        actionButton={actionButton}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalTerms}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </Paper>
    </div>
  );
}
