import React from "react";
import { ErrorMessage, Loading } from "components";
import { useStore } from "../../../store";
import { useParams, Link } from "react-router-dom";
import env from "../../../env";
import utils from "utils";
import DescriptionIcon from "@material-ui/icons/Description";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  //AppBar,
  //Avatar,
  //Tab,
  //Tabs,
  useTheme,
  Toolbar,
  lighten,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  //Checkbox,
  //TextField,
  IconButton,
  Tooltip,
  ButtonGroup,
  Button,
  Backdrop,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  //DialogActions,
  //ListItemIcon,
  //TextField,
  //Dialog,
  //Select,
  //DialogTitle,
  //List,
  //ListItem,
  //ListItemText,
  //DialogTitle,
  //DialogContent,
  //DialogContentText,
  Box,
  Grid,
  //Backdrop,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    //minWidth: 750,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 500,
    color: "#fff",
    //position: "absolute",
  },
}));

const useVModeStyles = makeStyles((theme) => ({
  boxEditMode: {
    backgroundColor: "white",
    padding: theme.spacing(3),
  },
  hr: {
    borderColor: "rgba(0,0,0,.1)",
  },
  buttonStyle: {
    //margin: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  main_title: { marginBottom: theme.spacing(2) },
  viewTitle: {},
  viewContents: {},
  list: {
    "& .MuiListItemIcon-root": {
      minWidth: "20px",
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      width: "10px",
    },
  },
}));

const ViewMode = (props) => {
  const classes = useVModeStyles();
  const theme = useTheme();
  const { referralFriend, studioId, handleChangeStatus } = props;
  const displayCreatedDate = utils.site.formatDDMMYYYY(
    new Date(referralFriend.dateCreated)
  );
  return (
    <Box className={classes.boxEditMode}>
      <Grid container spacing={3} alignItems={"center"}>
        <Grid item style={{ flexGrow: "1" }}>
          <Typography>
            <DescriptionIcon /> Referral Friend
          </Typography>
        </Grid>
        <Grid item style={{ textAlign: "right" }}>
          {referralFriend.status === 1 ? (
            <>
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonStyle}
                size="small"
                startIcon={<AttachFileIcon />}
                onClick={() => {
                  handleChangeStatus(2);
                }}
              >
                Approve Admission
              </Button>

              <Button
                variant="contained"
                color="primary"
                className={classes.buttonStyle}
                size="small"
                startIcon={<AttachFileIcon />}
                onClick={() => {
                  handleChangeStatus(3);
                }}
              >
                Cancel Admission
              </Button>
            </>
          ) : (
            <Typography variant="subtitle1" component="span">{referralFriend.displayStatus}</Typography>
          )}

          <Button
            variant="contained"
            color="primary"
            className={classes.buttonStyle}
            size="small"
            startIcon={<ArrowBackIcon />}
            component={Link}
            to={`/dashboard/studio/${studioId}/referral-friends`}
          >
            Back
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        alignItems={"center"}
        style={{ marginTop: theme.spacing(2) }}
      >
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Parent Name:
          </Typography>
          <Typography className={classes.viewContents}>
            {`${referralFriend.parnetFirstName} ${referralFriend.parentLastName}`}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Friend's Parent Name:
          </Typography>
          <Typography className={classes.viewContents}>
            {`${referralFriend.friendParentName}`}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Friend's Name:
          </Typography>
          <Typography className={classes.viewContents}>
            {`${referralFriend.friendName}`}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Email:
          </Typography>
          <Typography className={classes.viewContents}>
            {`${referralFriend.email}`}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Phone Number:
          </Typography>
          <Typography className={classes.viewContents}>
            {`${referralFriend.phoneNumber}`}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Suburb:
          </Typography>
          <Typography className={classes.viewContents}>
            {`${referralFriend.suburb}`}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Age:
          </Typography>
          <Typography className={classes.viewContents}>
            {`${referralFriend.age}`}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Requested Date:
          </Typography>
          <Typography className={classes.viewContents}>
            {`${displayCreatedDate}`}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Other Information:
          </Typography>
          <Typography className={classes.viewContents}>
            {`${referralFriend.describe}`}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Status:
          </Typography>
          <Typography className={classes.viewContents}>
            {`${referralFriend.displayStatus}`}
          </Typography>
        </Grid>
      </Grid>
      <Typography variant="h6" style={{ marginTop: theme.spacing(2) }}>
        Students
      </Typography>
      <Grid
        container
        spacing={3}
        alignItems={"center"}
        style={{ marginTop: theme.spacing(0.5) }}
      >
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            First Name:
          </Typography>
          <Typography className={classes.viewContents}>
            {`${referralFriend.firstName}`}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Last Name:
          </Typography>
          <Typography className={classes.viewContents}>
            {`${referralFriend.lastName}`}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export function ReferralFriend() {
  const { studioId, reItemId } = useParams();
  const classes = useStyles();
  const { dispatch } = useStore();
  const [apiTrigger, setApiTrigger] = React.useState(0);
  const previousController = React.useRef();
  const timerController = React.useRef();
  const [getApiResult, setGetApiResult] = React.useState();
  const [loading, setLoading] = React.useState(1);
  const [errorResult, setErrorResult] = React.useState({
    type: "server",
    errors: [],
  });

  const apiCall = async (
    url,
    apiData,
    returnFunction = null
    //retrunErrorFunction = null
  ) => {
    setLoading(2);

    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + url, {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          ...apiData,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setErrorResult({
          type: "snackbar",
          errors: data.errors,
        });
        setLoading(0);
        return;
      }

      if (returnFunction) {
        returnFunction(data.results);
        timerController.current = setTimeout(() => {
          setLoading(0);
        }, 800);
      } else {
        setApiTrigger(apiTrigger + 1);
      }
    } catch (error) {
      const _errors = [];
      _errors.push("error : " + error.message);
      setErrorResult({
        type: "server",
        errors: _errors,
      });
      setLoading(0);
      return;
    }
  };

  const handleChangeStatus = async (status) => {
    await apiCall("/api/studio/savereferralfriendstatus", {
      reItemId: reItemId,
      status: status,
    });
  };

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    let timerBackdropOpen = null;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(env.apiBase + "/api/studio/getreferralfriend", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            reItemId: reItemId,
          }),
          ...options,
        });

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();
        console.log("data ------ ", data);

        if (data.errors && data.errors.length > 0) {
          setErrorResult({
            type: "server",
            errors: data.errors,
          });
          setLoading(0);
          return;
        }

        if (data.results) {
          setGetApiResult({
            ...data.results,
          });
        }
      } catch (error) {
        const _errors = [];
        _errors.push("error : " + error.message);
        setErrorResult({
          type: "server",
          errors: _errors,
        });
        setLoading(0);
        return;
      }

      if (loading === 2) {
        timerBackdropOpen = setTimeout(() => {
          setLoading(0);
        }, 800);
      } else {
        setLoading(0);
      }
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [apiTrigger]);

  React.useEffect(() => {
    dispatch({
      type: "SET_STATE",
      payload: {
        breadcrumbs: [
          { title: "Dashboard", link: "/dashboard" },
          { title: "Studios", link: "/dashboard/studios" },
          { title: "Referral Friend", link: null },
        ],
      },
    });
    // eslint-disable-next-line
  }, []);

  if (loading === 1) {
    return <Loading />;
  } else if (errorResult.type === "server" && errorResult.errors.length > 0) {
    return <ErrorMessage errors={errorResult.errors} />;
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <ViewMode
          {...getApiResult}
          studioId={studioId}
          handleChangeStatus={handleChangeStatus}
        />
      </Paper>
    </div>
  );
}
