import React from "react";
import env from "../../../env";
import { ErrorMessage, Loading } from "components";
import { useParams } from "react-router-dom";
import {
  Paper,
  //Box,
  Typography,
  makeStyles,
  useTheme,
  Grid,
  //Avatar,
  //Grid,
  //Backdrop,
  //Dialog,
  //DialogTitle,
  //IconButton,
  //DialogContent,
  //DialogActions,
  //Button,
  //TextField,
  //List,
  //ListItem,
  //ListItemText,
  //ListItemIcon,
  //Container,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //paddingBottom: theme.spacing(10),
    //position: 'relative'
  },
  paper: {
    width: "100%",
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    //paddingLeft : theme.spacing(1),
    //paddingRight : theme.spacing(1)
    //marginBottom: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 500,
    color: "#fff",
    //position: "absolute",
  },
  listItem: {
    borderBottom: `1px solid rgba(0,0,0,.1)`,
  },
  listItemIcon: {
    "& svg": {
      marginLeft: "auto",
    },
  },
}));

const useViewStyles = makeStyles((theme) => ({
  // description: {
  //   marginTop: theme.spacing(1),
  // },
}));

const ClassDetailView = (props) => {
  const { classView, assignClass, studetnClassHistory } = props;
  const classes = useViewStyles();
  const theme = useTheme();
  return (
    <>
      <Grid container spacing={0}>
        <Typography variant="h6" style={{ flex: "1 1 auto" }}>
          {assignClass.programName}
        </Typography>
        {!classView.isCommitted && (
          <Typography variant="h6" style={{ color: "green" }}>
            Completed
          </Typography>
        )}

        {/* <Button
          color="primary"
          variant="contained"
          //type="submit"
          // startIcon={
          // //   isSubmitting && (
          // //     <span
          // //       className="spinner-grow spinner-grow-sm"
          // //       role="status"
          // //       aria-hidden="true"
          // //     ></span>
          // //   )
          // }
          //disabled={isSubmitting}
        >
          Extra
        </Button>
        <Button
          color="primary"
          variant="contained"
          style={{ marginLeft: theme.spacing(1) }}
          //type="submit"
          // startIcon={
          // //   isSubmitting && (
          // //     <span
          // //       className="spinner-grow spinner-grow-sm"
          // //       role="status"
          // //       aria-hidden="true"
          // //     ></span>
          // //   )
          // }
          //disabled={isSubmitting}
        >
          Private
        </Button> */}
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={4}>
          <Typography variant="subtitle1">Type</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">
            {classView.displayClassType}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1">Day and Time</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">
            {`${classView.displayDay} ${classView.startTime}`}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1">Attendance</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="subtitle1">
            {`${classView.displayDay} ${classView.startTime}`}
          </Typography>
          <Typography variant="subtitle1">
            {`${classView.displayDay} ${classView.startTime}`}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="subtitle1">Rewards</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">None</Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="subtitle1"> Discipline </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">None</Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="subtitle1"> BBA</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">
            {/* {studetnClassHistory ? studetnClassHistory} */}
          </Typography>
        </Grid>

      </Grid>
    </>
  );
};

export function ClassDetailForUser(props) {
  const { userInfo } = props;
  const { classId, assignClassId } = useParams();
  const classes = useStyles();
  const [errorResult, setErrorResult] = React.useState({
    type: "server",
    errors: [],
  });
  const [loading, setLoading] = React.useState(1);
  const previousController = React.useRef();
  const [getApiResult, setGetApiResult] = React.useState();

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    let timerBackdropOpen = null;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(
          env.apiBase + "/api/classes/getclassdetailforuser",
          {
            method: "post",
            signal: signal,
            body: JSON.stringify({
              userInfo: userInfo,
              classId: classId,
              assignClassId: assignClassId,
            }),
            ...options,
          }
        );

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();
        console.log("data", data);

        if (data.errors && data.errors.length > 0) {
          setErrorResult({
            type: "server",
            errors: data.errors,
          });
          setLoading(0);
          return;
        }

        if (data.results) {
          setGetApiResult({
            ...data.results,
          });
        }
      } catch (error) {
        const _errors = [];
        _errors.push("error : " + error.message);
        setErrorResult({
          type: "server",
          errors: _errors,
        });
        setLoading(0);
        return;
      }

      if (loading === 2) {
        timerBackdropOpen = setTimeout(() => {
          setLoading(0);
        }, 800);
      } else {
        setLoading(0);
      }
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  if (loading === 1) {
    return <Loading />;
  } else if (errorResult.type === "server" && errorResult.errors.length > 0) {
    return <ErrorMessage errors={errorResult.errors} />;
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <ClassDetailView {...getApiResult} />
      </Paper>
    </div>
  );
}
