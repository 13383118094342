import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage, Loading } from "components";
import { getIdToken } from "auth";
import env from "../../../env";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Paper,
  Box,
  Typography,
  makeStyles,
  useTheme,
  //Avatar,
  //Grid,
  Backdrop,
  //Dialog,
  //DialogTitle,
  //IconButton,
  //DialogContent,
  //DialogActions,
  Button,
  TextField,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(10),
    //paddingLeft : theme.spacing(1),
    //paddingRight : theme.spacing(1)
    //marginBottom: theme.spacing(2),
  },
  formControl: {
    width: `-webkit-fill-available`,
    display: "inline-flex",
    marginTop: theme.spacing(1),
    //marginBottom: theme.spacing(1),
  },
  textField: {
    width: "-webkit-fill-available",
    marginTop: theme.spacing(2),
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    //color: "#fff",
    //position: "absolute",
  },
}));

const schema = yup.object().shape({
  studentId: yup.string().required("Address 1 is required."),
  friendName: yup.string().required("Country is required."),
  friendParentName: yup.string().required("Country is required."),
  phoneNumber: yup.string().required("Country is required."),
  email: yup
    .string()
    .required("Country is required.")
    .email("Email is required"),
  suburb: yup.string().required("State is required."),
  age: yup.number().required("State is required."),
  describe: yup.string().nullable(true),
});

export function ReferralFormForUser(props) {
  const { userInfo } = props;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [errorResult, setErrorResult] = React.useState({
    type: "server",
    errors: [],
  });
  const [loading, setLoading] = React.useState(1);
  const [getApiResult, setGetApiResult] = React.useState();
  const previousController = React.useRef(null);
  const timerController = React.useRef();
  const {
    register,
    //control: controlAddr,
    //reset,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { studentId } = getValues();

  const apiCall = async (
    url,
    apiData,
    returnFunction = null
    //retrunErrorFunction = null
  ) => {
    setLoading(2);

    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + url, {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          ...apiData,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setErrorResult({
          type: "snackbar",
          errors: data.errors,
        });
        setLoading(0);
        return;
      }

      if (returnFunction) {
        timerController.current = setTimeout(() => {
          setLoading(0);
          returnFunction(data.results);
        }, 800);
      } else {
        //setApiTrigger(apiTrigger + 1);
      }
    } catch (error) {
      const _errors = [];
      _errors.push("error : " + error.message);
      setErrorResult({
        type: "server",
        errors: _errors,
      });
      setLoading(0);
      return;
    }
  };

  const submit = async (dataModel) => {
    await apiCall(
      "/api/studio/savereferralformforuser",
      {
        ...dataModel,
        userInfo: userInfo,
      },
      () => {
        history.push(`/dashboard/more-menus`);
      }
    );
  };

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    let timerBackdropOpen = null;

    const fetchData = async () => {
      try {
        const token = await getIdToken();
        const options = { headers: { "Content-Type": "application/json" } };
        if (token) {
          options.headers["Authorization"] = `Bearer ${token}`;
        }
        const res = await fetch(
          env.apiBase + "/api/studio/getreferralformForuser",
          {
            method: "post",
            signal: signal,
            body: JSON.stringify({
              userInfo: userInfo,
              //studentId: studentId,
            }),
            ...options,
          }
        );

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();
        
        if (data.errors && data.errors.length > 0) {
          setErrorResult({
            type: "server",
            errors: data.errors,
          });
          setLoading(0);
          return;
        }

        if (data.results) {
          setGetApiResult({
            ...data.results,
          });
        }
      } catch (error) {
        const _errors = [];
        _errors.push("error : " + error.message);
        setErrorResult({
          type: "server",
          errors: _errors,
        });
        setLoading(0);
        return;
      }

      if (loading === 2) {
        timerBackdropOpen = setTimeout(() => {
          setLoading(0);
        }, 800);
      } else {
        setLoading(0);
      }
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  if (loading === 1) {
    return <Loading />;
  } else if (errorResult.type === "server" && errorResult.errors.length > 0) {
    return <ErrorMessage errors={errorResult.errors} />;
  }
  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={loading === 2}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper className={classes.paper} elevation={0}>
        <Typography style={{ textAlign: "center" }}>
          Refer a friend or family member to us and you will earn yourself a
          Gold Start, PLUS 10 house team points when each referral completes
          their Introduction Assessment. Plus you will receive an awesome prize
          from us after your 5th referral!
        </Typography>

        <form
          method="POST"
          noValidate=""
          //className={classes.formRoot}
          onSubmit={handleSubmit((dataResult) => submit(dataResult))}
        >
          <Typography variant="h6" style={{ marginTop: theme.spacing(2) }}>
            {" "}
            Friend's Information{" "}
          </Typography>

          <Autocomplete
            id="studentId"
            options={getApiResult.students || []}
            className={classes.formControl}
            classes={{
              option: classes.option,
            }}
            autoHighlight
            getOptionLabel={(option) =>
              `${option.firstName} ${option.lastName}`
            }
            getOptionSelected={(option, value) =>
              option.studentId === value.studentId
            }
            value={
              (getApiResult.students || []).find(
                (x) => x.studentId === studentId
              ) || null
            }
            onChange={(event, newValue) => {
              setValue("studentId", newValue ? newValue.studentId : null);
              trigger("studentId");
            }}
            renderOption={(option) => (
              <React.Fragment>{`${option.firstName} ${option.lastName}`}</React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Student Name*"
                variant="outlined"
                size="small"
                error={!!errors.studentId}
                helperText={errors?.studentId?.message}
                inputProps={{
                  ...params.inputProps,
                  //autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />

          <TextField
            //id="outlined-basic"
            label="Friend's Name*"
            variant="outlined"
            error={!!errors.friendName}
            helperText={errors?.friendName?.message}
            size="small"
            className={classes.textField}
            {...register("friendName")}
          />

          <TextField
            //id="outlined-basic"
            label="Friend Parent Name*"
            variant="outlined"
            error={!!errors.friendParentName}
            helperText={errors?.friendParentName?.message}
            size="small"
            className={classes.textField}
            {...register("friendParentName")}
          />
          <TextField
            //id="outlined-basic"
            label="Phone Number*"
            variant="outlined"
            error={!!errors.phoneNumber}
            helperText={errors?.phoneNumber?.message}
            size="small"
            className={classes.textField}
            {...register("phoneNumber")}
          />
          <TextField
            //id="outlined-basic"
            label="Email*"
            variant="outlined"
            error={!!errors.email}
            helperText={errors?.email?.message}
            size="small"
            className={classes.textField}
            {...register("email")}
          />
          <TextField
            //id="outlined-basic"
            label="Suburb*"
            variant="outlined"
            error={!!errors.suburb}
            helperText={errors?.suburb?.message}
            size="small"
            className={classes.textField}
            {...register("suburb")}
          />
          <TextField
            //id="outlined-basic"
            label="Age*"
            variant="outlined"
            error={!!errors.age}
            helperText={errors?.age?.message}
            size="small"
            className={classes.textField}
            type="number"
            {...register("age")}
          />

          <Typography style={{ marginTop: theme.spacing(2) }}>
            Describe your Referral : School Friend, Sister, Cousin, etc
          </Typography>

          <TextField
            //id="outlined-basic"
            style={{ marginTop: theme.spacing(1) }}
            label="Describe your Referral"
            variant="outlined"
            error={!!errors.describe}
            helperText={errors?.describe?.message}
            size="small"
            className={classes.textField}
            multiline
            minRows={3}
            {...register("describe")}
          />
          <Box mt={2}>
            <Button
              style={{ width: "-webkit-fill-available" }}
              color="primary"
              variant="contained"
              type="submit"
              startIcon={
                isSubmitting && (
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )
              }
              disabled={isSubmitting}
            >
              Refer Friend
            </Button>
          </Box>
        </form>
      </Paper>
    </div>
  );
}
