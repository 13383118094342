import React from "react";
import { ErrorMessage, Loading } from "components";
import env from "../../../env";
import { FixedSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import utils from "utils";
import clsx from "clsx";
import { Link } from "react-router-dom";
import {
  Typography,
  makeStyles,
  useTheme,
  CircularProgress,
  Grid,
  Backdrop,
  ListItem,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //paddingBottom: theme.spacing(10),
    //position: 'relative'
  },
  paper: {
    width: "100%",
    //paddingLeft : theme.spacing(1),
    //paddingRight : theme.spacing(1)
    //marginBottom: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 500,
    color: "#fff",
    //position: "absolute",
  },
  listItem: {
    "&.MuiListItem-gutters": {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: "5px",
      paddingBottom: "5px",
      //border : '1px solid black'
    },
  },
  listItemIcon: {
    "& svg": {
      marginLeft: "auto",
    },
  },
  gridContainer: {
    //border: "1px solid black",
    height: "100%",
  },
  firstGrid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "orange",
    color: "white",
  },
  sencondGrid: {
    padding: theme.spacing(1),
  },
  priceStyle: {
    color: "orange",
    marginLeft: "5px",
  },
  titleStyle: {
    WebkitLineClamp: "1",
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    overflow: "hidden",
  },
}));

export function EventsForUser(props) {
  const { userInfo, studentId } = props;
  const classes = useStyles();
  const theme = useTheme();
  const previousController = React.useRef();
  const [apiTrigger, setApiTrigger] = React.useState(0);
  const [errorResult, setErrorResult] = React.useState({
    type: "server",
    errors: [],
  });
  const [funtionInfo, setFuntionInfo] = React.useState({
    hasNextPage: true,
    isNextPageLoading: true,
    page: 0,
  });
  const [loading, setLoading] = React.useState(1);
  const [getApiResult, setGetApiResult] = React.useState({
    events: [],
  });

  const isItemLoaded = (index) =>
    !funtionInfo.hasNextPage ||
    (index < getApiResult.events.length && getApiResult.events[index] !== null);

  const loadNextPage = () => {
    setFuntionInfo({
      ...funtionInfo,
      page: funtionInfo.page + 1,
      isNextPageLoading: true,
    });
    setLoading(2);
    setApiTrigger(apiTrigger + 1);
  };

  const renderRow = (props) => {
    const { index, style } = props;
    const eventItem = getApiResult.events[index];
    const displayDate = utils.site.formatDDMonthTextYYYY(
      new Date(eventItem.startDate)
    );
    const seperatedDate = displayDate.split("/");

    if (!isItemLoaded(index)) {
      return <div style={style}>Loading...</div>;
    }
    return (
      <ListItem
        button
        style={style}
        key={index}
        className={classes.listItem}
        component={Link}
        to={`/dashboard/event/${eventItem.eventId}`}
      >
        <Grid
          container
          spacing={0}
          className={clsx("MuiPaper-elevation4", classes.gridContainer)}
        >
          <Grid item xs={2} className={classes.firstGrid}>
            <Typography variant="h6">{seperatedDate[0]}</Typography>
            <Typography variant="caption" component="div">
              {`${seperatedDate[1]} - ${seperatedDate[2]}`}
            </Typography>
          </Grid>
          <Grid item xs={10} className={classes.sencondGrid}>
            <Typography variant="subtitle1" className={classes.titleStyle}>
              {eventItem.subject}
            </Typography>
            <Typography variant="caption" component="div">
              {`at ${eventItem.startTime} to ${eventItem.endTime}`}
            </Typography>
            <Typography variant="caption">
              Parent:{" "}
              <span
                className={classes.priceStyle}
              >{`$ ${eventItem.parentPrice}`}</span>
            </Typography>
            <Typography
              variant="caption"
              style={{ marginLeft: theme.spacing(1) }}
            >
              Child:{" "}
              <span
                className={classes.priceStyle}
              >{`$ ${eventItem.childPrice}`}</span>
            </Typography>
            <Typography
              variant="caption"
              style={{ marginLeft: theme.spacing(1) }}
            >
              Guest:{" "}
              <span
                className={classes.priceStyle}
              >{`$ ${eventItem.guestPrice}`}</span>
            </Typography>
          </Grid>
        </Grid>
      </ListItem>
    );
  };

  React.useEffect(() => {
    console.log("Check loop for eventforuser");

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    let timerBackdropOpen = null;

    const studioIds =
      userInfo.studioIds && userInfo.studioIds !== ""
        ? userInfo.studioIds.split(",")
        : [];

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(env.apiBase + "/api/studio/geteventsforuser", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            ...funtionInfo,
            studioIds: studioIds,
            studentId : studentId
          }),
          ...options,
        });

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();
        
        if (data.errors && data.errors.length > 0) {
          setErrorResult({
            type: "server",
            errors: data.errors,
          });
          setLoading(0);
          return;
        }

        if (data.results) {
          const { events, totalCount } = data.results;
          let _hasNextPage = false;

          getApiResult.events = getApiResult.events.concat(events || []);

          if (getApiResult.events.length < totalCount) {
            _hasNextPage = true;
          }

          setFuntionInfo({
            ...funtionInfo,
            hasNextPage: _hasNextPage,
            isNextPageLoading: false,
          });

          setGetApiResult({
            ...data.results,
            ...getApiResult,
          });
        }
      } catch (error) {
        const _errors = [];
        _errors.push("error : " + error.message);
        setErrorResult({
          type: "server",
          errors: _errors,
        });
        setLoading(0);
        return;
      }

      if (loading === 2) {
        timerBackdropOpen = setTimeout(() => {
          setLoading(0);
        }, 800);
      } else {
        setLoading(0);
      }
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [apiTrigger]);
  if (loading === 1) {
    return <Loading />;
  } else if (errorResult.type === "server" && errorResult.errors.length > 0) {
    return <ErrorMessage errors={errorResult.errors} />;
  }
  return (
    <>
      <Backdrop className={classes.backdrop} open={loading === 2}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {getApiResult.events.length > 0 ? (
        <AutoSizer>
          {({ height, width }) => (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={
                funtionInfo.hasNextPage
                  ? getApiResult.events.length + 1
                  : getApiResult.events.length
              }
              loadMoreItems={
                funtionInfo.isNextPageLoading ? () => {} : loadNextPage
              }
            >
              {({ onItemsRendered, ref }) => (
                <FixedSizeList
                  height={height - 75}
                  width={width}
                  itemSize={95}
                  itemCount={
                    funtionInfo.hasNextPage
                      ? getApiResult.events.length + 1
                      : getApiResult.events.length
                  }
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                  {...props}
                >
                  {renderRow}
                </FixedSizeList>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
      ) : (
        <AutoSizer>
          {({ height, width }) => (
            <Typography
              component="div"
              style={{
                width: width,
                height: height,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              Events Data Not Found.{" "}
            </Typography>
          )}
        </AutoSizer>
      )}
    </>
  );
}
