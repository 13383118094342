import React from "react";
import { ErrorMessage, Loading, SnackbarMessageV3 } from "components";
import { variables } from "../../../cssInJs";
import { useStore } from "../../../store";
import { useHistory, useParams, Link } from "react-router-dom";
import env from "../../../env";
import utils from "utils";
import clsx from "clsx";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePicker from "react-datepicker";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DescriptionIcon from "@material-ui/icons/Description";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import RefreshIcon from "@material-ui/icons/Refresh";
import {
  makeStyles,
  Typography,
  Paper,
  TextField,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Button,
  ButtonGroup,
  Tooltip,
  Backdrop,
  FormControl,
  Box,
  Grid,
  useTheme,
  Toolbar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingBottom: theme.spacing(7),
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  formRoot: {
    paddingBottom: theme.spacing(1),
  },
  table: {
    //minWidth: 750,
  },
  marginDiv: {
    margin: theme.spacing(1),
    display: "inline-block",
  },
  formControl: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
  },
  textField: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
  },
  textFieldFull: {
    margin: theme.spacing(1),
    width: `-webkit-fill-available`,
  },
  formControl2: {
    margin: theme.spacing(1),
    //width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
    "& label": {
      marginBottom: "0px",
      marginRight: "0px",
    },
  },
  datePicker: {
    margin: theme.spacing(1),
    display: "inline-block",
    width: `calc(50% - ${theme.spacing(2)}px)`,
    "& .react-datepicker-wrapper, & input": {
      width: "100%",
    },
    "& .react-datepicker-popper": {
      zIndex: 2,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 500,
    color: "#fff",
    //position: "absolute",
  },
  buttonContainer: {
    position: "fixed",
    bottom: "0",
    left: "0",
    backgroundColor: "white",
    width: "calc(100% - 240px)",
    marginLeft: "240px",
    marginTop: "0",
    marginBottom: "0",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    zIndex: "1",
  },
  option: {
    fontSize: 14,
  },
}));

const useVModeStyles = makeStyles((theme) => ({
  boxEditMode: {
    backgroundColor: "white",
    padding: theme.spacing(3),
  },
  hr: {
    borderColor: "rgba(0,0,0,.1)",
  },
  buttonStyle: {
    //margin: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  gridItem: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  typeStyle: {
    //marginTop: theme.spacing(2),
    //marginBottom: theme.spacing(2),
  },
  title: {},
  value: {},
  main_title: { marginBottom: theme.spacing(2) },
  viewTitle: {},
  viewContents: {},
  list: {
    "& .MuiListItemIcon-root": {
      minWidth: "20px",
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      width: "10px",
    },
  },
}));

const useEventRequestsViewStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  table: {
    //minWidth: 750,
  },
  title: {
    flex: "1 1 auto",
  },
  tableHead: {
    backgroundColor: variables.colorBlue3,
    "& .MuiTableCell-head": {
      color: "white",
    },
    "& .MuiIconButton-label": {
      color: "white",
    },
    "& .MuiTableSortLabel-root:hover": {
      color: "white",
    },
    "& .MuiTableSortLabel-root": {
      lineHeight: "1.1",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: "white",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon":
      {
        color: "white",
      },
  },
}));

const ViewMode = (props) => {
  const classes = useVModeStyles();
  const { changeViewMode, eventView } = props;
  const theme = useTheme();
  const displayEventStartDate = utils.site.formatDDMMYYYY(
    new Date(eventView.startDate)
  );
  const displayEventEndDate = utils.site.formatDDMMYYYY(
    new Date(eventView.endDate)
  );

  const displayDeadLine = utils.site.formatDDMMYYYY(
    new Date(eventView.deadLine)
  );

  return (
    <Box className={classes.boxEditMode}>
      <Grid container spacing={0} alignItems={"center"}>
        <Grid item style={{ flexGrow: "1" }}>
          <Typography variant="subtitle1">
            <DescriptionIcon /> Event Information
          </Typography>
        </Grid>
        <Grid>
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonStyle}
            size="small"
            startIcon={<AttachFileIcon />}
            onClick={changeViewMode}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonStyle}
            size="small"
            startIcon={<ArrowBackIcon />}
            component={Link}
            to={`/dashboard/belt-tying-videos`}
          >
            Back
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={0} style={{ marginTop: theme.spacing(1) }}>
        <Grid item xs={6} className={classes.gridItem}>
          <Typography variant="caption">Event Start Date: </Typography>
          <Typography
            variant="subtitle1"
            component="span"
            style={{ marginLeft: theme.spacing(1) }}
          >
            {" "}
            {displayEventStartDate}{" "}
          </Typography>
        </Grid>

        <Grid item xs={6} className={classes.gridItem}>
          <Typography variant="caption">Event End Date: </Typography>
          <Typography
            variant="subtitle1"
            component="span"
            style={{ marginLeft: theme.spacing(1) }}
          >
            {" "}
            {displayEventEndDate}{" "}
          </Typography>
        </Grid>

        <Grid item xs={6} className={classes.gridItem}>
          <Typography variant="caption">Owner Name: </Typography>
          <Typography
            variant="subtitle1"
            component="span"
            style={{ marginLeft: theme.spacing(1) }}
          >
            {eventView.ownerName}
          </Typography>
        </Grid>

        <Grid item xs={6} className={classes.gridItem}>
          <Typography variant="caption">Subject: </Typography>
          <Typography
            variant="subtitle1"
            component="span"
            style={{ marginLeft: theme.spacing(1) }}
          >
            {eventView.subject}
          </Typography>
        </Grid>

        <Grid item xs={6} className={classes.gridItem}>
          <Typography variant="caption">Description: </Typography>
          <Typography
            variant="subtitle1"
            component="span"
            style={{ marginLeft: theme.spacing(1) }}
          >
            {eventView.description}
          </Typography>
        </Grid>

        <Grid item xs={6} className={classes.gridItem}>
          <Typography variant="caption">Start Time: </Typography>
          <Typography
            variant="subtitle1"
            component="span"
            style={{ marginLeft: theme.spacing(1) }}
          >
            {eventView.startTime}
          </Typography>
        </Grid>

        <Grid item xs={6} className={classes.gridItem}>
          <Typography variant="caption">End Time: </Typography>
          <Typography
            variant="subtitle1"
            component="span"
            style={{ marginLeft: theme.spacing(1) }}
          >
            {eventView.endTime}
          </Typography>
        </Grid>

        <Grid item xs={6} className={classes.gridItem}>
          <Typography variant="caption">Guest Price: </Typography>
          <Typography
            variant="subtitle1"
            component="span"
            style={{ marginLeft: theme.spacing(1) }}
          >
            {`$${eventView.guestPrice ? eventView.guestPrice : "0"}`}
          </Typography>
        </Grid>

        <Grid item xs={6} className={classes.gridItem}>
          <Typography variant="caption">Child Price: </Typography>
          <Typography
            variant="subtitle1"
            component="span"
            style={{ marginLeft: theme.spacing(1) }}
          >
            {`$${eventView.childPrice}`}
          </Typography>
        </Grid>

        <Grid item xs={6} className={classes.gridItem}>
          <Typography variant="caption">Parent Price: </Typography>
          <Typography
            variant="subtitle1"
            component="span"
            style={{ marginLeft: theme.spacing(1) }}
          >
            {`$${eventView.childPrice}`}
          </Typography>
        </Grid>

        <Grid item xs={6} className={classes.gridItem}>
          <Typography variant="caption">Dead Line: </Typography>
          <Typography
            variant="subtitle1"
            component="span"
            style={{ marginLeft: theme.spacing(1) }}
          >
            {displayDeadLine}
          </Typography>
        </Grid>

        <Grid item xs={6} className={classes.gridItem}>
          <Typography variant="caption">Maximum Guests: </Typography>
          <Typography
            variant="subtitle1"
            component="span"
            style={{ marginLeft: theme.spacing(1) }}
          >
            {eventView.maximumGuests ? eventView.maximumGuests : "0"}
          </Typography>
        </Grid>

        <Grid item xs={6} className={classes.gridItem}>
          <Typography variant="caption">Address1: </Typography>
          <Typography
            variant="subtitle1"
            component="span"
            style={{ marginLeft: theme.spacing(1) }}
          >
            {eventView.address1}
          </Typography>
        </Grid>

        <Grid item xs={6} className={classes.gridItem}>
          <Typography variant="caption">Address2: </Typography>
          <Typography
            variant="subtitle1"
            component="span"
            style={{ marginLeft: theme.spacing(1) }}
          >
            {eventView.address2}
          </Typography>
        </Grid>

        <Grid item xs={6} className={classes.gridItem}>
          <Typography variant="caption">Contact Email: </Typography>
          <Typography
            variant="subtitle1"
            component="span"
            style={{ marginLeft: theme.spacing(1) }}
          >
            {eventView.contactEmail}
          </Typography>
        </Grid>

        <Grid item xs={6} className={classes.gridItem}>
          <Typography variant="caption">Invitation Type: </Typography>
          <Typography
            variant="subtitle1"
            component="span"
            style={{ marginLeft: theme.spacing(1) }}
          >
            Open Invitation
          </Typography>
        </Grid>

        <Grid item xs={6} className={classes.gridItem}>
          <Typography variant="caption">Country: </Typography>
          <Typography
            variant="subtitle1"
            component="span"
            style={{ marginLeft: theme.spacing(1) }}
          >
            {eventView.country}
          </Typography>
        </Grid>

        <Grid item xs={6} className={classes.gridItem}>
          <Typography variant="caption">State: </Typography>
          <Typography
            variant="subtitle1"
            component="span"
            style={{ marginLeft: theme.spacing(1) }}
          >
            {eventView.state}
          </Typography>
        </Grid>

        <Grid item xs={6} className={classes.gridItem}>
          <Typography variant="caption">City: </Typography>
          <Typography
            variant="subtitle1"
            component="span"
            style={{ marginLeft: theme.spacing(1) }}
          >
            {eventView.city}
          </Typography>
        </Grid>

        <Grid item xs={6} className={classes.gridItem}>
          <Typography variant="caption">Program: </Typography>
          <Typography
            variant="subtitle1"
            component="span"
            style={{ marginLeft: theme.spacing(1) }}
          >
            {eventView.programNames}
          </Typography>
        </Grid>

        {/* <Grid item xs={6} className={classes.gridItem}>
          
          <Typography variant="subtitle1" className={classes.typeStyle}>
            <span className={classes.title}> Owner Name: </span>
            <span className={classes.value}> {eventView.ownerName} </span>
          </Typography>
          <Typography variant="subtitle1" className={classes.typeStyle}>
            <span className={classes.title}> Description: </span>
            <span className={classes.value}> {eventView.description} </span>
          </Typography>
          <Typography variant="subtitle1" className={classes.typeStyle}>
            <span className={classes.title}> Description: </span>
            <span className={classes.value}> {eventView.description} </span>
          </Typography>
        </Grid> */}
      </Grid>
    </Box>
  );
};

const headCells = [
  {
    id: "FirstName",
    numeric: false,
    disablePadding: false,
    label: "First Name",
    hasSort: false,
  },
  {
    id: "LastName",
    numeric: false,
    disablePadding: false,
    label: "Last Name",
    hasSort: false,
  },
  {
    id: "Email",
    numeric: false,
    disablePadding: false,
    label: "Email",
    hasSort: false,
  },
  {
    id: "ContactNumber",
    numeric: false,
    disablePadding: false,
    label: "Contact Number",
    hasSort: false,
  },
  {
    id: "Attendee",
    numeric: false,
    disablePadding: false,
    label: "Attendee",
    hasSort: false,
  },
  {
    id: "PaymentMode",
    numeric: false,
    disablePadding: false,
    label: "Payment Mode",
    hasSort: false,
  },
  {
    id: "PaymentStatus",
    numeric: false,
    disablePadding: false,
    label: "Payment Status",
    hasSort: false,
  },
  {
    id: "RequestedBy",
    numeric: false,
    disablePadding: false,
    label: "Requested By",
    hasSort: false,
  },
  {
    id: "CreatedAt",
    numeric: false,
    disablePadding: false,
    label: "Created At",
    hasSort: false,
  },
];

const EventRequestsView = (props) => {
  const classes = useEventRequestsViewStyles();
  const { eventRequests } = props;
  return (
    <>
      <Toolbar className={clsx(classes.root)}>
        <Typography
          className={classes.title}
          //variant="h6"
          id="tableTitle"
          component="div"
          style={{ fontSize: "18px" }}
        >
          Event Requests
        </Typography>
        <ButtonGroup size="small" aria-label="small outlined button group">
          <Tooltip title="Reload" arrow>
            <Button
            //onClick={reloadFunction}
            >
              <RefreshIcon fontSize="small" />
            </Button>
          </Tooltip>
        </ButtonGroup>
      </Toolbar>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <TableHead className={classes.tableHead}>
            <TableRow>
              {headCells.map((headCell, index) => {
                return (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? "right" : "left"}
                    padding={headCell.disablePadding ? "none" : "normal"}
                  >
                    {headCell.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {eventRequests.map((eventRequestRow, index) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={eventRequestRow.eventRequestId}
                >
                  <TableCell align="left">
                    {eventRequestRow.firstName}
                  </TableCell>
                  <TableCell align="left">
                    {eventRequestRow.lastName}
                  </TableCell>
                  <TableCell align="left">
                    {eventRequestRow.email}
                  </TableCell>

                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const schema = yup.object().shape({
  programIds: yup.string().required("Program is required."),
  ownerName: yup.string().required("Owner Name is required."),
  subject: yup.string().required("Subject is required."),
  startDate: yup.date().required("Start Date is required."),
  endDate: yup.date().required("End Date is required."),
  description: yup.string().nullable(true),
  startTime: yup.string().required("Start Time is required."),
  endTime: yup.string().required("End Time is required."),
  sendNotification: yup.boolean(),
  guestsAllowed: yup.boolean(),
  maximumGuests: yup.number().when("guestsAllowed", {
    is: true,
    then: (sch) => sch.required("Maximum Guests is required."),
  }),
  guestPrice: yup.number().when("guestsAllowed", {
    is: true,
    then: (sch) =>
      sch.required("Guest Price is required.").moreThan(-1).integer(),
  }),
  childPrice: yup
    .number()
    .required("Child Price is required.")
    .moreThan(-1)
    .integer(),
  parentPrice: yup
    .number()
    .required("Parent Price is required.")
    .moreThan(-1)
    .integer(),
  deadLine: yup.date().required("Dead Line is required."),
  address1: yup.string().required("Address 1 is required."),
  address2: yup.string(),
  country: yup.string().required("Country is required."),
  state: yup.string().required("State is required."),
  city: yup.string().required("City is required."),
  contactEmail: yup
    .string()
    .required("Email is required.")
    .email("Email is required"),
  contactNumber1: yup.string().required("Owner Name is required."),
  contactNumber2: yup.string().nullable(true),
});

export function Event(props) {
  const { userInfo } = props;
  const { studioId, eventId } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const { dispatch } = useStore();
  //const [apiTrigger, setApiTrigger] = React.useState(0);
  const previousController = React.useRef();
  const timerController = React.useRef();
  const [getApiResult, setGetApiResult] = React.useState();
  const [loading, setLoading] = React.useState(1);
  const [errorResult, setErrorResult] = React.useState({
    type: "server",
    errors: [],
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    trigger,
    watch,
    //control,
    //reset,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      sendNotification: false,
      guestsAllowed: true,
    },
  });

  const {
    programIds,
    startDate,
    endDate,
    sendNotification,
    guestsAllowed,
    deadLine,
    country,
    state,
    city,
  } = getValues();

  const handleCloseSnackBar = () => {
    setErrorResult({
      ...errorResult,
      type: "",
      errors: [],
    });
  };

  const apiCall = async (
    url,
    apiData,
    returnFunction = null
    //retrunErrorFunction = null
  ) => {
    setLoading(2);

    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + url, {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          ...apiData,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();
      console.log("sdfsdfds", data);

      if (data.errors && data.errors.length > 0) {
        setErrorResult({
          type: "snackbar",
          errors: data.errors,
        });
        setLoading(0);
        return;
      }

      if (returnFunction) {
        returnFunction(data.results);
      } else {
        //setApiTrigger(apiTrigger + 1);
      }
    } catch (error) {
      const _errors = [];
      _errors.push("error : " + error.message);
      setErrorResult({
        type: "server",
        errors: _errors,
      });
      setLoading(0);
      return;
    }
  };

  const getStatesFun = async (code) => {
    await apiCall(
      "/api/studio/getstatelist",
      {
        code: code,
      },
      (result) => {
        if (timerController.current) {
          clearTimeout(timerController.current);
        }
        timerController.current = setTimeout(() => {
          setLoading(0);
        }, 800);
        setValue("state", null);
        setValue("city", null);
        setGetApiResult({
          ...getApiResult,
          ...result,
        });
      }
    );
  };

  const getCitiesFun = async (code) => {
    await apiCall(
      "/api/studio/getcitylist",
      {
        code: code,
      },
      (result) => {
        if (timerController.current) {
          clearTimeout(timerController.current);
        }
        timerController.current = setTimeout(() => {
          setLoading(0);
        }, 800);
        setValue("city", null);
        setGetApiResult({
          ...getApiResult,
          ...result,
        });
      }
    );
  };

  const submit = async (dataModel) => {
    await apiCall(
      "/api/studio/saveevent",
      {
        ...dataModel,
        studioId,
        eventId,
      },
      (result) => {
        // timerController.current = setTimeout(() => {
        //   setLoading(0);
        // }, 800);
        history.push(`/dashboard/studio/${studioId}/events`);
      }
    );
  };

  const saveAsDraft = async (dataModel) => {
    await apiCall(
      "/api/studio/saveasdraftevent",
      {
        ...dataModel,
        studioId,
        eventId,
      },
      (result) => {
        // timerController.current = setTimeout(() => {
        //   setLoading(0);
        // }, 800);
        history.push(`/dashboard/studio/${studioId}/events`);
      }
    );
  };

  const changeViewMode = () => {
    setGetApiResult({
      ...getApiResult,
      editMode: false,
    });
  };

  React.useEffect(() => {
    dispatch({
      type: "SET_STATE",
      payload: {
        breadcrumbs: [
          { title: "Dashboard", link: "/dashboard" },
          { title: "Studios", link: "/dashboard/studios" },
          { title: "Event", link: null },
        ],
      },
    });

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    const fetchData = async () => {
      try {
        //const token = await getIdToken();
        const options = { headers: { "Content-Type": "application/json" } };
        // if (token) {
        //   //options.headers["Authorization"] = `Bearer ${token}`;
        // }
        const res = await fetch(env.apiBase + "/api/studio/getevent", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            userInfo: userInfo,
            studioId: studioId,
            eventId: eventId,
          }),
          ...options,
        });

        if (!res.ok) {
          if (res.status === 401) {
            history.push(`/login`);
            return;
          } else {
            throw new Error(`Response status: ${res.status}`);
          }
        }

        const data = await res.json();
        console.log("data ----- ", data);

        if (data.errors && data.errors.length > 0) {
          setErrorResult({
            type: "server",
            errors: data.errors,
          });
          setLoading(0);
          return;
        }

        if (data.results) {
          const { programs, eventView } = data.results;

          if (programs && programs.length > 0) {
            programs.push({
              programId: "all",
              programName: "All",
            });
          }
          let editMode = false;
          let newBatch = true;

          if (eventView) {
            editMode = true;
            newBatch = false;
            reset({
              programIds: eventView.programIds,
              ownerName: eventView.ownerName,
              subject: eventView.subject,
              startDate: new Date(eventView.startDate),
              endDate: new Date(eventView.endDate),
              description: eventView.description,
              startTime: eventView.startTime,
              endTime: eventView.endTime,
              sendNotification: eventView.sendNotification,
              guestsAllowed: eventView.guestsAllowed,
              maximumGuests: eventView.maximumGuests
                ? eventView.maximumGuests
                : 0,
              guestPrice: eventView.guestPrice ? eventView.guestPrice : 0,
              childPrice: eventView.childPrice,
              parentPrice: eventView.parentPrice,
              deadLine: new Date(eventView.deadLine),
              address1: eventView.address1,
              address2: eventView.address2,
              country: eventView.country,
              state: eventView.state,
              city: eventView.city,
              contactEmail: eventView.contactEmail,
              contactNumber1: eventView.contactNumber1,
              contactNumber2: eventView.contactNumber2,
            });
          }

          setGetApiResult({
            ...data.results,
            programs,
            editMode,
            newBatch,
          });
        }
      } catch (error) {
        const _errors = [];
        _errors.push("error : " + error.message);
        setErrorResult({
          type: "server",
          errors: _errors,
        });
        setLoading(0);
        return;
      }
      setLoading(0);
    };

    fetchData();

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "guestsAllowed" || name === "sendNotification") {
        trigger(name);
      }
    });
    return () => subscription.unsubscribe();

    // eslint-disable-next-line
  }, [watch]);

  if (loading === 1) {
    return <Loading />;
  } else if (errorResult.type === "server" && errorResult.errors.length > 0) {
    return <ErrorMessage errors={errorResult.errors} />;
  }
  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={loading === 2}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <SnackbarMessageV3
        {...errorResult}
        handleCloseFunction={handleCloseSnackBar}
        severity="error"
      />

      <Paper className={classes.paper}>
        {getApiResult && getApiResult.editMode === true ? (
          <>
            <ViewMode {...getApiResult} changeViewMode={changeViewMode} />
            <hr
              style={{
                marginTop: "0px",
                marginBottom: "0px",
                borderColor: "rgba(0,0,0,.1)",
              }}
            />
            <EventRequestsView {...getApiResult} />
          </>
        ) : (
          <>
            <Typography className={classes.marginDiv}>
              <DateRangeIcon /> Event
            </Typography>

            <form
              method="POST"
              noValidate=""
              className={classes.formRoot}
              onSubmit={handleSubmit(submit)}
            >
              <Autocomplete
                id="programIds"
                multiple
                options={getApiResult.programs || []}
                className={classes.formControl}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => option.programName}
                getOptionSelected={(option, value) =>
                  option.programId === value.programId
                }
                value={
                  programIds && programIds !== ""
                    ? (getApiResult.programs || []).filter((x) =>
                        programIds
                          .toLowerCase()
                          .includes(x.programId.toLowerCase())
                      )
                    : []
                }
                onChange={(event, newValue) => {
                  let _newValue = newValue;
                  const _findedAllItem = _newValue.find(
                    (x) => x.programId === "all"
                  );
                  if (_findedAllItem) {
                    _newValue = getApiResult.programs.filter(
                      (x) => x.programId !== "all"
                    );
                  }

                  setValue(
                    "programIds",
                    _newValue
                      ? _newValue.map((x) => x.programId).toString()
                      : null
                  );
                  trigger("programIds");
                }}
                renderOption={(option) => (
                  <React.Fragment>{option.programName}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Program*"
                    variant="outlined"
                    size="small"
                    error={!!errors.programIds}
                    helperText={errors?.programIds?.message}
                    inputProps={{
                      ...params.inputProps,
                      //autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              <TextField
                //id="outlined-basic"
                label="Owner Name*"
                variant="outlined"
                error={!!errors.ownerName}
                helperText={errors?.ownerName?.message}
                size="small"
                className={classes.textField}
                {...register("ownerName")}
              />
              <TextField
                //id="outlined-basic"
                label="Subject*"
                variant="outlined"
                error={!!errors.subject}
                helperText={errors?.subject?.message}
                size="small"
                className={classes.textField}
                {...register("subject")}
              />
              <hr style={{ margin: "0px", visibility: "hidden" }} />
              <div className={classes.datePicker}>
                <Typography variant="caption">Event Start Date*</Typography>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => {
                    setValue("startDate", date);
                    trigger("startDate");
                  }}
                  showIcon
                  isClearable
                  dateFormat="dd/MM/yyyy"
                  placeholderText={"dd/mm/yyyy"}
                  toggleCalendarOnIconClick
                  //disabled={startDateInfo.dateDisabled}
                  //minDate={startDateInfo.minDate}
                />
                {!!errors.startDate && (
                  <FormHelperText error id="startDate-error">
                    {errors?.startDate?.message}
                  </FormHelperText>
                )}
              </div>
              <div className={classes.datePicker}>
                <Typography variant="caption">Event End Date*</Typography>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => {
                    setValue("endDate", date);
                    trigger("endDate");
                  }}
                  showIcon
                  isClearable
                  dateFormat="dd/MM/yyyy"
                  placeholderText={"dd/mm/yyyy"}
                  toggleCalendarOnIconClick
                  //disabled={startDateInfo.dateDisabled}
                  //minDate={startDateInfo.minDate}
                />
                {!!errors.endDate && (
                  <FormHelperText error id="startDate-error">
                    {errors?.endDate?.message}
                  </FormHelperText>
                )}
              </div>
              <TextField
                label="Description"
                variant="outlined"
                error={!!errors.description}
                helperText={errors?.description?.message}
                size="small"
                className={classes.textFieldFull}
                multiline
                minRows={3}
                {...register("description")}
              />
              <TextField
                //id="date"
                label="Start Time*"
                variant="outlined"
                error={!!errors.startTime}
                helperText={errors?.startTime?.message}
                size="small"
                className={classes.textField}
                type="time"
                style={{ maxWidth: "250px" }}
                //defaultValue="2017-05-24"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={
                  {
                    //step: 300, // 5 min
                  }
                }
                {...register("startTime")}
              />
              <TextField
                //id="date"
                label="End Time*"
                variant="outlined"
                error={!!errors.endTime}
                helperText={errors?.endTime?.message}
                size="small"
                className={classes.textField}
                type="time"
                style={{ maxWidth: "250px" }}
                //defaultValue="2017-05-24"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={
                  {
                    //step: 300, // 5 min
                  }
                }
                {...register("endTime")}
              />
              <hr style={{ margin: "0px", visibility: "hidden" }} />
              <FormControl
                error={!!errors.sendNotification}
                component="fieldset"
                className={classes.formControl2}
                size="small"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={sendNotification}
                      {...register("sendNotification")}
                    />
                  }
                  className={`${
                    !!errors.sendNotification ? "Mui-error" : "Mui-error-non"
                  }`}
                  label="Send Notification"
                />
              </FormControl>
              <FormControl
                error={!!errors.guestsAllowed}
                component="fieldset"
                className={classes.formControl2}
                size="small"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={guestsAllowed}
                      {...register("guestsAllowed")}
                    />
                  }
                  className={`${
                    !!errors.guestsAllowed ? "Mui-error" : "Mui-error-non"
                  }`}
                  label="Guests Allowed"
                />
              </FormControl>
              <hr style={{ margin: "0px", visibility: "hidden" }} />
              {guestsAllowed && (
                <TextField
                  label="Maximum Guests*"
                  variant="outlined"
                  error={!!errors.maximumGuests}
                  helperText={errors?.maximumGuests?.message}
                  size="small"
                  className={classes.textField}
                  type="number"
                  {...register("maximumGuests")}
                />
              )}
              {guestsAllowed && (
                <TextField
                  label="Guest Price $*"
                  variant="outlined"
                  error={!!errors.guestPrice}
                  helperText={errors?.guestPrice?.message}
                  size="small"
                  className={classes.textField}
                  type="number"
                  defaultValue={0}
                  {...register("guestPrice")}
                />
              )}
              <TextField
                label="Child Price $*"
                variant="outlined"
                error={!!errors.childPrice}
                helperText={errors?.childPrice?.message}
                size="small"
                className={classes.textField}
                type="number"
                defaultValue={0}
                {...register("childPrice")}
              />
              <TextField
                label="Parent Price $*"
                variant="outlined"
                error={!!errors.parentPrice}
                helperText={errors?.parentPrice?.message}
                size="small"
                className={classes.textField}
                type="number"
                defaultValue={0}
                {...register("parentPrice")}
              />
              <div className={classes.datePicker}>
                <Typography variant="caption">Dead Line*</Typography>
                <DatePicker
                  selected={deadLine}
                  onChange={(date) => {
                    setValue("deadLine", date);
                    trigger("deadLine");
                  }}
                  showIcon
                  isClearable
                  dateFormat="dd/MM/yyyy"
                  placeholderText={"dd/mm/yyyy"}
                  toggleCalendarOnIconClick
                  //disabled={startDateInfo.dateDisabled}
                  //minDate={startDateInfo.minDate}
                />
                {!!errors.deadLine && (
                  <FormHelperText error id="startDate-error">
                    {errors?.deadLine?.message}
                  </FormHelperText>
                )}
              </div>
              <hr style={{ margin: "0px", visibility: "hidden" }} />
              <TextField
                //id="outlined-basic"
                label="Address 1*"
                variant="outlined"
                error={!!errors.address1}
                helperText={errors?.address1?.message}
                size="small"
                className={classes.textField}
                {...register("address1")}
              />
              <TextField
                //id="outlined-basic"
                label="Address 2"
                variant="outlined"
                error={!!errors.address2}
                helperText={errors?.address2?.message}
                size="small"
                className={classes.textField}
                {...register("address2")}
              />
              <Autocomplete
                id="country"
                //disabled={!checkCreateBatch}
                options={variables.countries}
                className={classes.formControl}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) =>
                  option.label === value.label
                }
                value={
                  variables.countries.find((x) => x.label === country) || null
                }
                onChange={(event, newValue) => {
                  setValue("country", newValue ? newValue.label : null);
                  trigger("country");

                  if (newValue) {
                    getStatesFun(newValue.code);
                  }
                }}
                renderOption={(option) => (
                  <React.Fragment>{option.label}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country*"
                    variant="outlined"
                    size="small"
                    error={!!errors.country}
                    helperText={errors?.country?.message}
                    inputProps={{
                      ...params.inputProps,
                      //autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              <Autocomplete
                id="state"
                //disabled={!checkCreateBatch}
                options={getApiResult.stateList || []}
                className={classes.formControl}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) =>
                  option.iso2 === value.iso2
                }
                value={
                  (getApiResult.stateList || []).find(
                    (x) => x.iso2 === state
                  ) || null
                }
                onChange={(event, newValue) => {
                  //inputChangeState(event, newValue);
                  setValue("state", newValue ? newValue.iso2 : null);
                  trigger("state");
                  if (newValue) {
                    getCitiesFun(newValue.iso2);
                  }
                }}
                renderOption={(option) => (
                  <React.Fragment>{option.name}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="State*"
                    variant="outlined"
                    size="small"
                    error={!!errors.state}
                    helperText={errors?.state?.message}
                    inputProps={{
                      ...params.inputProps,
                      //autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              <Autocomplete
                id="city"
                //disabled={!checkCreateBatch}
                options={getApiResult.cityList || []}
                className={classes.formControl}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => option.suburb}
                getOptionSelected={(option, value) =>
                  option.suburb === value.suburb
                }
                value={
                  (getApiResult.cityList || []).find(
                    (x) => x.suburb === city
                  ) || null
                }
                onChange={(event, newValue) => {
                  setValue("city", newValue ? newValue.suburb : null);
                  trigger("city");
                }}
                renderOption={(option) => (
                  <React.Fragment>{option.suburb}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="City*"
                    variant="outlined"
                    size="small"
                    error={!!errors.city}
                    helperText={errors?.city?.message}
                    inputProps={{
                      ...params.inputProps,
                      //autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              <TextField
                //id="outlined-basic"
                label="Contact Email*"
                variant="outlined"
                error={!!errors.contactEmail}
                helperText={errors?.contactEmail?.message}
                size="small"
                className={classes.textField}
                {...register("contactEmail")}
              />
              <TextField
                //id="outlined-basic"
                label="Contact Number1*"
                variant="outlined"
                error={!!errors.contactNumber1}
                helperText={errors?.contactNumber1?.message}
                size="small"
                className={classes.textField}
                {...register("contactNumber1")}
              />
              <TextField
                //id="outlined-basic"
                label="Contact Number2*"
                variant="outlined"
                error={!!errors.contactNumber2}
                helperText={errors?.contactNumber2?.message}
                size="small"
                className={classes.textField}
                {...register("contactNumber2")}
              />
              <div
                className={classes.buttonContainer}
                style={{ textAlign: "center" }}
              >
                <Button
                  variant="contained"
                  component={Link}
                  size="small"
                  to={`/dashboard/studio/${studioId}/events`}
                  disabled={isSubmitting}
                  type="button"
                  //color="primary"
                  //className={classes.button}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginLeft: "1rem" }}
                  //className={classes.button}
                  startIcon={
                    isSubmitting && (
                      <span
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )
                  }
                  type="submit"
                  disabled={isSubmitting}
                >
                  Submit
                </Button>

                {(!getApiResult ||
                  !getApiResult.eventView ||
                  getApiResult.eventView.eventStatus !== 1) && (
                  <Button
                    style={{ marginLeft: "1rem" }}
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={isSubmitting}
                    type="button"
                    onClick={handleSubmit((dataResult) => {
                      saveAsDraft(dataResult);
                    })}
                    //color="primary"
                    //className={classes.button}
                  >
                    Save as Draft
                  </Button>
                )}
              </div>
            </form>
          </>
        )}
      </Paper>
    </div>
  );
}
