import React from "react";
import { variables } from "../../../cssInJs";
import { useParams, Link } from "react-router-dom";
import { useStore } from "../../../store";
import { ErrorMessage, Loading } from "components";
import env from "../../../env";
import clsx from "clsx";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import utils from "utils";
import { yupResolver } from "@hookform/resolvers/yup";
import CircularProgress from "@material-ui/core/CircularProgress";
import FilterListIcon from "@material-ui/icons/FilterList";
import AddIcon from "@material-ui/icons/Add";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import InfoIcon from "@material-ui/icons/Info";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Toolbar,
  lighten,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  ButtonGroup,
  Button,
  Backdrop,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    //minWidth: 750,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 500,
    color: "#fff",
    //position: "absolute",
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 auto",
  },
}));

const useETHeadStyles = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: variables.colorBlue3,
    "& .MuiTableCell-head": {
      color: "white",
    },
    "& .MuiIconButton-label": {
      color: "white",
    },
    "& .MuiTableSortLabel-root:hover": {
      color: "white",
    },
    "& .MuiTableSortLabel-root": {
      lineHeight: "1.1",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: "white",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon":
      {
        color: "white",
      },
  },
}));

const useSearchPanelStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "& > *": {
      //margin: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
  },
  textField: {
    width: "-webkit-fill-available",
    margin: theme.spacing(1),
    //width: `calc(50% - ${theme.spacing(2)}px)`,
  },
  formControl: {
    width: `calc(50% - ${theme.spacing(2)}px)`,
    //width: "-webkit-fill-available",
    display: "inline-flex",
    margin: theme.spacing(1),
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    handleFilter,
    studioId,
    activeStatus,
    inActiveStatus,
    deleteItems,
  } = props;
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          //color="inherit"
          //variant="subtitle1"
          component="div"
          style={{ fontSize: "18px" }}
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          //variant="h6"
          id="tableTitle"
          component="div"
          style={{ fontSize: "18px" }}
        >
          Events
        </Typography>
      )}

      <ButtonGroup size="small" aria-label="small outlined button group">
        <Tooltip title="Create Belt Size" arrow>
          <Button
            component={Link}
            to={`/dashboard/studio/${studioId}/event/new`}
          >
            <AddIcon fontSize="small" />
          </Button>
        </Tooltip>
        <Tooltip title="Filters" arrow>
          <Button onClick={handleFilter}>
            <FilterListIcon fontSize="small" />
          </Button>
        </Tooltip>

        <Tooltip title="Active" arrow>
          <Button onClick={activeStatus}>
            <CheckCircleOutlineIcon fontSize="small" />
          </Button>
        </Tooltip>
        <Tooltip title="In Active" arrow>
          <Button onClick={inActiveStatus}>
            <RemoveCircleIcon fontSize="small" />
          </Button>
        </Tooltip>
        <Tooltip title="Delete" arrow>
          <Button onClick={deleteItems}>
            <DeleteIcon fontSize="small" />
          </Button>
        </Tooltip>
      </ButtonGroup>
    </Toolbar>
  );
};

const headCells = [
  {
    id: "EventDate",
    numeric: false,
    disablePadding: true,
    label: "Event Date",
    hasSort: false,
  },
  {
    id: "OwnerName",
    numeric: false,
    disablePadding: false,
    label: "OwnerName",
    hasSort: false,
  },
  {
    id: "Subject",
    numeric: false,
    disablePadding: false,
    label: "Subject",
    hasSort: false,
  },
  {
    id: "GuestPrice",
    numeric: false,
    disablePadding: false,
    label: "Guest Price",
    hasSort: false,
  },
  {
    id: "Studio",
    numeric: false,
    disablePadding: false,
    label: "Studio",
    hasSort: false,
  },
  {
    id: "IsDraft",
    numeric: false,
    disablePadding: false,
    label: "Is Draft",
    hasSort: false,
  },
  {
    id: "MaximumGuests",
    numeric: false,
    disablePadding: false,
    label: "Maximum Guests",
    hasSort: false,
  },
  {
    id: "Info",
    numeric: false,
    disablePadding: false,
    label: "Info",
    hasSort: false,
  },
  {
    id: "Status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    hasSort: false,
  },
];

const EnhancedTableHead = (props) => {
  const classes = useETHeadStyles();
  const {
    orderBy,
    order,
    onRequestSort,
    selected,
    rowCount,
    onSelectAllClick,
  } = props;
  const numSelected = selected.length;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => {
          if (headCell.hasSort) {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            );
          } else {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
              >
                {headCell.label}
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};

const SearchPanel = (props) => {
  const classes = useSearchPanelStyles();
  const { register, searchType } = props;
  return (
    <form method="POST" noValidate="" className={classes.root}>
      <FormControl
        className={classes.textField}
        variant="outlined"
        size="small"
      >
        <InputLabel htmlFor="outlined-adornment-password-cp">
          Search by Owner, Subject or Studio
        </InputLabel>

        <OutlinedInput
          id="outlined-adornment-password-cp"
          type="text"
          inputProps={{
            //...params.inputProps,
            defaultValue: "",
            autoComplete: "off",
            //autoComplete: "new-password",
          }}
          {...register("search", {
            onChange: (e) => {
              searchType();
            },
          })}
          startAdornment={
            <InputAdornment position="start">
              <IconButton aria-label="toggle password visibility" edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          labelWidth={280}
        />
      </FormControl>
    </form>
  );
};

const schema = yup.object().shape({
  search: yup.string(),
});

export function Events() {
  const classes = useStyles();
  const { studioId } = useParams();
  const { dispatch } = useStore();
  const [apiTrigger, setApiTrigger] = React.useState(0);
  const [loading, setLoading] = React.useState(1);
  const [getApiResult, setGetApiResult] = React.useState();
  const previousController = React.useRef();
  const timerController = React.useRef();
  const [errorResult, setErrorResult] = React.useState({
    type: "server",
    errors: [],
  });
  const [funtionInfo, setFuntionInfo] = React.useState({
    filterOpen: false,
    page: 0,
    rowsPerPage: 10,
    order: "desc",
    orderBy: "StartDate",
    selected: [],
  });

  const { register, getValues } = useForm({
    resolver: yupResolver(schema),
  });

  const handleFilter = () => {
    setFuntionInfo({
      ...funtionInfo,
      filterOpen: !funtionInfo.filterOpen,
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc =
      funtionInfo.orderBy === property && funtionInfo.order === "asc";
    setFuntionInfo({
      ...funtionInfo,
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
    // if (getApiResult.appManages && getApiResult.appManages.length > 0) {
    //   setBackdropOpen(true);
    //   setApiTrigger(apiTrigger + 1);
    // }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = getApiResult.events.map((n) => n.eventId);
      setFuntionInfo({
        ...funtionInfo,
        selected: newSelecteds,
      });
      return;
    }
    setFuntionInfo({
      ...funtionInfo,
      selected: [],
    });
  };

  const handleChange = (event, eventId) => {
    const selectedIndex = funtionInfo.selected.indexOf(eventId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(funtionInfo.selected, eventId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(funtionInfo.selected.slice(1));
    } else if (selectedIndex === funtionInfo.selected.length - 1) {
      newSelected = newSelected.concat(funtionInfo.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        funtionInfo.selected.slice(0, selectedIndex),
        funtionInfo.selected.slice(selectedIndex + 1)
      );
    }
    setFuntionInfo({
      ...funtionInfo,
      selected: newSelected,
    });
  };

  const handleChangePage = (event, newPage) => {
    setFuntionInfo({
      ...funtionInfo,
      page: newPage,
    });

    if (getApiResult.events && getApiResult.events.length > 0) {
      setLoading(2);
      setApiTrigger(apiTrigger + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setFuntionInfo({
      ...funtionInfo,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });

    if (getApiResult.events && getApiResult.events.length > 0) {
      setLoading(2);
      setApiTrigger(apiTrigger + 1);
    }
  };

  const isSelected = (name) => funtionInfo.selected.indexOf(name) !== -1;

  const searchType = () => {
    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    timerController.current = setTimeout(() => {
      setFuntionInfo({
        ...funtionInfo,
        page: 0,
      });
      setLoading(2);
      setApiTrigger(apiTrigger + 1);
    }, 500);
  };

  const apiCall = async (
    url,
    apiData,
    returnFunction = null
    //retrunErrorFunction = null
  ) => {
    setLoading(2);

    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + url, {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          ...apiData,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setErrorResult({
          type: "snackbar",
          errors: data.errors,
        });
        setLoading(0);
        return;
      }

      if (returnFunction) {
        returnFunction(data.results);
        // timerController.current = setTimeout(() => {
        //   setLoading(0);
        // }, 800);
      } else {
        setApiTrigger(apiTrigger + 1);
      }
    } catch (error) {
      const _errors = [];
      _errors.push("error : " + error.message);
      setErrorResult({
        type: "server",
        errors: _errors,
      });
      setLoading(0);
      return;
    }
  };

  const activeStatus = async () => {
    await apiCall(
      "/api/studio/activestatusevents",
      {
        selectedIds: funtionInfo.selected,
      },
      () => {
        setFuntionInfo({
          ...funtionInfo,
          selected: [],
        });
        setApiTrigger(apiTrigger + 1);
      }
    );
  };

  const inActiveStatus = async () => {
    await apiCall(
      "/api/studio/inactivestatusevents",
      {
        selectedIds: funtionInfo.selected,
      },
      () => {
        setFuntionInfo({
          ...funtionInfo,
          selected: [],
        });
        setTimeout(() => {
          setApiTrigger(apiTrigger + 1);
        }, 800);
      }
    );
  };

  const deleteItems = async () => {
    await apiCall(
      "/api/studio/deleteevents",
      {
        selectedIds: funtionInfo.selected,
      },
      () => {
        setFuntionInfo({
          ...funtionInfo,
          selected: [],
        });
        setTimeout(() => {
          setApiTrigger(apiTrigger + 1);
        }, 800);
      }
    );
  };

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    let timerBackdropOpen = null;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(env.apiBase + "/api/studio/getevents", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            ...getValues(),
            ...funtionInfo,
            studioId: studioId,
          }),
          ...options,
        });

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();
        console.log("data -------", data);

        if (data.errors && data.errors.length > 0) {
          setErrorResult({
            type: "server",
            errors: data.errors,
          });
          setLoading(0);
          return;
        }

        if (data.results) {
          setGetApiResult({
            ...data.results,
          });
        }
      } catch (error) {
        const _errors = [];
        _errors.push("error : " + error.message);
        setErrorResult({
          type: "server",
          errors: _errors,
        });
        setLoading(0);
        return;
      }

      if (loading === 2) {
        timerBackdropOpen = setTimeout(() => {
          setLoading(0);
        }, 800);
      } else {
        setLoading(0);
      }
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [apiTrigger]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    dispatch({
      type: "SET_STATE",
      payload: {
        breadcrumbs: [
          { title: "Dashboard", link: "/dashboard" },
          { title: "Studios", link: "/dashboard/studios" },
          { title: "Events", link: null },
        ],
      },
    });
    // eslint-disable-next-line
  }, []);

  if (loading === 1) {
    return <Loading />;
  } else if (errorResult.type === "server" && errorResult.errors.length > 0) {
    return <ErrorMessage errors={errorResult.errors} />;
  }
  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={loading === 2}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={funtionInfo.selected.length}
          handleFilter={handleFilter}
          studioId={studioId}
          activeStatus={activeStatus}
          inActiveStatus={inActiveStatus}
          deleteItems={deleteItems}
        />
        {funtionInfo.filterOpen && (
          <SearchPanel
            {...getApiResult}
            searchType={searchType}
            register={register}
          />
        )}
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              {...funtionInfo}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
              rowCount={getApiResult.events.length}
            />
            <TableBody>
              {getApiResult.events.map((eventRow, index) => {
                const isItemSelected = isSelected(eventRow.eventId);
                const labelId = `enhanced-table-checkbox-${index}`;
                const displayStartDate = utils.site.formatDDMMYYYY(
                  new Date(eventRow.startDate)
                );
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={eventRow.eventId}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                        onChange={(event) =>
                          handleChange(event, eventRow.eventId)
                        }
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      <Link
                        to={`/dashboard/studio/${studioId}/event/${eventRow.eventId}`}
                      >
                        {displayStartDate}
                      </Link>
                    </TableCell>
                    <TableCell align="left">{eventRow.ownerName}</TableCell>
                    <TableCell align="left">{eventRow.subject}</TableCell>
                    <TableCell align="left">{`$${eventRow.guestPrice}`}</TableCell>
                    <TableCell align="left">{eventRow.studioName}</TableCell>
                    <TableCell align="left">
                      {eventRow.eventStatus === 2 ? (
                        <CheckCircleOutlineIcon
                          fontSize="small"
                          style={{ color: "green" }}
                        />
                      ) : (
                        <RemoveCircleIcon
                          fontSize="small"
                          style={{ color: "red" }}
                        />
                      )}
                    </TableCell>
                    <TableCell align="left">{eventRow.maximumGuests}</TableCell>
                    <TableCell align="left">
                      <InfoIcon fontSize="small" style={{ color: "blue" }} />
                    </TableCell>
                    <TableCell align="left">
                      {eventRow.status === 1 ? (
                        <CheckCircleOutlineIcon
                          fontSize="small"
                          style={{ color: "green" }}
                        />
                      ) : (
                        <RemoveCircleIcon
                          fontSize="small"
                          style={{ color: "red" }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={
            getApiResult.events && getApiResult.events.length > 0
              ? getApiResult.events[0].totalCount
              : 0
          }
          rowsPerPage={funtionInfo.rowsPerPage}
          page={funtionInfo.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
