import React from "react";
import { FixedSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import env from "../../../env";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { ErrorMessage, Loading } from "components";
import utils from "utils";
import {
  //Paper,
  //Box,
  Typography,
  makeStyles,
  useTheme,
  //Avatar,
  Grid,
  Backdrop,
  //Dialog,
  //DialogTitle,
  //IconButton,
  //DialogContent,
  //DialogActions,
  //Button,
  //TextField,
  //List,
  ListItem,
  //ListItemText,
  ListItemIcon,
  //Container,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //paddingBottom: theme.spacing(10),
    //position: 'relative'
  },
  paper: {
    width: "100%",
    //paddingLeft : theme.spacing(1),
    //paddingRight : theme.spacing(1)
    //marginBottom: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 500,
    color: "#fff",
    //position: "absolute",
  },
  listItem: {
    borderBottom: `1px solid rgba(0,0,0,.1)`,
  },
  listItemIcon: {
    "& svg": {
      marginLeft: "auto",
    },
  },
}));

export function NewsLettersForUser(props) {
  const { userInfo, studentId } = props;
  const classes = useStyles();
  const theme = useTheme();
  const previousController = React.useRef();
  const [apiTrigger, setApiTrigger] = React.useState(0);
  const [errorResult, setErrorResult] = React.useState({
    type: "server",
    errors: [],
  });
  const [funtionInfo, setFuntionInfo] = React.useState({
    hasNextPage: true,
    isNextPageLoading: true,
    page: 0,
  });
  const [loading, setLoading] = React.useState(1);
  const [getApiResult, setGetApiResult] = React.useState({
    programs: [],
  });

  const isItemLoaded = (index) =>
    !funtionInfo.hasNextPage ||
    (index < getApiResult.programs.length &&
      getApiResult.programs[index] !== null);

  const loadNextPage = () => {
    setFuntionInfo({
      ...funtionInfo,
      page: funtionInfo.page + 1,
      isNextPageLoading: true,
    });

    setLoading(2);
    setApiTrigger(apiTrigger + 1);
  };

  const renderRow = (props) => {
    const { index, style } = props;
    const appManageItem = getApiResult.appManages[index];
    const createdDate = utils.site.formatDDMMYYYYWithTime(
      new Date(appManageItem.dateCreated)
    );
    
    const _img =
      appManageItem.videoURL && appManageItem.videoURL !== ""
        ? `https://img.youtube.com/vi/${utils.site.getYoutubeId(
            appManageItem.videoURL
          )}/maxresdefault.jpg`
        : "/assets/no-image.png";

    const _img2 = "/assets/no-image.png";

    if (!isItemLoaded(index)) {
      return <div style={style}>Loading...</div>;
    }
    return (
      <ListItem
        button
        style={style}
        key={index}
        className={classes.listItem}
        component={Link}
        to={`/dashboard/newsletter/${appManageItem.appManagementId}`}
      >
        <Grid container spacing={0}>
          <Grid item style={{ display: "flex", alignItems: "center" }}>
            <img width="60x" src={_img} />
          </Grid>
          <Grid
            item
            style={{
              marginLeft: theme.spacing(2),
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography style={{ display: "inline-block" }}>
              {appManageItem.title}
            </Typography>

            <Typography variant="caption" component="div">
              {createdDate}
            </Typography>
          </Grid>
        </Grid>

        <ListItemIcon className={classes.listItemIcon}>
          <ArrowForwardIosIcon fontSize="small" />
        </ListItemIcon>
      </ListItem>
    );
  };

  React.useEffect(() => {
    console.log("Check loop for programforuser");

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    let timerBackdropOpen = null;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(
          env.apiBase + "/api/appmanage/getappmanageitemsforuser",
          {
            method: "post",
            signal: signal,
            body: JSON.stringify({
              ...funtionInfo,
              userInfo: userInfo,
              studentId: studentId,
              type: 2,
            }),
            ...options,
          }
        );

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setErrorResult({
            type: "server",
            errors: data.errors,
          });
          setLoading(0);
          return;
        }

        if (data.results) {
          const { programs, totalCount } = data.results;
          let _hasNextPage = false;

          getApiResult.programs = getApiResult.programs.concat(programs || []);

          if (getApiResult.programs.length < totalCount) {
            _hasNextPage = true;
          }

          setFuntionInfo({
            ...funtionInfo,
            hasNextPage: _hasNextPage,
            isNextPageLoading: false,
          });

          setGetApiResult({
            ...data.results,
            ...getApiResult,
          });
        }
      } catch (error) {
        const _errors = [];
        _errors.push("error : " + error.message);
        setErrorResult({
          type: "server",
          errors: _errors,
        });
        setLoading(0);
        return;
      }

      if (loading === 2) {
        timerBackdropOpen = setTimeout(() => {
          setLoading(0);
        }, 800);
      } else {
        setLoading(0);
      }
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [apiTrigger]);

  if (loading === 1) {
    return <Loading />;
  } else if (errorResult.type === "server" && errorResult.errors.length > 0) {
    return <ErrorMessage errors={errorResult.errors} />;
  }
  return (
    <>
      <Backdrop className={classes.backdrop} open={loading === 2}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {getApiResult.appManages.length > 0 ? (
        <AutoSizer>
          {({ height, width }) => (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={
                funtionInfo.hasNextPage
                  ? getApiResult.appManages.length + 1
                  : getApiResult.appManages.length
              }
              loadMoreItems={
                funtionInfo.isNextPageLoading ? () => {} : loadNextPage
              }
            >
              {({ onItemsRendered, ref }) => (
                <FixedSizeList
                  height={height - 75}
                  width={width}
                  itemSize={70}
                  itemCount={
                    funtionInfo.hasNextPage
                      ? getApiResult.appManages.length + 1
                      : getApiResult.appManages.length
                  }
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                  {...props}
                >
                  {renderRow}
                </FixedSizeList>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
      ) : (
        <AutoSizer>
          {({ height, width }) => (
            <Typography
              style={{
                width: width,
                height: height,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Programs Data Not Found.
            </Typography>
          )}
        </AutoSizer>
      )}
    </>
  );
}