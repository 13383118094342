import React from "react";
import { variables } from "../../../cssInJs";
import { useParams } from "react-router-dom";
import env from "../../../env";
import { ErrorMessage, Loading } from "components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
//import { useStore } from "../../../store";
import clsx from "clsx";
import * as yup from "yup";
//import Autocomplete from "@material-ui/lab/Autocomplete";
import FilterListIcon from "@material-ui/icons/FilterList";
//import SearchIcon from "@material-ui/icons/Search";
//import DescriptionIcon from "@material-ui/icons/Description";
//import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
//import RefreshIcon from "@material-ui/icons/Refresh";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchIcon from "@material-ui/icons/Search";
import {
  Toolbar,
  lighten,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  Tooltip,
  ButtonGroup,
  Button,
  Backdrop,
  Avatar,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  TablePagination
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    //minWidth: 750,
  },
  infoIcon: {
    cursor: "pointer",
    color: variables.colorBlue2,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 500,
    color: "#fff",
    //position: "absolute",
  },
  textField: {
    width: "-webkit-fill-available",
  },
  viewicon: {
    cursor: "pointer",
  },
  checkbox: {
    //backgroundColor : 'yellow',
    //accent-color: #9b59b6;
    //fill : 'yellow'
    "& path": {
      fill: "orange",
    },
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 auto",
  },
}));

const useETHeadStyles = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: variables.colorBlue3,
    "& .MuiTableCell-head": {
      color: "white",
    },
    "& .MuiIconButton-label": {
      color: "white",
    },
    "& .MuiTableSortLabel-root:hover": {
      color: "white",
    },
    "& .MuiTableSortLabel-root": {
      lineHeight: "1.1",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: "white",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon":
      {
        color: "white",
      },
  },
}));

const useSearchPanelStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "& > *": {
      //margin: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
  },
  textFieldForTablet: {
    width: "-webkit-fill-available",
    margin: theme.spacing(1),
    //width: `calc(50% - ${theme.spacing(2)}px)`,
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { handleFilter } = props;

  return (
    <Toolbar className={clsx(classes.root)}>
      <Typography
        className={classes.title}
        //variant="h6"
        id="tableTitle"
        component="div"
        style={{ fontSize: "18px" }}
      >
        Promotions
      </Typography>

      <ButtonGroup size="small" aria-label="small outlined button group">
        <Tooltip title="Filters" arrow>
          <Button onClick={handleFilter}>
            <FilterListIcon fontSize="small" />
          </Button>
        </Tooltip>
      </ButtonGroup>
    </Toolbar>
  );
};

const headCells = [
  {
    id: "Image",
    numeric: false,
    disablePadding: false,
    label: "Image",
    hasSort: false,
  },
  {
    id: "FirstName",
    numeric: false,
    disablePadding: false,
    label: "Firs tName",
    hasSort: false,
  },
  {
    id: "LastName",
    numeric: false,
    disablePadding: false,
    label: "Last Name",
    hasSort: false,
  },
  {
    id: "StudentId",
    numeric: false,
    disablePadding: false,
    label: "Student Id",
    hasSort: false,
  },
  {
    id: "Belt",
    numeric: false,
    disablePadding: false,
    label: "Belt",
    hasSort: false,
  },
  {
    id: "NewBelt",
    numeric: false,
    disablePadding: false,
    label: "New Belt",
    hasSort: false,
  },
  {
    id: "Attendance",
    numeric: false,
    disablePadding: false,
    label: "Attendance",
    hasSort: false,
  },
];

const EnhancedTableHead = (props) => {
  const classes = useETHeadStyles();
  const { orderBy, order, onRequestSort, inputType } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headCells.map((headCell) => {
          if (headCell.hasSort) {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            );
          } else {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
              >
                {headCell.label}
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};

const SearchPanel = (props) => {
  const classes = useSearchPanelStyles();
  const {
    register,
    searchType,
    //getValues,
    //filters,
    //studios,
    //programs,
    //setValue,
    //trigger,
    //forceTrigger,
    //handleReload,
    //inputType,
  } = props;
  //const { filter, studioId } = getValues();
  return (
    <form
      method="POST"
      noValidate=""
      className={classes.root}
      //onSubmit={handleSubmit(submit)}
    >
      <FormControl
        className={classes.textFieldForTablet}
        variant="outlined"
        size="small"
      >
        <InputLabel htmlFor="outlined-adornment-password-cp">
          Search
        </InputLabel>

        <OutlinedInput
          id="outlined-adornment-password-cp"
          type="text"
          inputProps={{
            //...params.inputProps,
            defaultValue: "",
            autoComplete: "off",
            //autoComplete: "new-password",
          }}
          {...register("search", {
            onChange: (e) => {
              searchType();
            },
          })}
          startAdornment={
            <InputAdornment position="start">
              <IconButton aria-label="toggle password visibility" edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          labelWidth={60}
        />
      </FormControl>
    </form>
  );
};

const schema = yup.object().shape({
  termId: yup.string(),
});

export function Promotions(props) {
  const { inputType } = props;
  const _inputType = inputType || "normal";
  const { studioId } = useParams();
  const classes = useStyles();
  //const { dispatch } = useStore();
  const [apiTrigger, setApiTrigger] = React.useState(0);
  const [loading, setLoading] = React.useState(1);
  const [getApiResult, setGetApiResult] = React.useState();
  const [errorResult, setErrorResult] = React.useState({
    type: "server",
    errors: [],
  });
  const [funtionInfo, setFuntionInfo] = React.useState({
    filterOpen: _inputType === "tablet" ? false : true,
    page: 0,
    rowsPerPage: 10,
    order: "asc",
    orderBy: "Date",
  });
  const previousController = React.useRef();
  const timerController = React.useRef();

  const {
    register,
    getValues,
    //reset,
    //setValue,
    //trigger,
    //formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleFilter = () => {
    setFuntionInfo({
      ...funtionInfo,
      filterOpen: !funtionInfo.filterOpen,
    });
  };

  const searchType = () => {
    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    timerController.current = setTimeout(() => {
      setFuntionInfo({
        ...funtionInfo,
        page: 0,
      });
      setLoading(2);
      setApiTrigger(apiTrigger + 1);
    }, 500);
  };

  const handleChangePage = (event, newPage) => {
    setFuntionInfo({
      ...funtionInfo,
      page: newPage,
    });
    if (getApiResult.promotions && getApiResult.promotions.length > 0) {
      setLoading(2);
      setApiTrigger(apiTrigger + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setFuntionInfo({
      ...funtionInfo,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
    if (getApiResult.promotions && getApiResult.promotions.length > 0) {
      setLoading(2);
      setApiTrigger(apiTrigger + 1);
    }
  };

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    let timerBackdropOpen = null;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(env.apiBase + "/api/studio/getPromotions", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            ...funtionInfo,
            ...getValues(),
            StudioIdForTerm: studioId,
          }),
          ...options,
        });

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setErrorResult({
            type: "server",
            errors: data.errors,
          });
          setLoading(0);
          return;
        }

        if (data.results) {
          setGetApiResult({
            ...data.results,
          });
        }
      } catch (error) {
        const _errors = [];
        _errors.push("error : " + error.message);
        setErrorResult({
          type: "server",
          errors: _errors,
        });
        setLoading(0);
        return;
      }

      if (loading === 2) {
        timerBackdropOpen = setTimeout(() => {
          setLoading(0);
        }, 800);
      } else {
        setLoading(0);
      }
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [apiTrigger]);

  if (loading === 1) {
    return <Loading />;
  } else if (errorResult.type === "server" && errorResult.errors.length > 0) {
    return <ErrorMessage errors={errorResult.errors} />;
  }
  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={loading === 2}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar handleFilter={handleFilter} />

        {funtionInfo.filterOpen && (
          <SearchPanel
            {...getApiResult}
            searchType={searchType}
            register={register}
          />
        )}

        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              {...funtionInfo}
              //onRequestSort={handleRequestSort}
              //inputType={_inputType}
              //rowCount={getStudentsResult.students.length}
            />
            <TableBody>
              {getApiResult.promotions.map((promRow, index) => {
                const _img =
                  promRow.profilePic && promRow.profilePic !== ""
                    ? `${env.resourceBase}/media/uploadimages/${promRow.profilePic}`
                    : "/broken-image.jpg";
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={promRow.promotionId}
                  >
                    <TableCell align="left">
                      <Avatar src={_img} />
                    </TableCell>
                    <TableCell align="left"> {promRow.firstName} </TableCell>
                    <TableCell align="left"> {promRow.lastName} </TableCell>
                    <TableCell align="left">
                      {" "}
                      {promRow.displayStudentNo}{" "}
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      {promRow.oldBeltLevelName}{" "}
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      {promRow.newBeltLevelName}{" "}
                    </TableCell>
                    <TableCell align="left"> - </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={
            getApiResult.promotions && getApiResult.promotions.length > 0
              ? getApiResult.promotions[0].totalCount
              : 0
          }
          rowsPerPage={funtionInfo.rowsPerPage}
          page={funtionInfo.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
