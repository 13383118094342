import React from "react";
import env from "../../../env";
import { useParams, useHistory } from "react-router-dom";
import { ErrorMessage, Loading, SnackbarMessageV3 } from "components";
import utils from "utils";
import { variables } from "../../../cssInJs";
import * as yup from "yup";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
//import Skeleton from "@material-ui/lab/Skeleton";
//import InboxIcon from "@material-ui/icons/MoveToInbox";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import RoomIcon from "@material-ui/icons/Room";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from "@material-ui/icons/Phone";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Paper,
  Box,
  Typography,
  makeStyles,
  useTheme,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  Button,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  FormHelperText,
  Backdrop,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(10),
    //position: 'relative'
  },
  paper: {
    width: "100%",
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    //paddingLeft : theme.spacing(1),
    //paddingRight : theme.spacing(1)
    //marginBottom: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 500,
    color: "#fff",
    //position: "absolute",
  },
  listItem: {
    borderBottom: `1px solid rgba(0,0,0,.1)`,
  },
  listItemIcon: {
    "& svg": {
      marginLeft: "auto",
    },
  },
}));

const useViewStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    //paddingLeft : theme.spacing(1),
    //paddingRight : theme.spacing(1)
    //marginBottom: theme.spacing(2),
  },
  listItem: {
    backgroundColor: "red",
    "& .MuiListItemIcon-root": {
      minWidth: theme.spacing(2),
    },
  },
}));

const useBookViewStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    //paddingLeft : theme.spacing(1),
    //paddingRight : theme.spacing(1)
    //marginBottom: theme.spacing(2),
  },
  formRoot: {
    "& > *": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      //paddingLeft: theme.spacing(1),
      //paddingRight: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
    "& .ck-editor": {
      margin: theme.spacing(1),
    },
    "& .ck-editor__editable": {
      minHeight: "150px",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      //transform: "translate(22px, -6px) scale(0.75)",
    },
    "& .MuiInputBase-input, & .MuiInputLabel-outlined": {
      //fontSize: "14px",
    },
  },
  textField: {
    //margin: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: `-webkit-fill-available`,
  },
  checkBoxTextField: {
    width: `-webkit-fill-available`,
    marginLeft: "3px",
    "& .MuiFormControlLabel-root": {
      marginBottom: "0px",
    },
  },
  hr: {
    borderColor: "rgba(0,0,0,.1)",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
}));

const EventForUserViewInfo = (props) => {
  const { eventView } = props;
  const classes = useViewStyles();
  const theme = useTheme();
  const displayEventStartDate = utils.site.formatDDMMYYYY(
    new Date(eventView.startDate)
  );

  return (
    <>
      <Paper className={classes.paper} elevation={4} style={{ padding: "0px" }}>
        <Typography
          variant="subtitle1"
          style={{
            backgroundColor: "blue",
            padding: theme.spacing(1),
            color: "white",
          }}
        >
          {`${eventView.subject} By ${eventView.ownerName}`}
        </Typography>
        <List component="nav" aria-label="main mailbox folders">
          <ListItem style={{ paddingTop: "0px", paddingBottom: "0px" }}>
            <ListItemIcon style={{ minWidth: theme.spacing(3.5) }}>
              <AccessTimeIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              secondary={
                <Typography color="textPrimary" component="div">
                  {`${displayEventStartDate}, at `}
                  <span style={{ fontWeight: "700" }}>
                    {eventView.startTime}
                  </span>
                  {` to `}
                  <span style={{ fontWeight: "700" }}>{eventView.endTime}</span>
                </Typography>
              }
            />
          </ListItem>
          <ListItem style={{ paddingTop: "0px", paddingBottom: "0px" }}>
            <ListItemIcon style={{ minWidth: theme.spacing(3.5) }}>
              <RoomIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              secondary={
                <Typography color="textPrimary" component="div">
                  {`${eventView.address1} ${eventView.address2} ${eventView.city} ${eventView.country}`}
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Paper>
    </>
  );
};

const EventForUserBookingView = (props) => {
  const {
    closeBookNowView,
    errors,
    register,
    familyFields,
    guestsFields,
    paymentTypes,
    addGuest,
    removeGuest,
    isSubmitting,
    handleSubmit,
    submit,
    getValues,
  } = props;
  const theme = useTheme();
  const classes = useBookViewStyles();
  const { amoutFee } = getValues();
  return (
    <>
      <Paper
        className={classes.paper}
        elevation={4}
        style={{
          padding: theme.spacing(1),
          paddingBottom: theme.spacing(2),
          marginTop: theme.spacing(1),
        }}
      >
        <Button
          color="primary"
          style={{ paddingLeft: "0px" }}
          onClick={closeBookNowView}
        >
          <ArrowBackIcon
            fontSize="small"
            style={{ marginRight: theme.spacing(1) }}
          />
          Back to Detail
        </Button>

        <Typography variant="subtitle1">Booking Information</Typography>
        <form
          method="POST"
          noValidate=""
          //onSubmit={submit}
          onSubmit={handleSubmit(submit)}
          //className={classes.formRoot}
        >
          <TextField
            //id="outlined-basic"
            label="First Name*"
            variant="outlined"
            error={!!errors.firstName}
            helperText={errors?.firstName?.message}
            size="small"
            className={classes.textField}
            {...register("firstName")}
          />
          <TextField
            //id="outlined-basic"
            label="Last Name*"
            variant="outlined"
            error={!!errors.lastName}
            helperText={errors?.lastName?.message}
            size="small"
            className={classes.textField}
            {...register("lastName")}
          />
          <TextField
            //id="outlined-basic"
            label="Email*"
            variant="outlined"
            error={!!errors.email}
            helperText={errors?.email?.message}
            size="small"
            className={classes.textField}
            {...register("email")}
          />

          <Typography
            variant="subtitle1"
            style={{ marginTop: theme.spacing(1) }}
          >
            Family
          </Typography>

          {familyFields.map((field, index) => {
            return (
              <FormControl
                key={field.id}
                error={!!errors.family?.[index]?.checkFamilyMember}
                component="fieldset"
                className={classes.checkBoxTextField}
                size="small"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      {...register(`family.${index}.checkFamilyMember`)}
                    />
                  }
                  className={`${
                    !!errors.family?.[index]?.checkFamilyMember
                      ? "Mui-error"
                      : "Mui-error-non"
                  }`}
                  label={`${field.firstName} ${field.lastName}`}
                />
              </FormControl>
            );
          })}
          <Typography
            variant="subtitle1"
            style={{ marginTop: theme.spacing(1) }}
          >
            Guest
            <Button
              color="primary"
              size="small"
              style={{ float: "right" }}
              onClick={addGuest}
            >
              Add
            </Button>
          </Typography>

          {guestsFields.map((field, index) => {
            return (
              <Box key={field.id}>
                <Typography
                  variant="subtitle1"
                  display="block"
                  style={{
                    overflowX: "auto",
                    width: "100%",
                    color: "gray",
                  }}
                >
                  {`Guest ${index + 1}`}
                  <Button
                    color="secondary"
                    size="small"
                    style={{ float: "right" }}
                    onClick={() => {
                      removeGuest(index);
                    }}
                  >
                    Remove
                  </Button>
                </Typography>
                <TextField
                  //id="outlined-basic"
                  label="Name*"
                  variant="outlined"
                  error={!!errors.guests?.[index]?.firstName}
                  helperText={errors?.guests?.[index]?.firstName?.message}
                  size="small"
                  className={classes.textField}
                  {...register(`guests.${index}.firstName`)}
                />

                <TextField
                  //id="outlined-basic"
                  label="Contact No*"
                  variant="outlined"
                  error={!!errors.guests?.[index]?.contactNo}
                  helperText={errors?.guests?.[index]?.contactNo?.message}
                  size="small"
                  className={classes.textField}
                  {...register(`guests.${index}.contactNo`)}
                />
              </Box>
            );
          })}

          <hr className={classes.hr} />

          <FormControl
            variant="outlined"
            size="small"
            className={classes.textField}
            error={!!errors.paymentMode}
          >
            <InputLabel htmlFor="paymentMode"> Payment Options* </InputLabel>
            <Select
              native
              label="Payment Options"
              inputProps={{
                name: "paymentMode",
                id: "paymentMode",
              }}
              {...register("paymentMode")}
              //labelWidth={80}
            >
              {/* <option aria-label="None" value="" /> */}
              {paymentTypes.map((paymentType, index) => {
                return (
                  <option
                    key={index}
                    aria-label="None"
                    value={paymentType.value}
                  >
                    {paymentType.name}
                  </option>
                );
              })}
            </Select>
            {!!errors.paymentMode && (
              <FormHelperText error id="weeks-error">
                {errors?.paymentMode?.message}
              </FormHelperText>
            )}
          </FormControl>

          <Typography
            variant="subtitle1"
            style={{
              paddingLeft: theme.spacing(0.5),
              paddingRight: theme.spacing(0.5),
              marginTop: theme.spacing(2),
            }}
          >
            Net Amount to Pay
            <span
              style={{ float: "right", color: "blue" }}
            >{`$ ${amoutFee}`}</span>
          </Typography>

          <Box mt={1}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              startIcon={
                isSubmitting && (
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )
              }
              type="submit"
              disabled={isSubmitting}
            >
              Book Now
            </Button>
          </Box>
        </form>
      </Paper>
    </>
  );
};

const EventForUserView = (props) => {
  const { eventView, openBookNowView } = props;
  const classes = useViewStyles();
  const theme = useTheme();
  const displayDeadLine = utils.site.formatDDMMYYYY(
    new Date(eventView.deadLine)
  );

  return (
    <>
      <Paper
        className={classes.paper}
        elevation={4}
        style={{
          padding: theme.spacing(1),
          paddingBottom: theme.spacing(2),
          marginTop: theme.spacing(1),
        }}
      >
        <Typography variant="subtitle1">Event Details</Typography>
        <Typography variant="body2">{eventView.description}</Typography>
        <Grid container spacing={0} style={{ marginTop: theme.spacing(2) }}>
          <Grid item style={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Guest Limit (max)</Typography>
          </Grid>
          <Grid item style={{ paddingLeft: theme.spacing(1) }}>
            <Typography variant="subtitle1">{`${eventView.maximumGuests} Persion(s)`}</Typography>
          </Grid>
        </Grid>

        <Typography
          variant="subtitle1"
          align="center"
          style={{
            backgroundColor: "blue",
            padding: theme.spacing(1),
            color: "white",
            marginTop: theme.spacing(0.5),
            marginLeft: theme.spacing(-1),
            marginRight: theme.spacing(-1),
          }}
        >
          {`Due Date : `}
          <span>{displayDeadLine}</span>
        </Typography>
        <Typography variant="subtitle1" style={{ marginTop: theme.spacing(1) }}>
          Contact
        </Typography>
        <List component="nav" aria-label="main mailbox folders">
          <ListItem
            style={{
              paddingTop: "0px",
              paddingBottom: "0px",
              paddingLeft: theme.spacing(0),
              paddingRight: theme.spacing(0),
            }}
          >
            <ListItemIcon style={{ minWidth: theme.spacing(3.5) }}>
              <MailOutlineIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              secondary={
                <Typography color="textPrimary" display="block">
                  {`${eventView.contactEmail}`}
                </Typography>
              }
            />
          </ListItem>
          <ListItem
            style={{
              paddingTop: "0px",
              paddingBottom: "0px",
              paddingLeft: theme.spacing(0),
              paddingRight: theme.spacing(0),
            }}
          >
            <ListItemIcon style={{ minWidth: theme.spacing(3.5) }}>
              <PhoneIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              secondary={
                <Typography color="textPrimary" display="block">
                  {`${eventView.contactNumber1}`}
                </Typography>
              }
            />
          </ListItem>
        </List>

        <Box
          style={{
            marginLeft: theme.spacing(-1),
            marginRight: theme.spacing(-1),
            backgroundColor: "blue",
          }}
        >
          <Grid
            container
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={4}>
              <Typography
                variant="subtitle1"
                align="center"
                style={{ color: "white" }}
              >
                {" "}
                $10{" "}
              </Typography>
              <Typography
                variant="caption"
                display="block"
                align="center"
                style={{ color: "white" }}
              >
                {" "}
                Parnet{" "}
              </Typography>
            </Grid>
            <Grid item xs={4} style={{ borderLeft: "1px solid white" }}>
              <Typography
                variant="subtitle1"
                align="center"
                style={{ color: "white" }}
              >
                {" "}
                $10{" "}
              </Typography>
              <Typography
                variant="caption"
                display="block"
                align="center"
                style={{ color: "white" }}
              >
                {" "}
                Child{" "}
              </Typography>
            </Grid>
            <Grid item xs={4} style={{ borderLeft: "1px solid white" }}>
              <Typography
                variant="subtitle1"
                align="center"
                style={{ color: "white" }}
              >
                {" "}
                $10{" "}
              </Typography>
              <Typography
                variant="caption"
                display="block"
                align="center"
                style={{ color: "white" }}
              >
                {" "}
                Guest{" "}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box mt={3}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={openBookNowView}
          >
            Book Now
          </Button>
        </Box>
      </Paper>
    </>
  );
};

const schema = yup.object().shape({
  firstName: yup.string().required("First Name is required."),
  lastName: yup.string().required("Last Name is required."),
  email: yup.string().required("Email is required.").email("Email is required"),
  family: yup.array().of(
    yup.object().shape({
      checkFamilyMember: yup.boolean(),
    })
  ),
  guests: yup.array().of(
    yup.object().shape({
      firstName: yup.string().required("Name is required."),
      contactNo: yup.string().required("Contact No is required."),
    })
  ),
  paymentMode: yup.number().required("Payment Option is required."),
  amoutFee: yup.number(),
});

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export function EventForUser(props) {
  const { userInfo, studentId } = props;
  const { eventId } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const [errorResult, setErrorResult] = React.useState({
    type: "server",
    errors: [],
  });
  const [loading, setLoading] = React.useState(1);
  const [getApiResult, setGetApiResult] = React.useState();
  const [funtionInfo, setFuntionInfo] = React.useState({
    bookNowMode: false,
  });
  const previousController = React.useRef();
  const timerController = React.useRef();

  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    getValues,
    watch,
    register,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { paymentMode: 1, amoutFee: 0 },
  });

  const {
    fields: familyFields,
    //append: familyAppend,
    //remove: familyRemove,
  } = useFieldArray({
    name: "family",
    control,
  });

  const {
    fields: guestsFields,
    append: guestsAppend,
    remove: guestsRemove,
  } = useFieldArray({
    name: "guests",
    control,
  });

  const updateAmoutFee = () => {
    const { childPrice, parentPrice, guestPrice } = (getApiResult &&
      getApiResult.eventView) || {
      childPrice: 0,
      parentPrice: 0,
      guestPrice: 0,
    };
    const { family, guests } = getValues();
    let totalAmoutFee = 0;
    family.forEach((familyMember) => {
      if (familyMember.checkFamilyMember === true) {
        if (familyMember.familyType === "parent") {
          totalAmoutFee = totalAmoutFee + parentPrice;
        } else if (familyMember.familyType === "child") {
          totalAmoutFee = totalAmoutFee + childPrice;
        }
      }
    });
    totalAmoutFee = totalAmoutFee + guests.length * guestPrice;
    setValue("amoutFee", totalAmoutFee);
    trigger("amoutFee");
  };

  const addGuest = () => {
    guestsAppend({ firstName: null, contactNo: null });
    updateAmoutFee();
  };

  const removeGuest = (index) => {
    guestsRemove(index);
    updateAmoutFee();
  };

  const openBookNowView = () => {
    setFuntionInfo({
      ...funtionInfo,
      bookNowMode: true,
    });
  };

  const closeBookNowView = () => {
    setFuntionInfo({
      ...funtionInfo,
      bookNowMode: false,
    });
  };

  const handleCloseSnackBar = () => {
    setErrorResult({
      ...errorResult,
      type: "",
      errors: [],
    });
  };

  const apiCall = async (
    url,
    apiData,
    returnFunction = null
    //retrunErrorFunction = null
  ) => {
    setLoading(2);

    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + url, {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          ...apiData,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setErrorResult({
          type: "snackbar",
          errors: data.errors,
        });
        setLoading(0);
        return;
      }

      if (returnFunction) {
        // timerController.current = setTimeout(async () => {
        //   setLoading(0);
        //   await returnFunction(data.results);
        // }, 800);
        await sleep(800);
        setLoading(0);
        returnFunction(data.results);
        // await sleep(3000);
        //await returnFunction(data.results);
        //setLoading(0);
        //returnFunction(data.results);
      } else {
        //setApiTrigger(apiTrigger + 1);
      }
    } catch (error) {
      const _errors = [];
      _errors.push("error : " + error.message);
      setErrorResult({
        type: "server",
        errors: _errors,
      });
      setLoading(0);
      return;
    }
  };

  const submit = async (dataModel) => {
    const checkFamilyMember = (dataModel.family || []).find(
      (x) => x.checkFamilyMember === true
    )
      ? true
      : false;
    if (!checkFamilyMember) {
      setErrorResult({
        type: "snackbar",
        errors: [{ errorMessage: "Please any family member" }],
      });
      return;
    }

    const contactNumber = getApiResult.parents[0].mobileNumber;
    const requestedBy = `${getApiResult.parents[0].firstName} ${getApiResult.parents[0].lastName}`;
    const requestedIdBy = getApiResult.parents[0].parentInfoId;
    const attendee = dataModel.family.length + dataModel.guests.length;

    await apiCall(
      "/api/studio/saveeventrequest",
      {
        ...dataModel,
        eventId: eventId,
        contactNumber: contactNumber,
        attendee: attendee,
        requestedBy: requestedBy,
        requestedIdBy: requestedIdBy,
      },
      () => {
        history.push(`/dashboard/events`);
      }
    );
  };

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    let timerBackdropOpen = null;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(env.apiBase + "/api/studio/geteventforuser", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            userInfo: userInfo,
            eventId: eventId,
            studentId: studentId,
          }),
          ...options,
        });

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setErrorResult({
            type: "server",
            errors: data.errors,
          });
          setLoading(0);
          return;
        }

        if (data.results) {
          const { parents, sibling } = data.results;
          const family = (parents || []).concat(sibling || []);

          if (parents && parents.length > 0) {
            setValue("firstName", parents[0].firstName);
            setValue("lastName", parents[0].lastName);
            setValue("email", parents[0].email);
            trigger(["firstName", "lastName", "email"]);
          }

          setValue("family", family);
          trigger("family");

          setGetApiResult({
            ...data.results,
            //family: family,
          });
        }
      } catch (error) {
        const _errors = [];
        _errors.push("error : " + error.message);
        setErrorResult({
          type: "server",
          errors: _errors,
        });
        setLoading(0);
        return;
      }

      if (loading === 2) {
        timerBackdropOpen = setTimeout(() => {
          setLoading(0);
        }, 800);
      } else {
        setLoading(0);
      }
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name.includes(".checkFamilyMember")) {
        updateAmoutFee();
      }
    });
    return () => subscription.unsubscribe();

    // eslint-disable-next-line
  }, [watch, getApiResult]);

  if (loading === 1) {
    return <Loading />;
  } else if (errorResult.type === "server" && errorResult.errors.length > 0) {
    return <ErrorMessage errors={errorResult.errors} />;
  }
  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={loading === 2}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <SnackbarMessageV3
        {...errorResult}
        handleCloseFunction={handleCloseSnackBar}
        severity="error"
      />

      <EventForUserViewInfo {...getApiResult} />
      {funtionInfo.bookNowMode ? (
        <EventForUserBookingView
          {...getApiResult}
          register={register}
          errors={errors}
          familyFields={familyFields || []}
          addGuest={addGuest}
          removeGuest={removeGuest}
          guestsFields={guestsFields}
          handleSubmit={handleSubmit}
          submit={submit}
          isSubmitting={isSubmitting}
          closeBookNowView={closeBookNowView}
          getValues={getValues}
        />
      ) : (
        <EventForUserView {...getApiResult} openBookNowView={openBookNowView} />
      )}
    </div>
  );
}
