import React from "react";
import { useParams } from "react-router-dom";
import { variables } from "../../../cssInJs";
import { ErrorMessage, Loading } from "components";
import env from "../../../env";
import { getIdToken } from "auth";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import utils from "utils";
import Autocomplete from "@material-ui/lab/Autocomplete";
import EditIcon from "@material-ui/icons/Edit";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import {
  Paper,
  Box,
  Typography,
  makeStyles,
  Avatar,
  Grid,
  Backdrop,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@material-ui/core";

registerPlugin(FilePondPluginFileValidateSize);
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginImageResize);
registerPlugin(FilePondPluginImageTransform);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    //paddingLeft : theme.spacing(1),
    //paddingRight : theme.spacing(1)
    //marginBottom: theme.spacing(2),
  },
  largeAvata: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    margin: "auto",
  },
  topInfo: {
    position: "fixed",
    width: "100%",
    maxWidth: "960px",
    backgroundColor: variables.colorBlue3,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    minHeight: "130px",
    [theme.breakpoints.up("md")]: {
      minHeight: "140px",
    },
    // necessary for content to be below app bar
  },
  mainInfoText: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    border: "1px solid rgba(0,0,0,.1)",
    borderLeft: "none",
    borderRight: "none",
  },
  gridc: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  gridleft: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  gridright: {
    padding: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  infoIcon: {
    cursor: "pointer",
    color: variables.colorBlue2,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 500,
    color: "#fff",
    //position: "absolute",
  },
  textField: {
    width: "-webkit-fill-available",
  },
  viewicon: {
    cursor: "pointer",
  },
  checkbox: {
    //backgroundColor : 'yellow',
    //accent-color: #9b59b6;
    //fill : 'yellow'
    "& path": {
      fill: "orange",
    },
  },
}));

const useCoDialogStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textField: {
    width: `-webkit-fill-available`,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formControl: {
    width: `-webkit-fill-available`,
    display: "inline-flex",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formControl2: {
    width: `calc(100% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
    margin: theme.spacing(1),
  },
  datePicker: {
    margin: theme.spacing(1),
    display: "inline-block",
    width: `calc(50% - ${theme.spacing(2)}px)`,
    "& .react-datepicker-wrapper, & input": {
      width: "100%",
    },
    "& .react-datepicker-popper": {
      zIndex: 2,
    },
  },
}));

const AddressDialog = (props) => {
  const classes = useCoDialogStyles();
  const {
    addressOpen,
    handleCloseAddrDialog,
    isSubmitting,
    errors,
    register,
    getValues,
    setValue,
    trigger,
    stateList,
    cityList,
    handleGetState,
    handleGetCitys,
    submitAddress,
    handleSubmit,
  } = props;

  const { country, state, city } = getValues();

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      //onClose={handleClose}
      open={addressOpen}
      scroll="paper"
      maxWidth="sm"
      fullWidth={true}
    >
      <form
        method="POST"
        noValidate=""
        //className={classes.formRoot}
        onSubmit={handleSubmit((dataResult) => submitAddress(dataResult))}
      >
        <DialogTitle disableTypography>
          <Typography variant="h6"> Address Information </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleCloseAddrDialog}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            //id="outlined-basic"
            label="Address 1*"
            variant="outlined"
            error={!!errors.address1}
            helperText={errors?.address1?.message}
            size="small"
            className={classes.textField}
            {...register("address1")}
          />
          <TextField
            //id="outlined-basic"
            label="Address 2*"
            variant="outlined"
            error={!!errors.address2}
            helperText={errors?.address2?.message}
            size="small"
            className={classes.textField}
            {...register("address2")}
          />

          <Autocomplete
            id="country"
            //disabled={!checkCreateBatch}
            options={variables.countries || []}
            className={classes.formControl}
            classes={{
              option: classes.option,
            }}
            autoHighlight
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option, value) => option.label === value.label}
            value={
              (variables.countries || []).find((x) => x.label === country) ||
              null
            }
            onChange={(event, newValue) => {
              setValue("country", newValue ? newValue.label : null);
              trigger("country");
              handleGetState();
            }}
            renderOption={(option) => (
              <React.Fragment>{option.label}</React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Country*"
                variant="outlined"
                size="small"
                error={!!errors.country}
                helperText={errors?.country?.message}
                inputProps={{
                  ...params.inputProps,
                  //autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />

          <Autocomplete
            id="state"
            //disabled={!checkCreateBatch}
            options={stateList || []}
            className={classes.formControl}
            classes={{
              option: classes.option,
            }}
            autoHighlight
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.iso2 === value.iso2}
            value={(stateList || []).find((x) => x.iso2 === state) || null}
            onChange={(event, newValue) => {
              setValue("state", newValue ? newValue.iso2 : null);
              trigger("state");
              handleGetCitys();
            }}
            renderOption={(option) => (
              <React.Fragment>{option.name}</React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="State*"
                variant="outlined"
                size="small"
                error={!!errors.state}
                helperText={errors?.state?.message}
                inputProps={{
                  ...params.inputProps,
                  //autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />

          <Autocomplete
            id="city"
            //disabled={!checkCreateBatch}
            options={cityList || []}
            className={classes.formControl}
            classes={{
              option: classes.option,
            }}
            autoHighlight
            getOptionLabel={(option) => option.suburb}
            getOptionSelected={(option, value) =>
              option.suburb === value.suburb
            }
            value={(cityList || []).find((x) => x.suburb === city) || null}
            onChange={(event, newValue) => {
              setValue("city", newValue ? newValue.suburb : null);
              trigger("city");
            }}
            renderOption={(option) => (
              <React.Fragment>{option.suburb}</React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="City*"
                variant="outlined"
                size="small"
                error={!!errors.city}
                helperText={errors?.city?.message}
                inputProps={{
                  ...params.inputProps,
                  //autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />

          <TextField
            //id="outlined-basic"
            label="Postal Code*"
            variant="outlined"
            error={!!errors.postalCode}
            helperText={errors?.postalCode?.message}
            size="small"
            className={classes.textField}
            {...register("postalCode")}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            type="submit"
            startIcon={
              isSubmitting && (
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )
            }
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const AccountDialog = (props) => {
  const classes = useCoDialogStyles();
  const {
    accountOpen,
    handleCloseAccountDialog,
    isSubmitting,
    errors,
    register,
    //getValues,
    //setValue,
    //trigger,
    submitAccount,
    handleSubmit,
  } = props;
  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      //onClose={handleClose}
      open={accountOpen}
      scroll="paper"
      maxWidth="sm"
      fullWidth={true}
    >
      <form
        method="POST"
        noValidate=""
        //className={classes.formRoot}
        onSubmit={handleSubmit((dataResult) => submitAccount(dataResult))}
      >
        <DialogTitle disableTypography>
          <Typography variant="h6"> Account Holder Information </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleCloseAccountDialog}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            //id="outlined-basic"
            label="First Name*"
            variant="outlined"
            error={!!errors.parent4FirstName}
            helperText={errors?.parent4FirstName?.message}
            size="small"
            className={classes.textField}
            {...register("parent4FirstName")}
          />
          <TextField
            //id="outlined-basic"
            label="Last Name*"
            variant="outlined"
            error={!!errors.parent4LastName}
            helperText={errors?.parent4LastName?.message}
            size="small"
            className={classes.textField}
            {...register("parent4LastName")}
          />

          <TextField
            //id="outlined-basic"
            label="Phone Number"
            variant="outlined"
            error={!!errors.parent4MobileNumber}
            helperText={errors?.parent4MobileNumber?.message}
            size="small"
            className={classes.textField}
            {...register("parent4MobileNumber")}
          />
          <TextField
            //id="outlined-basic"
            label="Email"
            variant="outlined"
            error={!!errors.parent4Email}
            helperText={errors?.parent4Email?.message}
            size="small"
            className={classes.textField}
            {...register("parent4Email")}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            type="submit"
            startIcon={
              isSubmitting && (
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )
            }
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const schemaAddress = yup.object().shape({
  address1: yup.string().required("Address 1 is required."),
  address2: yup.string(),
  country: yup.string().required("Country is required."),
  state: yup.string().required("State is required."),
  city: yup.string().required("City is required."),
  postalCode: yup.string().required("Postal Code is required."),
});

const schemaAccountHolder = yup.object().shape({
  parent4FirstName: yup.string().required("First Name is required."),
  parent4LastName: yup.string().required("Last Name is required."),
  parent4MobileNumber: yup.string().nullable(true),
  parent4Email: yup.string().nullable(true).email("Email is required"),
});

export function StudentDetailForUser(props) {
  const { userInfo } = props;
  const { studentId } = useParams();
  const classes = useStyles();
  const filePondRef = React.useRef(null);
  const previousController = React.useRef(null);
  const timerController = React.useRef();
  const [getApiResult, setGetApiResult] = React.useState();
  const [loading, setLoading] = React.useState(1);
  const [apiTrigger, setApiTrigger] = React.useState(0);
  const [errorResult, setErrorResult] = React.useState({
    type: "server",
    errors: [],
  });
  const [funtionInfo, setFuntionInfo] = React.useState({
    addressOpen: false,
    accountOpen: false,
  });

  const {
    register: registerAddr,
    //control: controlAddr,
    reset: resetAddr,
    handleSubmit: handleSubmitAddr,
    getValues: getValuesAddr,
    setValue: setValueAddr,
    trigger: triggerAddr,
    formState: { isSubmitting: isSubmittingAddr, errors: errorsAddr },
  } = useForm({
    resolver: yupResolver(schemaAddress),
  });

  const {
    register: registerAccount,
    //control: controlAddr,
    reset: resetAccount,
    handleSubmit: handleSubmitAccount,
    getValues: getValuesAccount,
    setValue: setValueAccount,
    trigger: triggerAccount,
    formState: { isSubmitting: isSubmittingAccount, errors: errorsAccount },
  } = useForm({
    resolver: yupResolver(schemaAccountHolder),
  });

  const handleOpenAddrDialog = () => {
    resetAddr({
      address1: getApiResult.student.address1,
      address2: getApiResult.student.address2,
      country: getApiResult.student.country,
      state: getApiResult.student.state,
      city: getApiResult.student.city,
      postalCode: getApiResult.student.postalCode,
    });
    setFuntionInfo({
      ...funtionInfo,
      addressOpen: true,
    });
  };

  const handleCloseAddrDialog = () => {
    setFuntionInfo({
      ...funtionInfo,
      addressOpen: false,
    });
    if (document.activeElement) {
      document.activeElement.blur();
    }
  };

  const handleOpenAccountDialog = () => {
    resetAccount({
      parent4FirstName: getApiResult.accountInfo.firstName,
      parent4LastName: getApiResult.accountInfo.lastName,
      parent4MobileNumber: getApiResult.accountInfo.mobileNumber,
      parent4Email: getApiResult.accountInfo.email,
    });
    setFuntionInfo({
      ...funtionInfo,
      accountOpen: true,
    });
  };

  const handleCloseAccountDialog = () => {
    setFuntionInfo({
      ...funtionInfo,
      accountOpen: false,
    });
    if (document.activeElement) {
      document.activeElement.blur();
    }
  };

  const apiCall = async (
    url,
    apiData,
    returnFunction = null
    //retrunErrorFunction = null
  ) => {
    setLoading(2);

    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + url, {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          ...apiData,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setErrorResult({
          type: "snackbar",
          errors: data.errors,
        });
        setLoading(0);
        return;
      }

      if (returnFunction) {
        returnFunction(data.results);
        timerController.current = setTimeout(() => {
          setLoading(0);
        }, 800);
      } else {
        setApiTrigger(apiTrigger + 1);
      }
    } catch (error) {
      const _errors = [];
      _errors.push("error : " + error.message);
      setErrorResult({
        type: "server",
        errors: _errors,
      });
      setLoading(0);
      return;
    }
  };

  const handleGetState = async () => {
    const { country } = getValuesAddr();
    if (!country) {
      return;
    }

    await apiCall(
      "/api/studio/getstatelist",
      {
        country: country,
      },
      (result) => {
        setValueAddr("state", null);
        setValueAddr("city", null);
        triggerAddr(["state", "city"]);
        setGetApiResult({
          ...getApiResult,
          ...result,
        });
      }
    );
  };

  const handleGetCitys = async () => {
    const { state } = getValuesAddr();
    if (!state) {
      return;
    }

    await apiCall(
      "/api/studio/getcitylist",
      {
        state: state,
      },
      (result) => {
        setValueAddr("city", null);
        triggerAddr("city");
        setGetApiResult({
          ...getApiResult,
          ...result,
        });
      }
    );
  };

  const submitAddress = async (dataResult) => {
    await apiCall(
      "/api/student/updatestudentaddress",
      {
        ...dataResult,
        studentId: getApiResult.student.studentId,
      },
      () => {
        if (document.activeElement) {
          document.activeElement.blur();
        }
        setFuntionInfo({
          ...funtionInfo,
          addressOpen: false,
        });
        setApiTrigger(apiTrigger + 1);
      }
    );
  };

  const submitAccount = async (dataResult) => {
    // await apiCall(
    //   "/api/student/updatestudentaddress",
    //   {
    //     ...dataResult,
    //     studentId: getApiResult.student.studentId,
    //   },
    //   () => {
    //     if (document.activeElement) {
    //       document.activeElement.blur();
    //     }
    //     setFuntionInfo({
    //       ...funtionInfo,
    //       addressOpen: false,
    //     });
    //     setApiTrigger(apiTrigger + 1);
    //   }
    // );
  };

  const openFilePond = () => {
    setLoading(2);
    setTimeout(() => {
      filePondRef.current.browse();
      setLoading(0);
    }, 500);
  };

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    let timerBackdropOpen = null;

    const fetchData = async () => {
      try {
        const token = await getIdToken();
        const options = { headers: { "Content-Type": "application/json" } };
        if (token) {
          options.headers["Authorization"] = `Bearer ${token}`;
        }
        const res = await fetch(
          env.apiBase + "/api/student/getstudentforuser",
          {
            method: "post",
            signal: signal,
            body: JSON.stringify({
              ...userInfo,
              studentId: studentId,
            }),
            ...options,
          }
        );

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();
        
        if (data.errors && data.errors.length > 0) {
          setErrorResult({
            type: "server",
            errors: data.errors,
          });
          setLoading(0);
          return;
        }

        if (data.results) {
          setGetApiResult({
            ...data.results,
          });
        }
      } catch (error) {
        const _errors = [];
        _errors.push("error : " + error.message);
        setErrorResult({
          type: "server",
          errors: _errors,
        });
        setLoading(0);
        return;
      }

      if (loading === 2) {
        timerBackdropOpen = setTimeout(() => {
          setLoading(0);
        }, 800);
      } else {
        setLoading(0);
      }
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [apiTrigger]);

  if (loading === 1) {
    return <Loading />;
  } else if (errorResult.type === "server" && errorResult.errors.length > 0) {
    return <ErrorMessage errors={errorResult.errors} />;
  }
  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={loading === 2}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <AddressDialog
        {...funtionInfo}
        {...getApiResult}
        handleCloseAddrDialog={handleCloseAddrDialog}
        register={registerAddr}
        isSubmitting={isSubmittingAddr}
        errors={errorsAddr}
        getValues={getValuesAddr}
        setValue={setValueAddr}
        trigger={triggerAddr}
        handleGetState={handleGetState}
        handleGetCitys={handleGetCitys}
        handleSubmit={handleSubmitAddr}
        submitAddress={submitAddress}
      />

      <AccountDialog
        {...funtionInfo}
        handleCloseAccountDialog={handleCloseAccountDialog}
        register={registerAccount}
        isSubmitting={isSubmittingAccount}
        errors={errorsAccount}
        getValues={getValuesAccount}
        setValue={setValueAccount}
        trigger={triggerAccount}
        handleSubmit={handleSubmitAccount}
        submitAccount={submitAccount}
      />

      <div style={{ visibility: "hidden", width: "0px", height: "0px" }}>
        <FilePond
          allowMultiple={false}
          maxFiles={1}
          name="uploadFile"
          maxFileSize={"10MB"}
          allowDrop={false}
          allowReorder={true}
          //captureMethod="environment"
          labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
          acceptedFileTypes={"image/*"}
          ref={filePondRef}
          oninitfile={() => {
            setLoading(2);
          }}
          onprocessfiles={() => {
            setApiTrigger(apiTrigger + 1);
          }}
          server={{
            process: {
              url: env.apiBase + "/api/file/uploadimagestudentdetailforuser",
              ondata: (formData) => {
                formData.append("studentId", getApiResult.student.studentId);
                return formData;
              },
              onload: (res) => {
                //setValue("uploadFile", res);
                //trigger("uploadFile");
              },
            },
            fetch: null,
            revert: null,
          }}
        />
      </div>

      <Paper className={classes.paper} elevation={0}>
        <Box p={1} className={classes.topInfo}>
          <Avatar
            style={{ cursor: "pointer" }}
            src={
              getApiResult && getApiResult.student.profilePic
                ? `${env.resourceBase}/media/uploadimages/${getApiResult.student.profilePic}`
                : null
            }
            className={classes.largeAvata}
            onClick={openFilePond}
          />
          <Typography align="center" style={{ color: "white" }}>
            {`${getApiResult.student.firstName} ${getApiResult.student.lastName}`}
          </Typography>
          <Typography
            variant="caption"
            align="center"
            display="block"
            style={{ color: "white" }}
          >
            {`${getApiResult.studentRego.programName}`}
          </Typography>
        </Box>

        <div className={classes.toolbar} />

        <Box pt={2} pb={7}>
          <Typography className={classes.mainInfoText}>
            Basic Information
          </Typography>
          <Grid container spacing={0} className={classes.gridc}>
            <Grid item xs={5} className={classes.gridleft}>
              Member ID
            </Grid>
            <Grid item xs={7} className={classes.gridright}>
              4066
            </Grid>
            <Grid item xs={5} className={classes.gridleft}>
              Email ID
            </Grid>
            <Grid item xs={7} className={classes.gridright}>
              {getApiResult.studentRego.studentLoginEmail}
            </Grid>
            <Grid item xs={5} className={classes.gridleft}>
              Gender
            </Grid>
            <Grid item xs={7} className={classes.gridright}>
              {getApiResult.student.displayGender}
            </Grid>
            <Grid item xs={5} className={classes.gridleft}>
              Start Date
            </Grid>
            <Grid item xs={7} className={classes.gridright}>
              {utils.site.formatDDMMYYYY(
                new Date(getApiResult.studentRego.startDate)
              )}
            </Grid>
            <Grid item xs={5} className={classes.gridleft}>
              Birthday
            </Grid>
            <Grid item xs={7} className={classes.gridright}>
              {utils.site.formatDDMMYYYY(
                new Date(getApiResult.student.dateOfBirth)
              )}
            </Grid>
          </Grid>
          <Typography className={classes.mainInfoText}>
            Address Information
            <EditIcon
              fontSize="small"
              style={{ float: "right" }}
              onClick={handleOpenAddrDialog}
            />
          </Typography>
          <Grid container spacing={0} className={classes.gridc}>
            <Grid item xs={5} className={classes.gridleft}>
              Address1
            </Grid>
            <Grid item xs={7} className={classes.gridright}>
              {getApiResult.student.address1}
            </Grid>
            <Grid item xs={5} className={classes.gridleft}>
              Address2
            </Grid>
            <Grid item xs={7} className={classes.gridright}>
              {getApiResult.student.address2}
            </Grid>
            <Grid item xs={5} className={classes.gridleft}>
              Country
            </Grid>
            <Grid item xs={7} className={classes.gridright}>
              {getApiResult.student.country}
            </Grid>
            <Grid item xs={5} className={classes.gridleft}>
              State
            </Grid>
            <Grid item xs={7} className={classes.gridright}>
              {getApiResult.student.state}
            </Grid>
            <Grid item xs={5} className={classes.gridleft}>
              City
            </Grid>
            <Grid item xs={7} className={classes.gridright}>
              {getApiResult.student.city}
            </Grid>
            <Grid item xs={5} className={classes.gridleft}>
              Post Code
            </Grid>
            <Grid item xs={7} className={classes.gridright}>
              {getApiResult.student.postalCode}
            </Grid>
          </Grid>
          <Typography className={classes.mainInfoText}>
            Account Holder Information
            <EditIcon
              fontSize="small"
              style={{ float: "right" }}
              onClick={handleOpenAccountDialog}
            />
          </Typography>
          <Grid container spacing={0} className={classes.gridc}>
            <Grid item xs={5} className={classes.gridleft}>
              Username
            </Grid>
            <Grid item xs={7} className={classes.gridright}>
              {`${getApiResult.accountInfo.firstName} ${getApiResult.accountInfo.lastName}`}
            </Grid>
            <Grid item xs={5} className={classes.gridleft}>
              Contact Phone
            </Grid>
            <Grid item xs={7} className={classes.gridright}>
              {getApiResult.accountInfo.mobileNumber}
            </Grid>

            <Grid item xs={5} className={classes.gridleft}>
              Email
            </Grid>
            <Grid item xs={7} className={classes.gridright}>
              {getApiResult.accountInfo.email}
            </Grid>
          </Grid>

          <Typography className={classes.mainInfoText}>
            Additional Information
          </Typography>

          <Grid container spacing={0} className={classes.gridc}>
            <Grid item xs={5} className={classes.gridleft}>
              How Hear About Us
            </Grid>
            <Grid item xs={7} className={classes.gridright}>
              {getApiResult.student.displayAboutUs}
            </Grid>
            <Grid item xs={5} className={classes.gridleft}>
              Mecical Condition
            </Grid>
            <Grid item xs={7} className={classes.gridright}>
              {getApiResult.student.displayMedicalCondition}
            </Grid>
            <Grid item xs={5} className={classes.gridleft}>
              Previous Martial Arts Training
            </Grid>
            <Grid item xs={7} className={classes.gridright}>
              {getApiResult.student.preMATraining}
            </Grid>
            <Grid item xs={5} className={classes.gridleft}>
              Benefits Hoping To Achieve
            </Grid>
            <Grid item xs={7} className={classes.gridright}>
              {getApiResult.student.benefitsAchieve}
            </Grid>
          </Grid>

          <Typography className={classes.mainInfoText}>
            School Information
          </Typography>
          <Grid container spacing={0} className={classes.gridc}>
            <Grid item xs={5} className={classes.gridleft}>
              School
            </Grid>
            <Grid item xs={7} className={classes.gridright}>
              {getApiResult.student.school}
            </Grid>
            <Grid item xs={5} className={classes.gridleft}>
              Principal Name
            </Grid>
            <Grid item xs={7} className={classes.gridright}>
              {getApiResult.student.principalName}
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </div>
  );
}
