import React, { useEffect } from "react";
import { Route, Link, useLocation } from "react-router-dom";
import clsx from "clsx";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import RefreshIcon from "@material-ui/icons/Refresh";
import { ReactComponent as YourSvg } from "../site/members/svg/new_captain_ki_ahp.svg";
import {
  Drawer,
  makeStyles,
  //useTheme,
  AppBar,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  IconButton,
  ListItem,
  ListItemIcon,
  Menu,
  MenuItem,
  Fade,
  //Box,
} from "@material-ui/core";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    //marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerClose: {
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(12) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  logoImg: {
    maxWidth: "120px",
    objectFit: "contain",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "200px",
    },
  },
  infoContainer: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: theme.spacing(2),
  },
  listItem: {
    paddingLeft: "0",
    paddingRight: "0",
    paddingTop: "0",
    paddingBottom: "0",
    justifyContent: "center",
    "&:first-of-type .MuiListItemIcon-root": {
      //paddingTop : 0
    },
    //border : '1px solid rgba(0,0,0,.1)',
    //marginTop : '5px',
    //marginLeft : '1px',
    //marginRight : '2px'
  },
  selectedMenuItem: {
    "& .MuiListItemIcon-root": {
      border: "1px solid red",
    },
  },
  listItemIcon: {
    minWidth: "96px",
    padding: theme.spacing(1),
    border: "1px solid rgba(0,0,0,.1)",
    //border : "1px solid red"
    //margin : theme.spacing(1)
  },
  listItemIcon2: {
    minWidth: "96px",
    border: "1px solid rgba(0,0,0,.1)",
    //padding: theme.spacing(1),
    //border : "1px solid red"
    //margin : theme.spacing(1)
  },
  menu: {
    "& .MuiList-root.MuiMenu-list": {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
    "& a": {
      paddingTop: "0px",
      paddingBottom: "0px",
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
}));

const classSubMenus = [
  {
    id: "classesSub1",
    title: "Classes",
    style: "red",
    icon: <InboxIcon style={{ color: "white" }} />,
    link: "classes",
  },
  {
    id: "classesSub2",
    title: "Class History",
    style: "red",
    icon: <InboxIcon style={{ color: "white" }} />,
    link: "class-list",
  },
  {
    id: "classesSub3",
    title: "Class List",
    style: "red",
    icon: <InboxIcon style={{ color: "white" }} />,
    link: "class-history",
  },
];

const menus = [
  {
    id: "classes",
    title: "Classes",
    style: "red",
    icon: <InboxIcon style={{ color: "white" }} />,
    link: null,
    submenus: classSubMenus,
  },
  {
    id: "students",
    title: "Students",
    style: "blue",
    icon: <InboxIcon style={{ color: "white" }} />,
    link: "students",
  },
  {
    id: "actionLog",
    title: "Action Log",
    style: "green",
    icon: <InboxIcon style={{ color: "white" }} />,
    link: "action-log",
  },
  {
    id: "upgrades",
    title: "Upgrades",
    style: "orange",
    icon: <InboxIcon style={{ color: "white" }} />,
    link: "upgrades",
  },
  {
    id: "promotions",
    title: "Promotions",
    style: "orange",
    icon: <InboxIcon style={{ color: "white" }} />,
    link: "promotions",
  },
  {
    id: "houseTeam",
    title: "House Team",
    style: "orange",
    icon: <InboxIcon style={{ color: "white" }} />,
    link: "house-team-reports",
  },
];

const DashboardLayout = ({ children, ...rest }) => {
  const { userInfo } = rest;
  const classes = useStyles();
  const pathname = useLocation().pathname;
  const [selected, setSelected] = React.useState("");

  //const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (
      pathname.includes("/classes") ||
      pathname.includes("/class-list") ||
      pathname.includes("/class-history")
    ) {
      setSelected("classes");
    } else if (
      pathname.includes("/students") ||
      pathname.includes("/student-detail/") ||
      pathname.includes("/evaluations/") ||
      pathname.includes("/assign-classes/")
    ) {
      setSelected("students");
    } else if (pathname.includes("/action-log")) {
      setSelected("actionLog");
    } else if (pathname.includes("/upgrades")) {
      setSelected("upgrades");
    } else if (pathname.includes("/promotions")) {
      setSelected("promotions");
    } else if (pathname.includes("house-team-reports")) {
      setSelected("houseTeam");
    } else {
      setSelected("");
    }
  }, [pathname]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={clsx(classes.appBar)}>
        <Toolbar style={{ justifyContent: "center" }}>
          <img
            src="/assets/logos/logo.jpg"
            width="100%"
            alt="logo"
            className={classes.logoImg}
          />
          <div className={classes.infoContainer}>
            <Typography
              variant="h6"
              noWrap
              style={{ fontSize: "16px" }}
              component="span"
            >
              {`${userInfo.singleStudioName}  |  ${userInfo.firstName} ${userInfo.lastName}`}
            </Typography>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              className={clsx(classes.menuButton)}
            >
              <RefreshIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, classes.drawerClose)}
        classes={{
          paper: clsx(classes.drawerClose),
        }}
      >
        <div className={classes.toolbar} />
        <List>
          {menus.map((menuItem, index) => {
            return (
              <ListItem
                button
                aria-controls={menuItem.id}
                aria-haspopup={menuItem.submenus ? "true" : "false"}
                key={menuItem.id}
                className={clsx(classes.listItem, {
                  [classes.selectedMenuItem]: selected === menuItem.id,
                })}
                component={menuItem.submenus ? undefined : Link}
                to={`/dashboard/studio/${userInfo.singleStudioId}/${menuItem.link}`}
              >
                <ListItemIcon
                  className={classes.listItemIcon}
                  onClick={menuItem.submenus ? handleClick : undefined}
                >
                  <YourSvg />
                </ListItemIcon>
                {menuItem.submenus && (
                  <Menu
                    id={menuItem.id}
                    className={classes.menu}
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    {menuItem.submenus.map((subMenuItem, index) => {
                      return (
                        <MenuItem
                          key={subMenuItem.id}
                          onClick={handleClose}
                          component={Link}
                          to={`/dashboard/studio/${userInfo.singleStudioId}/${subMenuItem.link}`}
                        >
                          <ListItemIcon className={classes.listItemIcon}>
                            <YourSvg />
                          </ListItemIcon>
                        </MenuItem>
                      );
                    })}
                  </Menu>
                )}
              </ListItem>
            );
          })}
        </List>
      </Drawer>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
};

const DashboardLayoutForTabletRoute = ({
  component: RenderComponent,
  apiResult,
  inputType,
  breadcrumbItem,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <DashboardLayout {...apiResult}>
          <RenderComponent
            {...matchProps}
            {...apiResult}
            inputType={inputType}
          />
        </DashboardLayout>
      )}
    />
  );
};

export default DashboardLayoutForTabletRoute;
