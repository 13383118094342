import React from "react";
import { variables } from "../../../cssInJs";
import { useParams } from "react-router-dom";
import utils from "../../../utils";
import env from "../../../env";
import { ErrorMessage, Loading } from "components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStore } from "../../../store";
import clsx from "clsx";
import * as yup from "yup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
//import DescriptionIcon from "@material-ui/icons/Description";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import RefreshIcon from "@material-ui/icons/Refresh";
import CircularProgress from "@material-ui/core/CircularProgress";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import {
  //AppBar,
  Avatar,
  //Tab,
  //Tabs,
  Toolbar,
  lighten,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  //Checkbox,
  TextField,
  IconButton,
  Tooltip,
  ButtonGroup,
  Button,
  Backdrop,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  //DialogActions,
  //ListItemIcon,
  //TextField,
  //Dialog,
  //Select,
  //DialogTitle,
  //List,
  //ListItem,
  //ListItemText,
  //DialogTitle,
  //DialogContent,
  //DialogContentText,
  //Box,
  //Grid,
  //Backdrop,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    //minWidth: 750,
  },
  infoIcon: {
    cursor: "pointer",
    color: variables.colorBlue2,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 500,
    color: "#fff",
    //position: "absolute",
  },
  textField: {
    width: "-webkit-fill-available",
  },
  viewicon: {
    cursor: "pointer",
  },
  checkbox: {
    //backgroundColor : 'yellow',
    //accent-color: #9b59b6;
    //fill : 'yellow'
    "& path": {
      fill: "orange",
    },
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 auto",
  },
}));

const useSearchPanelStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "& > *": {
      //margin: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
  },
  textField: {
    //width: "-webkit-fill-available",
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
  },
  formControl: {
    width: `calc(50% - ${theme.spacing(2)}px)`,
    //width: "-webkit-fill-available",
    display: "inline-flex",
    margin: theme.spacing(1),
  },
}));

const useETHeadStyles = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: variables.colorBlue3,
    "& .MuiTableCell-head": {
      color: "white",
    },
    "& .MuiIconButton-label": {
      color: "white",
    },
    "& .MuiTableSortLabel-root:hover": {
      color: "white",
    },
    "& .MuiTableSortLabel-root": {
      lineHeight: "1.1",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: "white",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon":
      {
        color: "white",
      },
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { handleFilter, reloadFunction } = props;

  return (
    <Toolbar className={clsx(classes.root)}>
      <Typography
        className={classes.title}
        //variant="h6"
        id="tableTitle"
        component="div"
        style={{ fontSize: "18px" }}
      >
        Studio Report
      </Typography>

      <ButtonGroup size="small" aria-label="small outlined button group">
        <Tooltip title="Filters" arrow>
          <Button onClick={handleFilter}>
            <FilterListIcon fontSize="small" />
          </Button>
        </Tooltip>

        <Tooltip title="Reload" arrow>
          <Button onClick={reloadFunction}>
            <RefreshIcon fontSize="small" />
          </Button>
        </Tooltip>
      </ButtonGroup>
    </Toolbar>
  );
};

const SearchPanel = (props) => {
  const classes = useSearchPanelStyles();
  const {
    setValue,
    trigger,
    handleChangeProgram,
    register,
    searchType,
    programs,
    programIdText,
  } = props;
  //const program = (terms || []).find((x) => x.termId === termId) || null;
  const program =
    (programs || []).find((x) => x.programId === programIdText) || null;
  return (
    <form method="POST" noValidate="" className={classes.root}>
      <FormControl
        className={classes.textField}
        variant="outlined"
        size="small"
      >
        <InputLabel htmlFor="outlined-adornment-password-cp">
          Search by Studio Name
        </InputLabel>

        <OutlinedInput
          id="outlined-adornment-password-cp"
          type="text"
          inputProps={{
            //...params.inputProps,
            defaultValue: "",
            autoComplete: "off",
            //autoComplete: "new-password",
          }}
          {...register("search", {
            onChange: (e) => {
              searchType();
            },
          })}
          startAdornment={
            <InputAdornment position="start">
              <IconButton aria-label="toggle password visibility" edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          labelWidth={190}
        />
      </FormControl>

      <Autocomplete
        id="programId"
        options={programs || []}
        className={classes.formControl}
        classes={{
          option: classes.option,
        }}
        autoHighlight
        getOptionLabel={(option) => option.programName}
        value={program}
        getOptionSelected={(option, value) =>
          option.programId === value.programId
        }
        onChange={(event, newValue) => {
          if (newValue) {
            setValue("programId", newValue ? newValue.programId : null);
            trigger("programId");
            handleChangeProgram();
          }
        }}
        renderOption={(option) => (
          <React.Fragment>{option.programName}</React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Term"
            variant="outlined"
            size="small"
            //error={!!errors.term}
            //helperText={errors?.term?.message}
            inputProps={{
              ...params.inputProps,
              //autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
      />
    </form>
  );
};

const headCells = [
  {
    id: "Image",
    numeric: false,
    disablePadding: false,
    label: "Image",
    hasSort: false,
  },
  {
    id: "FirstName",
    numeric: false,
    disablePadding: false,
    label: "First",
    hasSort: true,
  },
  {
    id: "LastName",
    numeric: false,
    disablePadding: false,
    label: "Last",
    hasSort: false,
  },
  {
    id: "Parents",
    numeric: false,
    disablePadding: false,
    label: "Parents",
    hasSort: false,
  },
  {
    id: "Program",
    numeric: false,
    disablePadding: false,
    label: "Program",
    hasSort: false,
  },
  {
    id: "StudentId",
    numeric: false,
    disablePadding: false,
    label: "Student Id",
    hasSort: false,
  },
  {
    id: "StartDate",
    numeric: false,
    disablePadding: false,
    label: "Start Date",
    hasSort: true,
  },
  {
    id: "Status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    hasSort: true,
  },
];

const EnhancedTableHead = (props) => {
  const classes = useETHeadStyles();
  const { orderBy, order, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headCells.map((headCell) => {
          if (headCell.hasSort) {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            );
          } else {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
              >
                {headCell.label}
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};

const getStatusIcon = (status) => {
  if (status === 1) {
    return (
      <CheckCircleOutlineIcon fontSize="small" style={{ color: "green" }} />
    );
  } else if (status === 99) {
    return <RemoveCircleIcon fontSize="small" style={{ color: "red" }} />;
  }
  return null;
};

const schema = yup.object().shape({
  search: yup.string(),
  programIdText: yup.string(),
});

export function StudioReports() {
  const { studioId } = useParams();
  const classes = useStyles();
  const { dispatch } = useStore();
  const [apiTrigger, setApiTrigger] = React.useState(0);
  const [loading, setLoading] = React.useState(1);
  const [getApiResult, setGetApiResult] = React.useState();
  const [errorResult, setErrorResult] = React.useState({
    type: "server",
    errors: [],
  });
  const [funtionInfo, setFuntionInfo] = React.useState({
    filterOpen: true,
    page: 0,
    rowsPerPage: 10,
    order: "asc",
    orderBy: "FirstName",
  });
  const previousController = React.useRef();
  const timerController = React.useRef();

  const {
    register,
    getValues,
    //reset,
    setValue,
    trigger,
    //formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { programIdText: "all" },
  });

  const { programIdText } = getValues();

  // const apiCall = async (
  //   url,
  //   apiData,
  //   returnFunction = null
  //   //retrunErrorFunction = null
  // ) => {
  //   setLoading(2);

  //   if (timerController.current) {
  //     clearTimeout(timerController.current);
  //   }

  //   if (previousController.current) {
  //     previousController.current.abort();
  //   }

  //   const abortController = new AbortController();
  //   const signal = abortController.signal;
  //   previousController.current = abortController;

  //   try {
  //     const options = { headers: { "Content-Type": "application/json" } };
  //     const res = await fetch(env.apiBase + url, {
  //       method: "post",
  //       signal: signal,
  //       body: JSON.stringify({
  //         ...apiData,
  //       }),
  //       ...options,
  //     });

  //     if (!res.ok) {
  //       throw new Error(`Response status: ${res.status}`);
  //     }

  //     const data = await res.json();

  //     if (data.errors && data.errors.length > 0) {
  //       setErrorResult({
  //         type: "snackbar",
  //         errors: data.errors,
  //       });
  //       setLoading(0);
  //       return;
  //     }

  //     if (returnFunction) {
  //       returnFunction(data.results);
  //       timerController.current = setTimeout(() => {
  //         setLoading(0);
  //       }, 800);
  //     } else {
  //       setApiTrigger(apiTrigger + 1);
  //     }
  //   } catch (error) {
  //     const _errors = [];
  //     _errors.push("error : " + error.message);
  //     setErrorResult({
  //       type: "server",
  //       errors: _errors,
  //     });
  //     setLoading(0);
  //     return;
  //   }
  // };

  const searchType = () => {
    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    timerController.current = setTimeout(() => {
      setLoading(2);
      setApiTrigger(apiTrigger + 1);
    }, 500);
  };

  const handleRequestSort = (event, property) => {
    const isAsc =
      funtionInfo.orderBy === property && funtionInfo.order === "asc";
    setFuntionInfo({
      ...funtionInfo,
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
    if (getApiResult.studentRegos && getApiResult.studentRegos.length > 0) {
      setLoading(2);
      setApiTrigger(apiTrigger + 1);
    }
  };

  const handleFilter = () => {
    setFuntionInfo({
      ...funtionInfo,
      filterOpen: !funtionInfo.filterOpen,
    });
  };

  const handleChangeProgram = () => {
    setLoading(2);
    setApiTrigger(apiTrigger + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setFuntionInfo({
      ...funtionInfo,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });

    if (getApiResult.studentRegos && getApiResult.studentRegos.length > 0) {
      setLoading(2);
      setApiTrigger(apiTrigger + 1);
    }
  };

  const handleChangePage = (event, newPage) => {
    setFuntionInfo({
      ...funtionInfo,
      page: newPage,
    });

    if (getApiResult.studentRegos && getApiResult.studentRegos.length > 0) {
      setLoading(2);
      setApiTrigger(apiTrigger + 1);
    }
  };

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    let timerBackdropOpen = null;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(env.apiBase + "/api/studio/getstudioreports", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            ...getValues(),
            ...funtionInfo,
            studioId: studioId,
          }),
          ...options,
        });

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setErrorResult({
            type: "server",
            errors: data.errors,
          });
          setLoading(0);
          return;
        }

        if (data.results) {
          const { programs } = data.results;
          programs.unshift({ programId: "all", programName: "All" });
          setGetApiResult({
            ...data.results,
            programs: programs,
          });
        }
      } catch (error) {
        const _errors = [];
        _errors.push("error : " + error.message);
        setErrorResult({
          type: "server",
          errors: _errors,
        });
        setLoading(0);
        return;
      }

      if (loading === 2) {
        timerBackdropOpen = setTimeout(() => {
          setLoading(0);
        }, 800);
      } else {
        setLoading(0);
      }
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [apiTrigger]);

  React.useEffect(() => {
    dispatch({
      type: "SET_STATE",
      payload: {
        breadcrumbs: [
          { title: "Dashboard", link: "/dashboard" },
          { title: "Studios", link: "/dashboard/studios" },
          { title: "Studio Report", link: null },
        ],
      },
    });

    // eslint-disable-next-line
  }, []);

  if (loading === 1) {
    return <Loading />;
  } else if (errorResult.type === "server" && errorResult.errors.length > 0) {
    return <ErrorMessage errors={errorResult.errors} />;
  }
  return (
    <div className={classes.root}>
      
      <Backdrop className={classes.backdrop} open={loading === 2}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Paper className={classes.paper}>
        
        <EnhancedTableToolbar
          handleFilter={handleFilter}
          reloadFunction={handleChangeProgram}
        />

        {funtionInfo.filterOpen && (
          <SearchPanel
            {...getApiResult}
            searchType={searchType}
            programIdText={programIdText}
            setValue={setValue}
            trigger={trigger}
            handleChangeProgram={handleChangeProgram}
            register={register}
          />
        )}

        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              {...funtionInfo}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {getApiResult.studentRegos.map((studentRegoRow, index) => {
                const _img =
                  studentRegoRow.profilePic && studentRegoRow.profilePic !== ""
                    ? `${env.resourceBase}/media/uploadimages/${studentRegoRow.profilePic}`
                    : "/broken-image.jpg";

                const displayStartDate = utils.site.formatDDMMYYYY(
                  new Date(studentRegoRow.startDate)
                );
                const statusIcon = getStatusIcon(studentRegoRow.status);

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={studentRegoRow.studentRegoId}
                  >
                    <TableCell align="left">
                      <Avatar src={_img} />
                    </TableCell>

                    <TableCell align="left">
                      {studentRegoRow.firstName}
                    </TableCell>

                    <TableCell align="left">
                      {studentRegoRow.lastName}
                    </TableCell>

                    <TableCell align="left">Parents</TableCell>

                    <TableCell align="left">
                      {studentRegoRow.programName}
                    </TableCell>

                    <TableCell align="left">StudentId</TableCell>

                    <TableCell align="left">{displayStartDate}</TableCell>

                    <TableCell align="left">
                      {statusIcon && (
                        <Tooltip title={"Active"} arrow placement="top">
                          {statusIcon}
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={
            getApiResult.studentRegos && getApiResult.studentRegos.length > 0
              ? getApiResult.studentRegos[0].totalCount
              : 0
          }
          rowsPerPage={funtionInfo.rowsPerPage}
          page={funtionInfo.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
