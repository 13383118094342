import React from 'react';
//import { useRouter } from './components';
//import { useHistoryStore } from './components';
//import { useParallax } from './components';
//import utils from './utils';
//import debounce from "lodash/debounce";

export function AppRouteChanged() {
  //const { path } = useRouter();
  //const parallax = useParallax();
  //const history = useHistoryStore();
  
  React.useLayoutEffect(() => {
    //console.log('route changed', path)
    // const scrolled = debounce((e) => saveScrollPosition(e, path), 100)
    // history.push({ path: path })
    // if (typeof window !== `undefined`) {
    //   parallax.reset();
    //   const pos = getPreviousPostition(path)
    //   // console.log('pos', pos)
    //   if (pos) {
    //     setTimeout(() => {
    //       window.scrollTo(pos.scrollLeft, pos.scrollTop)
    //     }, 500)
    //   } else {
    //   const hash = window.location.hash;
  
    //   if (hash) { 
    //     setTimeout(() => {
    //       const anchor = utils.ui.findElement(hash);
    //       if (anchor)
    //         utils.ui.scrollTo(anchor, 500);
    //     }, 500)
    //   } else {
    //     window.scrollTo(0, 0);
    //   }
    // }
    // }
    // window.addEventListener('scroll', scrolled)
    // return () => {
    //   window.removeEventListener('scroll', scrolled)
    // }
    //window.scrollTo(0, 0);

    // eslint-disable-next-line
  }, []);

  return null;
}

//#region scroll position store

// function saveScrollPosition(e, path) {
//   if (typeof window === `undefined`) return;
//   const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
//   const scrollTop = window.pageYOffset || document.documentElement.scrollTop
//   // console.log('save position', path, scrollTop, scrollLeft)
//   setPositionData(path, scrollLeft, scrollTop)
// }

// function getPreviousPostition(path) {
//   const dataArr = getPositionData() || []
//   // console.log(dataArr.length)
//   const pos = getPreviousPostitionInArray(dataArr, path)
//   return pos
// }

// function getPositionData() {
//   const dataStr = localStorage.getItem('scrollPosRouter')
//   // console.log(dataStr)
//   if (dataStr) {
//     let data
//     try {
//       data = JSON.parse(dataStr)
//     } catch (e) { return null }
//     return data
//   }
//   return null
// }

// function setPositionData(path, scrollLeft, scrollTop) {
//   const dataArr = getPositionData() || []
//   setCurrentPositionInArray(dataArr, path, scrollLeft, scrollTop)
//   // console.log(dataArr)
//   localStorage.setItem('scrollPosRouter', JSON.stringify(dataArr))
// }

//const MAX_SIZE = 5
// function setCurrentPositionInArray(dataArr, path, scrollLeft, scrollTop) {
//   const same = dataArr.length > 0 && dataArr[dataArr.length - 1].path === path
//   if (same) {
//     const found = dataArr[dataArr.length - 1]
//     found.scrollLeft = scrollLeft
//     found.scrollTop = scrollTop
//     found.ts = new Date()
//   } else {
//     if (dataArr.length >= MAX_SIZE) dataArr.shift()
//     dataArr.push({
//       path: path,
//       scrollLeft: scrollLeft,
//       scrollTop: scrollTop,
//       ts: new Date()
//     })
//   }
// }

function getPreviousPostitionInArray(dataArr, path) {
  const foundIndex = findIndexReverse(dataArr, x => x.path === path)
  // console.log(foundIndex, dataArr.length - 1)
  if (foundIndex >=0 && foundIndex >= dataArr.length - 2) {
    // remove after the index
    if (foundIndex < dataArr.length - 1) {
      dataArr.splice(foundIndex + 1)
      localStorage.setItem('scrollPosRouter', JSON.stringify(dataArr))
    }
    return dataArr[foundIndex]
  }
  return null
}

function findIndexReverse(list, fn) {
  if (!list) return -1;
  for (let i = list.length - 1; i >= 0 ; i--) {
    if (fn(list[i])) {
      return i;
    }
  }
  return -1;
}
//#endregion