import React from "react";
import { useStore } from "../../../store";
import { variables } from "../../../cssInJs";
import { ErrorMessage, Loading } from "components";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import env from "../../../env";
import * as yup from "yup";
import utils from "../../../utils";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import CircularProgress from "@material-ui/core/CircularProgress";
import LensIcon from "@material-ui/icons/Lens";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {
  Toolbar,
  lighten,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  TextField,
  IconButton,
  Tooltip,
  ButtonGroup,
  Button,
  Backdrop,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  TablePagination,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    //minWidth: 750,
  },
  infoIcon: {
    cursor: "pointer",
    color: variables.colorBlue2,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 500,
    color: "#fff",
    //position: "absolute",
  },
  textField: {
    width: "-webkit-fill-available",
  },
  viewicon: {
    cursor: "pointer",
  },
  checkbox: {
    //backgroundColor : 'yellow',
    //accent-color: #9b59b6;
    //fill : 'yellow'
    "& path": {
      fill: "orange",
    },
  },
  priority: {
    color: "blue",
  },
  priorityHigh: {
    color: "red",
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 auto",
  },
}));

const useSearchPanelStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    "& > *": {
      //margin: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
  },
  textField: {
    //width: "-webkit-fill-available",
    margin: theme.spacing(1),
    width: `calc(33.3% - ${theme.spacing(2)}px)`,
  },
  formControl: {
    width: `calc(33.3% - ${theme.spacing(2)}px)`,
    //width: "-webkit-fill-available",
    display: "inline-flex",
    margin: theme.spacing(1),
  },
  textFieldForTablet: {
    //width: "-webkit-fill-available",
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
  },
  formControlForTablet: {
    width: `calc(50% - ${theme.spacing(2)}px)`,
    //width: "-webkit-fill-available",
    display: "inline-flex",
    margin: theme.spacing(1),
  },
}));

const useETHeadStyles = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: variables.colorBlue3,
    "& .MuiTableCell-head": {
      color: "white",
    },
    "& .MuiIconButton-label": {
      color: "white",
    },
    "& .MuiTableSortLabel-root:hover": {
      color: "white",
    },
    "& .MuiTableSortLabel-root": {
      lineHeight: "1.1",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: "white",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon":
      {
        color: "white",
      },
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { handleFilter, reloadFunction } = props;

  return (
    <Toolbar className={clsx(classes.root)}>
      <Typography
        className={classes.title}
        //variant="h6"
        id="tableTitle"
        component="div"
        style={{ fontSize: "18px" }}
      >
        Action Log
      </Typography>

      <ButtonGroup size="small" aria-label="small outlined button group">
        <Tooltip title="Filters" arrow>
          <Button onClick={handleFilter}>
            <FilterListIcon fontSize="small" />
          </Button>
        </Tooltip>

        {/* <Tooltip title="Reload" arrow>
            <Button onClick={reloadFunction}>
              <RefreshIcon fontSize="small" />
            </Button>
          </Tooltip> */}
      </ButtonGroup>
    </Toolbar>
  );
};

const SearchPanel = (props) => {
  const classes = useSearchPanelStyles();
  const {
    register,
    searchType,
    filters,
    studios,
    //programs,
    setValue,
    trigger,
    getValues,
    //forceTrigger,
    handleReload,
    inputType,
  } = props;

  const { filter, studioId } = getValues();

  return (
    <form
      method="POST"
      noValidate=""
      className={classes.root}
      //onSubmit={handleSubmit(submit)}
    >
      <FormControl
        className={inputType === "tablet" ? classes.textFieldForTablet : classes.textField}
        variant="outlined"
        size="small"
      >
        <InputLabel htmlFor="outlined-adornment-password-cp">
          Search by Student, Program, Studio
        </InputLabel>

        <OutlinedInput
          id="outlined-adornment-password-cp"
          type="text"
          inputProps={{
            //...params.inputProps,
            defaultValue: "",
            autoComplete: "off",
            //autoComplete: "new-password",
          }}
          {...register("search", {
            onChange: (e) => {
              searchType();
            },
          })}
          startAdornment={
            <InputAdornment position="start">
              <IconButton aria-label="toggle password visibility" edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          labelWidth={280}
        />
      </FormControl>

      <Autocomplete
        id="filter"
        options={filters || []}
        className={inputType === "tablet" ? classes.formControlForTablet : classes.formControl}
        classes={{
          option: classes.option,
        }}
        autoHighlight
        getOptionLabel={(option) => option.name}
        value={(filters || []).find((x) => x.value === filter) || null}
        getOptionSelected={(option, value) => option.value === value.value}
        onChange={(event, newValue) => {
          if (newValue) {
            setValue("filter", newValue.value);
            trigger("filter");
            handleReload();
            //forceTrigger();
            //handleChangeTerm();
          }
        }}
        renderOption={(option) => (
          <React.Fragment>{option.name}</React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Filter By"
            variant="outlined"
            size="small"
            //error={!!errors.term}
            //helperText={errors?.term?.message}
            inputProps={{
              ...params.inputProps,
              //autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
      />

      {inputType !== "tablet" && (
        <Autocomplete
          id="studioId"
          options={studios || []}
          className={classes.formControl}
          classes={{
            option: classes.option,
          }}
          autoHighlight
          getOptionLabel={(option) => option.studioName}
          value={
            (studios || []).find(
              (x) => x.studioId.toLowerCase() === studioId.toLowerCase()
            ) || null
          }
          getOptionSelected={(option, value) =>
            option.studioId === value.studioId
          }
          onChange={(event, newValue) => {
            setValue("studioId", newValue ? newValue.studioId : null);
            trigger("studioId");
            handleReload();
          }}
          renderOption={(option) => (
            <React.Fragment>{option.studioName}</React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Studio"
              variant="outlined"
              size="small"
              //error={!!errors.term}
              //helperText={errors?.term?.message}
              inputProps={{
                ...params.inputProps,
                //autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />
      )}

      {/* <Autocomplete
        id="programId"
        options={programs || []}
        className={classes.formControl}
        classes={{
          option: classes.option,
        }}
        autoHighlight
        getOptionLabel={(option) => option.programName}
        value={(programs || []).find((x) => x.value === programId) || null}
        getOptionSelected={(option, value) =>
          option.programId === value.programId
        }
        onChange={(event, newValue) => {
          if (newValue) {
            setValue("programId", newValue.programId);
            trigger("programId");
            forceTrigger();
          }
        }}
        renderOption={(option) => (
          <React.Fragment>{option.programName}</React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Program Filter"
            variant="outlined"
            size="small"
            //error={!!errors.term}
            //helperText={errors?.term?.message}
            inputProps={{
              ...params.inputProps,
              //autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
      /> */}
    </form>
  );
};

const headCells = [
  {
    id: "Date",
    numeric: false,
    disablePadding: false,
    label: "Date",
    hasSort: false,
  },
  {
    id: "Student",
    numeric: false,
    disablePadding: false,
    label: "Student",
    hasSort: false,
  },
  {
    id: "Priority",
    numeric: false,
    disablePadding: false,
    label: "Priority",
    hasSort: false,
  },
  {
    id: "Studio",
    numeric: false,
    disablePadding: false,
    label: "Studio",
    hasSort: false,
  },
  {
    id: "Program",
    numeric: false,
    disablePadding: false,
    label: "Program",
    hasSort: false,
  },
  {
    id: "ActionType",
    numeric: false,
    disablePadding: false,
    label: "Action Type",
    hasSort: false,
  },
  {
    id: "Message",
    numeric: false,
    disablePadding: false,
    label: "Message",
    hasSort: false,
  },
  {
    id: "Acknowledge",
    numeric: false,
    disablePadding: false,
    label: "Acknowledge",
    hasSort: false,
  },
];

const headCellsForTablet = [
  {
    id: "Date",
    numeric: false,
    disablePadding: false,
    label: "Date",
    hasSort: false,
  },
  {
    id: "Student",
    numeric: false,
    disablePadding: false,
    label: "Student",
    hasSort: false,
  },
  {
    id: "Priority",
    numeric: false,
    disablePadding: false,
    label: "Priority",
    hasSort: false,
  },
  {
    id: "Program",
    numeric: false,
    disablePadding: false,
    label: "Program",
    hasSort: false,
  },
  {
    id: "ActionType",
    numeric: false,
    disablePadding: false,
    label: "Action Type",
    hasSort: false,
  },
  {
    id: "Message",
    numeric: false,
    disablePadding: false,
    label: "Message",
    hasSort: false,
  },
  {
    id: "Acknowledge",
    numeric: false,
    disablePadding: false,
    label: "Acknowledge",
    hasSort: false,
  },
];

const EnhancedTableHead = (props) => {
  const classes = useETHeadStyles();
  const { orderBy, order, onRequestSort, inputType } = props;

  console.log("inputType", inputType);

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {(inputType === "tablet" ? headCellsForTablet : headCells).map(
          (headCell) => {
            if (headCell.hasSort) {
              return (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? "right" : "left"}
                  padding={headCell.disablePadding ? "none" : "normal"}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              );
            } else {
              return (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? "right" : "left"}
                  padding={headCell.disablePadding ? "none" : "normal"}
                >
                  {headCell.label}
                </TableCell>
              );
            }
          }
        )}
      </TableRow>
    </TableHead>
  );
};

const schema = yup.object().shape({
  search: yup.string(),
  filter: yup.number(),
  studioId: yup.string(),
  //programId: yup.string(),
});

export function ActionLog(props) {
  const { userInfo, inputType } = props;
  const _inputType = inputType || "normal";
  const { dispatch } = useStore();
  const { studioId } = useParams();
  const classes = useStyles();
  const [apiTrigger, setApiTrigger] = React.useState(0);
  const [getApiResult, setGetApiResult] = React.useState();
  const [loading, setLoading] = React.useState(1);
  const [funtionInfo, setFuntionInfo] = React.useState({
    filterOpen: true,
    forceTrigger: 0,
    page: 0,
    rowsPerPage: 10,
    order: "desc",
    orderBy: "Date",
  });
  const [errorResult, setErrorResult] = React.useState({
    type: "server",
    errors: [],
  });

  const { register, getValues, setValue, trigger } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      filter: null,
      studioId: _inputType === "tablet" ? studioId : null,
    },
  });

  const previousController = React.useRef();
  const timerController = React.useRef();

  const handleFilter = () => {
    setFuntionInfo({
      ...funtionInfo,
      filterOpen: !funtionInfo.filterOpen,
    });
  };

  const searchType = () => {
    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    timerController.current = setTimeout(() => {
      setFuntionInfo({
        ...funtionInfo,
        page: 0,
      });
      setLoading(2);
      setApiTrigger(apiTrigger + 1);
    }, 500);
  };

  const handleRequestSort = (event, property) => {
    const isAsc =
      funtionInfo.orderBy === property && funtionInfo.order === "asc";
    setFuntionInfo({
      ...funtionInfo,
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
    // if (getApiResult.appManages && getApiResult.appManages.length > 0) {
    //   setBackdropOpen(true);
    //   setApiTrigger(apiTrigger + 1);
    // }
  };

  const forceTrigger = () => {
    const forceTrigger = funtionInfo.funtionInfo + 1;
    setFuntionInfo({
      ...funtionInfo,
      forceTrigger: forceTrigger,
    });
  };

  const handleReload = () => {
    setLoading(2);
    setApiTrigger(apiTrigger + 1);
  };

  const apiCall = async (
    url,
    apiData,
    returnFunction = null
    //retrunErrorFunction = null
  ) => {
    setLoading(2);

    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + url, {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          ...apiData,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setErrorResult({
          type: "snackbar",
          errors: data.errors,
        });
        setLoading(0);
        return;
      }

      if (returnFunction) {
        returnFunction(data.results);
        timerController.current = setTimeout(() => {
          setLoading(0);
        }, 800);
      } else {
        setApiTrigger(apiTrigger + 1);
      }
    } catch (error) {
      const _errors = [];
      _errors.push("error : " + error.message);
      setErrorResult({
        type: "server",
        errors: _errors,
      });
      setLoading(0);
      return;
    }
  };

  const HandleChangeAcknowledge = async (actionLogId, acknowledge) => {
    await apiCall("/api/dashboard/updateactionLogacknowledge", {
      actionLogId: actionLogId,
      acknowledge: acknowledge,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setFuntionInfo({
      ...funtionInfo,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
    if (getApiResult.actionLogs && getApiResult.actionLogs.length > 0) {
      setLoading(2);
      setApiTrigger(apiTrigger + 1);
    }
  };

  const handleChangePage = (event, newPage) => {
    setFuntionInfo({
      ...funtionInfo,
      page: newPage,
    });
    if (getApiResult.actionLogs && getApiResult.actionLogs.length > 0) {
      setLoading(2);
      setApiTrigger(apiTrigger + 1);
    }
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    let timerBackdropOpen = null;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(
          env.apiBase + "/api/dashboard/loaddashboardpage",
          {
            method: "post",
            signal: signal,
            body: JSON.stringify({
              //...getValues(),
              // page: page,
              // rowsPerPage: rowsPerPage,
              // order: order,
              // orderBy: orderBy,
              // type: 5,
              //programId: programIdTab,
              //programId: funtionInfo.programTabId,
              ...getValues(),
              ...funtionInfo,
              userInfo: userInfo,
              //studioId: studioId,
            }),
            ...options,
          }
        );

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setErrorResult({
            type: "server",
            errors: data.errors,
          });
          setLoading(0);
          return;
        }

        if (data.results) {
          const { filters } = data.results;
          // if (selectTermId) {
          //   reset({
          //     termId: selectTermId,
          //   });
          // }
          filters.unshift({
            name: "All",
            value: null,
          });
          setGetApiResult({
            ...data.results,
            filters: filters,
          });
        }
      } catch (error) {
        const _errors = [];
        _errors.push("error : " + error.message);
        setErrorResult({
          type: "server",
          errors: _errors,
        });
        setLoading(0);
        return;
      }

      if (loading === 2) {
        timerBackdropOpen = setTimeout(() => {
          setLoading(0);
        }, 800);
      } else {
        setLoading(0);
      }
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [apiTrigger]);

  React.useEffect(() => {
    dispatch({
      type: "SET_STATE",
      payload: {
        breadcrumbs: [{ title: "Dashboard", link: "/member/dashboard" }],
      },
    });

    // eslint-disable-next-line
  }, []);

  if (loading === 1) {
    return <Loading />;
  } else if (errorResult.type === "server" && errorResult.errors.length > 0) {
    return <ErrorMessage errors={errorResult.errors} />;
  }
  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={loading === 2}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          handleFilter={handleFilter}
          //reloadFunction={handleChangeTerm}
        />
        {funtionInfo.filterOpen && (
          <SearchPanel
            {...getApiResult}
            searchType={searchType}
            getValues={getValues}
            setValue={setValue}
            trigger={trigger}
            register={register}
            handleReload={handleReload}
            forceTrigger={forceTrigger}
            inputType={_inputType}
          />
        )}

        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              {...funtionInfo}
              onRequestSort={handleRequestSort}
              inputType={_inputType}
              //rowCount={getStudentsResult.students.length}
            />
            <TableBody>
              {getApiResult.actionLogs.map((actionLogRow, index) => {
                const displayDate = utils.site.formatDDMMYYYY(
                  new Date(actionLogRow.date)
                );

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={actionLogRow.actionLogId}
                  >
                    <TableCell align="left">{displayDate}</TableCell>

                    <TableCell align="left">
                      {`${actionLogRow.firstName} ${actionLogRow.lastName}`}
                    </TableCell>

                    <TableCell align="left">
                      <LensIcon
                        style={{ fontSize: "10px" }}
                        className={clsx(classes.priority, {
                          [classes.priorityHigh]: actionLogRow.priority === 1,
                        })}
                      />
                    </TableCell>
                    {_inputType !== "tablet" && (
                      <TableCell align="left">
                        {actionLogRow.studioName}
                      </TableCell>
                    )}

                    <TableCell align="left">
                      {actionLogRow.programName &&
                      actionLogRow.programName !== ""
                        ? actionLogRow.programName
                        : "N/A"}
                    </TableCell>
                    <TableCell align="left">
                      {actionLogRow.displayActionType}
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        variant="caption"
                        component="div"
                        style={{ lineHeight: "1.1" }}
                      >
                        {actionLogRow.message}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {actionLogRow.acknowledge === 2 ? (
                        <RemoveCircleIcon
                          fontSize="small"
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => {
                            HandleChangeAcknowledge(
                              actionLogRow.actionLogId,
                              1
                            );
                          }}
                        />
                      ) : (
                        <CheckCircleIcon
                          fontSize="small"
                          style={{ color: "green", cursor: "pointer" }}
                          onClick={() => {
                            HandleChangeAcknowledge(
                              actionLogRow.actionLogId,
                              2
                            );
                          }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={
            getApiResult.actionLogs && getApiResult.actionLogs.length > 0
              ? getApiResult.actionLogs[0].totalCount
              : 0
          }
          rowsPerPage={funtionInfo.rowsPerPage}
          page={funtionInfo.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
