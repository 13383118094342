import React from "react";
import env from "../../../env";
import { variables } from "../../../cssInJs";
import { useStore } from "../../../store";
import { StudentAppBar } from "./studentAppBar";
import { ErrorMessage, Loading, SnackbarMessageV2 } from "components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
//import clsx from "clsx";
import * as yup from "yup";
//import { useTheme } from "@material-ui/core/styles";
//import utils from "utils";
import DatePicker from "react-datepicker";
import { StudentView } from "./studentView";
//import { StudentBottom } from "./studentBottom";
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
//import AddIcon from "@material-ui/icons/Add";
//import FilterListIcon from "@material-ui/icons/FilterList";
//import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
//import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
//import CircularProgress from "@material-ui/core/CircularProgress";
//import DeleteIcon from "@material-ui/icons/Delete";
import DateRangeIcon from "@material-ui/icons/DateRange";

import {
  Link,
  useHistory,
  //useLocation,
  useParams,
} from "react-router-dom";
import {
  //Toolbar,
  //lighten,
  makeStyles,
  //Table,
  //TableBody,
  //TableCell,
  //TableContainer,
  //TableHead,
  //TablePagination,
  //TableRow,
  //TableSortLabel,
  Typography,
  Paper,
  //Checkbox,
  //IconButton,
  //Tooltip,
  //ButtonGroup,
  Button,
  //FormControl,
  //InputLabel,
  //OutlinedInput,
  //InputAdornment,
  TextField,
  Backdrop,
  //Dialog,
  //DialogTitle,
  //DialogContent,
  //DialogContentText,
  Box,
  //DialogActions,
  Grid,
  //FormControlLabel,
  FormHelperText,
  //Radio,
  //List,
  //ListItem,
  //ListItemIcon,
  //ListItemText,
  //Backdrop,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    backgroundColor: "transparent",
  },
  gridCon: {
    padding: theme.spacing(3),
    backgroundColor: "white",
    marginTop: theme.spacing(1),
  },
  hr: {
    marginBottom: "0",
    marginTop: theme.spacing(1),
    borderColor: "rgba(0,0,0,.1)",
  },
  typoBox2: {
    marginTop: theme.spacing(4),
  },
  typoTitle2: {
    //color: variables.colorGray1,
    //marginTop: theme.spacing(1),
    fontWeight: 700,
  },
  typoContent2: {},
  list: {
    "& .MuiListItem-gutters": {
      paddingLeft: "0px",
      paddingRight: "0px",
      alignItems: "flex-start",
    },
    "& .MuiListItemIcon-root": {
      marginTop: "5px",
    },
  },
  leftGrid: {
    overflowY: "scroll",
    height: "calc(100vh - 175px)",
  },
  rightGridBox: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(4),
    height: "calc(100vh - 175px)",
    overflowY: "auto",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    //position: "absolute",
  },
  bottom: {
    position: "fixed",
    bottom: "0",
    left: "0",
    backgroundColor: "red",
    width: "62%",
    //width: "calc(100% - 240px)",
    //marginLeft: "240px",
    marginTop: "0",
    marginBottom: "0",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const useEditView = makeStyles((theme) => ({
  formRoot: {
    "& > *": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      //paddingLeft: theme.spacing(1),
      //paddingRight: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
    "& .ck-editor": {
      margin: theme.spacing(1),
    },
    "& .ck-editor__editable": {
      minHeight: "150px",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      //transform: "translate(22px, -6px) scale(0.75)",
    },
    "& .MuiInputBase-input, & .MuiInputLabel-outlined": {
      //fontSize: "14px",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
  },
  textField: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
    //width: `-webkit-fill-available`,
    //marginTop: theme.spacing(1),
    //marginBottom: theme.spacing(1),
  },

  marginDiv: {
    margin: theme.spacing(1),
    display: "inline-block",
    //width: "-webkit-fill-available",
  },
  option: {
    fontSize: 14,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 3,
    color: "#fff",
    //position: "absolute",
  },

  buttonContainer: {
    position: "fixed",
    bottom: "0",
    left: "0",
    backgroundColor: "white",
    width: "calc(100% - 240px)",
    marginLeft: "240px",
    marginTop: "0",
    marginBottom: "0",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    zIndex: 1,
  },

  datePicker: {
    margin: theme.spacing(1),
    display: "inline-block",
    width: `calc(50% - ${theme.spacing(2)}px)`,
    "& .react-datepicker-wrapper, & input": {
      width: "100%",
    },
    "& .react-datepicker-popper": {
      zIndex: 2,
    },
  },

  box_criteria: {
    display: "flex",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },

  title_criteria: {
    flex: "1 1 100%",
  },

  button_criteria: {
    margin: theme.spacing(1),
  },

  box_field: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
  },

  textField_field: {
    //width: "-webkit-fill-available",
    flex: "1 1 100%",
  },

  buttonGroup_field: {
    marginLeft: theme.spacing(1),
    maxHeight: "42.38px",
  },

  filePond_field: {
    marginTop: theme.spacing(1),
  },

  paperi: {
    padding: theme.spacing(1),
  },

  hr: {
    borderColor: "rgba(0,0,0,.1)",
    marginTop: "0",
    marginBottom: "0",
    visibility: "hidden",
  },
}));

const StudentViewMemo = React.memo((props) => {
  return <StudentView {...props} />;
});

const schema = yup.object().shape({
  day: yup.number().required("Day is required."),
  batchId: yup.string().required("Batch is required."),
  startDate: yup.date().required("Start Date is required."),
  endDate: yup.date().required("End Date is required."),
});

const RightItemView = (props) => {
  const classes = useEditView();
  const {
    setValue,
    isSubmitting,
    errors,
    trigger,
    autoValue,
    //    inputAutoChange,
    inputChangeDay,
    inputChangeBatch,
    startDate,
    handleSubmit,
    submit,
    dayList,
    batches,
    endDate,
    studentId,
    studentRegoId,
  } = props;

  return (
    <>
      <Typography className={classes.marginDiv}>
        <DateRangeIcon /> Assign Class
      </Typography>

      <form
        method="POST"
        noValidate=""
        className={classes.formRoot}
        onSubmit={handleSubmit(submit)}
      >
        <Autocomplete
          id="day"
          disabled={!studentRegoId}
          options={dayList}
          className={classes.formControl}
          classes={{
            option: classes.option,
          }}
          autoHighlight
          getOptionLabel={(option) => option.name}
          getOptionSelected={(option, value) => option.value === value.value}
          value={autoValue.day || null}
          onChange={(event, newValue) => {
            inputChangeDay(event, newValue);
          }}
          renderOption={(option) => (
            <React.Fragment>{option.name}</React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Day*"
              variant="outlined"
              size="small"
              error={!!errors.day}
              helperText={errors?.day?.message}
              inputProps={{
                ...params.inputProps,
                //autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />

        <Autocomplete
          id="batchId"
          disabled={!studentRegoId}
          options={batches || []}
          className={classes.formControl}
          classes={{
            option: classes.option,
          }}
          autoHighlight
          getOptionLabel={(option) =>
            `${option.batchName} (${option.startTime} - ${option.endTime})`
          }
          getOptionSelected={(option, value) =>
            option.batchId === value.batchId
          }
          value={autoValue.batch || null}
          onChange={(event, newValue) => {
            inputChangeBatch(event, newValue);
          }}
          renderOption={(option) => (
            <React.Fragment>{`${option.batchName} (${option.startTime} - ${option.endTime})`}</React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Class*"
              variant="outlined"
              size="small"
              error={!!errors.batchId}
              helperText={errors?.batchId?.message}
              inputProps={{
                ...params.inputProps,
                //autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />

        <div className={classes.datePicker}>
          <Typography variant="caption"> Start Date </Typography>
          <DatePicker
            selected={startDate}
            onChange={(date) => {
              setValue("startDate", date);
              trigger("startDate");
            }}
            disabled
            showIcon
            isClearable
            dateFormat="dd/MM/yyyy"
            placeholderText={"dd/mm/yyyy"}
            toggleCalendarOnIconClick
          />
          {!!errors.startDate && (
            <FormHelperText error id="startDate-error">
              {errors?.startDate?.message}
            </FormHelperText>
          )}
        </div>

        <div className={classes.datePicker}>
          <Typography variant="caption"> End Date </Typography>
          <DatePicker
            selected={endDate}
            disabled
            onChange={(date) => {
              setValue("endDate", date);
              trigger("endDate");
            }}
            showIcon
            isClearable
            dateFormat="dd/MM/yyyy"
            placeholderText={"dd/mm/yyyy"}
            toggleCalendarOnIconClick
          />
          {!!errors.endDate && (
            <FormHelperText error id="endDate-error">
              {errors?.endDate?.message}
            </FormHelperText>
          )}
        </div>

        <div
          className={classes.buttonContainer}
          style={{ textAlign: "center" }}
        >
          <Button
            variant="contained"
            component={Link}
            size="small"
            to={`/dashboard/assign-classes/${studentId}`}
            disabled={isSubmitting}
            //color="primary"
            //className={classes.button}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: "1rem" }}
            //className={classes.button}
            startIcon={
              isSubmitting && (
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )
            }
            type="submit"
            disabled={isSubmitting || !studentRegoId}
          >
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};

export function StudentAssignClass(props) {
  const classes = useStyles();
  const { userInfo } = props;
  const { dispatch } = useStore();
  const { studentId, assignClassId } = useParams();
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [serverErrors, setServerErrors] = React.useState([]);
  const [getstudentDetailRe, setGetstudentDetailRe] = React.useState();
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [openSnackbarMessage, setOpenSnackbarMessage] = React.useState({
    openStatus: false,
    message: null,
  });

  const previousController = React.useRef(null);
  const timerController = React.useRef();

  const [autoValue, setAutoValue] = React.useState({
    day: null,
    batch: null,
  });

  const {
    //register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    trigger,
    //control,
    //watch,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { advancedUniform: false },
  });

  const { startDate, endDate } = getValues();

  // const inputAutoChange = (event, id, id2, newValue, newValue2) => {
  //   setValue(id, newValue);
  //   trigger(id);
  //   setAutoValue({
  //     ...autoValue,
  //     [id2]: newValue2,
  //   });
  // };

  const inputChangeBatch = (event, newValue) => {
    setValue("batchId", newValue ? newValue.batchId : null);
    setValue("startDate", newValue ? new Date(newValue.startDate) : null);
    setValue("endDate", newValue ? new Date(newValue.endDate) : null);

    trigger("batchId");
    trigger("startDate");
    trigger("endDate");

    setAutoValue({
      ...autoValue,
      batch: newValue,
    });
  };

  const inputChangeDay = async (event, newValue) => {
    if (newValue == null) {
      setValue("day", newValue ? newValue.value : null);
      setValue("batchId", null);
      trigger("day");

      setAutoValue({
        ...autoValue,
        day: newValue,
        batch: null,
      });
      return;
    }

    setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/classes/getbatchlist", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          day: newValue.value,
          studioId: getstudentDetailRe.student.studioId,
          programId: getstudentDetailRe.programId,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setServerErrors(data.errors);
        return;
      }

      if (data.results) {
        const _batches = data.results.batches;

        setGetstudentDetailRe({
          ...getstudentDetailRe,
          batches: _batches,
        });
      }

      timerController.current = setTimeout(() => {
        setBackdropOpen(false);
      }, 500);
    } catch (error) {
      console.error(error.message);
      serverErrors.push("error : " + error.message);
      setServerErrors([...serverErrors]);
      setBackdropOpen(false);
    }

    setValue("day", newValue ? newValue.value : null);
    setValue("batchId", null);
    trigger("day");

    setAutoValue({
      ...autoValue,
      day: newValue,
      batch: null,
    });
    return;
  };

  const handleCloseFunction = () => {
    setOpenSnackbarMessage(false);
  };

  const submit = async (dataModel) => {
    setServerErrors([]);
    setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/student/saveassignclass", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          ...dataModel,
          studentRegoId: getstudentDetailRe.studentRegoId,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }
      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setBackdropOpen(false);
        setOpenSnackbarMessage({
          openStatus: true,
          message: data.errors[0].errorMessage,
        });
        return;
      } else {
        history.push(`/dashboard/assign-classes/${studentId}`);
      }
    } catch (error) {
      //console.error(error.message);
      serverErrors.push("error : " + error.message);
      setServerErrors([...serverErrors]);
      setBackdropOpen(false);
    }
  };

  React.useEffect(() => {
    dispatch({
      type: "SET_STATE",
      payload: {
        breadcrumbs: [
          { title: "Dashboard", link: "/dashboard" },
          { title: "Students", link: "/dashboard/students" },
          { title: "Assign class", link: null },
        ],
      },
    });

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(
          env.apiBase + "/api/student/getstudentassignclass",
          {
            method: "post",
            signal: signal,
            body: JSON.stringify({
              studentId: studentId,
              assignClassId: assignClassId,
              userInfo: userInfo,
            }),
            ...options,
          }
        );

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
          setLoading(false);
          setBackdropOpen(false);
          return;
        }

        if (data.results) {
          const _result = { ...data.results };

          let editMode = false;
          let newBatch = true;

          const _assignClass = _result.assignClass;
          const _batches = _result.batches;
          const _dayList = _result.dayList;

          if (_assignClass) {
            editMode = true;
            newBatch = false;

            reset({
              day: _assignClass.day,
              batchId: _assignClass.batchId,
              startDate: new Date(_assignClass.startDate),
              endDate: new Date(_assignClass.endDate),
            });

            autoValue.batch = _batches.find(
              (x) => x.batchId === _assignClass.batchId
            );
            autoValue.day = _dayList.find((x) => x.value === _assignClass.day);

            setAutoValue({
              ...autoValue,
            });
          }

          setGetstudentDetailRe({
            ..._result,
            editMode,
            newBatch,
          });
        }
      } catch (error) {
        console.error(error.message);
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }
      setLoading(false);
      setBackdropOpen(false);
    };

    fetchData();

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [studentId]);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }
  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <img src="/assets/loader.36c5450235c205d74679.gif" alt="loader" />
      </Backdrop>

      <SnackbarMessageV2
        {...openSnackbarMessage}
        handleCloseFunction={handleCloseFunction}
        severity="error"
      />

      <Paper className={classes.paper} elevation={0}>
        <StudentAppBar tab={"classes"} studentId={studentId} />
        <div className={classes.gridCon}>
          <Grid container spacing={3}>
            <Grid item xs={3} className={classes.leftGrid}>
              <StudentViewMemo
                {...getstudentDetailRe}
                siblingLink={"/dashboard/assign-classes"}
                setBackdropOpen={setBackdropOpen}
              />
            </Grid>

            <Grid item xs={9}>
              <Box className={classes.rightGridBox}>
                <RightItemView
                  {...getstudentDetailRe}
                  startDate={startDate}
                  endDate={endDate}
                  isSubmitting={isSubmitting}
                  errors={errors}
                  autoValue={autoValue}
                  trigger={trigger}
                  setValue={setValue}
                  //inputAutoChange={inputAutoChange}
                  handleSubmit={handleSubmit}
                  submit={submit}
                  inputChangeDay={inputChangeDay}
                  inputChangeBatch={inputChangeBatch}
                  studentId={studentId}
                />
              </Box>
            </Grid>
          </Grid>
        </div>
        {/* <StudentBottom /> */}
      </Paper>
    </div>
  );
}
