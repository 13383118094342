import React from "react";
import env from "../../../env";
import { useForm } from "react-hook-form";
import { useStore } from "../../../store";
import { variables } from "../../../cssInJs";
import { yupResolver } from "@hookform/resolvers/yup";
import { StudentAppBar } from "./studentAppBar";
import { ErrorMessage, Loading, SnackbarMessageV3 } from "components";
import clsx from "clsx";
import * as yup from "yup";
//import { useTheme } from "@material-ui/core/styles";
import utils from "utils";
import { StudentView } from "./studentView";
import { StudentBottom } from "./studentBottom";
import AddIcon from "@material-ui/icons/Add";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import InfoIcon from "@material-ui/icons/Info";
//import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
//import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CircularProgress from "@material-ui/core/CircularProgress";
import LensIcon from "@material-ui/icons/Lens";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DatePicker from "react-datepicker";

import {
  //Link,
  //useHistory,
  //useLocation,
  useParams,
} from "react-router-dom";
import {
  Toolbar,
  lighten,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  //Checkbox,
  IconButton,
  Tooltip,
  ButtonGroup,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  TextField,
  Backdrop,
  //Dialog,
  //DialogTitle,
  //DialogContent,
  //DialogContentText,
  Box,
  DialogActions,
  Grid,
  FormHelperText,
  //Radio,
  //List,
  //ListItem,
  //ListItemIcon,
  //ListItemText,
  //Backdrop,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    backgroundColor: "transparent",
    //marginBottom: theme.spacing(2),
  },
  gridCon: {
    padding: theme.spacing(3),
    backgroundColor: "white",
    marginTop: theme.spacing(1),
  },
  hr: {
    marginBottom: "0",
    marginTop: theme.spacing(1),
    borderColor: "rgba(0,0,0,.1)",
  },
  typoBox2: {
    marginTop: theme.spacing(4),
  },
  typoTitle2: {
    //color: variables.colorGray1,
    //marginTop: theme.spacing(1),
    fontWeight: 700,
  },
  typoContent2: {},
  list: {
    "& .MuiListItem-gutters": {
      paddingLeft: "0px",
      paddingRight: "0px",
      alignItems: "flex-start",
    },
    "& .MuiListItemIcon-root": {
      marginTop: "5px",
    },
  },
  leftGrid: {
    overflowY: "scroll",
    height: "calc(100vh - 195px)",
    //height: "calc(100vh - 175px)",
  },
  rightGrid: {
    height: "calc(100vh - 290px)",
    overflowY: "auto",
  },
  rightGridBox: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 500,
    color: "#fff",
    //position: "absolute",
  },
  bottom: {
    position: "fixed",
    bottom: "0",
    left: "0",
    backgroundColor: "red",
    width: "62%",
    //width: "calc(100% - 240px)",
    //marginLeft: "240px",
    marginTop: "0",
    marginBottom: "0",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(1),
    paddingLeft: "3px",
    paddingRight: "3px",
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const useSearchPanelStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    "& > *": {
      //margin: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
  },
  textField: {
    //width: "-webkit-fill-available",
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
  },
  formControl: {
    width: `calc(50% - ${theme.spacing(2)}px)`,
    //width: "-webkit-fill-available",
    display: "inline-flex",
    margin: theme.spacing(1),
  },
}));

const useCoDialogStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textField: {
    //width: `-webkit-fill-available`,
    width: `calc(50% - ${theme.spacing(2)}px)`,
    margin: theme.spacing(1),
  },
  formControl: {
    width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
    margin: theme.spacing(1),
  },
  formControl2: {
    width: `calc(100% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
    margin: theme.spacing(1),
  },
  datePicker: {
    margin: theme.spacing(1),
    display: "inline-block",
    width: `calc(50% - ${theme.spacing(2)}px)`,
    "& .react-datepicker-wrapper, & input": {
      width: "100%",
    },
    "& .react-datepicker-popper": {
      zIndex: 2,
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      overflowY: "unset",
    },
  },
}));

const useETHeadStyles = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: variables.colorBlue3,
    "& .MuiTableCell-head": {
      color: "white",
    },
    "& .MuiIconButton-label": {
      color: "white",
    },
    "& .MuiTableSortLabel-root:hover": {
      color: "white",
    },
    "& .MuiTableSortLabel-root": {
      lineHeight: "1.1",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: "white",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon":
      {
        color: "white",
      },
  },
}));

const useRightStyles = makeStyles((theme) => ({
  table: {
    //minWidth: 750,
  },
  tableForTable: {
    "& .MuiTableCell-root": {
      fontSize: "0.75rem",
    },
    "& .MuiButton-root": {
      fontSize: "0.75rem",
    },
  },
}));

const StudentViewMemo = React.memo((props) => {
  return <StudentView {...props} />;
});

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    //studentId,
    handleFilter,
    openAddAwardDialog,
    //currentTab,
    //activeStatus,
    //inActiveStatus,

    //studioId,
  } = props;

  return (
    <Toolbar className={clsx(classes.root)}>
      <Typography
        className={classes.title}
        //variant="h6"
        id="tableTitle"
        component="div"
        style={{ fontSize: "18px" }}
      >
        Awards
      </Typography>

      <ButtonGroup size="small" aria-label="small outlined button group">
        <Tooltip title="Create" arrow>
          <Button onClick={openAddAwardDialog}>
            <AddIcon fontSize="small" />
          </Button>
        </Tooltip>

        <Tooltip title="Filters" arrow>
          <Button onClick={handleFilter}>
            <FilterListIcon fontSize="small" />
          </Button>
        </Tooltip>
      </ButtonGroup>
    </Toolbar>
  );
};

const headCells = [
  {
    id: "Date",
    numeric: false,
    disablePadding: false,
    label: "Date",
    hasSort: true,
  },
  {
    id: "DayTime",
    numeric: false,
    disablePadding: false,
    label: "Day/Time",
    hasSort: false,
  },
  {
    id: "Award",
    numeric: false,
    disablePadding: false,
    label: "Award",
    hasSort: false,
  },
  {
    id: "Criteria",
    numeric: false,
    disablePadding: false,
    label: "Criteria",
    hasSort: false,
  },
  {
    id: "Studio",
    numeric: false,
    disablePadding: false,
    label: "Studio",
    hasSort: false,
  },
  {
    id: "Program",
    numeric: false,
    disablePadding: false,
    label: "Program",
    hasSort: false,
  },
  {
    id: "Actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    hasSort: false,
  },
];

const EnhancedTableHead = (props) => {
  const classes = useETHeadStyles();
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headCells.map((headCell) => {
          if (headCell.hasSort) {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            );
          } else {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
              >
                {headCell.label}
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};

const SearchPanel = (props) => {
  const classes = useSearchPanelStyles();
  const {
    register,
    searchType,
    terms,
    setValue,
    trigger,
    getValues,
    handleChangeTerm,
  } = props;
  const { termId } = getValues();
  return (
    <form method="POST" noValidate="" className={classes.root}>
      <FormControl
        className={classes.textField}
        variant="outlined"
        size="small"
      >
        <InputLabel htmlFor="outlined-adornment-password-cp">
          Search by Award Name
        </InputLabel>

        <OutlinedInput
          id="outlined-adornment-password-cp"
          type="text"
          inputProps={{
            defaultValue: "",
            autoComplete: "off",
            //...params.inputProps,
            //autoComplete: "new-password",
          }}
          {...register("search", {
            onChange: (e) => {
              searchType();
            },
          })}
          startAdornment={
            <InputAdornment position="start">
              <IconButton aria-label="toggle password visibility" edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          labelWidth={190}
        />
      </FormControl>

      <Autocomplete
        id="termId"
        options={terms || []}
        className={classes.formControl}
        classes={{
          option: classes.option,
        }}
        autoHighlight
        getOptionLabel={(option) => option.termName}
        value={(terms || []).find((x) => x.termId === termId) || null}
        getOptionSelected={(option, value) => option.termId === value.termId}
        onChange={(event, newValue) => {
          if (newValue) {
            setValue("termId", newValue.termId);
            trigger("termId");
            handleChangeTerm();
          }
        }}
        renderOption={(option) => (
          <React.Fragment>{option.termName}</React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Term"
            variant="outlined"
            size="small"
            //error={!!errors.term}
            //helperText={errors?.term?.message}
            inputProps={{
              ...params.inputProps,
              //autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
      />
    </form>
  );
};

const RightItemView = (props) => {
  const classes = useRightStyles();
  const {
    studentId,
    handleFilter,
    filterOpen,
    register,
    searchType,
    openAddAwardDialog,
    terms,
    page,
    rowsPerPage,
    order,
    orderBy,
    setValue,
    trigger,
    getValues,
    handleChangeTerm,
    handleChangePage,
    awards,
    handleChangeRowsPerPage,
    handleRequestSort,
    student,
    deleteAward,
    openCriteriaDialog,
    inputTypeItem,
  } = props;

  return (
    <>
      <EnhancedTableToolbar
        studentId={studentId}
        handleFilter={handleFilter}
        openAddAwardDialog={openAddAwardDialog}
      />

      {filterOpen && (
        <SearchPanel
          //{...getApiResult}
          //searchType={searchType}
          //termId={termId}
          //handleChangeTerm={handleChangeTerm}
          setValue={setValue}
          getValues={getValues}
          trigger={trigger}
          terms={terms}
          searchType={searchType}
          register={register}
          handleChangeTerm={handleChangeTerm}
        />
      )}

      <TableContainer>
        <Table
          className={clsx(classes.table, {
            [classes.tableForTable]: inputTypeItem === "tablet",
          })}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />

          <TableBody>
            {(awards || []).map((awardRow, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;
              const displayDate = utils.site.formatDDMMYYYY(
                new Date(awardRow.date)
              );
              const displayDay = utils.site.GetDayText(new Date(awardRow.date));

              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={awardRow.awardUsedId}
                >
                  <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    //padding="none"
                  >
                    {displayDate}
                  </TableCell>
                  <TableCell align="left">{displayDay}</TableCell>
                  <TableCell align="left">{awardRow.awardName}</TableCell>
                  <TableCell align="left">
                    {" "}
                    <InfoIcon
                      fontSize="small"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        openCriteriaDialog(awardRow);
                      }}
                    />
                  </TableCell>
                  <TableCell align="left">{student.studioName}</TableCell>
                  <TableCell align="left">{awardRow.programName}</TableCell>
                  <TableCell align="left">
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => {
                        deleteAward(awardRow.awardUsedId);
                      }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={awards && awards.length > 0 ? awards[0].totalCount : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

const AddAwardDialog = (props) => {
  const classes = useCoDialogStyles();
  const {
    openAddAwardDialog,
    handleCloseAddAwardDialog,
    handleGetCriterion,
    awardCriterias,
    register,
    getValues,
    errors,
    awrds,
    setValue,
    trigger,
    batches,
    handleSubmit,
    submitAddAward,
    isSubmitting,
  } = props;

  const { setupAwardId, assignClassId, criterionId, date } = getValues();

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      //onClose={handleClose}
      open={openAddAwardDialog}
      scroll="paper"
      maxWidth="sm"
      fullWidth={true}
      className={classes.dialog}
    >
      <form
        method="POST"
        noValidate=""
        //className={classes.formRoot}
        onSubmit={handleSubmit((dataResult) =>
          submitAddAward(dataResult, awardCriterias)
        )}
      >
        <DialogTitle disableTypography>
          <Typography variant="h6"> Add Award </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleCloseAddAwardDialog}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <div style={{ marginLeft: "-8px", marginRight: "-8px" }}>
            <TextField
              //id="outlined-basic"
              label="Program Name*"
              variant="outlined"
              error={!!errors.programName}
              helperText={errors?.programName?.message}
              size="small"
              className={classes.textField}
              disabled
              {...register("programName")}
            />

            <Autocomplete
              id="assignClassId"
              options={batches || []}
              className={classes.formControl}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => option.batchName}
              getOptionSelected={(option, value) =>
                option.assignClassId === value.assignClassId
              }
              value={
                (batches || []).find(
                  (x) => x.assignClassId === assignClassId
                ) || null
              }
              onChange={(event, newValue) => {
                setValue(
                  "assignClassId",
                  newValue ? newValue.assignClassId : null
                );
                trigger("assignClassId");
              }}
              renderOption={(option) => (
                <React.Fragment>{option.batchName}</React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Batch *"
                  variant="outlined"
                  size="small"
                  error={!!errors.assignClassId}
                  helperText={errors?.assignClassId?.message}
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />

            <Autocomplete
              id="setupAwardId"
              options={awrds || []}
              className={classes.formControl}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => option.awardName}
              getOptionSelected={(option, value) =>
                option.setupAwardId === value.setupAwardId
              }
              value={
                (awrds || []).find((x) => x.setupAwardId === setupAwardId) ||
                null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setValue(
                    "setupAwardId",
                    newValue ? newValue.setupAwardId : null
                  );
                  trigger("setupAwardId");
                  handleGetCriterion();
                }
              }}
              renderOption={(option) => (
                <React.Fragment>{option.awardName}</React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Award  *"
                  variant="outlined"
                  size="small"
                  error={!!errors.setupAwardId}
                  helperText={errors?.setupAwardId?.message}
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />

            <Autocomplete
              id="criterionId"
              options={awardCriterias || []}
              className={classes.formControl}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => option.title}
              getOptionSelected={(option, value) =>
                option.criterionId === value.criterionId
              }
              value={
                (awardCriterias || []).find(
                  (x) => x.criterionId === criterionId
                ) || null
              }
              onChange={(event, newValue) => {
                setValue("criterionId", newValue ? newValue.criterionId : null);
                trigger("criterionId");
              }}
              renderOption={(option) => (
                <React.Fragment>{option.title}</React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Criteria*"
                  variant="outlined"
                  size="small"
                  error={!!errors.criterionId}
                  helperText={errors?.criterionId?.message}
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />

            <div className={classes.datePicker}>
              <Typography variant="caption">
                {" "}
                Certificate Grading Date{" "}
              </Typography>
              <DatePicker
                selected={date}
                onChange={(date) => {
                  setValue("date", date);
                  trigger("date");
                }}
                showIcon
                isClearable
                dateFormat="dd/MM/yyyy"
                placeholderText={"dd/mm/yyyy"}
                toggleCalendarOnIconClick
              />
              {!!errors.date && (
                <FormHelperText error id="startDate-error">
                  {errors?.date?.message}
                </FormHelperText>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            type="submit"
            startIcon={
              isSubmitting && (
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )
            }
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const CriteriaDialog = (props) => {
  const classes = useCoDialogStyles();
  const { openCriteriaDialog, closeCriteriaDialog, awardUsed } = props;
  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      //onClose={handleClose}
      open={openCriteriaDialog}
      scroll="paper"
      maxWidth="sm"
      fullWidth={true}
      className={classes.dialog}
    >
      <DialogTitle disableTypography>
        <Typography variant="h6"> Criteria </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={closeCriteriaDialog}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Typography>
          <LensIcon style={{ fontSize: "8px", marginRight: "8px" }} />
          {awardUsed && awardUsed.criterionTitle}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" type="button" onClick={closeCriteriaDialog}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const schema = yup.object().shape({
  search: yup.string(),
  termId: yup.string(),
});

const addAwardschema = yup.object().shape({
  programName: yup.string().required("Date Format is required."),
  assignClassId: yup.string().required("Date Format is required."),
  setupAwardId: yup.string().required("Date Format is required."),
  criterionId: yup.string().required("Date Format is required."),
  date: yup.date().required("Date Format is required."),
});

export function StudentAwards(props) {
  const { userInfo, inputType } = props;
  const inputTypeItem = inputType || "normal";
  const classes = useStyles();
  const { studioId, studentId } = useParams();
  const { dispatch } = useStore();
  const previousController = React.useRef();
  const timerController = React.useRef();
  const [apiTrigger, setApiTrigger] = React.useState(0);
  const [getApiResult, setGetApiResult] = React.useState();
  const [loading, setLoading] = React.useState(1);
  const rightGrid = React.useRef(null);
  const [funtionInfo, setFuntionInfo] = React.useState({
    filterOpen: true,
    openAddAwardDialog: false,
    openCriteriaDialog: false,
    page: 0,
    rowsPerPage: 10,
    order: "desc",
    orderBy: "Date",
  });
  const [errorResult, setErrorResult] = React.useState({
    type: "server",
    errors: [],
  });

  const {
    register,
    getValues,
    setValue,
    trigger,
    reset,
    //formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { termId: null },
  });

  const {
    register: awardschemaRegister,
    getValues: awardschemaGetValues,
    setValue: awardschemaSetValue,
    trigger: awardschemaTrigger,
    reset: awardschemaReset,
    clearErrors: awardschemaClearErrors,
    handleSubmit: awardschemaHandleSubmit,
    formState: {
      isSubmitting: awardschemaIsSubmitting,
      errors: awardschemaErrors,
    },
  } = useForm({
    resolver: yupResolver(addAwardschema),
  });

  const searchType = () => {
    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    timerController.current = setTimeout(() => {
      setLoading(2);
      setApiTrigger(apiTrigger + 1);
    }, 500);
  };

  const handleFilter = () => {
    setFuntionInfo({
      ...funtionInfo,
      filterOpen: !funtionInfo.filterOpen,
    });
  };

  const handleCloseAddAwardDialog = () => {
    setFuntionInfo({
      ...funtionInfo,
      openAddAwardDialog: false,
    });
  };

  const handleCloseSnackBar = () => {
    setErrorResult({
      ...errorResult,
      type: "",
      errors: [],
    });
  };

  const apiCall = async (
    url,
    apiData,
    returnFunction = null
    //retrunErrorFunction = null
  ) => {
    setLoading(2);

    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + url, {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          ...apiData,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setErrorResult({
          type: "snackbar",
          errors: data.errors,
        });
        setLoading(0);
        return;
      }

      if (returnFunction) {
        returnFunction(data.results);
        timerController.current = setTimeout(() => {
          setLoading(0);
        }, 800);
      } else {
        setApiTrigger(apiTrigger + 1);
      }
    } catch (error) {
      const _errors = [];
      _errors.push("error : " + error.message);
      setErrorResult({
        type: "server",
        errors: _errors,
      });
      setLoading(0);
      return;
    }
  };

  const openAddAwardDialog = async () => {
    await apiCall(
      "/api/award/getaddawardforstudent",
      {
        studentId: studentId,
      },
      (result) => {
        const { programName } = result;
        if (programName && programName != null) {
          awardschemaReset({ programName: programName });
        }

        setFuntionInfo({
          ...funtionInfo,
          openAddAwardDialog: true,
        });
        setGetApiResult({
          ...getApiResult,
          ...result,
        });
      }
    );
  };

  const handleGetCriterion = async () => {
    const setupAwardId = awardschemaGetValues("setupAwardId");
    await apiCall(
      "/api/award/getawardcriterions",
      {
        setupAwardId: setupAwardId,
      },
      (result) => {
        awardschemaSetValue("criteriaId", null);
        awardschemaClearErrors("criteriaId");
        setGetApiResult({
          ...getApiResult,
          ...result,
        });
      }
    );
  };

  const handleChangeTerm = () => {
    setLoading(2);
    setApiTrigger(apiTrigger + 1);
  };

  const submitAddAward = async (dataModel, awardCriterias) => {
    const awardCriteria = awardCriterias.find(
      (x) => x.criterionId === dataModel.criterionId
    );
    await apiCall(
      "/api/award/saveAddawardforstudent",
      {
        ...dataModel,
        criterionTitle: awardCriteria ? awardCriteria.title : null,
        criterionAttachment: awardCriteria ? awardCriteria.attachment : null,
      },
      (result) => {
        setFuntionInfo({
          ...funtionInfo,
          openAddAwardDialog: false,
        });
        setApiTrigger(apiTrigger + 1);
      }
    );
  };

  const handleChangePage = (event, newPage) => {
    setFuntionInfo({
      ...funtionInfo,
      page: newPage,
    });

    if (getApiResult.awards && getApiResult.awards.length > 0) {
      rightGrid.current.scrollTo(0, 0);
      setLoading(2);
      setApiTrigger(apiTrigger + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setFuntionInfo({
      ...funtionInfo,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });

    if (getApiResult.awards && getApiResult.awards.length > 0) {
      rightGrid.current.scrollTo(0, 0);
      setLoading(2);
      setApiTrigger(apiTrigger + 1);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc =
      funtionInfo.orderBy === property && funtionInfo.order === "asc";

    setFuntionInfo({
      ...funtionInfo,
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });

    if (getApiResult.awards && getApiResult.awards.length > 0) {
      setLoading(2);
      setApiTrigger(apiTrigger + 1);
    }
  };

  const deleteAward = async (awardUsedId) => {
    await apiCall("/api/award/removeawardused", {
      awardUsedId: awardUsedId,
    });
  };

  const openCriteriaDialog = (awardUsed) => {
    setFuntionInfo({
      ...funtionInfo,
      awardUsed: awardUsed,
      openCriteriaDialog: true,
    });
  };

  const closeCriteriaDialog = () => {
    setFuntionInfo({
      ...funtionInfo,
      openCriteriaDialog: false,
      awardUsed: null,
    });
  };

  React.useEffect(() => {
    if (apiTrigger === 0) {
      return;
    }

    console.log("useEffect - apiTrigger");

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    let timerBackdropOpen = null;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(env.apiBase + "/api/student/getstudentawards", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            ...getValues(),
            ...funtionInfo,
            studentId: studentId,
          }),
          ...options,
        });

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setErrorResult({
            type: "server",
            errors: data.errors,
          });
          setLoading(0);
          return;
        }

        if (data.results) {
          const { terms } = data.results;

          if (terms) {
            terms.unshift({ termId: null, termName: "All" });
          }

          setGetApiResult({
            ...data.results,
            terms: terms,
          });
        }
      } catch (error) {
        const _errors = [];
        _errors.push("error : " + error.message);
        setErrorResult({
          type: "server",
          errors: _errors,
        });
        setLoading(0);
        return;
      }

      if (loading === 2) {
        timerBackdropOpen = setTimeout(() => {
          setLoading(0);
        }, 800);
      } else {
        setLoading(0);
      }
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [apiTrigger]);

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    let timerBackdropOpen = null;

    reset({
      search: null,
      termId: null,
    });

    setFuntionInfo({
      ...funtionInfo,
      page: 0,
      rowsPerPage: 10,
      order: "desc",
      orderBy: "Date",
    });

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(env.apiBase + "/api/student/getstudentawards", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            ...getValues(),
            ...funtionInfo,
            studentId: studentId,
          }),
          ...options,
        });

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setErrorResult({
            type: "server",
            errors: data.errors,
          });
          setLoading(0);
          return;
        }

        if (data.results) {
          const { terms } = data.results;

          if (terms) {
            terms.unshift({ termId: null, termName: "All" });
          }

          setGetApiResult({
            ...data.results,
            terms: terms,
          });
        }
      } catch (error) {
        const _errors = [];
        _errors.push("error : " + error.message);
        setErrorResult({
          type: "server",
          errors: _errors,
        });
        setLoading(0);
        return;
      }

      if (loading === 2) {
        timerBackdropOpen = setTimeout(() => {
          setLoading(0);
        }, 800);
      } else {
        setLoading(0);
      }
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [studentId]);

  React.useEffect(() => {
    dispatch({
      type: "SET_STATE",
      payload: {
        breadcrumbs: [
          { title: "Dashboard", link: "/dashboard" },
          { title: "Students", link: "/dashboard/students" },
          { title: "Details", link: null },
        ],
      },
    });

    // eslint-disable-next-line
  }, []);

  if (loading === 1) {
    return <Loading />;
  } else if (errorResult.type === "server" && errorResult.errors.length > 0) {
    return <ErrorMessage errors={errorResult.errors} />;
  }
  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={loading === 2}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <SnackbarMessageV3
        {...errorResult}
        handleCloseFunction={handleCloseSnackBar}
        severity="error"
      />

      <AddAwardDialog
        {...funtionInfo}
        {...getApiResult}
        handleCloseAddAwardDialog={handleCloseAddAwardDialog}
        register={awardschemaRegister}
        getValues={awardschemaGetValues}
        errors={awardschemaErrors}
        setValue={awardschemaSetValue}
        trigger={awardschemaTrigger}
        handleGetCriterion={handleGetCriterion}
        handleSubmit={awardschemaHandleSubmit}
        submitAddAward={submitAddAward}
        isSubmitting={awardschemaIsSubmitting}
      />

      <CriteriaDialog
        {...funtionInfo}
        closeCriteriaDialog={closeCriteriaDialog}
      />

      <Paper className={classes.paper} elevation={0}>
        <StudentAppBar
          tab={"awards"}
          studentId={studentId}
          inputTypeItem={inputTypeItem}
          studioId={studioId}
        />

        <div className={classes.gridCon}>
          <Grid container spacing={3}>
            <Grid item xs={3} className={classes.leftGrid}>
              <StudentViewMemo
                {...getApiResult}
                siblingLink={"/dashboard/award-list"}
                setBackdropOpen={setLoading}
              />
            </Grid>
            <Grid item xs={9} className={classes.rightGrid} ref={rightGrid}>
              <Box className={classes.rightGridBox}>
                <RightItemView
                  {...funtionInfo}
                  {...getApiResult}
                  studentId={studentId}
                  searchType={searchType}
                  register={register}
                  handleFilter={handleFilter}
                  openAddAwardDialog={openAddAwardDialog}
                  getValues={getValues}
                  setValue={setValue}
                  trigger={trigger}
                  handleChangeTerm={handleChangeTerm}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  handleRequestSort={handleRequestSort}
                  deleteAward={deleteAward}
                  openCriteriaDialog={openCriteriaDialog}
                  inputTypeItem={inputTypeItem}
                />
              </Box>
            </Grid>
          </Grid>
        </div>
        <StudentBottom
          {...getApiResult}
          inputTypeItem={inputTypeItem}
          styleTye="list"
        />
      </Paper>
    </div>
  );
}
