import React from "react";
import env from "../../../env";
import { useStore } from "../../../store";
import { useParams, useHistory } from "react-router-dom";
import utils from "../../../utils";
import { ErrorMessage, Loading, SnackbarMessageV2 } from "components";
import { variables } from "../../../cssInJs";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ChatIcon from "@material-ui/icons/Chat";
import CloseIcon from "@material-ui/icons/Close";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import {
  makeStyles,
  AppBar,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  //TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  lighten,
  Toolbar,
  Backdrop,
  Checkbox,
  IconButton,
  //Tooltip,
  //ButtonGroup,
  Button,
  FormControl,
  //InputLabel,
  //OutlinedInput,
  //InputAdornment,
  TextField,
  FormHelperText,
  FormControlLabel,
  //Backdrop,
  Dialog,
  DialogTitle,
  DialogContent,
  //DialogContentText,
  Box,
  DialogActions,
  //Grid,
  List,
  ListItem,
  //ListItemIcon,
  ListItemText,
  RadioGroup,
  Radio,
  //Grid,
  //Backdrop,
} from "@material-ui/core";

registerPlugin(FilePondPluginFileValidateSize);
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginImageResize);
registerPlugin(FilePondPluginImageTransform);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingBottom: theme.spacing(2),
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 2,
    color: "#fff",
    //position: "absolute",
  },
}));

const useStylesTabView = makeStyles((theme) => ({
  table: {
    //minWidth: 750,
  },
  tableHead: {
    backgroundColor: variables.colorBlue3,
    "& .MuiTableCell-head": {
      color: "white",
    },
    "& .MuiIconButton-label": {
      color: "white",
    },
    "& .MuiTableSortLabel-root:hover": {
      color: "white",
    },
    "& .MuiTableSortLabel-root": {
      lineHeight: "1.1",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: "white",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon":
      {
        color: "white",
      },
  },
  formControl: {
    // width: `calc(50% - ${theme.spacing(2)}px)`,
    // display: "inline-flex",
    // margin: theme.spacing(1),
    "& button.MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
      display: "none",
    },
  },
  notAttend: {
    backgroundColor: "#fdf0f0",
    "&:hover": {
      backgroundColor: "#fdf0f0 !important",
    },
  },
  awardsIcon: {
    opacity: "0.5",
  },
  selected: {
    //color: "gold",
    //fontWeight: 900,
    opacity: "1",
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const useCoDialogStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textField: {
    width: `-webkit-fill-available`,
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const TabViewItem = (props) => {
  const classes = useStylesTabView();
  const {
    order,
    orderBy,
    onRequestSort,
    assignClasses,
    beltLevels,
    handleBeltlevelChange,
    handleCheckAttendance,
    handleCheckAbsentEmail,
    handleOpenComment,
    openFilePond,
    setStudetnClassHistoryInfo,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const [newHeadCells] = React.useState([
    {
      id: "Image",
      numeric: false,
      disablePadding: false,
      label: "Image",
      hasSort: false,
    },
    {
      id: "FirstName",
      numeric: false,
      disablePadding: false,
      label: "First Name",
      hasSort: false,
    },
    {
      id: "LastName",
      numeric: false,
      disablePadding: false,
      label: "Last Name",
      hasSort: false,
    },
    {
      id: "Info",
      numeric: false,
      disablePadding: false,
      label: "Info",
      hasSort: false,
    },
    {
      id: "BeltLevel",
      numeric: false,
      disablePadding: false,
      label: "Belt Level",
      hasSort: false,
    },
    {
      id: "Age",
      numeric: false,
      disablePadding: false,
      label: "Age",
      hasSort: false,
    },
    {
      id: "Attendance",
      numeric: false,
      disablePadding: false,
      label: "Attendance",
      hasSort: false,
    },
    {
      id: "AbsentEmail",
      numeric: false,
      disablePadding: false,
      label: "Absent Email",
      hasSort: false,
    },
    {
      id: "Comment",
      numeric: false,
      disablePadding: false,
      label: "Comment",
      hasSort: false,
    },
    {
      id: "Camera",
      numeric: false,
      disablePadding: false,
      label: "Camera",
      hasSort: false,
    },
  ]);

  return (
    <TableContainer>
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size="medium"
        aria-label="enhanced table"
      >
        <TableHead className={classes.tableHead}>
          <TableRow>
            {newHeadCells.map((headCell) => {
              if (headCell.hasSort) {
                return (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? "right" : "left"}
                    padding={headCell.disablePadding ? "none" : "normal"}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                );
              } else {
                return (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? "right" : "left"}
                    padding={headCell.disablePadding ? "none" : "normal"}
                  >
                    {headCell.label}
                  </TableCell>
                );
              }
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {assignClasses.map((row, index) => {
            const labelId = `enhanced-table-checkbox-${index}`;
            const _img =
              row.photo && row.photo !== ""
                ? `${env.resourceBase}/media/uploadimages/${row.photo}`
                : "/assets/no-image.png";
            const _beltLevel = (beltLevels || []).find(
              (x) => x.beltLevelId === row.beltLevelId
            );

            const _attendance =
              row.history && row.history.status === 2 ? true : false;
            const _absentEmail =
              row.history && row.history.status === 3 ? true : false;
            const _age = utils.site.calculateAge(new Date(row.dateOfBirth));

            return (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={row.assignClassId}
                className={clsx({ [classes.notAttend]: _attendance === false })}
              >
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  //padding="none"
                >
                  <img width="40px" src={_img} alt="belt" />
                </TableCell>
                <TableCell align="left">{row.firstName}</TableCell>
                <TableCell align="left">{row.lastName}</TableCell>
                <TableCell align="left">-</TableCell>
                <TableCell align="left">
                  <Autocomplete
                    id="beltLevelId"
                    //disabled={!checkCreateBatch}
                    options={beltLevels || []}
                    className={classes.formControl}
                    classes={{
                      option: classes.option,
                    }}
                    autoHighlight
                    getOptionLabel={(option) => option.beltName}
                    getOptionSelected={(option, value) =>
                      option.beltLevelId === value.beltLevelId
                    }
                    value={_beltLevel || null}
                    onChange={(event, newValue) => {
                      handleBeltlevelChange(
                        row.studentRegoId,
                        newValue ? newValue.beltLevelId : null
                      );
                    }}
                    renderOption={(option) => (
                      <React.Fragment>{option.beltName}</React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label=""
                        variant="outlined"
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </TableCell>
                <TableCell align="left">{_age}</TableCell>

                <TableCell align="left">
                  <Checkbox
                    checked={_attendance || false}
                    onChange={(event) => {
                      handleCheckAttendance(
                        event,
                        row.assignClassId,
                        row.history ? row.history.studetnClassHistoryId : null
                      );
                    }}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </TableCell>

                <TableCell align="left">
                  <Checkbox
                    checked={_absentEmail || false}
                    onChange={(event) => {
                      handleCheckAbsentEmail(
                        event,
                        row.assignClassId,
                        row.history ? row.history.studetnClassHistoryId : null
                      );
                    }}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </TableCell>

                <TableCell align="left">
                  <ChatIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleOpenComment(
                        row.assignClassId,
                        row.history ? row.history.studetnClassHistoryId : null,
                        row.historyComments || []
                      );
                    }}
                  />
                </TableCell>

                <TableCell align="left">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setStudetnClassHistoryInfo({
                        assignClassId: row.assignClassId,
                        studetnClassHistoryId: row.history
                          ? row.history.studetnClassHistoryId
                          : null,
                      });
                      openFilePond();
                    }}
                  >
                    {row.history && row.history.camera ? (
                      <img
                        src={`${env.resourceBase}/media/uploadimages/${row.history.camera}`}
                        width="30px"
                        alt="test"
                      />
                    ) : (
                      <CameraAltIcon size="small" />
                    )}
                  </div>

                  {/* <input
                    type="file"
                    id="avatar"
                    name="avatar"
                    accept="image/png, image/jpeg"
                  /> */}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const TabViewItem2 = (props) => {
  const classes = useStylesTabView();
  const {
    assignClasses,
    order,
    orderBy,
    onRequestSort,
    setupAwards1,
    handleOpenRemindDialog,
    handleOpenAwardInfoDialog,
  } = props;

  const [newHeadCells] = React.useState(() => {
    const abc = [
      {
        id: "Image",
        numeric: false,
        disablePadding: false,
        label: "Image",
        hasSort: false,
      },
      {
        id: "FirstName",
        numeric: false,
        disablePadding: false,
        label: "First Name",
        hasSort: false,
      },
      {
        id: "LastName",
        numeric: false,
        disablePadding: false,
        label: "Last Name",
        hasSort: false,
      },
      {
        id: "Info",
        numeric: false,
        disablePadding: false,
        label: "Info",
        hasSort: false,
      },
    ];
    setupAwards1.forEach((x) => {
      abc.push({
        id: x.setupAwardId,
        numeric: false,
        disablePadding: false,
        label: x.awardName,
        hasSort: false,
      });
    });
    return abc;
  });

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableContainer>
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size="medium"
        aria-label="enhanced table"
      >
        <TableHead className={classes.tableHead}>
          <TableRow>
            {newHeadCells.map((headCell) => {
              if (headCell.hasSort) {
                return (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? "right" : "left"}
                    padding={headCell.disablePadding ? "none" : "normal"}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                );
              } else {
                return (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? "right" : "left"}
                    padding={headCell.disablePadding ? "none" : "normal"}
                  >
                    {headCell.label}
                  </TableCell>
                );
              }
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {assignClasses.map((row, index) => {
            const labelId = `enhanced-table-checkbox-${index}`;
            const _attendance =
              row.history && row.history.status === 2 ? true : false;
            const _img =
              row.photo && row.photo !== ""
                ? `${env.resourceBase}/media/uploadimages/${row.photo}`
                : "/assets/no-image.png";
            return (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={row.assignClassId}
                className={clsx({ [classes.notAttend]: _attendance === false })}
              >
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  //padding="none"
                >
                  <img width="40px" src={_img} alt="belt" />
                </TableCell>
                <TableCell align="left">{row.firstName}</TableCell>
                <TableCell align="left">{row.lastName}</TableCell>
                <TableCell align="left">
                  {row.awardUseds && row.awardUseds.length > 0 ? (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleOpenAwardInfoDialog(row.awardUseds);
                      }}
                    >
                      {row.awardUseds[0].icon ? (
                        <img
                          width="40px"
                          src={`/assets/${row.awardUseds[0].icon}`}
                          alt="belt"
                        />
                      ) : (
                        <CheckBoxIcon
                          style={{ fontSize: 30, color: "#BEBEBE" }}
                        />
                      )}
                      {row.awardUseds && row.awardUseds.length > 1 && (
                        <span> + {row.awardUseds.length}</span>
                      )}
                    </div>
                  ) : (
                    "N/A"
                  )}
                </TableCell>

                {setupAwards1.map((row2, index2) => {
                  const _img =
                    row2.icon && row2.icon !== ""
                      ? `/assets/${row2.icon}`
                      : null;
                  const _awardUsed = row.awardUseds.find(
                    (x) => x.setupAwardId === row2.setupAwardId
                  );

                  return (
                    <React.Fragment key={index2}>
                      <TableCell align="left">
                        {_img ? (
                          <img
                            width="40px"
                            src={_img}
                            alt="belt"
                            style={{ cursor: "pointer" }}
                            className={clsx(classes.awardsIcon, {
                              [classes.selected]: _awardUsed,
                            })}
                            onClick={() => {
                              handleOpenRemindDialog(
                                row.assignClassId,
                                row2,
                                _awardUsed
                              );
                            }}
                          />
                        ) : (
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleOpenRemindDialog(
                                row.assignClassId,
                                row2,
                                _awardUsed
                              );
                            }}
                          >
                            {_awardUsed ? (
                              <CheckBoxIcon
                                style={{ fontSize: 30, color: "#BEBEBE" }}
                              />
                            ) : (
                              <CheckBoxOutlineBlankIcon
                                style={{ fontSize: 30, color: "#BEBEBE" }}
                              />
                            )}
                          </div>
                        )}
                      </TableCell>
                    </React.Fragment>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const TabViewItem3 = (props) => {
  const classes = useStylesTabView();
  const {
    assignClasses,
    order,
    orderBy,
    onRequestSort,
    setupAwards2,
    handleOpenRemindDialog,
  } = props;

  const [newHeadCells] = React.useState(() => {
    const abc = [
      {
        id: "Image",
        numeric: false,
        disablePadding: false,
        label: "Image",
        hasSort: false,
      },
      {
        id: "FirstName",
        numeric: false,
        disablePadding: false,
        label: "First Name",
        hasSort: false,
      },
      {
        id: "LastName",
        numeric: false,
        disablePadding: false,
        label: "Last Name",
        hasSort: false,
      },
      {
        id: "Info",
        numeric: false,
        disablePadding: false,
        label: "Info",
        hasSort: false,
      },
    ];
    setupAwards2.forEach((x) => {
      abc.push({
        id: x.setupAwardId,
        numeric: false,
        disablePadding: false,
        label: x.awardName,
        hasSort: false,
      });
      abc.push({
        id: x.setupAwardId + "Last",
        numeric: false,
        disablePadding: false,
        label: "Last",
        hasSort: false,
      });
    });
    return abc;
  });

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableContainer>
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size="medium"
        aria-label="enhanced table"
      >
        <TableHead className={classes.tableHead}>
          <TableRow>
            {newHeadCells.map((headCell) => {
              if (headCell.hasSort) {
                return (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? "right" : "left"}
                    padding={headCell.disablePadding ? "none" : "normal"}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                );
              } else {
                return (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? "right" : "left"}
                    padding={headCell.disablePadding ? "none" : "normal"}
                  >
                    {headCell.label}
                  </TableCell>
                );
              }
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {assignClasses.map((row, index) => {
            const labelId = `enhanced-table-checkbox-${index}`;
            const _attendance =
              row.history && row.history.status === 2 ? true : false;
            const _img =
              row.photo && row.photo !== ""
                ? `${env.resourceBase}/media/uploadimages/${row.photo}`
                : "/assets/no-image.png";

            return (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={row.assignClassId}
                className={clsx({ [classes.notAttend]: _attendance === false })}
              >
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  //padding="none"
                >
                  <img width="40px" src={_img} alt="belt" />
                </TableCell>
                <TableCell align="left">{row.firstName}</TableCell>
                <TableCell align="left">{row.lastName}</TableCell>
                <TableCell align="left">-</TableCell>

                {setupAwards2.map((row2, index2) => {
                  const _img =
                    row2.icon && row2.icon !== ""
                      ? `${env.resourceBase}/media/uploadimages/${row2.icon}`
                      : "/assets/no-image.png";
                  const _awardUsed = row.awardUseds.find(
                    (x) => x.setupAwardId === row2.setupAwardId
                  );
                  const _last = _awardUsed
                    ? utils.site.formatDDMMYYYY(
                        new Date(_awardUsed.dateUpdated)
                      )
                    : "-";

                  return (
                    <React.Fragment key={index2}>
                      <TableCell align="left">
                        <img
                          width="50px"
                          src={_img}
                          alt="belt"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleOpenRemindDialog(
                              row.assignClassId,
                              row2,
                              _awardUsed
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell align="left"> {_last} </TableCell>
                    </React.Fragment>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const BbaTextItem = (props) => {
  const { handleChangeBba, row, bba, termId } = props;
  const navEl = React.useRef(null);
  // React.useLayoutEffect(() => {
  //   navEl.current.addEventListener("focusin", (event) => {
  //     const ddd = navEl.current.querySelector("input");
  //     ddd.setAttribute("data-old", ddd.value);
  //   });
  //   navEl.current.addEventListener("focusout", (event) => {
  //     const ddd = navEl.current.querySelector("input");
  //     console.log("evnddddd", event.target.value); //recent
  //     console.log("navEl.current", ddd.getAttribute("data-old"));
  //   });
  //   // eslint-disable-next-line
  // }, []);
  return (
    <TextField
      ref={navEl}
      label=""
      variant="outlined"
      size="small"
      type="number"
      onChange={(event) => {
        handleChangeBba(
          event,
          row.studentRegoId,
          termId,
          row.studentRegoTermReport
            ? row.studentRegoTermReport.studentRegoTermReportId
            : null
        );
      }}
      defaultValue={bba}
    />
  );
};

const TabViewItem4 = (props) => {
  const classes = useStylesTabView();
  const {
    assignClasses,
    beltLevels,
    handleBeltlevelChange,
    handleChangeBba,
    handleUpgrade,
    classView,
    handlePromotion,
  } = props;

  const [newHeadCells] = React.useState([
    {
      id: "Image",
      numeric: false,
      disablePadding: false,
      label: "Image",
      hasSort: false,
    },
    {
      id: "FirstName",
      numeric: false,
      disablePadding: false,
      label: "First Name",
      hasSort: false,
    },
    {
      id: "LastName",
      numeric: false,
      disablePadding: false,
      label: "Last Name",
      hasSort: false,
    },
    {
      id: "Info",
      numeric: false,
      disablePadding: false,
      label: "Info",
      hasSort: false,
    },
    {
      id: "BeltLevel",
      numeric: false,
      disablePadding: false,
      label: "Belt Level",
      hasSort: false,
    },
    {
      id: "Age",
      numeric: false,
      disablePadding: false,
      label: "Age",
      hasSort: false,
    },
    {
      id: "Attendance",
      numeric: false,
      disablePadding: false,
      label: "Attendance",
      hasSort: false,
    },
    {
      id: "Promotion",
      numeric: false,
      disablePadding: false,
      label: "Promotion",
      hasSort: false,
    },
    {
      id: "BBA",
      numeric: false,
      disablePadding: false,
      label: "BBA",
      hasSort: false,
    },
    {
      id: "Upgrade",
      numeric: false,
      disablePadding: false,
      label: "Upgrade",
      hasSort: false,
    },
  ]);

  return (
    <TableContainer>
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size="medium"
        aria-label="enhanced table"
      >
        <TableHead className={classes.tableHead}>
          <TableRow>
            {newHeadCells.map((headCell) => {
              return (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? "right" : "left"}
                  padding={headCell.disablePadding ? "none" : "normal"}
                >
                  {headCell.label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {(assignClasses || []).map((row, index) => {
            const labelId = `enhanced-table-checkbox-${index}`;
            const _attendance =
              row.history && row.history.status === 2 ? true : false;
            const _img =
              row.photo && row.photo !== ""
                ? `${env.resourceBase}/media/uploadimages/${row.photo}`
                : "/assets/no-image.png";
            const _beltLevel = (beltLevels || []).find(
              (x) => x.beltLevelId === row.beltLevelId
            );
            const _age = utils.site.calculateAge(new Date(row.dateOfBirth));
            const _bba = row.studentRegoTermReport
              ? row.studentRegoTermReport.bba
              : 0;
            const _upgrade =
              row.studentRegoTermReport &&
              row.studentRegoTermReport.status === 1
                ? true
                : false;

            const _promotion =
              row.studentRegoTermReport &&
              row.studentRegoTermReport.status === 2
                ? true
                : false;

            console.log("row ------ >", row);

            return (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={row.assignClassId}
                className={clsx({ [classes.notAttend]: _attendance === false })}
              >
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  //padding="none"
                >
                  <img width="50px" src={_img} alt="belt" />
                </TableCell>

                <TableCell align="left">{row.firstName}</TableCell>
                <TableCell align="left">{row.lastName}</TableCell>
                <TableCell align="left">-</TableCell>
                <TableCell align="left">
                  <Autocomplete
                    id="beltLevelId"
                    options={beltLevels || []}
                    className={classes.formControl}
                    classes={{
                      option: classes.option,
                    }}
                    autoHighlight
                    getOptionLabel={(option) => option.beltName}
                    getOptionSelected={(option, value) =>
                      option.beltLevelId === value.beltLevelId
                    }
                    value={_beltLevel || null}
                    onChange={(event, newValue) => {
                      handleBeltlevelChange(
                        row.studentRegoId,
                        newValue ? newValue.beltLevelId : null
                      );
                    }}
                    renderOption={(option) => (
                      <React.Fragment>{option.beltName}</React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label=""
                        variant="outlined"
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </TableCell>
                <TableCell align="left">{_age}</TableCell>
                <TableCell align="left">-</TableCell>

                <TableCell align="left">
                  <Checkbox
                    checked={_promotion || false}
                    onChange={(event) => {
                      handlePromotion(
                        event,
                        row.studentRegoId,
                        classView.termId,
                        row.studentRegoTermReport
                          ? row.studentRegoTermReport.studentRegoTermReportId
                          : null,
                        row.assignClassId,
                        row.beltLevelId,
                        row.programId
                      );
                    }}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </TableCell>

                <TableCell align="left">
                  <BbaTextItem
                    handleChangeBba={handleChangeBba}
                    row={row}
                    bba={_bba}
                    termId={classView.termId}
                  />
                  {/* <TextField
                    label=""
                    variant="outlined"
                    size="small"
                    type="number"
                    onChange={(event) => {
                      handleChangeBba(event, row.assignClassId);
                    }}
                    defaultValue={_bba}
                  /> */}
                </TableCell>
                <TableCell align="left">
                  <Checkbox
                    checked={_upgrade || false}
                    onChange={(event) => {
                      handleUpgrade(
                        event,
                        row.studentRegoId,
                        classView.termId,
                        row.studentRegoTermReport
                          ? row.studentRegoTermReport.studentRegoTermReportId
                          : null,
                        row.assignClassId,
                        row.beltLevelId
                      );
                    }}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const createTab = (value, label) => {
  return { value, label };
};

const tabs = [
  createTab("attendance", "Attendance"),
  createTab("rewards", "Rewards"),
  createTab("discipline", "Discipline"),
  createTab("graduation", "Graduation"),
];

const a11yProps = (index) => {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
};

const EnhancedTableToolbar2 = (props) => {
  const classes = useToolbarStyles();
  const {
    //numSelected,
    //handleFilter,
    //currentTab,
    //activeSetupBeltSize,
    //inCctiveSetupBeltSize,
    //deleteItems,
    //studioId,
    classView,
    commit,
  } = props;

  return (
    <Toolbar className={clsx(classes.root)} style={{ minHeight: "50px" }}>
      <Box className={classes.title}>
        <Typography> Class Details </Typography>
      </Box>

      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={commit}
        //className={classes.button}
        // startIcon={
        //   isSubmitting && (
        //     <span
        //       className="spinner-grow spinner-grow-sm"
        //       role="status"
        //       aria-hidden="true"
        //     ></span>
        //   )
        // }
        // disabled={isSubmitting}
      >
        {classView.isCommitted ? "Committed" : "Commit"}
      </Button>
    </Toolbar>
  );
};

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    //numSelected,
    //handleFilter,
    //currentTab,
    //activeSetupBeltSize,
    //inCctiveSetupBeltSize,
    //deleteItems,
    //studioId,
    classView,
  } = props;

  return (
    <Toolbar className={clsx(classes.root)}>
      <Box className={classes.title}>
        <Typography> {classView.programName} </Typography>
        <Typography variant="body2">
          {`Week #${classView.week} | ${utils.site.formatDDMMYYYY(
            new Date(classView.date)
          )} | ${classView.displayDay} | ${classView.startTime} - ${
            classView.endTime
          }`}
        </Typography>
      </Box>

      {/* <ButtonGroup size="small" aria-label="small outlined button group">
        <Tooltip title="Create Belt Size" arrow>
          <Button
            component={Link}
            to={`/dashboard/property-metrics/setup-belt-size/new`}
          >
            <AddIcon fontSize="small" />
          </Button>
        </Tooltip>
        <Tooltip title="Filters" arrow>
          <Button onClick={handleFilter}>
            <FilterListIcon fontSize="small" />
          </Button>
        </Tooltip>

        <Tooltip title="Active" arrow>
          <Button onClick={activeSetupBeltSize}>
            <CheckCircleOutlineIcon fontSize="small" />
          </Button>
        </Tooltip>
        <Tooltip title="In Active" arrow>
          <Button onClick={inCctiveSetupBeltSize}>
            <RemoveCircleIcon fontSize="small" />
          </Button>
        </Tooltip>
        <Tooltip title="Delete Studio" arrow>
          <Button onClick={deleteItems}>
            <DeleteIcon fontSize="small" />
          </Button>
        </Tooltip>
      </ButtonGroup> */}
    </Toolbar>
  );
};

const CommentDialog = (props) => {
  const classes = useCoDialogStyles();
  const {
    open,
    historyComment,
    handleCloseComment,
    register,
    isSubmitting,
    errors,
    submitComment,
    handleSubmit,
  } = props;

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      //onClose={handleClose}
      open={open}
      scroll="paper"
      maxWidth="sm"
      fullWidth={true}
    >
      <form
        method="POST"
        noValidate=""
        //className={classes.formRoot}
        onSubmit={handleSubmit(submitComment)}
      >
        <DialogTitle disableTypography>
          <Typography variant="h6">Comments</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleCloseComment}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            //id="outlined-basic"
            label="Commnet"
            variant="outlined"
            error={!!errors.comment}
            helperText={errors?.comment?.message}
            size="small"
            className={classes.textField}
            multiline
            minRows={2}
            {...register("comment")}
          />
          <List component="nav" aria-label="secondary mailbox folders">
            {historyComment &&
              historyComment.map((row, index) => {
                return (
                  <ListItem key={index}>
                    <ListItemText
                      primary={
                        <div>
                          <Typography variant="caption">
                            {"Adrian Adams"}
                          </Typography>
                          <Typography
                            variant="caption"
                            style={{ float: "right" }}
                          >
                            {utils.site.formatDDMMYYYY(
                              new Date(row.dateCreated)
                            )}
                          </Typography>
                        </div>
                      }
                      secondary={row.comment}
                    />
                  </ListItem>
                );
              })}
          </List>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            type="submit"
            startIcon={
              isSubmitting && (
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )
            }
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const RemindDialog = (props) => {
  const classes = useCoDialogStyles();
  const {
    open,
    setupAward,
    assignClassId,
    classView,
    control2,
    awardCriterias,
    submitComment2,
    handleSubmit2,
    handleCloseRemindDialog,
    isSubmitting2,
    errors2,
    register2,
    criterionId,
    awardUsed,
    removeAward,
  } = props;

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      //onClose={handleClose}
      open={open}
      scroll="paper"
      maxWidth="sm"
      fullWidth={true}
    >
      <form
        method="POST"
        noValidate=""
        //className={classes.formRoot}
        onSubmit={handleSubmit2((dataModel) => {
          submitComment2(
            dataModel,
            setupAward.setupAwardId,
            assignClassId,
            classView.classId,
            awardCriterias
          );
        })}
      >
        <DialogTitle disableTypography>
          <Typography variant="h6">
            {" "}
            {setupAward && setupAward.awardName}{" "}
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleCloseRemindDialog}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <FormControl
            component="fieldset"
            className={classes.textField}
            size="small"
            error={!!errors2.criterionId}
            disabled={awardUsed ? true : false}
          >
            <Controller
              control={control2}
              name="criterionId"
              aria-label="criterionId"
              render={({ field }) => (
                <RadioGroup {...field}>
                  {(awardCriterias || []).map((row, index) => {
                    return (
                      <FormControlLabel
                        key={index}
                        value={row.title}
                        control={<Radio color="primary" />}
                        label={row.title}
                      />
                    );
                  })}
                  <FormControlLabel
                    value="Other"
                    control={<Radio color="primary" />}
                    label="Other"
                  />
                </RadioGroup>
              )}
            />

            {!!register2.criterionId && (
              <FormHelperText error id="endDate-error">
                {register2?.criterionId?.message}
              </FormHelperText>
            )}
          </FormControl>

          {criterionId === "Other" && (
            <TextField
              //id="outlined-basic"
              label="Description*"
              variant="outlined"
              error={!!errors2.details}
              helperText={errors2?.details?.message}
              size="small"
              className={classes.textField}
              multiline
              minRows={3}
              disabled={awardUsed ? true : false}
              {...register2("details")}
            />
          )}
        </DialogContent>

        <DialogActions>
          {awardUsed && (
            <Button
              color="primary"
              type="button"
              onClick={() => {
                removeAward(awardUsed.awardUsedId);
              }}
            >
              Remove
            </Button>
          )}

          <Button
            style={{ visibility: awardUsed ? "hidden" : "visible" }}
            color="primary"
            type="submit"
            startIcon={
              isSubmitting2 && (
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )
            }
            disabled={isSubmitting2}
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const AwardInfoDialog = (props) => {
  const classes = useCoDialogStyles();
  const { open, handleCloseAwardInfoDialog, rowAwardUseds } = props;
  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      //onClose={handleClose}
      open={open}
      scroll="paper"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle disableTypography>
        <Typography variant="h6">Info</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleCloseAwardInfoDialog}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {(rowAwardUseds || []).map((rowAwardUsed, index) => {
          return (
            <Typography key={rowAwardUsed.awardUsedId}>
              {rowAwardUsed.icon ? (
                <img
                  width="40px"
                  src={`/assets/${rowAwardUsed.icon}`}
                  alt="belt"
                />
              ) : (
                <CheckBoxIcon style={{ fontSize: 30, color: "#BEBEBE" }} />
              )}

              <span style={{ marginLeft: "16px" }}>
                {rowAwardUsed.awardName}
              </span>
            </Typography>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          type="button"
          onClick={handleCloseAwardInfoDialog}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const schema = yup.object().shape({
  comment: yup.string().required("Comment is required."),
});

const schema2 = yup.object().shape({
  criterionId: yup.string().required("Criterion is required."),
  details: yup.string().nullable(true),
});

export function ClassDetails(props) {
  const { inputType } = props;
  const inputTypeItem = inputType || "normal";
  const classes = useStyles();
  const { dispatch } = useStore();
  const history = useHistory();
  const { studioId, classId } = useParams(); //termId
  const previousController = React.useRef();
  const filePondRef = React.useRef(null);
  const timerController = React.useRef();
  const [serverErrors, setServerErrors] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [getCDResultModel, setGetCDResultModel] = React.useState();
  const [tab, setTab] = React.useState(tabs[0].value);
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [apiTrigger, setApiTrigger] = React.useState(0);
  const [studetnClassHistoryInfo, setStudetnClassHistoryInfo] =
    React.useState();
  const [commentDialogInfo, setCommentDialogInfo] = React.useState({
    open: false,
    assignClassId: null,
    studetnClassHistoryId: null,
    historyComment: null,
  });
  const [remindDialogInfo, setRemindDialogInfo] = React.useState({
    open: false,
  });
  const [awardInfoDialogInfo, setAwardInfoDialogInfo] = React.useState({
    open: false,
  });
  const [openSnackbarMessage, setOpenSnackbarMessage] = React.useState({
    openStatus: false,
    message: null,
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    control: control2,
    watch: watch2,
    trigger: trigger2,
    reset: reset2,
    getValues: getValues2,
    formState: { isSubmitting: isSubmitting2, errors: errors2 },
  } = useForm({
    resolver: yupResolver(schema2),
    defaultValues: {
      criterionId: "Other",
    },
  });

  const { criterionId } = getValues2();

  const handleChange = (event, newValue) => {
    //console.log("Tab", tab, newValue);
    setTab(newValue);
  };

  const handleBeltlevelChange = async (studentRegoId, beltLevelId) => {
    if (!beltLevelId) {
      return;
    }
    setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(
        env.apiBase + "/api/student/updatestudentregoforbeltlevel",
        {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            studentRegoId: studentRegoId,
            beltLevelId: beltLevelId,
          }),
          ...options,
        }
      );

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setServerErrors(data.errors);
      } else {
        setApiTrigger(apiTrigger + 1);
      }
    } catch (error) {
      serverErrors.push("error : " + error.message);
      setServerErrors([...serverErrors]);
      setBackdropOpen(false);
    }
  };

  const apiCall = async (url, apiData, returnFunction = null) => {
    setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + url, {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          ...apiData,
          classId: classId,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setBackdropOpen(false);
        setOpenSnackbarMessage({
          openStatus: true,
          message: data.errors[0].errorMessage,
        });
        return;
      }
      if (returnFunction) {
        returnFunction(data.results);
      } else {
        setApiTrigger(apiTrigger + 1);
      }
    } catch (error) {
      serverErrors.push("error : " + error.message);
      setServerErrors([...serverErrors]);
      setBackdropOpen(false);
    }
  };

  const handleCheckAttendance = (
    event,
    assignClassId,
    studetnClassHistoryId
  ) => {
    apiCall("/api/student/updatehistoryattend", {
      checkAttendance: event.target.checked,
      assignClassId: assignClassId,
      studetnClassHistoryId: studetnClassHistoryId,
    });
  };

  const handleCheckAbsentEmail = (
    event,
    assignClassId,
    studetnClassHistoryId
  ) => {
    apiCall("/api/student/updatehistoryabsentemail", {
      checkAbsentEmail: event.target.checked,
      assignClassId: assignClassId,
      studetnClassHistoryId: studetnClassHistoryId,
    });
  };

  const handleUpgrade = async (
    event,
    studentRegoId,
    termId,
    studentRegoTermReportId,
    assignClassId,
    beltLevelId
  ) => {
    await apiCall("/api/term/updateupgrade", {
      checkUpgrade: event.target.checked,
      studentRegoId: studentRegoId,
      termId: termId,
      studentRegoTermReportId: studentRegoTermReportId,
      assignClassId: assignClassId,
      oldBeltLevelId: beltLevelId,
    });
  };

  const handlePromotion = async (
    event,
    studentRegoId,
    termId,
    studentRegoTermReportId,
    assignClassId,
    beltLevelId,
    programId
  ) => {
    await apiCall("/api/term/updatepromotion", {
      checkPromotion: event.target.checked,
      studentRegoId: studentRegoId,
      termId: termId,
      studentRegoTermReportId: studentRegoTermReportId,
      assignClassId: assignClassId,
      oldBeltLevelId: beltLevelId,
      programId: programId,
    });
  };

  const handleChangeBba = (
    event,
    studentRegoId,
    termId,
    studentRegoTermReportId
  ) => {
    if (!event.target.value) {
      return;
    }

    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    const _bbaValue = event.target.value;

    timerController.current = setTimeout(() => {
      const fetchData = async () => {
        await apiCall("/api/term/updatebba", {
          bba: _bbaValue,
          studentRegoId: studentRegoId,
          termId: termId,
          studentRegoTermReportId: studentRegoTermReportId,
        });
      };

      fetchData();
    }, 500);
  };

  const handleOpenComment = (
    assignClassId,
    studetnClassHistoryId,
    historyComments
  ) => {
    commentDialogInfo.open = true;
    commentDialogInfo.assignClassId = assignClassId;
    commentDialogInfo.studetnClassHistoryId = studetnClassHistoryId;
    commentDialogInfo.historyComment = historyComments || [];
    setCommentDialogInfo({
      ...commentDialogInfo,
    });
  };

  const handleCloseComment = () => {
    reset();
    commentDialogInfo.open = false;
    commentDialogInfo.assignClassId = null;
    commentDialogInfo.studetnClassHistoryId = null;
    setCommentDialogInfo({
      ...commentDialogInfo,
    });
  };

  const submitComment = (dataModel) => {
    apiCall("/api/student/addhistorycomment", {
      ...dataModel,
      assignClassId: commentDialogInfo.assignClassId,
      studetnClassHistoryId: commentDialogInfo.studetnClassHistoryId,
      classId: getCDResultModel.classView.classId,
    });

    reset();
    commentDialogInfo.open = false;
    commentDialogInfo.assignClassId = null;
    setCommentDialogInfo({
      ...commentDialogInfo,
    });
  };

  const handleOpenRemindDialog = async (
    assignClassId,
    setupAward,
    awardUsed
  ) => {
    // remindDialogInfo.open = true;
    // setRemindDialogInfo({...remindDialogInfo});
    setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/award/getawardcriterions", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          //...apiData,
          //classId: classId,
          setupAwardId: setupAward.setupAwardId,
          awardUsedId: awardUsed ? awardUsed.awardUsedId : null,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setServerErrors(data.errors);
        setBackdropOpen(false);
        return;
      }

      if (data.results) {
        const { awardUsed: _awardUsed } = data.results;

        if (_awardUsed) {
          
          console.log("_awardUsed", _awardUsed);
          
          reset2({
            criterionId: _awardUsed.criterionTitle,
            details: _awardUsed.details,
          });
        }

        remindDialogInfo.open = true;
        setRemindDialogInfo({
          ...remindDialogInfo,
          ...data.results,
          setupAward: setupAward,
          assignClassId: assignClassId,
        });
      }
    } catch (error) {
      serverErrors.push("error : " + error.message);
      setServerErrors([...serverErrors]);
      setBackdropOpen(false);
    }
    setBackdropOpen(false);
  };

  const handleCloseRemindDialog = () => {
    reset2({
      criterionId: "other",
      details: "",
    });
    setRemindDialogInfo({
      open: false,
    });

    if (document.activeElement) {
      document.activeElement.blur();
    }
  };

  const submitComment2 = async (
    dataModel,
    setupAwardId,
    assignClassId,
    classId,
    awardCriterias
  ) => {
    const awardCriteria =
      dataModel.criterionId === "Other"
        ? { title: "Other" }
        : awardCriterias.find((x) => x.title === dataModel.criterionId);

    await apiCall("/api/award/saveaddawardforstudent", {
      ...dataModel,
      setupAwardId: setupAwardId,
      assignClassId: assignClassId,
      classId: classId,
      criterionTitle: awardCriteria ? awardCriteria.title : null,
      criterionAttachment: awardCriteria ? awardCriteria.attachment : null,
    });
    reset2({
      criterionId: "other",
      details: "",
    });
    setRemindDialogInfo({
      open: false,
    });
  };

  const removeAward = (awardUsedId) => {
    apiCall("/api/award/removeawardused", {
      awardUsedId: awardUsedId,
    });
    reset2({
      criterionId: "Other",
      details: "",
    });
    setRemindDialogInfo({
      open: false,
    });
  };

  const commit = async () => {
    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    await apiCall(
      "/api/classes/updateiscommittedclass",
      {
        classId: classId,
      },
      (result) => {
        timerController.current = setTimeout(() => {
          history.push(`/dashboard/studio/${studioId}/classes`);
        }, 1000);
      }
    );
  };

  const openFilePond = () => {
    setBackdropOpen(true);
    setTimeout(() => {
      filePondRef.current.browse();
      setBackdropOpen(false);
    }, 500);
  };

  const handleOpenAwardInfoDialog = (rowAwardUseds) => {
    setAwardInfoDialogInfo({
      open: true,
      rowAwardUseds: rowAwardUseds,
    });
  };

  const handleCloseAwardInfoDialog = () => {
    if (document.activeElement) {
      document.activeElement.blur();
    }
    setAwardInfoDialogInfo({
      open: false,
    });
  };

  const handleCloseFunction = () => {
    setOpenSnackbarMessage(false);
  };

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    let timerBackdropOpen = null;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(env.apiBase + "/api/classes/getclassdetails", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            studioId: studioId,
            classId: classId,
          }),
          ...options,
        });

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
          setLoading(false);
          return;
        }

        if (data.results) {
          setGetCDResultModel({
            ...data.results,
          });
        }
      } catch (error) {
        console.error(error.message);
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }

      setLoading(false);
      timerBackdropOpen = setTimeout(() => {
        setBackdropOpen(false);
      }, 800);
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [apiTrigger]);

  React.useEffect(() => {
    const subscription = watch2((value, { name, type }) => {
      if (name === "criterionId" || name === "criterionId") {
        trigger2(name);
      }
    });

    return () => subscription.unsubscribe();
    // eslint-disable-next-line
  }, [watch2]);

  React.useEffect(() => {
    dispatch({
      type: "SET_STATE",
      payload: {
        breadcrumbs: [
          { title: "Dashboard", link: "/dashboard" },
          { title: "Studios", link: "/dashboard/studios" },
          { title: "Manage Class", link: null },
        ],
      },
    });

    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }
  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <SnackbarMessageV2
        {...openSnackbarMessage}
        handleCloseFunction={handleCloseFunction}
        severity="error"
      />

      <div style={{ visibility: "hidden", width: "1px", height: "1px" }}>
        <FilePond
          allowMultiple={false}
          maxFiles={1}
          name="uploadFile"
          maxFileSize={"10MB"}
          allowDrop={false}
          allowReorder={true}
          captureMethod="environment"
          labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
          acceptedFileTypes={"image/*"}
          ref={filePondRef}
          oninitfile={() => {
            setBackdropOpen(true);
          }}
          onprocessfiles={() => {
            setApiTrigger(apiTrigger + 1);
          }}
          server={{
            process: {
              url: env.apiBase + "/api/file/uploadimageforclasscamera",
              ondata: (formData) => {
                formData.append(
                  "studetnClassHistoryId",
                  studetnClassHistoryInfo.studetnClassHistoryId
                );
                formData.append("classId", classId);
                formData.append(
                  "assignClassId",
                  studetnClassHistoryInfo.assignClassId
                );
                return formData;
              },
              onload: (res) => {
                //setValue("uploadFile", res);
                //trigger("uploadFile");
              },
            },
            fetch: null,
            revert: null,
          }}
        />
      </div>

      <AwardInfoDialog
        {...awardInfoDialogInfo}
        handleCloseAwardInfoDialog={handleCloseAwardInfoDialog}
      />

      <CommentDialog
        {...commentDialogInfo}
        handleCloseComment={handleCloseComment}
        register={register}
        isSubmitting={isSubmitting}
        errors={errors}
        submitComment={submitComment}
        handleSubmit={handleSubmit}
      />

      <RemindDialog
        {...remindDialogInfo}
        {...getCDResultModel}
        control2={control2}
        submitComment2={submitComment2}
        handleSubmit2={handleSubmit2}
        handleCloseRemindDialog={handleCloseRemindDialog}
        isSubmitting2={isSubmitting2}
        errors2={errors2}
        register2={register2}
        criterionId={criterionId}
        removeAward={removeAward}
      />

      <Paper className={classes.paper}>
        <EnhancedTableToolbar2 {...getCDResultModel} commit={commit} />
        <hr
          style={{
            marginTop: "0px",
            marginBottom: "0px",
            borderColor: "rgba(0,0,0,.1)",
          }}
        />
        <EnhancedTableToolbar {...getCDResultModel} />
        <AppBar position="static">
          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="wrapped label tabs example"
          >
            {tabs.map((row, index) => {
              return (
                <Tab
                  wrapped
                  key={row.value}
                  value={row.value}
                  label={row.label}
                  {...a11yProps(row.value)}
                />
              );
            })}
          </Tabs>
        </AppBar>
        {tabs.map((row, index) => {
          return (
            <TabPanel key={row.value} value={tab} index={row.value}>
              {row.value === "attendance" && (
                <TabViewItem
                  {...getCDResultModel}
                  handleBeltlevelChange={handleBeltlevelChange}
                  handleCheckAttendance={handleCheckAttendance}
                  handleCheckAbsentEmail={handleCheckAbsentEmail}
                  handleOpenComment={handleOpenComment}
                  handleCloseComment={handleCloseComment}
                  openFilePond={openFilePond}
                  setStudetnClassHistoryInfo={setStudetnClassHistoryInfo}
                />
              )}
              {row.value === "rewards" && (
                <TabViewItem2
                  {...getCDResultModel}
                  handleOpenRemindDialog={handleOpenRemindDialog}
                  handleOpenAwardInfoDialog={handleOpenAwardInfoDialog}
                />
              )}
              {row.value === "discipline" && (
                <TabViewItem3
                  {...getCDResultModel}
                  handleOpenRemindDialog={handleOpenRemindDialog}
                />
              )}
              {row.value === "graduation" && (
                <TabViewItem4
                  {...getCDResultModel}
                  handleBeltlevelChange={handleBeltlevelChange}
                  handleChangeBba={handleChangeBba}
                  handleUpgrade={handleUpgrade}
                  handlePromotion={handlePromotion}
                />
              )}
            </TabPanel>
          );
        })}
      </Paper>
    </div>
  );
}
