import React from "react";
import env from "../../../env";
import { useParams } from "react-router-dom";
import { ErrorMessage, Loading } from "components";
import utils from "utils";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  Paper,
  Box,
  Typography,
  makeStyles,
  useTheme,
  //Avatar,
  //Grid,
  //Backdrop,
  //Dialog,
  //DialogTitle,
  //IconButton,
  //DialogContent,
  //DialogActions,
  //Button,
  //TextField,
  //List,
  //ListItem,
  //ListItemText,
  //ListItemIcon,
  //Container,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //paddingBottom: theme.spacing(10),
    //position: 'relative'
  },
  paper: {
    width: "100%",
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    //paddingLeft : theme.spacing(1),
    //paddingRight : theme.spacing(1)
    //marginBottom: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 500,
    color: "#fff",
    //position: "absolute",
  },
  listItem: {
    borderBottom: `1px solid rgba(0,0,0,.1)`,
  },
  listItemIcon: {
    "& svg": {
      marginLeft: "auto",
    },
  },
}));

const useViewStyles = makeStyles((theme) => ({
  description: {
    marginTop: theme.spacing(1),
  },
}));

const StudentManualForUserView = (props) => {
  const { appManage } = props;
  const theme = useTheme();
  const classes = useViewStyles();
  const createdDate = utils.site.formatDDMMYYYYWithTime(
    new Date(appManage.dateCreated)
  );
  return (
    <>
      <Typography variant="subtitle1">{appManage.title}</Typography>
      <Typography
        variant="caption"
        component="div"
        style={{ marginTop: theme.spacing(1) }}
      >
        {createdDate}
      </Typography>
      <div
        className={classes.description}
        dangerouslySetInnerHTML={{ __html: appManage.description }}
      />
      {appManage.videoURL && appManage.videoURL !== "" && (
        <Box maxWidth="560px" position="relative">
          {/* <img
            width="100%"
            src={`https://img.youtube.com/vi/${utils.site.getYoutubeId(
              appManage.videoURL
            )}/maxresdefault.jpg`}
          /> */}
          <div style={{position : "absolute", top: '0', left: '0', width: '100%', height: '100%', zIndex: 1}}>
            <iframe
              width="560"
              height="315"
              src={`https://www.youtube.com/embed/${utils.site.getYoutubeId(appManage.videoURL)}?si=bmBoEYiWaiPFIRXa&rel=0`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen="allowfullscreen"
            ></iframe>
          </div>
          <Skeleton variant="rect" width={`100%`} height={315} />
        </Box>
      )}

      {appManage.uploadFile &&
        appManage.uploadFile !== "" &&
        appManage.uploadFile.includes(".pdf") && (
          <Box style={{ marginTop: theme.spacing(1) }}>
            <a
              href={`${env.resourceBase}/media/uploadimages/${appManage.uploadFile}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/assets/pdf-icon.png" width="50px" />
            </a>
            <Typography variant="caption" component="div">
              View PDF
            </Typography>
          </Box>
        )}
    </>
  );
};

export function StudentManualForUser(props) {
  const { userInfo, studentId } = props;
  const { appManagementId } = useParams();
  const classes = useStyles();
  const [errorResult, setErrorResult] = React.useState({
    type: "server",
    errors: [],
  });
  const [loading, setLoading] = React.useState(1);
  const [getApiResult, setGetApiResult] = React.useState();
  const previousController = React.useRef();

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    let timerBackdropOpen = null;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(
          env.apiBase + "/api/appmanage/getappmanageitemforuser",
          {
            method: "post",
            signal: signal,
            body: JSON.stringify({
              userInfo: userInfo,
              studentId: studentId,
              appManagementId: appManagementId,
            }),
            ...options,
          }
        );

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();
        console.log("data", data);

        if (data.errors && data.errors.length > 0) {
          setErrorResult({
            type: "server",
            errors: data.errors,
          });
          setLoading(0);
          return;
        }

        if (data.results) {
          setGetApiResult({
            ...data.results,
          });
        }
      } catch (error) {
        const _errors = [];
        _errors.push("error : " + error.message);
        setErrorResult({
          type: "server",
          errors: _errors,
        });
        setLoading(0);
        return;
      }

      if (loading === 2) {
        timerBackdropOpen = setTimeout(() => {
          setLoading(0);
        }, 800);
      } else {
        setLoading(0);
      }
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  if (loading === 1) {
    return <Loading />;
  } else if (errorResult.type === "server" && errorResult.errors.length > 0) {
    return <ErrorMessage errors={errorResult.errors} />;
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <StudentManualForUserView {...getApiResult} />
      </Paper>
    </div>
  );
}
