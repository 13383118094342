import React from "react";
//import { useStore } from "../../../store";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Tabs, Tab, Box } from "@material-ui/core";
import {
  //useParams,
  Link,
} from "react-router-dom";
import { ConfigMenuStudio } from "./configMenu";
import { StudioMenuStudio } from "./studioMenu";
import { StudioMenuReports } from "./studioMenuReports";
import {
  Switch,
  Route,
  Redirect,
  useParams,
  useLocation,
} from "react-router-dom";
import { Programs } from "./programs";
import { Program } from "./program";
import { Terms } from "./terms";
import { Term } from "./term";
import { Batches } from "./batches";
import { Batche } from "./batche";
import { BeltLevels } from "./beltLevels";
import { BeltLevel } from "./beltLevel";
import { HouseTeams } from "./houseTeams";
import { StudioSetting } from "./studioSetting";
import { Dashboard } from "./dashboard";
import { Awards } from "./awards";
import { Award } from "./award";
import { Classes } from "./classes";
import { ClassDetails } from "./classDetails";
import { ClassHistory } from "./classHistory";
import { Classlist } from "./classList";
import { PrivateLessons } from "./privateLessons";
import { PrivateClass } from "./PrivateLesson";
import { Upgrades } from "./upgrades";
import { Certificates } from "./certificates";
import { NonPromotinReports } from "./nonPromotinReports";
import { HouseTeamReports } from "./houseTeamReports";
import { StudioReports } from "./studioReports";
import { ReferralFriends } from "./referralFriends";
import { ReferralFriend } from "./referralFriend";
import { Events } from "./events";
import { Event } from "./event";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tab: {
    "&:focus": {
      color: "inherit",
      //opacity: '0.7'
    },
  },
}));

export function StudioSection(props) {
  const { userInfo } = props;
  //const { dispatch } = useStore();
  const classes = useStyles();
  const { studioId } = useParams();
  const pathname = useLocation().pathname;
  const [value, setValue] = React.useState("one");

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  React.useEffect(() => {
    let result = "one";
    if (
      pathname.includes("/classes") ||
      pathname.includes("/class/") ||
      pathname.includes("/class-history") ||
      pathname.includes("/history-class/") ||
      pathname.includes("/class-list") ||
      pathname.includes("private-classes") ||
      pathname.includes("/referral-friends") ||
      pathname.includes("/referral-friend/") ||
      pathname.includes("/events") ||
      pathname.includes("/event/")
    ) {
      result = "two";
    } else if (
      pathname.includes("/upgrades") ||
      pathname.includes("/certificates") ||
      pathname.includes("/non-promotion-reports") ||
      pathname.includes("/house-team-reports") ||
      pathname.includes("/studio-reports")
    ) {
      result = "three";
    }
    setValue(result);
  }, [pathname]);

  return (
    <>
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs
            value={value}
            aria-label="wrapped label tabs example"
            //variant="fullWidth"
            //onChange={handleChange}
            //indicatorColor="primary"
          >
            <Tab
              value="one"
              wrapped
              label="Config Menu"
              component={Link}
              to={`/dashboard/studio/${studioId}/dashboard`}
              className={classes.tab}
              {...a11yProps("one")}
            />
            <Tab
              value="two"
              wrapped
              label="Studio Menu"
              component={Link}
              to={`/dashboard/studio/${studioId}/classes`}
              className={classes.tab}
              {...a11yProps("two")}
            />
            <Tab
              value="three"
              wrapped
              label="Reports"
              component={Link}
              to={`/dashboard/studio/${studioId}/upgrades`}
              className={classes.tab}
              {...a11yProps("three")}
            />
          </Tabs>
        </AppBar>

        <TabPanel value={value} index="one">
          <ConfigMenuStudio studioId={studioId} pathname={pathname} />
        </TabPanel>
        <TabPanel value={value} index="two">
          <StudioMenuStudio studioId={studioId} pathname={pathname} />
        </TabPanel>
        <TabPanel value={value} index="three">
          <StudioMenuReports studioId={studioId} pathname={pathname} />
        </TabPanel>
      </div>
      <Box mt={2} mb={2}>
        <Switch>
          <Route
            exact
            path="/dashboard/studio/:studioId/private-class/new"
            component={PrivateClass}
          />
          <Route
            exact
            path="/dashboard/studio/:studioId/private-class/:privateClassId"
            component={PrivateClass}
          />
          <Route
            exact
            path="/dashboard/studio/:studioId/private-classes"
            userInfo={userInfo}
          >
            <PrivateLessons />
          </Route>

          <Route
            exact
            path="/dashboard/studio/:studioId/referral-friend/:reItemId"
            userInfo={userInfo}
          >
            <ReferralFriend />
          </Route>

          <Route
            exact
            path="/dashboard/studio/:studioId/referral-friends"
            userInfo={userInfo}
          >
            <ReferralFriends />
          </Route>

          <Route
            exact
            path="/dashboard/studio/:studioId/event/new"
            userInfo={userInfo}
          >
            <Event />
          </Route>

          <Route
            exact
            path="/dashboard/studio/:studioId/event/:eventId"
            userInfo={userInfo}
          >
            <Event />
          </Route>

          <Route
            exact
            path="/dashboard/studio/:studioId/events"
            userInfo={userInfo}
          >
            <Events />
          </Route>

          <Route exact path="/dashboard/studio/:studioId/class-list">
            <Classlist userInfo={userInfo} />
          </Route>

          <Route exact path="/dashboard/studio/:studioId/class-history">
            <ClassHistory userInfo={userInfo} />
          </Route>
          <Route
            exact
            path="/dashboard/studio/:studioId/history-class/:classId"
          >
            <ClassDetails userInfo={userInfo} />
          </Route>
          <Route exact path="/dashboard/studio/:studioId/class/:classId">
            <ClassDetails userInfo={userInfo} />
          </Route>
          <Route exact path="/dashboard/studio/:studioId/classes">
            <Classes userInfo={userInfo} />
          </Route>
          <Route
            exact
            path="/dashboard/studio/:studioId/dashboard"
            component={Dashboard}
          />
          <Route exact path="/dashboard/studio/:studioId/program/new">
            <Program userInfo={userInfo} />
          </Route>
          <Route exact path="/dashboard/studio/:studioId/program/:programId">
            <Program userInfo={userInfo} />
          </Route>
          <Route exact path="/dashboard/studio/:studioId/programs">
            <Programs userInfo={userInfo} />
          </Route>
          <Route
            exact
            path="/dashboard/studio/:studioId/term/new"
            component={Term}
          />
          <Route
            exact
            path="/dashboard/studio/:studioId/term/:termId"
            component={Term}
          />
          <Route exact path="/dashboard/studio/:studioId/terms">
            <Terms userInfo={userInfo} />
          </Route>
          <Route exact path="/dashboard/studio/:studioId/batche/new">
            <Batche userInfo={userInfo} />
          </Route>
          <Route exact path="/dashboard/studio/:studioId/batche/:batcheId">
            <Batche userInfo={userInfo} />
          </Route>
          <Route
            exact
            path="/dashboard/studio/:studioId/batches"
            component={Batches}
          />

          <Route exact path="/dashboard/studio/:studioId/beltLevel/new">
            <BeltLevel userInfo={userInfo} />
          </Route>

          <Route
            exact
            path="/dashboard/studio/:studioId/beltLevel/:beltLevelId"
          >
            <BeltLevel userInfo={userInfo} />
          </Route>

          <Route exact path="/dashboard/studio/:studioId/beltLevels">
            <BeltLevels userInfo={userInfo} />
          </Route>
          <Route exact path="/dashboard/studio/:studioId/houseTeams">
            <HouseTeams userInfo={userInfo} />
          </Route>
          <Route exact path="/dashboard/studio/:studioId/studio-setting">
            <StudioSetting userInfo={userInfo} />
          </Route>

          <Route exact path="/dashboard/studio/:studioId/award/:awardId">
            <Award userInfo={userInfo} />
          </Route>

          <Route exact path="/dashboard/studio/:studioId/awards">
            <Awards userInfo={userInfo} />
          </Route>

          <Route exact path="/dashboard/studio/:studioId/upgrades">
            <Upgrades userInfo={userInfo} />
          </Route>

          <Route exact path="/dashboard/studio/:studioId/certificates">
            <Certificates userInfo={userInfo} />
          </Route>

          <Route exact path="/dashboard/studio/:studioId/non-promotion-reports">
            <NonPromotinReports userInfo={userInfo} />
          </Route>

          <Route exact path="/dashboard/studio/:studioId/house-team-reports">
            <HouseTeamReports userInfo={userInfo} />
          </Route>

          <Route exact path="/dashboard/studio/:studioId/studio-reports">
            <StudioReports userInfo={userInfo} />
          </Route>

          <Route exact path="/dashboard/studio/:studioId">
            <Redirect to={`/dashboard/studio/${studioId}/dashboard`} />
          </Route>

          <Route path="/dashboard">
            <Redirect to="/dashboard" status="301" />
          </Route>
        </Switch>
      </Box>
    </>
  );
}
