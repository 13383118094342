import React from "react";
import env from "../../../env";
import { variables } from "../../../cssInJs";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
//import clsx from "clsx";
//import utils from "../../../utils";
import { ErrorMessage, Loading } from "components";
import Autocomplete from "@material-ui/lab/Autocomplete";
//import CKEditor from "@ckeditor/ckeditor5-react";
//import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DeleteIcon from "@material-ui/icons/Delete";
//import LensIcon from "@material-ui/icons/Lens";
//import AddIcon from "@material-ui/icons/Add";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
//import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import DescriptionIcon from "@material-ui/icons/Description";
//import queryString from "query-string";
import {
  makeStyles,
  //AppBar,
  //Tab,
  //Tabs,
  //Table,
  //TableBody,
  //TableCell,
  //TableContainer,
  //TableHead,
  //TablePagination,
  //TableRow,
  //TableSortLabel,
  Typography,
  Paper,
  FormControlLabel,
  Checkbox,
  //IconButton,
  //Tooltip,
  //ButtonGroup,
  Button,
  FormControl,
  //InputLabel,
  //OutlinedInput,
  //InputAdornment,
  TextField,
  //FormHelperText,
  Backdrop,
  //Dialog,
  //DialogTitle,
  //DialogContent,
  //DialogContentText,
  Box,
  //DialogActions,
  Grid,
  //List,
  //ListItem,
  //ListItemIcon,
  //ListItemText,
  //Backdrop,
} from "@material-ui/core";

registerPlugin(FilePondPluginFileValidateSize);
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginImageResize);
registerPlugin(FilePondPluginImageTransform);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  formRoot: {
    "& > *": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      //paddingLeft: theme.spacing(1),
      //paddingRight: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
    "& .ck-editor": {
      margin: theme.spacing(1),
    },
    "& .ck-editor__editable": {
      minHeight: "150px",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      //transform: "translate(22px, -6px) scale(0.75)",
    },
    "& .MuiInputBase-input, & .MuiInputLabel-outlined": {
      //fontSize: "14px",
    },
  },

  formControl: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
  },

  formControl2: {
    margin: theme.spacing(1),
    width: "-webkit-fill-available",
    display: "inline-flex",
    "& label": {
      marginBottom: "0px",
      marginRight: "0px",
    },
  },

  textField: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
  },

  marginDiv: {
    margin: theme.spacing(1),
    display: "inline-block",
    //width: "-webkit-fill-available",
  },
  option: {
    fontSize: 14,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    //color: "#fff",
    //position: "absolute",
  },

  buttonContainer: {
    position: "fixed",
    bottom: "0",
    left: "0",
    backgroundColor: "white",
    width: "calc(100% - 240px)",
    marginLeft: "240px",
    marginTop: "0",
    marginBottom: "0",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },

  datePicker: {
    margin: theme.spacing(1),
    display: "inline-block",
    width: `calc(50% - ${theme.spacing(2)}px)`,
    "& .react-datepicker-wrapper, & input": {
      width: "100%",
    },
  },

  box_criteria: {
    display: "flex",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },

  title_criteria: {
    flex: "1 1 100%",
  },

  button_criteria: {
    margin: theme.spacing(1),
  },

  box_field: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
  },

  textField_field: {
    //width: "-webkit-fill-available",
    flex: "1 1 100%",
  },

  buttonGroup_field: {
    marginLeft: theme.spacing(1),
    maxHeight: "42.38px",
  },

  filePond_field: {
    marginTop: theme.spacing(1),
  },
}));

const useVModeStyles = makeStyles((theme) => ({
  boxEditMode: {
    backgroundColor: "white",
    padding: theme.spacing(3),
  },
  hr: {
    borderColor: "rgba(0,0,0,.1)",
  },
  buttonStyle: {
    //margin: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  main_title: { marginBottom: theme.spacing(2) },
  viewTitle: {},
  viewContents: {},
  list: {
    "& .MuiListItemIcon-root": {
      minWidth: "20px",
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      width: "10px",
    },
  },
}));

const ViewMode = (props) => {
  const classes = useVModeStyles();
  const { user, changeViewMode } = props;
  const _img =
    user && user.photo !== null && user.photo !== ""
      ? `${env.resourceBase}/media/uploadimages/${user.photo}`
      : "/assets/no-image.png";

  return (
    <Box className={classes.boxEditMode}>
      <Grid container spacing={3} alignItems={"center"}>
        <Grid item style={{ flexGrow: "1" }}>
          <Typography>
            <DescriptionIcon /> Instructor
          </Typography>
        </Grid>
        <Grid item style={{ textAlign: "right" }}>
          <Box>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonStyle}
              size="small"
              startIcon={<AttachFileIcon />}
              onClick={changeViewMode}
            >
              Edit
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonStyle}
              size="small"
              startIcon={<ArrowBackIcon />}
              component={Link}
              to={`/dashboard/instructors`}
            >
              Back
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Studio:
          </Typography>
          <Typography className={classes.viewContents}>
            {user.studioName}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            First Name:
          </Typography>
          <Typography className={classes.viewContents}>
            {user.firstName}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Last Name:
          </Typography>
          <Typography className={classes.viewContents}>
            {user.lastName}
          </Typography>
        </Grid>
      </Grid>

      <hr className={classes.hr} />

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Email:
          </Typography>
          <Typography className={classes.viewContents}>{user.email}</Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Profile Picture:
          </Typography>
          <div className={classes.viewContents} style={{ maxWidth: "100px" }}>
            <img src={_img} width="100%" alt="logo" />
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

const schema = yup.object().shape({
  changeEmail: yup.boolean(),
  studioId: yup.string().required("Studio is required."),
  firstName: yup.string().required("First Name is required."),
  lastName: yup.string().required("Last Name is required."),
  email: yup.string().required("Email is required.").email("Email is required"),
  password: yup.string().when("changeEmail", {
    is: true,
    then: (sch) => sch.required("Password is required."),
  }),
  confirmPassword: yup.string().when("changeEmail", {
    is: true,
    then: (sch) => sch.required("Confirm Password is required."),
  }),

  photo: yup.string().nullable(true),
});

export function UserInstructor() {
  const { userId } = useParams();
  const classes = useStyles();
  const previousController = React.useRef();
  //const timerController = React.useRef();
  const [serverErrors, setServerErrors] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [backdropOpen] = React.useState(false); //setBackdropOpen
  const filePondRef = React.useRef(null);
  const [autoValue, setAutoValue] = React.useState({
    studio: null,
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    trigger,
    //control,
    //reset,
    watch,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { changeEmail: true },
  });

  const { photo, changeEmail } = getValues();

  const [getApiResult, setGetApiResult] = React.useState();
  const history = useHistory();

  const inputAutoChange = (event, id, id2, newValue, newValue2) => {
    setValue(id, newValue);
    trigger(id);
    setAutoValue({
      ...autoValue,
      [id2]: newValue2,
    });
  };

  const submit = async (dataModel) => {
    //setServerErrors([]);
    //setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(
        env.apiBase + "/api/usermanage/saveuserinstructor",
        {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            ...dataModel,
            userId: userId,
          }),
          ...options,
        }
      );

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        //setBackdropOpen(false);
        setServerErrors(data.errors);
      } else {
        history.push(`/dashboard/instructors`);
      }
    } catch (error) {
      console.error(error.message);
      serverErrors.push("error : " + error.message);
      setServerErrors([...serverErrors]);
      //setBackdropOpen(false);
    }
  };

  const changeViewMode = () => {
    getApiResult.editMode = false;
    setGetApiResult({ ...getApiResult });
  };

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(
          env.apiBase + "/api/usermanage/getuserinstructor",
          {
            method: "post",
            signal: signal,
            body: JSON.stringify({
              userId: userId,
            }),
            ...options,
          }
        );

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
          setLoading(false);
          return;
        }

        if (data.results) {
          const _result = { ...data.results };

          let editMode = false;
          let newBatch = true;

          const _studios = _result.studios;
          const _user = _result.user;

          if (_user) {
            editMode = true;
            newBatch = false;

            reset({
              changeEmail: false,
              studioId: _user.studioId,
              firstName: _user.firstName,
              lastName: _user.lastName,
              email: _user.email,
              photo: _user.photo,
            });

            autoValue.studio = _studios.find(
              (x) => x.studioId === _user.studioId
            );

            setAutoValue({ ...autoValue });
          }

          setGetApiResult({
            ..._result,
            editMode,
            newBatch,
          });
        }
      } catch (error) {
        console.error(error.message);
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }
      setLoading(false);
    };

    fetchData();

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "changeEmail" || name === "changeEmail") {
        trigger(name);
      }
    });
    return () => subscription.unsubscribe();

    // eslint-disable-next-line
  }, [watch]);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }
  return (
    <div className={classes.root}>
      <Backdrop open={backdropOpen} className={classes.backdrop}>
        <img src="/assets/loader.36c5450235c205d74679.gif" alt="loader" />
      </Backdrop>
      <Paper className={classes.paper}>
        {getApiResult && getApiResult.editMode === true ? (
          <ViewMode {...getApiResult} changeViewMode={changeViewMode} />
        ) : (
          <>
            <Typography className={classes.marginDiv}>
              <DateRangeIcon /> Instructor
            </Typography>

            <form
              method="POST"
              noValidate=""
              className={classes.formRoot}
              onSubmit={handleSubmit(submit)}
            >
              {getApiResult && getApiResult.newBatch === false && (
                <FormControl
                  error={!!errors.changeEmail}
                  component="fieldset"
                  className={classes.formControl2}
                  size="small"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        {...register("changeEmail")}
                        checked={changeEmail}
                      />
                    }
                    className={`${
                      !!errors.changeEmail ? "Mui-error" : "Mui-error-non"
                    }`}
                    label="Change Email"
                  />
                </FormControl>
              )}

              <Autocomplete
                id="studioId"
                options={getApiResult.studios}
                className={classes.formControl}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => option.studioName}
                getOptionSelected={(option, value) =>
                  option.studioId === value.studioId
                }
                value={autoValue.studio || null}
                onChange={(event, newValue) => {
                  inputAutoChange(
                    event,
                    "studioId",
                    "studio",
                    newValue ? newValue.studioId : null,
                    newValue
                  );
                }}
                renderOption={(option) => (
                  <React.Fragment>{option.studioName}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Studio*"
                    variant="outlined"
                    size="small"
                    error={!!errors.studioId}
                    helperText={errors?.studioId?.message}
                    inputProps={{
                      ...params.inputProps,
                      //autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />

              <TextField
                label="First Name*"
                variant="outlined"
                error={!!errors.firstName}
                helperText={errors?.firstName?.message}
                size="small"
                className={classes.textField}
                {...register("firstName")}
              />

              <TextField
                label="Last Name*"
                variant="outlined"
                error={!!errors.lastName}
                helperText={errors?.lastName?.message}
                size="small"
                className={classes.textField}
                {...register("lastName")}
              />

              <TextField
                label="Email*"
                variant="outlined"
                error={!!errors.email}
                helperText={errors?.email?.message}
                size="small"
                className={classes.textField}
                disabled={!changeEmail}
                {...register("email")}
              />

              {changeEmail && (
                <TextField
                  label="Password*"
                  variant="outlined"
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                  size="small"
                  className={classes.textField}
                  type="password"
                  disabled={!changeEmail}
                  {...register("password")}
                />
              )}

              {changeEmail && (
                <TextField
                  label="Confirm Password"
                  variant="outlined"
                  error={!!errors.confirmPassword}
                  helperText={errors?.confirmPassword?.message}
                  size="small"
                  className={classes.textField}
                  type="password"
                  disabled={!changeEmail}
                  {...register("confirmPassword")}
                />
              )}

              <Box className={classes.marginDiv}>
                <Typography
                  className={classes.marginDiv}
                  style={{ marginBottom: "8px" }}
                >
                  Profile Picture
                </Typography>
                <Grid
                  container
                  spacing={3}
                  alignItems="center"
                  className={classes.gridContainer}
                >
                  <Grid item xs={3}>
                    <FilePond
                      allowMultiple={false}
                      maxFiles={1}
                      name="photo"
                      maxFileSize={"10MB"}
                      allowDrop={false}
                      allowReorder={true}
                      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                      acceptedFileTypes={["image/*"]}
                      ref={filePondRef}
                      server={{
                        process: {
                          url: env.apiBase + "/api/file/uploadimage",
                          onload: (res) => {
                            setValue("photo", res);
                            trigger("photo");
                          },
                        },
                        fetch: null,
                        revert: null,
                      }}
                      onremovefile={() => {
                        setValue("photo", "");
                        trigger("photo");
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <div style={{ maxWidth: "100px", position: "relative" }}>
                      <img
                        src={`${
                          photo && photo !== ""
                            ? `${env.resourceBase}/media/uploadimages/${photo}`
                            : "/assets/no-image.png"
                        }`}
                        width="100%"
                        alt="logo"
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: "0px",
                          right: "0px",
                          zIndex: "3",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          filePondRef.current.removeFiles();
                          setValue("photo", "");
                          trigger("photo");
                        }}
                      >
                        <DeleteIcon />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Box>

              <div
                className={classes.buttonContainer}
                style={{ textAlign: "center" }}
              >
                <Button
                  variant="contained"
                  component={Link}
                  size="small"
                  to={`/dashboard/instructors`}
                  disabled={isSubmitting}
                  //color="primary"
                  //className={classes.button}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginLeft: "1rem" }}
                  //className={classes.button}
                  startIcon={
                    isSubmitting && (
                      <span
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )
                  }
                  type="submit"
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </div>
            </form>
          </>
        )}
      </Paper>
    </div>
  );
}
