import React from "react";
import env from "../../../env";
//import { variables } from "../../../cssInJs";
import { StudentAppBar } from "./studentAppBar";
import { ErrorMessage, Loading } from "components";
import clsx from "clsx";
//import { useTheme } from "@material-ui/core/styles";
//import utils from "utils";
import { StudentView } from "./studentView";
import { StudentBottom } from "./studentBottom";
import AddIcon from "@material-ui/icons/Add";
import FilterListIcon from "@material-ui/icons/FilterList";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteIcon from "@material-ui/icons/Delete";

import {
  Link,
  //useHistory,
  //useLocation,
  useParams,
} from "react-router-dom";
import {
  Toolbar,
  lighten,
  makeStyles,
  //Table,
  //TableBody,
  //TableCell,
  //TableContainer,
  //TableHead,
  //TablePagination,
  //TableRow,
  //TableSortLabel,
  Typography,
  Paper,
  //Checkbox,
  //IconButton,
  Tooltip,
  ButtonGroup,
  Button,
  //FormControl,
  //InputLabel,
  //OutlinedInput,
  //InputAdornment,
  //TextField,
  //Backdrop,
  //Dialog,
  //DialogTitle,
  //DialogContent,
  //DialogContentText,
  Box,
  //DialogActions,
  Grid,
  //Radio,
  //List,
  //ListItem,
  //ListItemIcon,
  //ListItemText,
  //Backdrop,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    backgroundColor: "transparent",
    //marginBottom: theme.spacing(2),
  },
  gridCon: {
    padding: theme.spacing(3),
    backgroundColor: "white",
    marginTop: theme.spacing(1),
  },
  hr: {
    marginBottom: "0",
    marginTop: theme.spacing(1),
    borderColor: "rgba(0,0,0,.1)",
  },
  typoBox2: {
    marginTop: theme.spacing(4),
  },
  typoTitle2: {
    //color: variables.colorGray1,
    //marginTop: theme.spacing(1),
    fontWeight: 700,
  },
  typoContent2: {},
  list: {
    "& .MuiListItem-gutters": {
      paddingLeft: "0px",
      paddingRight: "0px",
      alignItems: "flex-start",
    },
    "& .MuiListItemIcon-root": {
      marginTop: "5px",
    },
  },
  leftGrid: {
    overflowY: "scroll",
    height: "calc(100vh - 195px)",
    zIndex: 2,
  },
  rightGrid: {
    height: "calc(100vh - 280px)",
    overflowY: "auto",
  },
  rightGridBox: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    position: "absolute",
  },
  bottom: {
    position: "fixed",
    bottom: "0",
    left: "0",
    backgroundColor: "red",
    width: "62%",
    //width: "calc(100% - 240px)",
    //marginLeft: "240px",
    marginTop: "0",
    marginBottom: "0",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const StudentViewMemo = React.memo((props) => {
  return <StudentView {...props} />;
});

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    studentId,
    //handleFilter,
    //currentTab,
    //activeStatus,
    //inActiveStatus,
    //deleteItems
    //studioId,
  } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          //color="inherit"
          //variant="subtitle1"
          component="div"
          style={{ fontSize: "18px" }}
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          //variant="h6"
          id="tableTitle"
          component="div"
          style={{ fontSize: "18px" }}
        >
          Evaluations
        </Typography>
      )}

      <ButtonGroup size="small" aria-label="small outlined button group">
        <Tooltip title="Create" arrow>
          <Button
            component={Link}
            to={`/dashboard/evaluation/${studentId}/new`}
          >
            <AddIcon fontSize="small" />
          </Button>
        </Tooltip>

        {/* <Tooltip title="Filters" arrow>
          <Button onClick={handleFilter}>
            <FilterListIcon fontSize="small" />
          </Button>
        </Tooltip> */}

        {/* <Tooltip title="Active" arrow>
          <Button onClick={activeStatus}>
            <CheckCircleOutlineIcon fontSize="small" />
          </Button>
        </Tooltip> */}

        {/* <Tooltip title="In Active" arrow>
          <Button onClick={inActiveStatus}>
            <RemoveCircleIcon fontSize="small" />
          </Button>
        </Tooltip> */}

        {/* <Tooltip title="Delete" arrow>
          <Button onClick={deleteItems}>
            <DeleteIcon fontSize="small" />
          </Button>
        </Tooltip> */}
      </ButtonGroup>
    </Toolbar>
  );
};

const EvaluationListView = (props) => {
  const { studentId } = props;
  const [selected, setSelected] = React.useState([]);

  return (
    <>
      <EnhancedTableToolbar
        numSelected={selected.length}
        studentId={studentId}
        //handleFilter={handleFilter}
        //currentTab={currentTab}
        //activeStatus={activeStatus}
        //inActiveStatus={inActiveStatus}
        //deleteItems={deleteItems}
      />
    </>
  );
};

export function StudentEvaluations(props) {
  const { userInfo, inputType } = props;
  const inputTypeItem = inputType || "normal";
  const classes = useStyles();
  const { studioId, studentId } = useParams();
  //const theme = useTheme();
  const [loading, setLoading] = React.useState(true);
  const [serverErrors, setServerErrors] = React.useState([]);
  const [getstudentDetailRe, setGetstudentDetailRe] = React.useState();
  const previousController = React.useRef(null);

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(env.apiBase + "/api/student/getstudentdetail", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            //setupAwardId: setupAwardId,
            studentId: studentId,
          }),
          ...options,
        });

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
        }

        if (data.results && data.results.getstudentDetailRe) {
          setGetstudentDetailRe({
            ...data.results.getstudentDetailRe,
          });
        }
      } catch (error) {
        console.error(error.message);
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }
      setLoading(false);
    };

    fetchData();

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <StudentAppBar
          tab={"evaluations"}
          studentId={studentId}
          inputTypeItem={inputTypeItem}
          studioId={studioId}
        />
        <div className={classes.gridCon}>
          <Grid container spacing={3}>
            <Grid item xs={3} className={classes.leftGrid}>
              <StudentViewMemo {...getstudentDetailRe} />
            </Grid>

            <Grid item xs={9} className={classes.rightGrid}>
              <Box className={classes.rightGridBox}>
                <EvaluationListView studentId={studentId} />
              </Box>
            </Grid>
          </Grid>
        </div>
        <StudentBottom
          {...getstudentDetailRe}
          inputTypeItem={inputTypeItem}
          styleTye="list"
        />
      </Paper>
    </div>
  );
}
