import React from "react";
import { variables } from "../../../cssInJs";
import { useStore } from "../../../store";
import { ErrorMessage, Loading, SnackbarMessageV3 } from "components";
import env from "../../../env";
import { useHistory, Link, useParams } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import DeleteIcon from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  makeStyles,
  //AppBar,
  //Tab,
  //Tabs,
  //Table,
  //TableBody,
  //TableCell,
  //TableContainer,
  //TableHead,
  //TablePagination,
  //TableRow,
  //TableSortLabel,
  Typography,
  Paper,
  FormControlLabel,
  Checkbox,
  //IconButton,
  //Tooltip,
  //ButtonGroup,
  Button,
  FormControl,
  //InputLabel,
  //OutlinedInput,
  //InputAdornment,
  TextField,
  //FormHelperText,
  Backdrop,
  //Dialog,
  //DialogTitle,
  //DialogContent,
  //DialogContentText,
  Box,
  //DialogActions,
  //Grid,
  //List,
  //ListItem,
  //ListItemIcon,
  //ListItemText,
  //Backdrop,
  useTheme,
} from "@material-ui/core";

registerPlugin(FilePondPluginFileValidateSize);
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginImageResize);
registerPlugin(FilePondPluginImageTransform);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingBottom: theme.spacing(5),
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    backgroundColor: "transparent",
  },
  formRoot: {
    "& > *": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      //paddingLeft: theme.spacing(1),
      //paddingRight: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
    "& .ck-editor": {
      margin: theme.spacing(1),
    },
    "& .ck-editor__editable": {
      minHeight: "150px",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      //transform: "translate(22px, -6px) scale(0.75)",
    },
    "& .MuiInputBase-input, & .MuiInputLabel-outlined": {
      //fontSize: "14px",
    },
  },
  box: {
    backgroundColor: "white",
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
  },
  filePondCon: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
  },
  filePond_field: {
    marginTop: theme.spacing(1),
    zIndex: 2,
    height: "200px",
    "& .filepond--drop-label": {
      height: "200px",
    },
    "& .filepond--panel-root": {
      backgroundColor: "transparent !important",
    },
    "& ul.filepond--list.hide": {
      display: "none",
    },
  },
  title: {
    //marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
  },
  textField: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
  },

  formControl2: {
    margin: theme.spacing(1),
    width: "-webkit-fill-available",
    display: "inline-flex",
    "& label": {
      marginBottom: "0px",
      marginRight: "0px",
    },
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 500,
    color: "#fff",
    //position: "absolute",
  },
  buttonContainer: {
    position: "fixed",
    bottom: "0",
    left: "0",
    backgroundColor: "white",
    width: "calc(100% - 240px)",
    marginLeft: "240px",
    marginTop: "0",
    marginBottom: "0",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    zIndex: "1",
  },
}));

const schema = yup.object().shape({
  studioIds: yup.string().required("Studio is required."),
  firstName: yup.string().required("First Name is required."),
  lastName: yup.string().required("Last Name is required."),
  loginEmail: yup
    .string()
    .required("Email is required.")
    .email("Email is required"),
  profilePic: yup.string().nullable(true),
  changeEmail: yup.boolean(),
  password: yup
    .string()
    .when("changeEmail", {
      is: true,
      then: (sch) => sch.required("Password is required."),
    })
    .when("changeEmail", {
      is: false,
      then: (sch) => sch.nullable(true),
    }),
  confirmPassword: yup
    .string()
    .when("changeEmail", {
      is: true,
      then: (sch) =>
        sch.oneOf([yup.ref("password"), null], "Passwords must match"),
    })
    .when("changeEmail", {
      is: false,
      then: (sch) => sch.nullable(true),
    }),
  address1: yup.string().required("Address 1 is required."),
  address2: yup.string(),
  country: yup.string().required("Country is required."),
  state: yup.string().required("State is required."),
  city: yup.string().required("City is required."),
  postalCode: yup.string().required("Postal Code is required."),
  mobileNumber: yup.string().required("Mobile Number is required."),
});

export function UserStudioManager(props) {
  const { userInfo } = props;
  const classes = useStyles();
  const { dispatch } = useStore();
  const { studioManagerId } = useParams();
  const filePondRef = React.useRef(null);
  const theme = useTheme();
  const [getApiResult, setGetApiResult] = React.useState();
  const [loading, setLoading] = React.useState(1);
  const [errorResult, setErrorResult] = React.useState({
    type: "server",
    errors: [],
  });
  //   const [funtionInfo, setFuntionInfo] = React.useState({
  //     trigger : 0
  //   });
  const previousController = React.useRef();
  const timerController = React.useRef();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    trigger,
    //control,
    watch,
    //reset,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      changeEmail: true,
    },
  });

  const { studioIds, profilePic, country, state, city, changeEmail } =
    getValues();
    
  const handleCloseSnackBar = () => {
    setErrorResult({
      ...errorResult,
      type: "",
      errors: [],
    });
  };

  const apiCall = async (
    url,
    apiData,
    returnFunction = null
    //retrunErrorFunction = null
  ) => {
    setLoading(2);

    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + url, {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          ...apiData,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setErrorResult({
          type: "snackbar",
          errors: data.errors,
        });
        setLoading(0);
        return;
      }

      if (returnFunction) {
        returnFunction(data.results);
      } else {
        //setApiTrigger(apiTrigger + 1);
      }
    } catch (error) {
      const _errors = [];
      _errors.push("error : " + error.message);
      setErrorResult({
        type: "server",
        errors: _errors,
      });
      setLoading(0);
      return;
    }
  };

  const getStatesFun = async (code) => {
    await apiCall(
      "/api/studio/getstatelist",
      {
        code: code,
      },
      (result) => {
        if (timerController.current) {
          clearTimeout(timerController.current);
        }
        timerController.current = setTimeout(() => {
          setLoading(0);
        }, 800);
        setValue("state", null);
        setValue("city", null);
        setGetApiResult({
          ...getApiResult,
          ...result,
        });
      }
    );
  };

  const getCitiesFun = async (code) => {
    await apiCall(
      "/api/studio/getcitylist",
      {
        code: code,
      },
      (result) => {
        if (timerController.current) {
          clearTimeout(timerController.current);
        }
        timerController.current = setTimeout(() => {
          setLoading(0);
        }, 800);
        setValue("city", null);
        setGetApiResult({
          ...getApiResult,
          ...result,
        });
      }
    );
  };

  const submit = async (dataModel) => {
    await apiCall(
      "/api/usermanage/saveuserstudiomanager",
      {
        ...dataModel,
        userInfo: userInfo,
        studioManagerId: studioManagerId,
      },
      (result) => {
        // timerController.current = setTimeout(() => {
        //   setLoading(0);
        // }, 800);
        history.push(`/dashboard/studio-managers`);
      }
    );
  };

  React.useEffect(() => {
    dispatch({
      type: "SET_STATE",
      payload: {
        breadcrumbs: [
          { title: "Dashboard", link: "/dashboard" },
          { title: "Studio Managers", link: "/dashboard/studio-managers" },
          { title: "Create", link: null },
        ],
      },
    });

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    const fetchData = async () => {
      try {
        //const token = await getIdToken();
        const options = { headers: { "Content-Type": "application/json" } };
        // if (token) {
        //   //options.headers["Authorization"] = `Bearer ${token}`;
        // }
        const res = await fetch(
          env.apiBase + "/api/usermanage/getuserstudiomanager",
          {
            method: "post",
            signal: signal,
            body: JSON.stringify({
              //tshirtId: tshirtId,
              //appManagementId: appManagementId,
              studioManagerId: studioManagerId,
              userInfo: userInfo,
            }),
            ...options,
          }
        );

        if (!res.ok) {
          if (res.status === 401) {
            history.push(`/login`);
            return;
          } else {
            throw new Error(`Response status: ${res.status}`);
          }
        }

        const data = await res.json();
        console.log("data ----- ", data);

        if (data.errors && data.errors.length > 0) {
          setErrorResult({
            type: "server",
            errors: data.errors,
          });
          setLoading(0);
          return;
        }

        if (data.results) {
          //   const _result = { ...data.results };

          //   const _studios = _result.studios;
          //   const _appManage = _result.appManage;
          //   const _programs = _result.programs || [];

          //   if (_appManage) {
          //     editMode = true;
          //     newBatch = false;
          //   }
          const { studioManager } = data.results;
          let editMode = false;
          let newBatch = true;

          if (studioManager) {
            editMode = true;
            newBatch = false;
            reset({
              studioIds: studioManager.studioIds,
              firstName: studioManager.firstName,
              lastName: studioManager.lastName,
              loginEmail: studioManager.loginEmail,
              profilePic: studioManager.profilePic,
              changeEmail: false,
              password: null,
              confirmPassword: null,
              address1: studioManager.address1,
              address2: studioManager.address2,
              country: studioManager.country,
              state: studioManager.state,
              city: studioManager.city,
              postalCode: studioManager.postalCode,
              mobileNumber: studioManager.mobileNumber,
            });
          }

          setGetApiResult({
            ...data.results,
            editMode,
            newBatch,
          });
        }
      } catch (error) {
        const _errors = [];
        _errors.push("error : " + error.message);
        setErrorResult({
          type: "server",
          errors: _errors,
        });
        setLoading(0);
        return;
      }
      setLoading(0);
    };

    fetchData();

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "changeEmail") {
        trigger(name);
      }
    });
    return () => subscription.unsubscribe();

    // eslint-disable-next-line
  }, [watch]);

  if (loading === 1) {
    return <Loading />;
  } else if (errorResult.type === "server" && errorResult.errors.length > 0) {
    return <ErrorMessage errors={errorResult.errors} />;
  }
  return (
    <div className={classes.root}>
      
      <Backdrop className={classes.backdrop} open={loading === 2}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <SnackbarMessageV3
        {...errorResult}
        handleCloseFunction={handleCloseSnackBar}
        severity="error"
      />

      <Paper className={classes.paper} elevation={0}>
        <form
          method="POST"
          noValidate=""
          className={classes.formRoot}
          onSubmit={handleSubmit(submit)}
        >
          <Box className={classes.box}>
            <Typography className={classes.title}>Basic Information</Typography>

            <Autocomplete
              id="studioIds"
              multiple
              options={getApiResult.studios || []}
              className={classes.formControl}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => option.studioName}
              getOptionSelected={(option, value) =>
                option.studioId === value.studioId
              }
              value={
                studioIds != null
                  ? (getApiResult.studios || []).filter((x) =>
                      studioIds.toLowerCase().includes(x.studioId.toLowerCase())
                    )
                  : []
              }
              onChange={(event, newValue) => {
                setValue(
                  "studioIds",
                  newValue ? newValue.map((x) => x.studioId).toString() : null
                );
                trigger("studioIds");
              }}
              renderOption={(option) => (
                <React.Fragment>{option.studioName}</React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Studio*"
                  variant="outlined"
                  size="small"
                  error={!!errors.studioIds}
                  helperText={errors?.studioIds?.message}
                  inputProps={{
                    ...params.inputProps,
                    //autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />

            <TextField
              //id="outlined-basic"
              label="First Name*"
              variant="outlined"
              error={!!errors.firstName}
              helperText={errors?.firstName?.message}
              size="small"
              className={classes.textField}
              {...register("firstName")}
            />
            <TextField
              //id="outlined-basic"
              label="Last Name*"
              variant="outlined"
              error={!!errors.lastName}
              helperText={errors?.lastName?.message}
              size="small"
              className={classes.textField}
              {...register("lastName")}
            />

            {getApiResult.newBatch === false && (
              <FormControl
                error={!!errors.changeEmail}
                component="fieldset"
                className={classes.formControl2}
                size="small"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={changeEmail}
                      {...register("changeEmail")}
                    />
                  }
                  className={`${
                    !!errors.changeEmail ? "Mui-error" : "Mui-error-non"
                  }`}
                  label="Change Student Email"
                />
              </FormControl>
            )}

            <TextField
              //id="outlined-basic"
              label="Email*"
              variant="outlined"
              error={!!errors.loginEmail}
              helperText={errors?.loginEmail?.message}
              size="small"
              className={classes.textField}
              disabled={!changeEmail}
              {...register("loginEmail")}
            />

            {changeEmail && (
              <TextField
                //id="outlined-basic"
                label="Password*"
                variant="outlined"
                error={!!errors.password}
                helperText={errors?.password?.message}
                size="small"
                className={classes.textField}
                type="password"
                {...register("password")}
              />
            )}

            {changeEmail && (
              <TextField
                //id="outlined-basic"
                label="Confirm Password*"
                variant="outlined"
                error={!!errors.confirmPassword}
                helperText={errors?.confirmPassword?.message}
                size="small"
                className={classes.textField}
                type="password"
                {...register("confirmPassword")}
              />
            )}

            <Typography
              variant="caption"
              component="div"
              style={{
                marginLeft: theme.spacing(1),
                marginTop: theme.spacing(1),
                marginBottom: "-5px",
              }}
            >
              {" "}
              Select Profile Pic{" "}
            </Typography>
            <div
              className={classes.filePondCon}
              style={{ position: "relative", backgroundColor: "#f1f0ef" }}
            >
              <FilePond
                className={classes.filePond_field}
                allowMultiple={false}
                maxFiles={1}
                name="signatureImage"
                maxFileSize={"10MB"}
                allowDrop={false}
                allowReorder={true}
                //labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                labelIdle=""
                acceptedFileTypes={["image/*"]}
                ref={filePondRef}
                server={{
                  process: {
                    url: env.apiBase + "/api/file/uploadimage",
                    onload: (res) => {
                      setValue("profilePic", res);
                      trigger("profilePic");
                      setTimeout(() => {
                        const f_list =
                          document.querySelector("ul.filepond--list");
                        if (f_list) {
                          f_list.classList.add("hide");
                        }
                      }, 1500);
                    },
                  },
                  fetch: null,
                  revert: null,
                }}
                onremovefile={() => {
                  //setValue(`criteria.${index}.attachment`, "");
                  //trigger(`criteria.${index}.attachment`);
                }}
                oninitfile={() => {
                  const f_list = document.querySelector("ul.filepond--list");
                  if (f_list) {
                    f_list.classList.remove("hide");
                  }
                }}
              />
              <div
                style={{
                  position: "absolute",
                  //maxWidth: "100px",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  textAlign: "center",
                }}
              >
                <img
                  src={
                    profilePic && profilePic !== ""
                      ? `${env.resourceBase}/media/uploadimages/${profilePic}`
                      : "/assets/no-image.png"
                  }
                  width="100%"
                  style={{ maxWidth: "100px" }}
                  alt="test"
                />
                <Typography variant="caption" style={{ display: "block" }}>
                  Drop file here or click to upload
                </Typography>
              </div>
              <div
                style={{
                  position: "absolute",
                  //maxWidth: "50px",
                  top: "0px",
                  right: "0px",
                  zIndex: "3",
                  cursor: "pointer",
                  //left: "50%",
                  //transform: "translate(-50%, -50%)",
                }}
                onClick={() => {
                  filePondRef.current.removeFiles();
                  setValue("profilePic", null);
                  trigger("profilePic");
                }}
              >
                <DeleteIcon />
              </div>
            </div>
          </Box>
          <Box className={classes.box}>
            <Typography className={classes.title}>
              Address Information
            </Typography>
            <TextField
              //id="outlined-basic"
              label="Address 1*"
              variant="outlined"
              error={!!errors.address1}
              helperText={errors?.address1?.message}
              size="small"
              className={classes.textField}
              {...register("address1")}
            />
            <TextField
              //id="outlined-basic"
              label="Address 2*"
              variant="outlined"
              error={!!errors.address2}
              helperText={errors?.address2?.message}
              size="small"
              className={classes.textField}
              {...register("address2")}
            />
            
            <Autocomplete
              id="country"
              //disabled={!checkCreateBatch}
              options={variables.countries}
              className={classes.formControl}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => option.label}
              getOptionSelected={(option, value) =>
                option.label === value.label
              }
              value={
                variables.countries.find((x) => x.label === country) || null
              }
              onChange={(event, newValue) => {
                setValue("country", newValue ? newValue.label : null);
                trigger("country");

                if (newValue) {
                  getStatesFun(newValue.code);
                }
              }}
              renderOption={(option) => (
                <React.Fragment>{option.label}</React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Country*"
                  variant="outlined"
                  size="small"
                  error={!!errors.country}
                  helperText={errors?.country?.message}
                  inputProps={{
                    ...params.inputProps,
                    //autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />

            <Autocomplete
              id="state"
              //disabled={!checkCreateBatch}
              options={getApiResult.stateList || []}
              className={classes.formControl}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.iso2 === value.iso2}
              value={
                (getApiResult.stateList || []).find((x) => x.iso2 === state) ||
                null
              }
              onChange={(event, newValue) => {
                //inputChangeState(event, newValue);
                setValue("state", newValue ? newValue.iso2 : null);
                trigger("state");
                if (newValue) {
                  getCitiesFun(newValue.iso2);
                }
              }}
              renderOption={(option) => (
                <React.Fragment>{option.name}</React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="State*"
                  variant="outlined"
                  size="small"
                  error={!!errors.state}
                  helperText={errors?.state?.message}
                  inputProps={{
                    ...params.inputProps,
                    //autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />

            <Autocomplete
              id="city"
              //disabled={!checkCreateBatch}
              options={getApiResult.cityList || []}
              className={classes.formControl}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => option.suburb}
              getOptionSelected={(option, value) =>
                option.suburb === value.suburb
              }
              value={
                (getApiResult.cityList || []).find((x) => x.suburb === city) ||
                null
              }
              onChange={(event, newValue) => {
                setValue("city", newValue ? newValue.suburb : null);
                trigger("city");
              }}
              renderOption={(option) => (
                <React.Fragment>{option.suburb}</React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="City*"
                  variant="outlined"
                  size="small"
                  error={!!errors.city}
                  helperText={errors?.city?.message}
                  inputProps={{
                    ...params.inputProps,
                    //autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />

            <TextField
              //id="outlined-basic"
              label="Postal Code*"
              variant="outlined"
              error={!!errors.postalCode}
              helperText={errors?.postalCode?.message}
              size="small"
              className={classes.textField}
              {...register("postalCode")}
            />
          </Box>

          <Box className={classes.box}>
            <Typography className={classes.title}>
              Mobile Number Information
            </Typography>
            <TextField
              //id="outlined-basic"
              label="Mobile Number*"
              variant="outlined"
              error={!!errors.mobileNumber}
              helperText={errors?.mobileNumber?.message}
              size="small"
              className={classes.textField}
              {...register("mobileNumber")}
            />
          </Box>

          <div
            className={classes.buttonContainer}
            style={{ textAlign: "center" }}
          >
            <Button
              variant="contained"
              component={Link}
              size="small"
              to={`/dashboard/studio-managers`}
              disabled={isSubmitting}
              //color="primary"
              //className={classes.button}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: "1rem" }}
              //className={classes.button}
              startIcon={
                isSubmitting && (
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )
              }
              type="submit"
              disabled={isSubmitting}
            >
              Submit
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  );
}
