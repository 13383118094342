import React from "react";
//import { useForm } from "react-hook-form";
//import { variables } from "../../../cssInJs";
import { useParams, Link, useLocation, useHistory } from "react-router-dom";
import { Loading, ErrorMessage } from "components";
import env from "../../../env";
import DescriptionIcon from "@material-ui/icons/Description";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DeleteIcon from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloseIcon from "@material-ui/icons/Close";
import utils from "../../../utils";
import queryString from "query-string";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import { variables } from "../../../cssInJs";
import {
  AppBar,
  Tab,
  Tabs,
  useTheme,
  makeStyles,
  Typography,
  Paper,
  Button,
  TextField,
  Box,
  Grid,
  Backdrop,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
} from "@material-ui/core";

registerPlugin(FilePondPluginFileValidateSize);
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginImageResize);
registerPlugin(FilePondPluginImageTransform);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  textField: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
  },
  formControl: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
  },
  textFieldFull: {
    margin: theme.spacing(1),
    width: `-webkit-fill-available`,
  },
  marginDiv: {
    margin: theme.spacing(1),
    display: "inline-block",
    //width: "-webkit-fill-available",
  },
  buttonContainer: {
    position: "fixed",
    bottom: "0",
    left: "0",
    backgroundColor: "white",
    width: "calc(100% - 240px)",
    marginLeft: "240px",
    marginTop: "0",
    marginBottom: "0",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    //position: "absolute",
  },
}));

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const schema = yup.object().shape({
  beltName: yup.string().required("Belt Name is required."),
  beltType: yup.string().required("Belt Type is required."),
  position: yup.number().required("Position is required."),
  status: yup.number().required("status is required."),
  beltIcon: yup.string().nullable(true),
  certificateLabel: yup.string().required("CertificateLabel is required."),
  description: yup.string().nullable(true),
});

const useIconStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const IconsDialog = (props) => {
  const classes = useIconStyles();
  const { iconsDialogOpen, closeIconsDialog, selectIcon } = props;
  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      //onClose={handleClose}
      open={iconsDialogOpen}
      scroll="paper"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">Belt Icons</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={closeIconsDialog}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={3}>
          {variables.beltIcons.map((beltIcon, index) => {
            return (
              <Grid
                key={index}
                item
                xs={3}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  selectIcon(`/assets/belts/${beltIcon.url}`);
                }}
              >
                <img src={`/assets/belts/${beltIcon.url}`} width="100%" />
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export function BeltLevel() {
  const { studioId, beltLevelId } = useParams();
  const theme = useTheme();
  const location = useLocation();
  const history = useHistory();
  const { programIdParm } = queryString.parse(location.search);
  const [serverErrors, setServerErrors] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const previousController = React.useRef();
  const [getBeltlevelResult, setGetBeltlevelResult] = React.useState();
  const filePondRef = React.useRef(null);
  //const beltIcon = null;
  const [currentTab, setCurrentTab] = React.useState(() => {
    if (programIdParm) {
      return programIdParm;
    }
  });

  const [funtionInfo, setFuntionInfo] = React.useState({
    iconsDialogOpen: false,
  });

  const classes = useStyles();
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    trigger,
    //control,
    //reset,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { status, beltIcon } = getValues();

  const submit = async (dataModel) => {
    setLoading(2);
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/belt/savebeltlevel", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          ...dataModel,
          beltLevelId: beltLevelId,
          programId: programIdParm,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        //setBackdropOpen(false);
        setServerErrors(data.errors);
        setLoading(0);
        return;
      } else {
        history.push(
          `/dashboard/studio/${studioId}/beltLevels?programIdParm=${programIdParm}`
        );
        //window.location.reload();
      }
    } catch (error) {
      console.error(error.message);
      serverErrors.push("error : " + error.message);
      setServerErrors([...serverErrors]);
      setLoading(0);
      return;
    }
  };

  const changeViewMode = () => {
    getBeltlevelResult.editMode = false;
    setGetBeltlevelResult({ ...getBeltlevelResult });
  };

  const openIconsDialog = () => {
    setFuntionInfo({
      ...funtionInfo,
      iconsDialogOpen: true,
    });
  };

  const closeIconsDialog = () => {
    setFuntionInfo({
      ...funtionInfo,
      iconsDialogOpen: false,
    });
  };

  const selectIcon = (selectedIconUrl) => {
    filePondRef.current.removeFiles();
    closeIconsDialog();
    setTimeout(() => {
      setValue("beltIcon", selectedIconUrl);
      trigger("beltIcon");
    }, 100);
  };

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(env.apiBase + "/api/belt/getbeltlevel", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            studioId: studioId,
            beltLevelId: beltLevelId,
          }),
          ...options,
        });

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }
        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
          setLoading(false);
          return;
        }

        if (data.results && data.results.getBeltlevelResult) {
          const _beltLevel = data.results.getBeltlevelResult.beltLevel;
          let editMode = false;
          let checkNew = true;
          if (_beltLevel) {
            editMode = true;
            checkNew = false;

            reset({
              beltName: _beltLevel.beltName,
              beltType: _beltLevel.beltType,
              position: _beltLevel.position,
              status: _beltLevel.status,
              beltIcon: _beltLevel.beltIcon,
              certificateLabel: _beltLevel.certificateLabel,
              description: _beltLevel.description,
            });
          }

          setGetBeltlevelResult({
            editMode: editMode,
            checkNew: checkNew,
            ...data.results.getBeltlevelResult,
          });
          //setCurrentTab(data.results.getBeltlevelResult.programId);
        }
      } catch (error) {
        //console.error(error.message);
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
        setLoading(false);
        return;
      }

      setLoading(false);
    };

    fetchData();

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }
  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={loading === 2}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <IconsDialog
        {...funtionInfo}
        closeIconsDialog={closeIconsDialog}
        selectIcon={selectIcon}
      />

      <Paper className={classes.paper}>
        <AppBar position="static">
          <Tabs
            value={currentTab}
            //onChange={handleChange}
            aria-label="wrapped label tabs example"
          >
            {getBeltlevelResult.programs.map((program, index) => {
              return (
                <Tab
                  key={program.programId}
                  value={program.programId}
                  label={program.programName}
                  {...a11yProps(program.programId)}
                  component={Link}
                  wrapped
                  to={`/dashboard/studio/${studioId}/beltLevels?programIdParm=${program.programId}`}
                />
              );
            })}
          </Tabs>
        </AppBar>

        {getBeltlevelResult && getBeltlevelResult.editMode === true ? (
          <GetViewMode
            studioId={studioId}
            getBeltlevelResult={getBeltlevelResult}
            changeViewMode={changeViewMode}
          />
        ) : (
          <>
            <Typography className={classes.marginDiv}>
              <DateRangeIcon /> Belt Level
            </Typography>
            <form
              method="POST"
              noValidate=""
              className={classes.formRoot}
              onSubmit={handleSubmit(submit)}
            >
              <TextField
                label="Belt Name*"
                variant="outlined"
                error={!!errors.beltName}
                helperText={errors?.beltName?.message}
                size="small"
                className={classes.textField}
                {...register("beltName")}
              />
              <TextField
                label="Belt Type*"
                variant="outlined"
                error={!!errors.beltType}
                helperText={errors?.beltType?.message}
                size="small"
                className={classes.textField}
                {...register("beltType")}
              />

              <TextField
                label="Position*"
                variant="outlined"
                error={!!errors.position}
                helperText={errors?.position?.message}
                size="small"
                className={classes.textField}
                type="number"
                {...register("position")}
              />

              <Autocomplete
                id="status"
                //disabled={!checkCreateBatch}
                options={getBeltlevelResult.activeTypes}
                className={classes.formControl}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) =>
                  option.value === value.value
                }
                value={
                  (getBeltlevelResult.activeTypes || []).find(
                    (x) => x.value === status
                  ) || null
                }
                onChange={(event, newValue) => {
                  setValue("status", newValue ? newValue.value : null);
                  trigger("status");
                }}
                renderOption={(option) => (
                  <React.Fragment>{option.name}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status*"
                    variant="outlined"
                    size="small"
                    error={!!errors.status}
                    helperText={errors?.status?.message}
                    inputProps={{
                      ...params.inputProps,
                      //autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />

              <TextField
                label="CertificateLabel*"
                variant="outlined"
                error={!!errors.certificateLabel}
                helperText={errors?.certificateLabel?.message}
                size="small"
                className={classes.textField}
                {...register("certificateLabel")}
              />

              <TextField
                label="Description"
                variant="outlined"
                error={!!errors.description}
                helperText={errors?.description?.message}
                size="small"
                className={classes.textFieldFull}
                multiline
                minRows={3}
                {...register("description")}
              />

              <Box
                className={classes.marginDiv}
                style={{ width: "-webkit-fill-available" }}
              >
                <Typography
                  className={classes.marginDiv}
                  style={{ marginBottom: "8px" }}
                >
                  Belt Icon
                </Typography>

                <Button
                  variant="text"
                  color="primary"
                  size="small"
                  onClick={openIconsDialog}
                >
                  Open icons
                </Button>

                <Grid
                  container
                  spacing={3}
                  alignItems="center"
                  className={classes.gridContainer}
                  style={{ marginTop: theme.spacing(0.5) }}
                >
                  <Grid item xs={3}>
                    <FilePond
                      allowMultiple={false}
                      maxFiles={1}
                      name="beltIcon"
                      maxFileSize={"10MB"}
                      allowDrop={true}
                      allowReorder={true}
                      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                      acceptedFileTypes={["image/*"]}
                      ref={filePondRef}
                      server={{
                        process: {
                          url: env.apiBase + "/api/file/uploadimage",
                          onload: (res) => {
                            setValue("beltIcon", res);
                            trigger("beltIcon");
                          },
                        },
                        fetch: null,
                        revert: null,
                      }}
                      onprocessfiles={(result) => {
                        console.log("result", result);
                      }}
                      onremovefile={() => {
                        setValue("beltIcon", null);
                        trigger("beltIcon");
                      }}
                      onerror={(error) => {
                        console.log("error", error);
                      }}
                      onwarning={(error) => {
                        console.log("error111", error);
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <div style={{ maxWidth: "100px", position: "relative" }}>
                      <img
                        src={`${
                          beltIcon && beltIcon !== ""
                            ? beltIcon.includes("/assets/belts/")
                              ? beltIcon
                              : `${env.resourceBase}/media/uploadimages/${beltIcon}`
                            : "/assets/no-image.png"
                        }`}
                        width="100%"
                        alt="logo"
                      />

                      <div
                        style={{
                          position: "absolute",
                          top: "0px",
                          right: "0px",
                          zIndex: "3",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          filePondRef.current.removeFiles();
                          setValue("beltIcon", null);
                          trigger("beltIcon");
                        }}
                      >
                        <DeleteIcon />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Box>

              <div
                className={classes.buttonContainer}
                style={{ textAlign: "center" }}
              >
                <Button
                  variant="contained"
                  component={Link}
                  size="small"
                  to={`/dashboard/studio/${studioId}/beltLevels?programIdParm=${programIdParm}`}
                  disabled={isSubmitting}
                  //color="primary"
                  //className={classes.button}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginLeft: "1rem" }}
                  //className={classes.button}
                  startIcon={
                    isSubmitting && (
                      <span
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )
                  }
                  type="submit"
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </div>
            </form>
          </>
        )}
      </Paper>
    </div>
  );
}

const useGVModeStyles = makeStyles((theme) => ({
  boxEditMode: {
    backgroundColor: "white",
    padding: theme.spacing(1),
  },
  hr: {
    borderColor: "rgba(0,0,0,.1)",
  },
  viewTitle: {},
  viewContents: {},
}));

function GetViewMode(props) {
  const classes = useGVModeStyles();
  const theme = useTheme();
  const { studioId, getBeltlevelResult, changeViewMode } = props;
  const { beltLevel, programId } = getBeltlevelResult;
  const displayDateUpdated = utils.site.formatDDMMYYYYWithTime(
    new Date(beltLevel.dateUpdated)
  );

  const _beltIcon =
    beltLevel.beltIcon && beltLevel.beltIcon !== ""
      ? beltLevel.beltIcon.includes("/assets/belts/")
        ? beltLevel.beltIcon
        : `${env.resourceBase}/media/uploadimages/${beltLevel.beltIcon}`
      : "/assets/no-image.png";

  return (
    <Box className={classes.boxEditMode}>
      <Grid container spacing={3} alignItems={"center"}>
        <Grid item style={{ flexGrow: "1" }}>
          <Typography>
            <DescriptionIcon /> Belt Lebel Details
          </Typography>
        </Grid>
        <Grid item style={{ textAlign: "right" }}>
          <Box>
            {!beltLevel.isDefault && (
              <Button
                style={{ marginRight: theme.spacing(1) }}
                variant="contained"
                color="primary"
                className={classes.buttonStyle}
                size="small"
                startIcon={<AttachFileIcon />}
                onClick={changeViewMode}
              >
                Edit
              </Button>
            )}

            <Button
              variant="contained"
              color="primary"
              className={classes.buttonStyle2}
              size="small"
              startIcon={<ArrowBackIcon />}
              component={Link}
              to={`/dashboard/studio/${studioId}/beltLevels?programIdParm=${programId}`}
            >
              Back
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Belt Name:
          </Typography>
          <Typography className={classes.viewContents}>
            {beltLevel.beltName}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Belt Type:
          </Typography>
          <Typography className={classes.viewContents}>
            {beltLevel.beltType}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Position:
          </Typography>
          <Typography className={classes.viewContents}>
            {beltLevel.position}
          </Typography>
        </Grid>
      </Grid>

      <hr className={classes.hr} />

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Belt Icon:
          </Typography>
          <div style={{ maxWidth: "80px" }}>
            <img width="100%" src={_beltIcon} alt="belt" />
          </div>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Status:
          </Typography>
          <Typography className={classes.viewContents}>
            {beltLevel.displayStatus}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Is Default:
          </Typography>
          <Typography className={classes.viewContents}>
            {beltLevel.isDefault ? "Yes" : "No"}
          </Typography>
        </Grid>
      </Grid>

      <hr className={classes.hr} />

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={12}>
          <Typography className={classes.viewTitle} variant="caption">
            Certificate Label:
          </Typography>
          <Typography className={classes.viewContents}>
            {beltLevel.certificateLabel}
          </Typography>
        </Grid>
      </Grid>

      <hr className={classes.hr} />

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={12}>
          <Typography className={classes.viewTitle} variant="caption">
            Belt Level Description:
          </Typography>
          <Typography className={classes.viewContents}>
            {beltLevel.description}
          </Typography>
        </Grid>
      </Grid>
      <hr className={classes.hr} />

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={6}>
          <Typography className={classes.viewTitle} variant="caption">
            Last Updated:
          </Typography>
          <Typography className={classes.viewContents}>
            {displayDateUpdated}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.viewTitle} variant="caption">
            Modified By:
          </Typography>
          <Typography className={classes.viewContents}>
            {"Super Admin"}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
