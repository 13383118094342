import utils from "../utils";
import env from "../env";
import { isMin } from "../lib/css-emotion";

export const isMobile = () => {
  // isMin.md() ==> min-width: 768px
  return !isMin.md();
};

export function resourcePath(url) {
  if (!url) return null;
  if (url && url.indexOf("/assets/") === 0) return url;
  return utils.url.isExternalLink(url) ? url : env.resourceBase + url;
}

export function fullUrl(url) {
  return utils.url.isExternalLink(url) ? url : env.baseOrigin + url;
}

export function formatDDMMYYYY(dateObj) {
  const date = dateObj;
  //console.log(dateObj, date)
  if (!date) return "";
  var dd = date.getDate();
  var mm = date.getMonth() + 1; //January is 0!

  var yyyy = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return `${dd}/${mm}/${yyyy}`;
}

export function formatDDMonthTextYYYY(dateObj) {
  const date = dateObj;
  //console.log(dateObj, date)
  if (!date) return "";
  var dd = date.getDate();
  var mm = date.getMonth();
  var mmText = "";

  var yyyy = date.getFullYear().toString().substr(-2);
  
  if (dd < 10) {
    dd = "0" + dd;
  }

  switch (mm) {
    case 0:
      mmText = "Jan";
      break;
    case 1:
      mmText = "Feb";
      break;
    case 2:
      mmText = "Mar";
      break;
    case 3:
      mmText = "Apr";
      break;
    case 4:
      mmText = "May";
      break;
    case 5:
      mmText = "Jun";
      break;
    case 6:
      mmText = "Jul";
      break;
    case 7:
      mmText = "Aug";
      break;
    case 8:
      mmText = "Sep";
      break;
    case 9:
      mmText = "Oct";
      break;
    case 10:
      mmText = "Nov";
      break;
    case 11:
      mmText = "Dec";
      break;
    default:
      break;
  }
  return `${dd}/${mmText}/${yyyy}`;
}

export function formatYYYYMMDD(dateObj) {
  const date = dateObj;
  //console.log(dateObj, date)
  if (!date) return "";
  var dd = date.getDate();
  var mm = date.getMonth() + 1; //January is 0!

  var yyyy = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return `${yyyy}-${mm}-${dd}`;
}

export function formatDDMMYYYYWithTime(dateObj) {
  const date = dateObj;
  //console.log(dateObj, date)
  if (!date) return "";
  var dd = date.getDate();
  var mm = date.getMonth() + 1; //January is 0!

  var yyyy = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return `${dd}/${mm}/${yyyy} ${date.getHours()}:${date.getMinutes()}`;
}

export function GetDayText(dateObj) {
  const date = dateObj;
  if (!date) return "";
  var _day = date.getDay();
  var result = "";
  switch (_day) {
    case 0:
      result = "Sunday";
      break;
    case 1:
      result = "Monday";
      break;
    case 2:
      result = "Tuesday";
      break;
    case 3:
      result = "Wednesday";
      break;
    case 4:
      result = "Thursday";
      break;
    case 5:
      result = "Friday";
      break;
    case 6:
      result = "Saturday";
      break;
    default:
      break;
  }

  return result;
}

// export function toDateTime(dateObj) {
//   if (!dateObj) return null;
//   if (typeof dateObj === "string") {
//     return (
//       parseDate(dateObj, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") ||
//       parseDate(dateObj, "yyyy-MM-dd HH:mm:ss.SSSxxx") ||
//       parseDate(dateObj, "yyyy-M-d HH:mm:ss.SSSxxx") ||
//       parseDate(dateObj, "dd/MM/yyyy HH:mm:ss.SSSxxx") ||
//       parseDate(dateObj, "d/M/yyyy HH:mm:ss.SSSxxx") ||
//       parseISO(dateObj)
//     );
//   } else {
//     return dateObj;
//   }
// }

export function calculateAge(dateOfBirth) {
  var birthYear = dateOfBirth.getFullYear();
  var birthMonth = dateOfBirth.getMonth();
  var birthDay = dateOfBirth.getDate();

  var currentDate = new Date();
  var currentYear = currentDate.getFullYear();
  var currentMonth = currentDate.getMonth();
  var currentDay = currentDate.getDate();

  var age = currentYear - birthYear;
  if (currentMonth < birthMonth) {
    age--;
  } else if (currentMonth === birthMonth && currentDay < birthDay) {
    age--;
  }
  return age;
}

export function getRandomText() {
  let result = "";
  const length = 8;
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export function getFileIcon(file) {
  let _imgUrl = "/assets/no-image.png";
  if (file && file !== "") {
    _imgUrl = `${env.resourceBase}/media/uploadimages/${file}`;
    if (file.includes(".pdf")) {
      _imgUrl = "/assets/pdf-icon.png";
    } else if (
      file.includes(".mp4") ||
      file.includes(".flv") ||
      file.includes(".m4v") ||
      file.includes(".mov")
    ) {
      _imgUrl = "/assets/video.png";
    }
  }
  return _imgUrl;
}

export const isNZ = env.locale === "nz";

export function getYoutubeId(url) {
  url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  return url[2] !== undefined ? url[2].split(/[^0-9a-z_-]/i)[0] : url[0];
}
