import React from "react";
import { variables } from "../../../cssInJs";
import { useStore } from "../../../store";
import { useForm } from "react-hook-form";
import { useParams, Link, useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Loading, ErrorMessage } from "components";
import env from "../../../env";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DatePicker from "react-datepicker";
import clsx from "clsx";
import "react-datepicker/dist/react-datepicker.css";
//import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import utils from "../../../utils";
import { getIdToken } from "auth";
import {
  Button,
  Typography,
  FormHelperText,
  TextField,
  Grid,
  makeStyles,
  Box,
  //Backdrop,
  lighten,
  Toolbar,
  //ButtonGroup,
  //Tooltip,
  TableHead,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  //Checkbox,
} from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DescriptionIcon from "@material-ui/icons/Description";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
  root: {
    //backgroundColor: "white",
    //paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(5),

    "& > *": {
      //margin: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      //paddingLeft: theme.spacing(1),
      //paddingRight: theme.spacing(1),
      //marginLeft: theme.spacing(1),
      width: "-webkit-fill-available",
      //width: "50%",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
    "& .ck-editor__editable": {
      minHeight: "150px",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      //transform: "translate(22px, -6px) scale(0.75)",
    },

    "& .MuiInputBase-input, & .MuiInputLabel-outlined": {
      //fontSize: "14px",
    },
  },
  button: {
    //margin: theme.spacing(1),
    width: "100%",
  },
  buttonStyle2: {
    //margin: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField: {
    //width: "-webkit-fill-available",
    //paddingLeft: theme.spacing(1),
    //paddingRight: theme.spacing(1),
    //marginTop: theme.spacing(1),
    //marginBottom: theme.spacing(1),

    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
  },
  formControl: {
    //margin: theme.spacing(1),
    //minWidth: 120,
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
    //marginTop: theme.spacing(1),
    //marginBottom: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  boxFormControl: {
    width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
  },

  buttonContainer: {
    position: "fixed",
    bottom: "0",
    left: "0",
    backgroundColor: "white",
    width: "calc(100% - 240px)",
    marginLeft: "240px",
    marginTop: "0",
    marginBottom: "0",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  title: {
    //marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  box: {
    backgroundColor: "white",
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
  },
  boxEditMode: {
    backgroundColor: "white",
    padding: theme.spacing(1),
  },

  hr: {
    //paddingTop : '0px',
    //paddingBottom: '0px',
    borderColor: "rgba(0,0,0,.1)",
  },

  viewTitle: {},
  viewContents: {},

  gridContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    //marginTop : '-10px'
  },
  option: {
    fontSize: 14,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    position: "absolute",
  },
  datePicker: {
    margin: theme.spacing(1),
    display: "inline-block",
    width: `calc(50% - ${theme.spacing(2)}px)`,
    "& .react-datepicker-wrapper, & input": {
      width: "100%",
    },
  },

  tableHead: {
    backgroundColor: variables.colorBlue3,
    "& .MuiTableCell-head": {
      color: "white",
    },
    "& .MuiIconButton-label": {
      color: "white",
    },
    "& .MuiTableSortLabel-root:hover": {
      color: "white",
    },
    "& .MuiTableSortLabel-root": {
      lineHeight: "1.1",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: "white",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon":
      {
        color: "white",
      },
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const schema = yup.object().shape({
  programId: yup.string().required("Program is required."),
  termId: yup.string().required("Term is required."),
  startDate: yup.date().required("Start Date is required."),
  endDate: yup.date().required("End Date is required."),
  startTime: yup.string().required("Start Time is required."),
  endTime: yup.string().required("End Time is required."),
  studentLimit: yup.number().required("Student Limit is required."),
  day: yup.string().required("Day is required."),
  description: yup.string(),
});

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, newBatch, removeClasses } = props;
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          //color="inherit"
          //variant="subtitle1"
          component="div"
          style={{ fontSize: "18px" }}
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          //variant="h6"
          id="tableTitle"
          component="div"
          style={{ fontSize: "18px" }}
        >
          Classes
        </Typography>
      )}

      {/* <ButtonGroup size="small" aria-label="small outlined button group">
        <Tooltip title="Remove classes" arrow>
          <Button component="span" disabled={!newBatch} onClick={removeClasses}>
            <DeleteIcon fontSize="small" />
          </Button>
        </Tooltip>
      </ButtonGroup> */}

    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const headCells = [
  {
    id: "Date",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  { id: "Day", numeric: false, disablePadding: false, label: "Day" },
  {
    id: "startTime",
    numeric: false,
    disablePadding: false,
    label: "Start Time",
  },
  { id: "endTime", numeric: false, disablePadding: false, label: "End Time" },
  { id: "studio", numeric: false, disablePadding: false, label: "Studio" },

  {
    id: "program",
    numeric: false,
    disablePadding: false,
    label: "Program",
  },
  { id: "term", numeric: false, disablePadding: false, label: "Term" },
  { id: "created", numeric: false, disablePadding: false, label: "Created" },
  {
    id: "classType",
    numeric: false,
    disablePadding: false,
    label: "Class/Type",
  },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, numSelected, rowCount, newBatch } = props;

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
            disabled={newBatch ? false : true}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export function Batche(props) {
  const { userInfo } = props;
  const classes = useStyles();
  const { dispatch } = useStore();
  const { studioId, batcheId } = useParams();
  const checkCreateBatch = batcheId ? false : true;
  const previousController = React.useRef();
  const [serverErrors, setServerErrors] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [classeItems, setClasseItems] = React.useState([]);

  const [getClassResult, setGetClassResult] = React.useState();
  const history = useHistory();

  const [autoValue, setAutoValue] = React.useState({
    programId: null,
    day: null,
    termId: null,
  });

  const [startDateInfo, setStartDateInfo] = React.useState({
    minDate: new Date(),
    maxDate: new Date(),
    dateDisabled: true,
  });

  const [endDateInfo, setEndDateInfo] = React.useState({
    minDate: new Date(),
    maxDate: new Date(),
    dateDisabled: true,
  });

  const [loading, setLoading] = React.useState(true);
  
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    trigger,
    //reset,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    //defaultValues: { country: ""},
  });

  const {
    programId,
    termId,
    startDate,
    endDate,
    day,
    startTime,
    endTime,
    //studentLimit,
    //description,
  } = getValues();

  const updateClasseItems = () => {
    //const { startDate, endDate, day, termId, startTime, endTime, programId } = getValues();
    const _values = getValues();
    if (
      !_values.startDate ||
      _values.startDate === "" ||
      !_values.endDate ||
      _values.endDate === "" ||
      !_values.day ||
      (!_values.termId && _values.termId === "") ||
      !_values.startTime ||
      _values.startTime === "" ||
      !_values.endTime ||
      _values.endTime === "" ||
      !_values.programId ||
      _values.programId === ""
    ) {
      setClasseItems([]);
      return;
    }
    
    const _startDate = new Date(_values.startDate);
    const _endDate = new Date(_values.endDate);
    const weeks = Math.round(_endDate - _startDate) / (7 * 24 * 60 * 60 * 1000);
    const _day = _values.day;

    const date = new Date(_startDate);
    let checLoop = true;
    
    while (checLoop) {
      date.setDate(date.getDate() + 1);
      if (date.getDay() === _day) {
        checLoop = false;
      }
    }

    if (checkCreateBatch) {
      const _classeItems = [];
      for (let index = 0; index < weeks; index++) {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + index * 7);
        _classeItems.push({
          date: new Date(newDate),
          classType: weeks - 1 === index ? 2 : 1,
          displayClassType: weeks - 1 === index ? "Graduation" : "Class",
          randomId: utils.site.getRandomText(),
          created: "",
        });
      }
      setClasseItems(_classeItems);
    } else {
      for (let index in classeItems) {
        date.setDate(date.getDate() + index * 7);
        classeItems[index].date = new Date(date);
      }
      setClasseItems([...classeItems]);
    }

    

    trigger([
      "startDate",
      "endDate",
      "day",
      "termId",
      "startTime",
      "endTime",
      "programId",
    ]);
  };

  const changeViewMode = () => {
    getClassResult.editMode = false;
    setGetClassResult({ ...getClassResult });
  };

  const inputAutoChange = (event, newValue, id, value) => {
    setValue(id, value);
    trigger(id);

    if (id === "termId") {
      const _termValue = getValues("termId");
      if (_termValue != null) {
        const _term = getClassResult.termList.find(
          (x) => x.termId === _termValue
        );

        const sDate = new Date(_term.startDate);
        const eDate = new Date(_term.endDate);

        startDateInfo.minDate = sDate;
        startDateInfo.maxDate = eDate;
        startDateInfo.dateDisabled = false;
        setStartDateInfo({ ...startDateInfo });

        endDateInfo.minDate = sDate;
        endDateInfo.maxDate = eDate;
        endDateInfo.dateDisabled = false;
        setEndDateInfo({ ...endDateInfo });

        setValue("startDate", sDate);
        setValue("endDate", eDate);
        trigger(["startDate", "endDate"]);
      }
    }

    setAutoValue({
      ...autoValue,
      [id]: newValue,
    });

    //updateClasseItems();
  };

  const handleFilter = () => {
    setFilterOpen(!filterOpen);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = classeItems.map((n) => n.randomId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleChange = (event, randomId) => {
  //   //event.preventDefault();
  //   //event.stopPropagation();
  //   const selectedIndex = selected.indexOf(randomId);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, randomId);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  const onChnageStartDate = (date) => {
    setValue("startDate", date);
    trigger("startDate");
  };

  const onChnageEndDate = (date) => {
    setValue("endDate", date);
    trigger("endDate");
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const removeClasses = () => {
    if (selected && selected.length > 0) {
      selected.forEach((el) => {
        const selectedIndex = classeItems.findIndex((x) => x.randomId === el);
        classeItems.splice(selectedIndex, 1);
      });
      setClasseItems([...classeItems]);
      setSelected([]);
    }
  };

  async function submit(dataModel) {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/classes/saveclasses", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          studioId: studioId,
          classeItems: classeItems,
          batchId: batcheId,
          ...dataModel,
        }),
        ...options,
      });

      if (!res.ok) {
        //throw new Error(`Response status: ${res.status}`);
      }
      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
      } else {
        history.push(`/dashboard/studio/${studioId}/batches`);
      }
    } catch (error) {}
  }

  React.useEffect(() => {
    
    dispatch({
      type: "SET_STATE",
      payload: {
        breadcrumbs: [
          { title: "Dashboard", link: "/dashboard" },
          { title: "Studios", link: "/dashboard/studios" },
          { title: "Batches", link: null },
        ],
      },
    });
    
    
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    const fetchData = async () => {
      try {
        const token = await getIdToken();
        const options = { headers: { "Content-Type": "application/json" } };
        if (token) {
          options.headers["Authorization"] = `Bearer ${token}`;
        }
        const res = await fetch(env.apiBase + "/api/classes/getclass", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            //page: page,
            //rowsPerPage: rowsPerPage,
            //order: order,
            //orderBy: orderBy,
            //...getValues(),
            batcheId: batcheId,
            studioId: studioId,
            userInfo : userInfo
          }),
          ...options,
        });

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }
        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
          setLoading(false);
          return;
        }

        if (data.results && data.results.getClassResult) {
          const _batch = data.results.getClassResult.batch;
          const _programList = data.results.getClassResult.programList;
          const _daytypeList = data.results.getClassResult.daytypeList;
          const _termList = data.results.getClassResult.termList;
          const _classes = data.results.getClassResult.classes;
          let editMode = false;
          let newBatch = true;
          if (_batch) {
            setValue("programId", _batch.programId);
            setValue("termId", _batch.termId);
            setValue("startDate", new Date(_batch.startDate));
            setValue("endDate", new Date(_batch.endDate));
            setValue("startTime", _batch.startTime);
            setValue("endTime", _batch.endTime);
            setValue("studentLimit", _batch.studentLimit);
            setValue("day", _batch.day);
            setValue("description", _batch.description);

            autoValue.programId = _programList.find(
              (x) => x.programId === _batch.programId
            );
            autoValue.day = _daytypeList.find((x) => x.value === _batch.day);
            autoValue.termId = _termList.find(
              (x) => x.termId === _batch.termId
            );

            startDateInfo.minDate = new Date(_batch.startDate);
            startDateInfo.maxDate = new Date(_batch.endDate);
            startDateInfo.dateDisabled = true;

            endDateInfo.minDate = new Date(_batch.startDate);
            endDateInfo.maxDate = new Date(_batch.endDate);
            endDateInfo.dateDisabled = true;

            editMode = true;
            newBatch = false;

            trigger([
              "programId",
              "termId",
              "startDate",
              "endDate",
              "startTime",
              "endTime",
              "studentLimit",
              "day",
              "description",
            ]);

            setAutoValue({ ...autoValue });
            setStartDateInfo({ ...startDateInfo });
            setEndDateInfo({ ...endDateInfo });
            setClasseItems(_classes);
          }
          setGetClassResult({
            editMode: editMode,
            newBatch: newBatch,
            ...data.results.getClassResult,
          });
        }
      } catch (error) {
        console.error(error.message);
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }

      setLoading(false);
    };

    fetchData();

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      //console.log("classeItems", classeItems);
      //trigger(name);
      updateClasseItems();
    });
    return () => subscription.unsubscribe();

    // eslint-disable-next-line
  }, [watch, classeItems]);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  } else if (getClassResult && getClassResult.editMode === true) {
    return (
      <GetViewMode
        classes={classes}
        getClassResult={getClassResult}
        changeViewMode={changeViewMode}
        studioId={studioId}
      />
    );
  }
  return (
    <>
      <Typography>
        <DateRangeIcon /> Batch
      </Typography>

      <form
        method="POST"
        noValidate=""
        className={classes.root}
        onSubmit={handleSubmit(submit)}
      >
        <Box className={classes.box}>
          <Autocomplete
            id="programId"
            disabled={!checkCreateBatch}
            options={getClassResult.programList}
            className={classes.formControl}
            classes={{
              option: classes.option,
            }}
            autoHighlight
            getOptionLabel={(option) => option.programName}
            value={autoValue.programId}
            onChange={(event, newValue) => {
              inputAutoChange(
                event,
                newValue,
                "programId",
                newValue ? newValue.programId : null
              );
            }}
            renderOption={(option) => (
              <React.Fragment>{option.programName}</React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Program*"
                variant="outlined"
                size="small"
                error={!!errors.programId}
                helperText={errors?.programId?.message}
                inputProps={{
                  ...params.inputProps,
                  //autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          ></Autocomplete>

          <Autocomplete
            id="day"
            options={getClassResult.daytypeList}
            className={classes.formControl}
            classes={{
              option: classes.option,
            }}
            autoHighlight
            getOptionLabel={(option) => option.name}
            value={autoValue.day}
            onChange={(event, newValue) => {
              inputAutoChange(
                event,
                newValue,
                "day",
                newValue ? newValue.value : null
              );
            }}
            renderOption={(option) => (
              <React.Fragment>{option.name}</React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Day*"
                variant="outlined"
                size="small"
                error={!!errors.day}
                helperText={errors?.day?.message}
                inputProps={{
                  ...params.inputProps,
                  //autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          ></Autocomplete>

          <div style={{ display: "flex", alignItems: "end" }}>
            <Autocomplete
              id="termId"
              disabled={!checkCreateBatch}
              options={getClassResult.termList}
              className={classes.formControl}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => option.termName}
              getOptionSelected={(option, value) =>
                option.termName === value.termName
              }
              value={autoValue.termId || null}
              onChange={(event, newValue) => {
                inputAutoChange(
                  event,
                  newValue,
                  "termId",
                  newValue ? newValue.termId : null
                );
              }}
              renderOption={(option) => (
                <React.Fragment>{option.termName}</React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Term*"
                  variant="outlined"
                  size="small"
                  error={!!errors.termId}
                  helperText={errors?.termId?.message}
                  inputProps={{
                    ...params.inputProps,
                    //autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            ></Autocomplete>

            <div className={classes.datePicker}>
              <Typography variant="caption">Start Date*</Typography>
              <DatePicker
                selected={startDate}
                onChange={onChnageStartDate}
                showIcon
                isClearable
                dateFormat="dd/MM/yyyy"
                placeholderText={"dd/mm/yyyy"}
                //disabled={startDateInfo.dateDisabled}
                disabled
                minDate={startDateInfo.minDate}
                maxDate={startDateInfo.maxDate}
                toggleCalendarOnIconClick
              />
              {!!errors.startDate && (
                <FormHelperText error id="startDate-error">
                  {errors?.startDate?.message}
                </FormHelperText>
              )}
            </div>
          </div>

          <div style={{ display: "flex", alignItems: "end" }}>
            <div className={classes.datePicker}>
              <Typography variant="caption">End Date*</Typography>
              <DatePicker
                selected={endDate}
                onChange={onChnageEndDate}
                showIcon
                isClearable
                dateFormat="dd/MM/yyyy"
                placeholderText={"dd/mm/yyyy"}
                //disabled={endDateInfo.dateDisabled}
                disabled
                minDate={endDateInfo.minDate}
                maxDate={endDateInfo.maxDate}
                toggleCalendarOnIconClick
              />
              {!!errors.endDate && (
                <FormHelperText error id="endDate-error">
                  {errors?.endDate?.message}
                </FormHelperText>
              )}
            </div>

            <TextField
              //id="outlined-basic"
              label="Student Limit*"
              variant="outlined"
              error={!!errors.studentLimit}
              helperText={errors?.studentLimit?.message}
              size="small"
              className={classes.textField}
              type="number"
              //defaultValue={0}
              {...register("studentLimit")}
            />
          </div>

          <TextField
            //id="date"
            label="Start Time*"
            variant="outlined"
            error={!!errors.startTime}
            helperText={errors?.startTime?.message}
            size="small"
            className={classes.textField}
            type="time"
            style={{ maxWidth: "250px" }}
            //defaultValue="2017-05-24"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={
              {
                //step: 300, // 5 min
              }
            }
            {...register("startTime")}
          />

          <TextField
            //id="date"
            label="End Time*"
            variant="outlined"
            error={!!errors.endTime}
            helperText={errors?.endTime?.message}
            size="small"
            className={classes.textField}
            type="time"
            style={{ maxWidth: "250px" }}
            //defaultValue="2017-05-24"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={
              {
                //step: 300, // 5 min
              }
            }
            {...register("endTime")}
          />

          <TextField
            //id="outlined-basic"
            label="Description"
            variant="outlined"
            error={!!errors.description}
            helperText={errors?.description?.message}
            size="small"
            className={classes.textField}
            multiline
            minRows={3}
            style={{ width: `calc(100% - 16px)` }}
            {...register("description")}
          />
        </Box>

        {classeItems && classeItems.length > 0 && (
          <Box className={classes.box}>
            <EnhancedTableToolbar
              numSelected={selected.length}
              handleFilter={handleFilter}
              newBatch={checkCreateBatch}
              removeClasses={removeClasses}
            />
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="medium"
                //size="small"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={classeItems.length}
                  newBatch={checkCreateBatch}
                />

                <TableBody>
                  {classeItems.map((row, index) => {
                    const isItemSelected = isSelected(row.randomId);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const mapProgram = getClassResult.programList.find(
                      (x) => x.programId === programId
                    );
                    const mapTerm = getClassResult.termList.find(
                      (x) => x.termId === termId
                    );
                    const mapDay = getClassResult.daytypeList.find(
                      (x) => x.value === day
                    );
                    const displayDate = utils.site.formatDDMMYYYY(
                      new Date(row.date)
                    );
                    return (
                      <TableRow
                        hover
                        //onClick={(event) => handleClick(event, row.studioId)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.randomId}
                        selected={isItemSelected}
                      >
                        
                        {/* <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            disabled={!checkCreateBatch}
                            inputProps={{ "aria-labelledby": labelId }}
                            onChange={(event) =>
                              handleChange(event, row.randomId)
                            }
                          />
                        </TableCell> */}
                        
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          //padding="none"
                        >
                          {displayDate}
                        </TableCell>
                        <TableCell align="left">
                          {mapDay ? mapDay.name : "-"}
                        </TableCell>
                        <TableCell align="left">{startTime}</TableCell>
                        <TableCell align="left">{endTime}</TableCell>
                        <TableCell align="left">
                          {getClassResult.studio.studioName}
                        </TableCell>
                        <TableCell align="left">
                          {mapProgram ? mapProgram.programName : "-"}
                        </TableCell>
                        <TableCell align="left">
                          {mapTerm ? mapTerm.termName : "-"}
                        </TableCell>
                        <TableCell align="left">
                          {" "}
                          {row.displayDateCreated &&
                          row.displayDateCreated !== ""
                            ? row.displayDateCreated
                            : "-"}{" "}
                        </TableCell>
                        <TableCell align="left">
                          {row.displayClassType}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}

        <div
          className={classes.buttonContainer}
          style={{ textAlign: "center" }}
        >
          <Button
            variant="contained"
            component={Link}
            size="small"
            to={`/dashboard/studio/${studioId}/batches`}
            disabled={isSubmitting}
            //color="primary"
            //className={classes.button}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: "1rem" }}
            //className={classes.button}
            startIcon={
              isSubmitting && (
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )
            }
            type="submit"
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </div>
      </form>
    </>
  );
}

function GetViewMode(props) {
  const { classes, getClassResult, changeViewMode, studioId } = props;
  const { batch, studio } = getClassResult;
  const displayDate = `${utils.site.formatDDMMYYYY(
    new Date(batch.startDate)
  )} - ${utils.site.formatDDMMYYYY(new Date(batch.endDate))}`;
  return (
    <Box className={classes.boxEditMode}>
      <Grid container spacing={3} alignItems={"center"}>
        <Grid item style={{ flexGrow: "1" }}>
          <Typography>
            <DescriptionIcon /> Batch Details
          </Typography>
        </Grid>
        <Grid item style={{ textAlign: "right" }}>
          <Box>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonStyle2}
              size="small"
              startIcon={<AttachFileIcon />}
              onClick={changeViewMode}
            >
              Edit
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonStyle2}
              size="small"
              startIcon={<ArrowBackIcon />}
              component={Link}
              to={`/dashboard/studio/${studioId}/batches`}
            >
              Back
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={6}>
          <Typography className={classes.viewTitle} variant="caption">
            Batch Name:
          </Typography>
          <Typography className={classes.viewContents}>
            {batch.batchName}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.viewTitle} variant="caption">
            Day:
          </Typography>
          <Typography className={classes.viewContents}>
            {batch.displayDay}
          </Typography>
        </Grid>
      </Grid>
      <hr className={classes.hr} />
      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={6}>
          <Typography className={classes.viewTitle} variant="caption">
            Studio:
          </Typography>
          <Typography className={classes.viewContents}>
            {studio.studioName}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.viewTitle} variant="caption">
            Program:
          </Typography>
          <Typography className={classes.viewContents}>
            {batch.programName}
          </Typography>
        </Grid>
      </Grid>
      <hr className={classes.hr} />

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={6}>
          <Typography className={classes.viewTitle} variant="caption">
            Term:
          </Typography>
          <Typography className={classes.viewContents}>
            {`${batch.termName} (${batch.year})`}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.viewTitle} variant="caption">
            Duration:
          </Typography>
          <Typography className={classes.viewContents}>
            {displayDate}
          </Typography>
        </Grid>
      </Grid>

      <hr className={classes.hr} />

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Start Time:
          </Typography>
          <Typography className={classes.viewContents}>
            {batch.startTime}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            End Time:
          </Typography>
          <Typography className={classes.viewContents}>
            {batch.endTime}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Status:
          </Typography>
          <Typography className={classes.viewContents}>
            {batch.displayStatus}
          </Typography>
        </Grid>
      </Grid>

      <hr className={classes.hr} />

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={6}>
          <Typography className={classes.viewTitle} variant="caption">
            Description:
          </Typography>
          <Typography className={classes.viewContents}>
            {batch.description}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.viewTitle} variant="caption">
            Student Limit:
          </Typography>
          <Typography className={classes.viewContents}>
            {batch.studentLimit}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={12}>
          <Typography className={classes.viewTitle} variant="caption">
            Last Updated:
          </Typography>
          <Typography className={classes.viewContents}>
            {batch.displayUpdate}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
