import React from "react";
import env from "../../../env";
import { useForm } from "react-hook-form";
import { useStore } from "../../../store";
import { variables } from "../../../cssInJs";
import { yupResolver } from "@hookform/resolvers/yup";
import { StudentAppBar } from "./studentAppBar";
import { ErrorMessage, Loading, SnackbarMessageV3 } from "components";
import clsx from "clsx";
import * as yup from "yup";
//import { useTheme } from "@material-ui/core/styles";
import utils from "utils";
import { StudentView } from "./studentView";
import { StudentBottom } from "./studentBottom";
import AddIcon from "@material-ui/icons/Add";
//import FilterListIcon from "@material-ui/icons/FilterList";
//import SearchIcon from "@material-ui/icons/Search";
//import InfoIcon from "@material-ui/icons/Info";
//import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
//import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import CircularProgress from "@material-ui/core/CircularProgress";
//import LensIcon from "@material-ui/icons/Lens";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DatePicker from "react-datepicker";

import {
  //Link,
  //useHistory,
  //useLocation,
  useParams,
} from "react-router-dom";
import {
  Toolbar,
  lighten,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  //Checkbox,
  IconButton,
  Tooltip,
  ButtonGroup,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  //FormControl,
  //InputLabel,
  //OutlinedInput,
  //InputAdornment,
  TextField,
  Backdrop,
  //Dialog,
  //DialogTitle,
  //DialogContent,
  //DialogContentText,
  Box,
  DialogActions,
  Grid,
  FormHelperText,
  //Radio,
  //List,
  //ListItem,
  //ListItemIcon,
  //ListItemText,
  //Backdrop,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    backgroundColor: "transparent",
    //marginBottom: theme.spacing(2),
  },
  gridCon: {
    padding: theme.spacing(3),
    backgroundColor: "white",
    marginTop: theme.spacing(1),
  },
  hr: {
    marginBottom: "0",
    marginTop: theme.spacing(1),
    borderColor: "rgba(0,0,0,.1)",
  },
  typoBox2: {
    marginTop: theme.spacing(4),
  },
  typoTitle2: {
    //color: variables.colorGray1,
    //marginTop: theme.spacing(1),
    fontWeight: 700,
  },
  typoContent2: {},
  list: {
    "& .MuiListItem-gutters": {
      paddingLeft: "0px",
      paddingRight: "0px",
      alignItems: "flex-start",
    },
    "& .MuiListItemIcon-root": {
      marginTop: "5px",
    },
  },
  leftGrid: {
    overflowY: "scroll",
    height: "calc(100vh - 195px)",
  },
  rightGrid: {
    height: "calc(100vh - 290px)",
    overflowY: "auto",
  },
  rightGridBox: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 500,
    color: "#fff",
    //position: "absolute",
  },
  bottom: {
    position: "fixed",
    bottom: "0",
    left: "0",
    backgroundColor: "red",
    width: "62%",
    //width: "calc(100% - 240px)",
    //marginLeft: "240px",
    marginTop: "0",
    marginBottom: "0",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const useRightStyles = makeStyles((theme) => ({
  table: {
    //minWidth: 750,
  },
  tableForTable: {
    "& .MuiTableCell-root": {
      fontSize: "0.75rem",
    },
    "& .MuiButton-root": {
      fontSize: "0.75rem",
    },
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(1),
    paddingLeft: "3px",
    paddingRight: "3px",
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const useETHeadStyles = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: variables.colorBlue3,
    "& .MuiTableCell-head": {
      color: "white",
    },
    "& .MuiIconButton-label": {
      color: "white",
    },
    "& .MuiTableSortLabel-root:hover": {
      color: "white",
    },
    "& .MuiTableSortLabel-root": {
      lineHeight: "1.1",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: "white",
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon":
      {
        color: "white",
      },
  },
}));

const useCoDialogStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textField: {
    //width: `-webkit-fill-available`,
    width: `calc(50% - ${theme.spacing(2)}px)`,
    margin: theme.spacing(1),
  },
  formControl: {
    width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
    margin: theme.spacing(1),
  },
  formControl2: {
    width: `calc(100% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
    margin: theme.spacing(1),
  },
  datePicker: {
    margin: theme.spacing(1),
    display: "inline-block",
    width: `calc(50% - ${theme.spacing(2)}px)`,
    "& .react-datepicker-wrapper, & input": {
      width: "100%",
    },
    "& .react-datepicker-popper": {
      zIndex: 2,
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      overflowY: "unset",
    },
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    //studentId,
    openAddPromotionDialog,
    //currentTab,
    //activeStatus,
    //inActiveStatus,

    //studioId,
  } = props;

  return (
    <Toolbar className={clsx(classes.root)}>
      <Typography
        className={classes.title}
        //variant="h6"
        id="tableTitle"
        component="div"
        style={{ fontSize: "18px" }}
      >
        Historical Promotions
      </Typography>

      <ButtonGroup size="small" aria-label="small outlined button group">
        <Tooltip title="Create" arrow>
          <Button onClick={openAddPromotionDialog}>
            <AddIcon fontSize="small" />
          </Button>
        </Tooltip>
      </ButtonGroup>
    </Toolbar>
  );
};

const headCells = [
  {
    id: "Date",
    numeric: false,
    disablePadding: false,
    label: "Date",
    hasSort: false,
  },
  {
    id: "Program",
    numeric: false,
    disablePadding: false,
    label: "Program",
    hasSort: false,
  },
  {
    id: "NewBeltLevel",
    numeric: false,
    disablePadding: false,
    label: "New Belt Level",
    hasSort: false,
  },
  {
    id: "Actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    hasSort: false,
  },
];

const EnhancedTableHead = (props) => {
  const classes = useETHeadStyles();
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headCells.map((headCell) => {
          if (headCell.hasSort) {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            );
          } else {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
              >
                {headCell.label}
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};

const RightItemView = (props) => {
  const classes = useRightStyles();
  const {
    studentId,
    page,
    rowsPerPage,
    order,
    orderBy,
    handleChangePage,
    historicalPromotions,
    handleChangeRowsPerPage,
    handleRequestSort,
    //student,
    deletePromotion,
    openAddPromotionDialog,
    inputTypeItem,
  } = props;

  return (
    <>
      <EnhancedTableToolbar
        studentId={studentId}
        openAddPromotionDialog={openAddPromotionDialog}
      />

      <TableContainer>
        <Table
          className={clsx(classes.table, {
            [classes.tableForTable]: inputTypeItem === "tablet",
          })}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />

          <TableBody>
            {(historicalPromotions || []).map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;
              const displayDate = utils.site.formatDDMMYYYY(
                new Date(row.dateCreated)
              );

              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.historicalPromotionId}
                >
                  <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    //padding="none"
                  >
                    {displayDate}
                  </TableCell>
                  <TableCell align="left">{row.program}</TableCell>
                  <TableCell align="left">{row.newBeltLevel}</TableCell>

                  <TableCell align="left">
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => {
                        deletePromotion(row.historicalPromotionId);
                      }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={
          historicalPromotions && historicalPromotions.length > 0
            ? historicalPromotions[0].totalCount
            : 0
        }
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

const StudentViewMemo = React.memo((props) => {
  return <StudentView {...props} />;
});

const AddPromotionDialog = (props) => {
  const classes = useCoDialogStyles();
  const {
    openAddPromotionDialog,
    closeAddPromotionDialog,
    //register,
    getValues,
    errors,
    setValue,
    trigger,
    //batches,
    handleSubmit,
    submitAddPromotion,
    isSubmitting,
    programs,
    beltLevels,
    getBeltLevels,
  } = props;

  const { program, newBeltLevel, dateCreated } = getValues();

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      //onClose={handleClose}
      open={openAddPromotionDialog}
      scroll="paper"
      maxWidth="sm"
      fullWidth={true}
      className={classes.dialog}
    >
      <form
        method="POST"
        noValidate=""
        //className={classes.formRoot}
        onSubmit={handleSubmit((dataResult) => submitAddPromotion(dataResult))}
      >
        <DialogTitle disableTypography>
          <Typography variant="h6"> Add Promotion </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={closeAddPromotionDialog}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <div style={{ marginLeft: "-8px", marginRight: "-8px" }}>
            <Autocomplete
              id="program"
              options={programs || []}
              className={classes.formControl}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => option.programName}
              getOptionSelected={(option, value) =>
                option.programId === value.programId
              }
              value={
                (programs || []).find((x) => x.programName === program) || null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setValue("program", newValue ? newValue.programName : null);
                  trigger("program");
                  getBeltLevels(newValue.programId);
                }
              }}
              renderOption={(option) => (
                <React.Fragment>{option.programName}</React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Program *"
                  variant="outlined"
                  size="small"
                  error={!!errors.program}
                  helperText={errors?.program?.message}
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />

            <Autocomplete
              id="newBeltLevel"
              options={beltLevels || []}
              className={classes.formControl}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => option.beltName}
              getOptionSelected={(option, value) =>
                option.beltLevelId === value.beltLevelId
              }
              value={
                (beltLevels || []).find((x) => x.beltName === newBeltLevel) ||
                null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setValue("newBeltLevel", newValue ? newValue.beltName : null);
                  trigger("newBeltLevel");
                }
              }}
              renderOption={(option) => (
                <React.Fragment>{option.beltName}</React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="New Belt*"
                  variant="outlined"
                  size="small"
                  error={!!errors.newBeltLevel}
                  helperText={errors?.newBeltLevel?.message}
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />

            <div className={classes.datePicker}>
              <Typography variant="caption">Date</Typography>
              <DatePicker
                selected={dateCreated}
                onChange={(date) => {
                  setValue("dateCreated", date);
                  trigger("dateCreated");
                }}
                showIcon
                isClearable
                dateFormat="dd/MM/yyyy"
                placeholderText={"dd/mm/yyyy"}
                toggleCalendarOnIconClick
              />
              {!!errors.date && (
                <FormHelperText error id="startDate-error">
                  {errors?.dateCreated?.message}
                </FormHelperText>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            type="submit"
            startIcon={
              isSubmitting && (
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )
            }
            disabled={isSubmitting}
          >
            Submit
          </Button>
          <Button
            color="primary"
            type="button"
            disabled={isSubmitting}
            onClick={closeAddPromotionDialog}
          >
            Close
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const schema = yup.object().shape({
  program: yup.string().required("Program is required."),
  newBeltLevel: yup.string().required("New Belt Level is required."),
  dateCreated: yup.date().required("Date Format is required."),
});

export function StudentHistoricalPromotions(props) {
  const { userInfo, inputType } = props;
  const inputTypeItem = inputType || "normal";
  const classes = useStyles();
  const { studioId, studentId } = useParams();
  const { dispatch } = useStore();
  const previousController = React.useRef();
  const timerController = React.useRef();
  const [apiTrigger, setApiTrigger] = React.useState(0);
  const [getApiResult, setGetApiResult] = React.useState();
  const [loading, setLoading] = React.useState(1);
  const rightGrid = React.useRef(null);
  const [funtionInfo, setFuntionInfo] = React.useState({
    openAddPromotionDialog: false,
    page: 0,
    rowsPerPage: 10,
    order: "desc",
    orderBy: "DateCreated",
  });
  const [errorResult, setErrorResult] = React.useState({
    type: "server",
    errors: [],
  });

  const {
    register,
    getValues,
    setValue,
    trigger,
    reset,
    //clearErrors,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleCloseSnackBar = () => {
    setErrorResult({
      ...errorResult,
      type: "",
      errors: [],
    });
  };

  const handleChangePage = (event, newPage) => {
    setFuntionInfo({
      ...funtionInfo,
      page: newPage,
    });

    if (getApiResult.awards && getApiResult.awards.length > 0) {
      rightGrid.current.scrollTo(0, 0);
      setLoading(2);
      setApiTrigger(apiTrigger + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setFuntionInfo({
      ...funtionInfo,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });

    if (getApiResult.awards && getApiResult.awards.length > 0) {
      rightGrid.current.scrollTo(0, 0);
      setLoading(2);
      setApiTrigger(apiTrigger + 1);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc =
      funtionInfo.orderBy === property && funtionInfo.order === "asc";

    setFuntionInfo({
      ...funtionInfo,
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });

    if (getApiResult.awards && getApiResult.awards.length > 0) {
      setLoading(2);
      setApiTrigger(apiTrigger + 1);
    }
  };

  const closeAddPromotionDialog = () => {
    setFuntionInfo({
      ...funtionInfo,
      openAddPromotionDialog: false,
    });
    if (document.activeElement) {
      document.activeElement.blur();
    }
  };

  const apiCall = async (
    url,
    apiData,
    returnFunction = null
    //retrunErrorFunction = null
  ) => {
    setLoading(2);

    if (timerController.current) {
      clearTimeout(timerController.current);
    }

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + url, {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          ...apiData,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        setErrorResult({
          type: "snackbar",
          errors: data.errors,
        });
        setLoading(0);
        return;
      }

      if (returnFunction) {
        returnFunction(data.results);
        timerController.current = setTimeout(() => {
          setLoading(0);
        }, 800);
      } else {
        setApiTrigger(apiTrigger + 1);
      }
    } catch (error) {
      const _errors = [];
      _errors.push("error : " + error.message);
      setErrorResult({
        type: "server",
        errors: _errors,
      });
      setLoading(0);
      return;
    }
  };

  const openAddPromotionDialog = async () => {
    await apiCall(
      "/api/belt/getaddhistoricalpromotionforstudent",
      {
        studioId: studioId,
        studentId: studentId,
      },
      (result) => {
        reset();
        setFuntionInfo({
          ...funtionInfo,
          openAddPromotionDialog: true,
        });
        setGetApiResult({
          ...getApiResult,
          ...result,
        });
      }
    );
  };

  const getBeltLevels = async (programId) => {
    await apiCall(
      "/api/belt/getonlybeltlevelsv2",
      {
        programId: programId,
      },
      (result) => {
        setGetApiResult({
          ...getApiResult,
          ...result,
        });
      }
    );
  };

  const submitAddPromotion = async (dataModel) => {
    await apiCall(
      "/api/belt/saveaddhistoricalpromotion",
      { ...dataModel, studentId: studentId },
      () => {
        setFuntionInfo({
          ...funtionInfo,
          page: 0,
          openAddPromotionDialog: false,
        });
        setApiTrigger(apiTrigger + 1);
        if (document.activeElement) {
          document.activeElement.blur();
        }
      }
    );
  };

  const deletePromotion = async (historicalPromotionId) => {
    await apiCall(
      "/api/belt/removehistoricalpromotion",
      {
        historicalPromotionId: historicalPromotionId,
      },
      () => {
        setFuntionInfo({
          ...funtionInfo,
          page: 0,
        });
        setApiTrigger(apiTrigger + 1);
      }
    );
  };

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    let timerBackdropOpen = null;

    setFuntionInfo({
      ...funtionInfo,
      page: 0,
      order: "desc",
      orderBy: "DateCreated",
    });

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(
          env.apiBase + "/api/student/getstudenthistoricalpromotions",
          {
            method: "post",
            signal: signal,
            body: JSON.stringify({
              ...funtionInfo,
              studentId: studentId,
            }),
            ...options,
          }
        );

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();
        console.log("data", data);

        if (data.errors && data.errors.length > 0) {
          setErrorResult({
            type: "server",
            errors: data.errors,
          });
          setLoading(0);
          return;
        }

        if (data.results) {
          const { terms } = data.results;

          if (terms) {
            terms.unshift({ termId: null, termName: "All" });
          }

          setGetApiResult({
            ...data.results,
            terms: terms,
          });
        }
      } catch (error) {
        const _errors = [];
        _errors.push("error : " + error.message);
        setErrorResult({
          type: "server",
          errors: _errors,
        });
        setLoading(0);
        return;
      }

      if (loading === 2) {
        timerBackdropOpen = setTimeout(() => {
          setLoading(0);
        }, 800);
      } else {
        setLoading(0);
      }
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [studentId, apiTrigger]);

  React.useEffect(() => {
    dispatch({
      type: "SET_STATE",
      payload: {
        breadcrumbs: [
          { title: "Dashboard", link: "/dashboard" },
          { title: "Students", link: "/dashboard/students" },
          { title: "Details", link: null },
        ],
      },
    });

    // eslint-disable-next-line
  }, []);

  if (loading === 1) {
    return <Loading />;
  } else if (errorResult.type === "server" && errorResult.errors.length > 0) {
    return <ErrorMessage errors={errorResult.errors} />;
  }
  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={loading === 2}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <SnackbarMessageV3
        {...errorResult}
        handleCloseFunction={handleCloseSnackBar}
        severity="error"
      />

      <AddPromotionDialog
        {...funtionInfo}
        {...getApiResult}
        closeAddPromotionDialog={closeAddPromotionDialog}
        register={register}
        getValues={getValues}
        errors={errors}
        setValue={setValue}
        trigger={trigger}
        handleSubmit={handleSubmit}
        submitAddPromotion={submitAddPromotion}
        isSubmitting={isSubmitting}
        getBeltLevels={getBeltLevels}
      />

      <Paper className={classes.paper} elevation={0}>
        <StudentAppBar
          tab={"historicalPromotions"}
          studentId={studentId}
          inputTypeItem={inputTypeItem}
          studioId={studioId}
        />

        <div className={classes.gridCon}>
          <Grid container spacing={3}>
            <Grid item xs={3} className={classes.leftGrid}>
              <StudentViewMemo
                {...getApiResult}
                siblingLink={"/dashboard/promotion-list"}
                setBackdropOpen={setLoading}
              />
            </Grid>
            <Grid item xs={9} className={classes.rightGrid} ref={rightGrid}>
              <Box className={classes.rightGridBox}>
                <RightItemView
                  {...funtionInfo}
                  {...getApiResult}
                  openAddPromotionDialog={openAddPromotionDialog}
                  studentId={studentId}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  handleRequestSort={handleRequestSort}
                  deletePromotion={deletePromotion}
                  inputTypeItem={inputTypeItem}
                />
              </Box>
            </Grid>
          </Grid>
        </div>
        <StudentBottom
          {...getApiResult}
          styleTye="list"
          inputTypeItem={inputTypeItem}
        />
      </Paper>
    </div>
  );
}
