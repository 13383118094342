import React from "react";
import { useParams } from "react-router-dom";
import { ErrorMessage, Loading } from "components";
import env from "../../../env";
import {
  Paper,
  //Box,
  Typography,
  makeStyles,
  //useTheme,
  //Avatar,
  //Grid,
  //Backdrop,
  //Dialog,
  //DialogTitle,
  //IconButton,
  //DialogContent,
  //DialogActions,
  //Button,
  //TextField,
  //List,
  //ListItem,
  //ListItemText,
  //ListItemIcon,
  //Container,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //paddingBottom: theme.spacing(10),
    //position: 'relative'
  },
  paper: {
    width: "100%",
    padding : theme.spacing(2)
    //paddingLeft : theme.spacing(1),
    //paddingRight : theme.spacing(1)
    //marginBottom: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 500,
    color: "#fff",
    //position: "absolute",
  },
}));

export function ProgramForUser(props) {
  const { userInfo } = props;
  const { programId } = useParams();
  const classes = useStyles();
  const [errorResult, setErrorResult] = React.useState({
    type: "server",
    errors: [],
  });
  const [loading, setLoading] = React.useState(1);
  const [getApiResult, setGetApiResult] = React.useState();
  const previousController = React.useRef();
  
  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    let timerBackdropOpen = null;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(
          env.apiBase + "/api/program/getprogramforuser",
          {
            method: "post",
            signal: signal,
            body: JSON.stringify({
              userInfo: userInfo,
              programId: programId,
            }),
            ...options,
          }
        );

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();
        console.log("data", data);

        if (data.errors && data.errors.length > 0) {
          setErrorResult({
            type: "server",
            errors: data.errors,
          });
          setLoading(0);
          return;
        }

        if (data.results) {
          setGetApiResult({
            ...data.results
          });
        }
      } catch (error) {
        const _errors = [];
        _errors.push("error : " + error.message);
        setErrorResult({
          type: "server",
          errors: _errors,
        });
        setLoading(0);
        return;
      }

      if (loading === 2) {
        timerBackdropOpen = setTimeout(() => {
          setLoading(0);
        }, 800);
      } else {
        setLoading(0);
      }
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  if (loading === 1) {
    return <Loading />;
  } else if (errorResult.type === "server" && errorResult.errors.length > 0) {
    return <ErrorMessage errors={errorResult.errors} />;
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <Typography>{getApiResult.program.programDescription}</Typography>
      </Paper>
    </div>
  );
}
