import React from "react";
import { useStore } from "../../../store";
import env from "../../../env";
import { variables } from "../../../cssInJs";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import utils from "../../../utils";
import { ErrorMessage, Loading } from "components";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DescriptionIcon from "@material-ui/icons/Description";
import { getIdToken } from "auth";
import {
  makeStyles,
  Typography,
  Paper,
  Button,
  TextField,
  Box,
  Grid,
  FormControlLabel,
  Checkbox,
  FormControl,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  formRoot: {
    "& > *": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      //paddingLeft: theme.spacing(1),
      //paddingRight: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
    "& .ck-editor": {
      margin: theme.spacing(1),
    },
    "& .ck-editor__editable": {
      minHeight: "150px",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      //transform: "translate(22px, -6px) scale(0.75)",
    },
    "& .MuiInputBase-input, & .MuiInputLabel-outlined": {
      //fontSize: "14px",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
  },
  formControl2: {
    margin: theme.spacing(1),
    width: "-webkit-fill-available",
    display: "inline-flex",
    "& label": {
      marginBottom: "0px",
      marginRight: "0px",
    },
  },
  textField: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
  },

  marginDiv: {
    margin: theme.spacing(1),
    display: "inline-block",
    //width: "-webkit-fill-available",
  },
  option: {
    fontSize: 14,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    //position: "absolute",
  },

  buttonContainer: {
    position: "fixed",
    bottom: "0",
    left: "0",
    backgroundColor: "white",
    width: "calc(100% - 240px)",
    marginLeft: "240px",
    marginTop: "0",
    marginBottom: "0",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },

  datePicker: {
    margin: theme.spacing(1),
    display: "inline-block",
    width: `calc(50% - ${theme.spacing(2)}px)`,
    "& .react-datepicker-wrapper, & input": {
      width: "100%",
    },
  },
  box_criteria: {
    display: "flex",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  title_criteria: {
    flex: "1 1 100%",
  },
  button_criteria: {
    margin: theme.spacing(1),
  },

  box_field: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
  },

  textField_field: {
    //width: "-webkit-fill-available",
    flex: "1 1 100%",
  },

  buttonGroup_field: {
    marginLeft: theme.spacing(1),
    maxHeight: "42.38px",
  },

  filePond_field: {
    marginTop: theme.spacing(1),
  },
}));

const useVModeStyles = makeStyles((theme) => ({
  boxEditMode: {
    backgroundColor: "white",
    padding: theme.spacing(3),
  },
  hr: {
    borderColor: "rgba(0,0,0,.1)",
  },
  buttonStyle: {
    //margin: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  main_title: { marginBottom: theme.spacing(2) },
  viewTitle: {},
  viewContents: {},
  list: {
    "& .MuiListItemIcon-root": {
      minWidth: "20px",
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      width: "10px",
    },
  },
}));

const ViewMode = (props) => {
  const classes = useVModeStyles();
  const { tshirt, changeViewMode, userInfo } = props;
  const lastUpdated = utils.site.formatDDMMYYYYWithTime(
    new Date(tshirt.dateUpdated)
  );

  return (
    <Box className={classes.boxEditMode}>
      <Grid container spacing={3} alignItems={"center"}>
        <Grid item style={{ flexGrow: "1" }}>
          <Typography>
            <DescriptionIcon /> T-shirt
          </Typography>
        </Grid>
        <Grid item style={{ textAlign: "right" }}>
          <Box>
            {tshirt.isDefault == false ||
              (userInfo.memberType === 99 && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonStyle}
                  size="small"
                  startIcon={<AttachFileIcon />}
                  onClick={changeViewMode}
                >
                  Edit
                </Button>
              ))}

            <Button
              variant="contained"
              color="primary"
              className={classes.buttonStyle}
              size="small"
              startIcon={<ArrowBackIcon />}
              component={Link}
              to={`/dashboard/property-metrics/setup-tshirts`}
            >
              Back
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Studio:
          </Typography>
          <Typography className={classes.viewContents}>
            {tshirt.studioName}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Size:
          </Typography>
          <Typography className={classes.viewContents}>
            {tshirt.value}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Units:
          </Typography>
          <Typography className={classes.viewContents}>
            {tshirt.units}
          </Typography>
        </Grid>
      </Grid>

      <hr className={classes.hr} />

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Last Updated:
          </Typography>
          <Typography className={classes.viewContents}>
            {lastUpdated}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Modified By:
          </Typography>
          <Typography className={classes.viewContents}>
            {tshirt.modifiedBy}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const schema = yup.object().shape({
  isDefault: yup.boolean(),
  studioId: yup
    .string()
    .when("isDefault", {
      is: false,
      then: (sch) => sch.required("Studio is required."),
    })
    .when("isDefault", {
      is: true,
      then: (sch) => sch.nullable(true),
    }),
  value: yup.string().required("Value is required."),
  units: yup.string().required("Units is required."),
  status: yup.number().required("Status is required."),
  position: yup.number().required("Position is required."),
});

export function SetupTshirt(props) {
  const { userInfo } = props;
  const { dispatch } = useStore();
  const { tshirtId } = useParams();
  const classes = useStyles();
  const previousController = React.useRef();
  const [serverErrors, setServerErrors] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [autoValue, setAutoValue] = React.useState({
    studio: null,
    status: null,
  });
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    trigger,
    watch,
    //control,
    //reset,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { isDefault: false },
  });

  const { isDefault } = getValues();

  const [getApiResult, setGetApiResult] = React.useState();
  const history = useHistory();

  const inputAutoChange = (event, id, id2, newValue, newValue2) => {
    setValue(id, newValue);
    trigger(id);
    setAutoValue({
      ...autoValue,
      [id2]: newValue2,
    });
    //setBackdropOpen(true);
    //setTrigger(trigger + 1);
  };

  const submit = async (dataModel) => {
    //setServerErrors([]);
    //setBackdropOpen(true);
    
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/tshirt/savesetuptshirt", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          ...dataModel,
          tshirtId: tshirtId,
          userInfo: userInfo,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        //setBackdropOpen(false);
        setServerErrors(data.errors);
      } else {
        history.push(`/dashboard/property-metrics/setup-tshirts`);
        //window.location.reload();
      }
    } catch (error) {
      console.error(error.message);
      serverErrors.push("error : " + error.message);
      setServerErrors([...serverErrors]);
      //setBackdropOpen(false);
    }
  };

  const changeViewMode = () => {
    getApiResult.editMode = false;
    setGetApiResult({ ...getApiResult });
  };

  React.useEffect(() => {
    dispatch({
      type: "SET_STATE",
      payload: {
        breadcrumbs: [
          { title: "Dashboard", link: "/dashboard" },
          { title: "T-Shirts", link: "/dashboard/property-metrics/setup-tshirts" },
          { title: tshirtId ? "Details" : "Create", link: null },
        ],
      },
    });

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    const fetchData = async () => {
      try {
        const token = await getIdToken();
        const options = { headers: { "Content-Type": "application/json" } };
        if (token) {
          options.headers["Authorization"] = `Bearer ${token}`;
        }
        const res = await fetch(env.apiBase + "/api/tshirt/getsetuptshirt", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            tshirtId: tshirtId,
            userInfo: userInfo,
          }),
          ...options,
        });

        if (!res.ok) {
          if (res.status === 401) {
            history.push(`/login`);
            return;
          } else {
            throw new Error(`Response status: ${res.status}`);
          }
        }

        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
          setLoading(false);
          return;
        }

        if (data.results && data.results.getSetupTshirtRe) {
          let editMode = false;
          let newBatch = true;

          const _tshirt = data.results.getSetupTshirtRe.tshirt;
          const _activeTypes = data.results.getSetupTshirtRe.activeTypes;
          const _studios = data.results.getSetupTshirtRe.studios;

          if (_tshirt) {
            editMode = true;
            newBatch = false;
            reset({
              studioId: _tshirt.studioId,
              value: _tshirt.value,
              units: _tshirt.units,
              status: _tshirt.status,
              position: _tshirt.position,
              isDefault: _tshirt.isDefault,
            });

            autoValue.studio = _studios.find(
              (x) => x.studioId === _tshirt.studioId
            );
            autoValue.status = _activeTypes.find(
              (x) => x.value === _tshirt.status
            );

            setAutoValue({ ...autoValue });
          }

          setGetApiResult({
            editMode,
            newBatch,
            ...data.results.getSetupTshirtRe,
          });
        }
      } catch (error) {
        console.error(error.message);
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }
      setLoading(false);
    };

    fetchData();

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "isDefault" || name === "isDefault") {
        setValue("studioId", "");
        trigger(name);
        setAutoValue({
          ...autoValue,
          studio: null,
        });
      }
    });
    return () => subscription.unsubscribe();

    // eslint-disable-next-line
  }, [watch]);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {getApiResult && getApiResult.editMode === true ? (
          <ViewMode
            {...getApiResult}
            changeViewMode={changeViewMode}
            userInfo={userInfo}
          />
        ) : (
          <>
            <Typography className={classes.marginDiv}>
              <DateRangeIcon /> T-shirt
            </Typography>
            <form
              method="POST"
              noValidate=""
              className={classes.formRoot}
              onSubmit={handleSubmit(submit)}
            >
              {userInfo && userInfo.memberType === 99 && (
                <FormControl
                  error={!!errors.isDefault}
                  component="fieldset"
                  className={classes.formControl2}
                  size="small"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        {...register("isDefault")}
                        checked={isDefault}
                      />
                    }
                    className={`${
                      !!errors.isDefault ? "Mui-error" : "Mui-error-non"
                    }`}
                    label="Default"
                  />
                </FormControl>
              )}

              <Autocomplete
                id="studioId"
                disabled={isDefault}
                options={getApiResult.studios}
                className={classes.formControl}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => option.studioName}
                getOptionSelected={(option, value) =>
                  option.studioName === value.studioName
                }
                value={autoValue.studio || null}
                onChange={(event, newValue) => {
                  inputAutoChange(
                    event,
                    "studioId",
                    "studio",
                    newValue ? newValue.studioId : null,
                    newValue
                  );
                }}
                renderOption={(option) => (
                  <React.Fragment>{option.studioName}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Studio*"
                    variant="outlined"
                    size="small"
                    error={!!errors.studioId}
                    helperText={errors?.studioId?.message}
                    inputProps={{
                      ...params.inputProps,
                      //autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />

              <TextField
                //id="outlined-basic"
                label="Value*"
                variant="outlined"
                error={!!errors.value}
                helperText={errors?.value?.message}
                size="small"
                className={classes.textField}
                {...register("value")}
              />

              <TextField
                //id="outlined-basic"
                label="Units*"
                variant="outlined"
                error={!!errors.units}
                helperText={errors?.units?.message}
                size="small"
                className={classes.textField}
                {...register("units")}
              />

              <Autocomplete
                id="status"
                //disabled={!checkCreateBatch}
                options={getApiResult.activeTypes}
                className={classes.formControl}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                value={autoValue.status || null}
                onChange={(event, newValue) => {
                  inputAutoChange(
                    event,
                    "status",
                    "status",
                    newValue ? newValue.value : null,
                    newValue
                  );
                }}
                renderOption={(option) => (
                  <React.Fragment>{option.name}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status*"
                    variant="outlined"
                    size="small"
                    error={!!errors.status}
                    helperText={errors?.status?.message}
                    inputProps={{
                      ...params.inputProps,
                      //autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />

              <TextField
                label="Position*"
                variant="outlined"
                error={!!errors.position}
                helperText={errors?.position?.message}
                size="small"
                className={classes.textField}
                type="number"
                {...register("position")}
              />

              <div
                className={classes.buttonContainer}
                style={{ textAlign: "center" }}
              >
                <Button
                  variant="contained"
                  component={Link}
                  size="small"
                  to={`/dashboard/property-metrics/setup-tshirts`}
                  disabled={isSubmitting}
                  //color="primary"
                  //className={classes.button}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginLeft: "1rem" }}
                  //className={classes.button}
                  startIcon={
                    isSubmitting && (
                      <span
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )
                  }
                  type="submit"
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </div>
            </form>
          </>
        )}
      </Paper>
    </div>
  );
}
