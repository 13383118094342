import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage, Loading } from "components";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
//import { variables } from "../../../cssInJs";
//import { getIdToken } from "auth";
import utils from "utils";
import env from "../../../env";
import * as yup from "yup";
import { useHistory, Link } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
//import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import clsx from "clsx";
import {
  //Paper,
  Box,
  Typography,
  makeStyles,
  useTheme,
  //Avatar,
  Grid,
  //Backdrop,
  //Dialog,
  //DialogTitle,
  //IconButton,
  //DialogContent,
  //DialogActions,
  Button,
  TextField,
  ListItem,
  //ListItemIcon,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //paddingBottom: theme.spacing(10),
    //position: 'relative'
  },
  paper: {
    width: "100%",
    //paddingLeft : theme.spacing(1),
    //paddingRight : theme.spacing(1)
    //marginBottom: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 500,
    color: "#fff",
    //position: "absolute",
  },
  listItem: {
    //borderBottom: `1px solid rgba(0,0,0,.1)`,
    "&.MuiListItem-gutters": {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: "5px",
      paddingBottom: "5px",
      //border : '1px solid black'
    },
  },
  gridContainer: {
    border: "1px solid black",
    //borderRight: "none",
    height: "100%",
  },
  firstGrid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "green",
    color: "white",
  },
  sencondGrid: {
    //paddingLeft: theme.spacing(2),
    padding: theme.spacing(1),
    //display: "flex",
    //flexDirection: "column",
    //justifyContent: "center",
    //alignItems: "center",
  },
  listItemIcon: {
    height: "100%",
    border: "1px solid black",
    borderLeft: "none",
    "& svg": {
      //marginLeft: "auto",
      margin: "auto",
      marginRight: theme.spacing(1),
    },
  },
  statusText: {
    color: "green",
  },
}));

const useSearchPanelStyles = makeStyles((theme) => ({
  textField: {
    //width: "-webkit-fill-available",
    margin: theme.spacing(1),
    width: `calc(33.3% - ${theme.spacing(2)}px)`,
  },
  formControl: {
    //width: `calc(33.3% - ${theme.spacing(2)}px)`,
    width: "-webkit-fill-available",
    display: "inline-flex",
    margin: theme.spacing(1),
  },
}));

const useTitleStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: "5px",
    backgroundColor: "#ebebeb",
  },
}));

const SelectStudentView = (props) => {
  const { students, getValues } = props;
  const { studentId } = getValues();
  const classes = useSearchPanelStyles();

  return (
    <Box mt={1}>
      <Autocomplete
        id="studentId"
        options={students || []}
        className={classes.formControl}
        classes={{
          option: classes.option,
        }}
        autoHighlight
        getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
        value={(students || []).find((x) => x.studentId === studentId) || null}
        getOptionSelected={(option, value) =>
          option.studentId === value.studentId
        }
        onChange={(event, newValue) => {
          //setValue("studioId", newValue ? newValue.studioId : null);
          //trigger("studioId");
          //handleReload();
        }}
        renderOption={(option) => (
          <React.Fragment>{`${option.firstName} ${option.lastName}`}</React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Studio"
            variant="outlined"
            size="small"
            //error={!!errors.term}
            //helperText={errors?.term?.message}
            inputProps={{
              ...params.inputProps,
              //autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
      />
    </Box>
  );
};

const TtitleView = (props) => {
  const { assignClass } = props;
  const classes = useTitleStyles();
  const theme = useTheme();

  return (
    <div className={classes.container}>
      <Typography variant="h6" style={{ flex: "1 1 auto" }}>
        {assignClass.programName}
      </Typography>
      <Button
        color="primary"
        variant="contained"
        //type="submit"
        // startIcon={
        // //   isSubmitting && (
        // //     <span
        // //       className="spinner-grow spinner-grow-sm"
        // //       role="status"
        // //       aria-hidden="true"
        // //     ></span>
        // //   )
        // }
        //disabled={isSubmitting}
      >
        Extra
      </Button>
      <Button
        color="primary"
        variant="contained"
        style={{ marginLeft: theme.spacing(1) }}
        //type="submit"
        // startIcon={
        // //   isSubmitting && (
        // //     <span
        // //       className="spinner-grow spinner-grow-sm"
        // //       role="status"
        // //       aria-hidden="true"
        // //     ></span>
        // //   )
        // }
        //disabled={isSubmitting}
      >
        Private
      </Button>
    </div>
  );
};

const schema = yup.object().shape({
  studentId: yup.string(),
});

export function ClassForUser(props) {
  const { userInfo } = props;
  const classes = useStyles();
  //const theme = useTheme();
  const history = useHistory();
  const [apiTrigger, setApiTrigger] = React.useState(0);
  const [errorResult, setErrorResult] = React.useState({
    type: "server",
    errors: [],
  });
  const [loading, setLoading] = React.useState(1);
  const [getApiResult, setGetApiResult] = React.useState();
  const previousController = React.useRef(null);
  const timerController = React.useRef();

  const {
    register,
    //control: controlAddr,
    reset,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const renderRow = (props) => {
    const { index, style } = props;
    const classItem = getApiResult.classViews[index];
    const displayDate = utils.site.formatDDMonthTextYYYY(
      new Date(classItem.date)
    );
    const seperatedDate = displayDate.split("/");
    const statusText = !classItem.isCommitted ? "New" : "";

    return (
      <ListItem
        button
        style={style}
        key={index}
        className={classes.listItem}
        component={Link}
        to={`/dashboard/class-detail/${classItem.classId}/${classItem.assignClassId}`}
      >
        <Grid container spacing={0} className={classes.gridContainer}>
          <Grid item xs={2} className={classes.firstGrid}>
            <Typography variant="h6">{seperatedDate[0]}</Typography>
            <Typography variant="caption" component="div">
              {`${seperatedDate[1]} - ${seperatedDate[2]}`}
            </Typography>
          </Grid>

          <Grid
            item
            xs={10}
            container
            direction="column"
            justifyContent="space-between"
            className={classes.sencondGrid}
          >
            <Grid item container>
              <Grid item xs={11} container direction="column">
                <Grid item>
                  <Typography variant="subtitle1" component="span">
                    {" "}
                    Type{" "}
                  </Typography>
                  <Typography variant="subtitle1" component="span">
                    {" "}
                    {classItem.displayClassType}{" "}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" component="span">
                    {" "}
                    Time{" "}
                  </Typography>
                  <Typography variant="subtitle1" component="span">
                    {" "}
                    {`${classItem.displayDay}, ${classItem.startTime}`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={1}
                container
                justifyContent="flex-end"
                alignItems="center"
              >
                <ArrowForwardIosIcon fontSize="small" />
              </Grid>
            </Grid>

            <Grid item>
              <Typography
                variant="subtitle1"
                style={{ textAlign: "right" }}
                className={clsx(classes.statusText)}
              >
                {statusText}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
    );
  };

  React.useEffect(() => {
    console.log("Check loop for programforuser");

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;
    let timerBackdropOpen = null;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(env.apiBase + "/api/classes/getclassforuser", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            ...getValues(),
            userInfo: userInfo,
          }),
          ...options,
        });

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();
        console.log("data ----->> ", data);

        if (data.errors && data.errors.length > 0) {
          setErrorResult({
            type: "server",
            errors: data.errors,
          });
          setLoading(0);
          return;
        }

        if (data.results) {
          const { currentStudentId } = data.results;
          if (currentStudentId && currentStudentId !== "") {
            reset({
              studentId: currentStudentId,
            });
          }
          setGetApiResult({
            ...data.results,
          });
        }
      } catch (error) {
        const _errors = [];
        _errors.push("error : " + error.message);
        setErrorResult({
          type: "server",
          errors: _errors,
        });
        setLoading(0);
        return;
      }

      if (loading === 2) {
        timerBackdropOpen = setTimeout(() => {
          setLoading(0);
        }, 800);
      } else {
        setLoading(0);
      }
    };

    fetchData();

    return function cleanup() {
      if (timerBackdropOpen) {
        clearTimeout(timerBackdropOpen);
      }
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [apiTrigger]);
  if (loading === 1) {
    return <Loading />;
  } else if (errorResult.type === "server" && errorResult.errors.length > 0) {
    return <ErrorMessage errors={errorResult.errors} />;
  }
  return (
    <>
      <SelectStudentView {...getApiResult} getValues={getValues} />
      <TtitleView {...getApiResult} />
      {getApiResult.classViews.length > 0 ? (
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              height={height - 160}
              width={width}
              itemSize={110}
              itemCount={getApiResult.classViews.length}
            >
              {renderRow}
            </FixedSizeList>
          )}
        </AutoSizer>
      ) : (
        <AutoSizer></AutoSizer>
      )}
    </>
  );
}
