import React from "react";
import env from "../../../env";
import { variables } from "../../../cssInJs";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
//import clsx from "clsx";
import utils from "../../../utils";
import { ErrorMessage, Loading } from "components";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DescriptionIcon from "@material-ui/icons/Description";
import queryString from "query-string";
import {
  makeStyles,
  Typography,
  Paper,
  Button,
  TextField,
  Box,
  Grid,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@material-ui/core";

registerPlugin(FilePondPluginFileValidateSize);
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginImageResize);
registerPlugin(FilePondPluginImageTransform);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //position: 'relative'
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  formRoot: {
    "& > *": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      //paddingLeft: theme.spacing(1),
      //paddingRight: theme.spacing(1),
      width: "-webkit-fill-available",
    },
    "& .MuiFormControlLabel-root.Mui-error": {
      color: `${variables.colorRed} !important`,
    },
    "& .ck-editor": {
      margin: theme.spacing(1),
    },
    "& .ck-editor__editable": {
      minHeight: "150px",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      //transform: "translate(22px, -6px) scale(0.75)",
    },
    "& .MuiInputBase-input, & .MuiInputLabel-outlined": {
      //fontSize: "14px",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
  },
  textField: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
  },
  textFieldFull: {
    margin: theme.spacing(1),
    width: `-webkit-fill-available`,
  },

  marginDiv: {
    margin: theme.spacing(1),
    display: "inline-block",
    //width: "-webkit-fill-available",
  },
  option: {
    fontSize: 14,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    position: "absolute",
  },

  buttonContainer: {
    position: "fixed",
    bottom: "0",
    left: "0",
    backgroundColor: "white",
    width: "calc(100% - 240px)",
    marginLeft: "240px",
    marginTop: "0",
    marginBottom: "0",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },

  datePicker: {
    margin: theme.spacing(1),
    display: "inline-block",
    width: `calc(50% - ${theme.spacing(2)}px)`,
    "& .react-datepicker-wrapper, & input": {
      width: "100%",
    },
  },
  box_criteria: {
    display: "flex",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  title_criteria: {
    flex: "1 1 100%",
  },
  button_criteria: {
    margin: theme.spacing(1),
  },

  box_field: {
    margin: theme.spacing(1),
    width: `calc(50% - ${theme.spacing(2)}px)`,
    display: "inline-flex",
  },

  textField_field: {
    //width: "-webkit-fill-available",
    flex: "1 1 100%",
  },

  buttonGroup_field: {
    marginLeft: theme.spacing(1),
    maxHeight: "42.38px",
  },

  filePond_field: {
    marginTop: theme.spacing(1),
  },
}));

const useVModeStyles = makeStyles((theme) => ({
  boxEditMode: {
    backgroundColor: "white",
    padding: theme.spacing(3),
  },
  hr: {
    borderColor: "rgba(0,0,0,.1)",
  },
  buttonStyle: {
    //margin: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  main_title: { marginBottom: theme.spacing(2) },
  viewTitle: {},
  viewContents: {},
  list: {
    "& .MuiListItemIcon-root": {
      minWidth: "20px",
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      width: "10px",
    },
  },
}));

const useIconStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const IconsDialog = (props) => {
  const classes = useIconStyles();
  const { iconsDialogOpen, closeIconsDialog, selectIcon } = props;
  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      //onClose={handleClose}
      open={iconsDialogOpen}
      scroll="paper"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">Belt Icons</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={closeIconsDialog}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={3}>
          {variables.beltIcons.map((beltIcon, index) => {
            return (
              <Grid
                key={index}
                item
                xs={3}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  selectIcon(`/assets/belts/${beltIcon.url}`);
                }}
              >
                <img src={`/assets/belts/${beltIcon.url}`} width="100%" />
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const ViewMode = (props) => {
  const classes = useVModeStyles();
  const { beltLevel, changeViewMode } = props;
  const lastUpdated = utils.site.formatDDMMYYYYWithTime(
    new Date(beltLevel.dateUpdated)
  );
  const logoImg =
    beltLevel.beltIcon && beltLevel.beltIcon !== ""
      ? beltLevel.beltIcon.includes("/assets/belts/")
        ? beltLevel.beltIcon
        : `${env.resourceBase}/media/uploadimages/${beltLevel.beltIcon}`
      : "/assets/no-image.png";
  return (
    <Box className={classes.boxEditMode}>
      <Grid container spacing={3} alignItems={"center"}>
        <Grid item style={{ flexGrow: "1" }}>
          <Typography>
            <DescriptionIcon /> Belt Level
          </Typography>
        </Grid>
        <Grid item style={{ textAlign: "right" }}>
          <Box>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonStyle}
              size="small"
              startIcon={<AttachFileIcon />}
              onClick={changeViewMode}
            >
              Edit
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonStyle}
              size="small"
              startIcon={<ArrowBackIcon />}
              component={Link}
              to={`/dashboard/property-metrics/setup-belt-levels`}
            >
              Back
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Belt Name:
          </Typography>
          <Typography className={classes.viewContents}>
            {beltLevel.beltName}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Belt Type:
          </Typography>
          <Typography className={classes.viewContents}>
            {beltLevel.beltType}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Position:
          </Typography>
          <Typography className={classes.viewContents}>
            {beltLevel.position}
          </Typography>
        </Grid>
      </Grid>

      <hr className={classes.hr} />

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Belt Icon:
          </Typography>

          <div style={{ maxWidth: "80px" }}>
            <img width="100%" src={logoImg} alt="belt" />
          </div>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Status:
          </Typography>
          <Typography className={classes.viewContents}>
            {beltLevel.displayStatus}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Is Default:
          </Typography>
          <Typography className={classes.viewContents}>
            {beltLevel.isDefault ? "Yes" : "No"}
          </Typography>
        </Grid>
      </Grid>

      <hr className={classes.hr} />

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={12}>
          <Typography className={classes.viewTitle} variant="caption">
            Certificate Label:
          </Typography>
          <Typography className={classes.viewContents}>
            {beltLevel.certificateLabel}
          </Typography>
        </Grid>
      </Grid>

      <hr className={classes.hr} />

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={12}>
          <Typography className={classes.viewTitle} variant="caption">
            Belt Level Description:
          </Typography>
          <Typography className={classes.viewContents}>
            {beltLevel.description}
          </Typography>
        </Grid>
      </Grid>

      <hr className={classes.hr} />

      <Grid container spacing={3} alignItems={"center"}>
        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Last Updated:
          </Typography>
          <Typography className={classes.viewContents}>
            {lastUpdated}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.viewTitle} variant="caption">
            Modified By:
          </Typography>
          <Typography className={classes.viewContents}>Adrian Adams</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const schema = yup.object().shape({
  programId: yup.string().required("Program is required."),
  beltName: yup.string().required("Belt Name is required."),
  beltType: yup.string().required("Belt Type is required."),
  position: yup.number().required("Position is required."),
  beltIcon: yup.string().nullable(true),
  certificateLabel: yup.string().required("CertificateLabel is required."),
  description: yup.string().nullable(true),
});

export function SetupBeltLevel() {
  const { beltLevelId } = useParams();
  const location = useLocation();
  const theme = useTheme();
  const { programId } = queryString.parse(location.search);
  const classes = useStyles();
  const previousController = React.useRef();
  const [serverErrors, setServerErrors] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [autoValue, setAutoValue] = React.useState({
    program: null,
    status: null,
  });
  const [funtionInfo, setFuntionInfo] = React.useState({
    iconsDialogOpen: false,
  });
  const filePondRef = React.useRef(null);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    trigger,
    //control,
    //reset,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { beltIcon } = getValues();

  const [getApiResult, setGetApiResult] = React.useState();
  const history = useHistory();

  const inputAutoChange = (event, id, id2, newValue, newValue2) => {
    setValue(id, newValue);
    trigger(id);
    setAutoValue({
      ...autoValue,
      [id2]: newValue2,
    });
    //setBackdropOpen(true);
    //setTrigger(trigger + 1);
  };

  const submit = async (dataModel) => {
    //setServerErrors([]);
    //setBackdropOpen(true);

    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    try {
      const options = { headers: { "Content-Type": "application/json" } };
      const res = await fetch(env.apiBase + "/api/belt/savesetuptbeltlevel", {
        method: "post",
        signal: signal,
        body: JSON.stringify({
          ...dataModel,
          beltLevelId: beltLevelId,
        }),
        ...options,
      });

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();

      if (data.errors && data.errors.length > 0) {
        //setBackdropOpen(false);
        setServerErrors(data.errors);
      } else {
        history.push(`/dashboard/property-metrics/setup-belt-levels`);
        //window.location.reload();
      }
    } catch (error) {
      console.error(error.message);
      serverErrors.push("error : " + error.message);
      setServerErrors([...serverErrors]);
      //setBackdropOpen(false);
    }
  };

  const openIconsDialog = () => {
    setFuntionInfo({
      ...funtionInfo,
      iconsDialogOpen: true,
    });
  };

  const closeIconsDialog = () => {
    setFuntionInfo({
      ...funtionInfo,
      iconsDialogOpen: false,
    });
  };

  const selectIcon = (selectedIconUrl) => {
    filePondRef.current.removeFiles();
    closeIconsDialog();
    setTimeout(() => {
      setValue("beltIcon", selectedIconUrl);
      trigger("beltIcon");
    }, 100);
  };

  const changeViewMode = () => {
    getApiResult.editMode = false;
    setGetApiResult({ ...getApiResult });
  };

  React.useEffect(() => {
    if (previousController.current) {
      previousController.current.abort();
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    previousController.current = abortController;

    const fetchData = async () => {
      try {
        const options = { headers: { "Content-Type": "application/json" } };
        const res = await fetch(env.apiBase + "/api/belt/getsetuptbeltlevel", {
          method: "post",
          signal: signal,
          body: JSON.stringify({
            beltLevelId: beltLevelId,
          }),
          ...options,
        });

        if (!res.ok) {
          throw new Error(`Response status: ${res.status}`);
        }

        const data = await res.json();

        if (data.errors && data.errors.length > 0) {
          setServerErrors(data.errors);
        }

        if (data.results && data.results.getSeBeltLebelRe) {
          let editMode = false;
          let newBatch = true;

          const _beltLevel = data.results.getSeBeltLebelRe.beltLevel;
          const _activeTypes = data.results.getSeBeltLebelRe.activeTypes;
          const _defaultPrograms =
            data.results.getSeBeltLebelRe.defaultPrograms;

          autoValue.program = _defaultPrograms.find(
            (x) => x.programId === programId
          );

          if (_beltLevel) {
            editMode = true;
            newBatch = false;

            reset({
              programId: _beltLevel.programId,
              beltName: _beltLevel.beltName,
              beltType: _beltLevel.beltType,
              position: _beltLevel.position,
              beltIcon: _beltLevel.beltIcon,
              certificateLabel: _beltLevel.certificateLabel,
              description: _beltLevel.description,
            });

            autoValue.status = _activeTypes.find(
              (x) => x.value === _beltLevel.status
            );

            autoValue.program = _defaultPrograms.find(
              (x) => x.programId === _beltLevel.programId
            );
          } else {
            setValue("programId", programId);
            trigger("programId");
          }

          setAutoValue({ ...autoValue });
          setGetApiResult({
            ...data.results.getSeBeltLebelRe,
            editMode,
            newBatch,
          });
        }
      } catch (error) {
        console.error(error.message);
        serverErrors.push("error : " + error.message);
        setServerErrors([...serverErrors]);
      }
      setLoading(false);
    };

    fetchData();

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loading />;
  } else if (serverErrors && serverErrors.length > 0) {
    return <ErrorMessage errors={serverErrors} />;
  }
  return (
    <div className={classes.root}>
      <IconsDialog
        {...funtionInfo}
        closeIconsDialog={closeIconsDialog}
        selectIcon={selectIcon}
      />
      <Paper className={classes.paper}>
        {getApiResult && getApiResult.editMode === true ? (
          <ViewMode {...getApiResult} changeViewMode={changeViewMode} />
        ) : (
          <>
            <Typography className={classes.marginDiv}>
              <DateRangeIcon /> Belt Level
            </Typography>
            <form
              method="POST"
              noValidate=""
              className={classes.formRoot}
              onSubmit={handleSubmit(submit)}
            >
              <Autocomplete
                id="programId"
                options={getApiResult.defaultPrograms}
                className={classes.formControl}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => option.programName}
                getOptionSelected={(option, value) =>
                  option.programName === value.programName
                }
                value={autoValue.program || null}
                onChange={(event, newValue) => {
                  inputAutoChange(
                    event,
                    "programId",
                    "program",
                    newValue ? newValue.program : null,
                    newValue
                  );
                }}
                renderOption={(option) => (
                  <React.Fragment>{option.programName}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Program*"
                    variant="outlined"
                    size="small"
                    error={!!errors.programId}
                    helperText={errors?.programId?.message}
                    inputProps={{
                      ...params.inputProps,
                      //autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />

              <TextField
                label="Belt Name*"
                variant="outlined"
                error={!!errors.beltName}
                helperText={errors?.beltName?.message}
                size="small"
                className={classes.textField}
                {...register("beltName")}
              />

              <TextField
                label="Belt Type*"
                variant="outlined"
                error={!!errors.beltType}
                helperText={errors?.beltType?.message}
                size="small"
                className={classes.textField}
                {...register("beltType")}
              />

              <TextField
                label="Position*"
                variant="outlined"
                error={!!errors.position}
                helperText={errors?.position?.message}
                size="small"
                className={classes.textField}
                type="number"
                {...register("position")}
              />

              <Autocomplete
                id="status"
                //disabled={!checkCreateBatch}
                options={getApiResult.activeTypes}
                className={classes.formControl}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                value={autoValue.status || null}
                onChange={(event, newValue) => {
                  inputAutoChange(
                    event,
                    "status",
                    "status",
                    newValue ? newValue.value : null,
                    newValue
                  );
                }}
                renderOption={(option) => (
                  <React.Fragment>{option.name}</React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status*"
                    variant="outlined"
                    size="small"
                    error={!!errors.status}
                    helperText={errors?.status?.message}
                    inputProps={{
                      ...params.inputProps,
                      //autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />

              <TextField
                label="CertificateLabel*"
                variant="outlined"
                error={!!errors.certificateLabel}
                helperText={errors?.certificateLabel?.message}
                size="small"
                className={classes.textField}
                {...register("certificateLabel")}
              />

              <TextField
                label="Description"
                variant="outlined"
                error={!!errors.description}
                helperText={errors?.description?.message}
                size="small"
                className={classes.textFieldFull}
                multiline
                minRows={3}
                {...register("description")}
              />

              <Box className={classes.marginDiv}>
                <Typography
                  className={classes.marginDiv}
                  style={{ marginBottom: "8px" }}
                >
                  Belt Icon
                </Typography>
                <Button
                  variant="text"
                  color="primary"
                  size="small"
                  onClick={openIconsDialog}
                >
                  Open icons
                </Button>
                <Grid
                  container
                  spacing={3}
                  alignItems="center"
                  className={classes.gridContainer}
                  style={{ marginTop: theme.spacing(0.5) }}
                >
                  <Grid item xs={3}>
                    <FilePond
                      allowMultiple={false}
                      maxFiles={1}
                      name="beltIcon"
                      maxFileSize={"10MB"}
                      allowDrop={true}
                      allowReorder={true}
                      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                      acceptedFileTypes={["image/*"]}
                      ref={filePondRef}
                      server={{
                        process: {
                          url: env.apiBase + "/api/file/uploadimage",
                          onload: (res) => {
                            setValue("beltIcon", res);
                            trigger("beltIcon");
                          },
                        },
                        fetch: null,
                        revert: null,
                      }}
                      onremovefile={() => {
                        setValue("beltIcon", null);
                        trigger("beltIcon");
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <div style={{ maxWidth: "100px", position: "relative" }}>
                      <img
                        src={`${
                          beltIcon && beltIcon !== ""
                            ? beltIcon.includes("/assets/belts/")
                              ? beltIcon
                              : `${env.resourceBase}/media/uploadimages/${beltIcon}`
                            : "/assets/no-image.png"
                        }`}
                        width="100%"
                        alt="logo"
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: "0px",
                          right: "0px",
                          zIndex: "3",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          filePondRef.current.removeFiles();
                          setValue("beltIcon", null);
                          trigger("beltIcon");
                        }}
                      >
                        <DeleteIcon />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Box>

              <div
                className={classes.buttonContainer}
                style={{ textAlign: "center" }}
              >
                <Button
                  variant="contained"
                  component={Link}
                  size="small"
                  to={`/dashboard/property-metrics/setup-belt-levels`}
                  disabled={isSubmitting}
                  //color="primary"
                  //className={classes.button}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginLeft: "1rem" }}
                  //className={classes.button}
                  startIcon={
                    isSubmitting && (
                      <span
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )
                  }
                  type="submit"
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </div>
            </form>
          </>
        )}
      </Paper>
    </div>
  );
}
