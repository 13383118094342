import React from "react";
import { variables } from "../../../cssInJs";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useTheme } from "@material-ui/core/styles";
import { useAuth } from "auth";
import { useHistory } from "react-router-dom";
import env from "../../../env";
import * as firebase from "firebase";
import {
  Paper,
  //Box,
  Typography,
  makeStyles,
  //Avatar,
  Grid,
  //Backdrop,
  //Dialog,
  //DialogTitle,
  //IconButton,
  //DialogContent,
  //DialogActions,
  //Button,
  //TextField,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingBottom: theme.spacing(10),
    //position: 'relative'
  },
  paper: {
    width: "100%",
    //paddingLeft : theme.spacing(1),
    //paddingRight : theme.spacing(1)
    //marginBottom: theme.spacing(2),
  },
  list: {
    "& a.MuiListItem-root:nth-child(odd)": {
      backgroundColor: variables.colorLightGray,
    },
  },
  listItemIcon: {
    "& svg": {
      marginLeft: "auto",
    },
  },
  firstGridStyle: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    "& .MuiGrid-item": {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
}));

const menuCells = [
  {
    id: "settings",
    label: "Settings",
    link: "",
  },
  {
    id: "newsletters",
    label: "Newsletters",
    link: "/dashboard/newsletters",
  },
  {
    id: "studentJournal",
    label: "Student Journal",
    link: "",
  },
  {
    id: "events",
    label: "Events",
    link: "/dashboard/events",
  },
  {
    id: "programs",
    label: "Programs",
    link: "/dashboard/programs",
  },
  {
    id: "instructor",
    label: "Instructor / Staff List Bio's",
    link: "",
  },
  {
    id: "howtoFitBadges",
    label: "How to Fit Badges",
    link: "",
  },
  {
    id: "parentTips",
    label: "Parent Tips",
    link: "",
  },
  {
    id: "student Manual",
    label: "Student Manual",
    link: "/dashboard/studentmanuals",
  },
  {
    id: "terminology",
    label: "Terminology",
    link: "/dashboard/terminologies",
  },
  {
    id: "birthdayParties",
    label: "Birthday Parties",
    link: "",
  },
  {
    id: "beltTyingVideo",
    label: "Belt Tying Video",
    link: "/dashboard/belttyings",
  },
  {
    id: "referral Form",
    label: "Referral Form",
    link: "/dashboard/referral-form",
  },
  {
    id: "tutorials",
    label: "Tutorials",
    link: "",
  },
  {
    id: "termsConditions",
    label: "Terms & Conditions",
    link: "",
  },
];

export function MoreMenuForUser(props) {
  const { userInfo } = props;
  const classes = useStyles();
  const theme = useTheme();
  const auth = useAuth();
  const history = useHistory();

  // const signout = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();

  //   auth
  //     .signout()
  //     .then((user) => {
  //       history.push("/member/login");
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const signout = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const user = await auth.signout();
      const options = {
        headers: { "Content-Type": "application/json" },
      };
      let currentToken = null;
      try{
        const messaging = firebase.messaging();
        currentToken = await messaging.getToken({
          vapidKey: env.publicVapIdKey,
        });
      }
      catch(error){
        currentToken = null;
      }

      const res = await fetch(
        env.apiBase + "/api/userprofile/removepushnotificationtoken",
        {
          method: "post",
          //signal: signal,
          body: JSON.stringify({
            token : currentToken
          }),
          ...options,
        }
      );

      if (!res.ok) {
        throw new Error(`Response status: ${res.status}`);
      }

      const data = await res.json();
      if (data.errors && data.errors.length > 0) {
        throw new Error(`Response status: ${data.errors[0].errorMessage}`);
      }
      history.push("/member/login");
    } catch (error) {
      console.log(error);
    }

    // auth
    //   .signout()
    //   .then((user) => {
    //     history.push("/member/login");
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <List
          component="nav"
          aria-label="main mailbox folders"
          className={classes.list}
        >
          {menuCells.map((menuCell, index) => {
            return (
              <ListItem
                key={menuCell.id}
                button
                component={Link}
                to={menuCell.link}
              >
                <ListItemText primary={menuCell.label} />
                <ListItemIcon className={classes.listItemIcon}>
                  <ArrowForwardIosIcon fontSize="small" />
                </ListItemIcon>
              </ListItem>
            );
          })}
        </List>
        {/* <Box>
          <Typography>
            Contact Us
          </Typography>
        </Box> */}

        <Grid container spacing={0} className={classes.firstGridStyle}>
          <Grid item xs={6}>
            <Typography>Contact Us</Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <PhoneIcon fontSize="small" />
            <EmailIcon
              fontSize="small"
              style={{ marginLeft: theme.spacing(3) }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.firstGridStyle}
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography> Social Links </Typography>
          </Grid>
          <Grid item xs={6}>
            <FacebookIcon style={{ fontSize: "50px" }} />
            <InstagramIcon
              style={{ fontSize: "50px", marginLeft: theme.spacing(2) }}
            />
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <img src="/assets/logos/logo.jpg" alt="logo" width="100px" />
          </Grid>
        </Grid>

        <Typography
          style={{ padding: theme.spacing(2), cursor: "pointer" }}
          onClick={(e) => {
            signout(e);
          }}
        >
          Logout <ExitToAppIcon style={{ fontSize: "40px" }} />
        </Typography>
      </Paper>
    </div>
  );
}
